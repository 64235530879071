import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
    container: {
        width: "100%",
        border: "0px solid rgba(255, 255, 255, 1)",
        padding: "16px",
        borderRadius: "4px"
    },
    line: {
        width: "98%",
        height: "55px",
        alignSelf: "center"
    }
};
class ReportTableShimmer extends Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.container}>
                    <Shimmer>
                        <div className={classes.line} />
                        <br />
                        <div className={classes.line} />
                        <br />
                        <div className={classes.line} />
                        <br />
                    </Shimmer>
                </div>
            </>
        );
    }
}

export default injectSheet(StyleSheet)(ReportTableShimmer);
