import React, { useRef, useState } from "react";
import UseOutsideAlerter from "./OnBlurHooks";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Tooltip,
} from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

const CustomInputDropDown = (props) => {
  const {
    newLastFiveTimeEntryList,
    setIsProjectWorkTitle,
    setProjectWorkTitle,
    setcurrentTimeEntry,
    currentTimeEntry,
    setIsEditing,
    projectWorkTitle,
    debouncedChangeTitleHandler,
    onchangesProjectAndTitle,
    isActive,
    isPaused,
    isprojectWorkTitle,
    doHandleStart,
    AddNewTimeEntry,
    setProjectId,
    workTitle,
    setWorkTitle,
    newProjectList,
    timeEntriesData,
    timer,
    remark,
    setRemark,
    onchangesRemak,
    isUserDetails,
    newJiraTimeEntryList,
    showJiraTickets,
    doHandleClickOnFetchLatestTimeEntries,
    isFetchLatestJiraTimeEntries
  } = props;

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isRemarkData, setIsRemarkData] = useState(remark)
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isSelectedTitle, setIsSelectedTitle] = useState([]);
  const wrapperRef = useRef(null);
  const wrapperModalRef = useRef(null);
  UseOutsideAlerter(wrapperRef, setIsDropDownOpen, isDropDownOpen);
  UseOutsideAlerter(wrapperModalRef, setDropDownOpen, dropDownOpen);

  const accessibleNotesUserIds = [237, 313, 342, 305, 283, 288, 226, 336, 219];
  const isNotesAccessible = (givenUserId) => accessibleNotesUserIds?.includes(parseInt(givenUserId))

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setRemark(data);
  };

  const handleSave = () => {
    onchangesRemak(remark, currentTimeEntry)
    setDropDownOpen(false)
  };

  const doHandleOnItemClick = (e, newSelectedItem) => {
    if (AddNewTimeEntry == false) {
      if (!isActive && !isPaused && !props.isStartTime) {
        const playEntryId = {
          title: newSelectedItem.label,
          project: { id: newSelectedItem.value },
        };
        doHandleStart(playEntryId);
      } else {
        setProjectWorkTitle(newSelectedItem.label);
        onchangesProjectAndTitle(
          newSelectedItem.label,
          newSelectedItem.value,
          currentTimeEntry
        );
      }
    } else {
      let doGetProjectData = [];
      newProjectList.map((item, key) => {
        item.options.filter((data, key) => {
          if (newSelectedItem.value == data.id) {
            doGetProjectData.push(data);
          }
        });
      });
      setWorkTitle(newSelectedItem.label);
      setProjectId(doGetProjectData[0]);
    }
    setIsDropDownOpen(false);
    setDropDownOpen(false);
  };

  return (
    <div>
      <div className="t-dropdown-block" style={{ display: "flex", }}>
        <div
          className="t-dropdown-select"
          style={{ display: "flex", marginRight: "2.5rem" }}
          onClick={(e) => {
            if (AddNewTimeEntry == false) {
              if (projectWorkTitle.length == 0 || isSelectedTitle.length > 0) {
                setIsDropDownOpen(true);
              }
              if (projectWorkTitle.length == 0) {
                setIsDropDownOpen(true);
                setIsSelectedTitle([]);
              }
            } else {
            }
          }}
        >
          <input
            type="text"
            className="t-dropdown-input "
            disabled={timeEntriesData?.workType == "meeting"}
            placeholder="What are you working on?"
            value={AddNewTimeEntry == false ? projectWorkTitle : workTitle}
            style={{
              paddingLeft: 15,
            }}
            onChange={(e) => {
              if (AddNewTimeEntry == false) {
                setIsProjectWorkTitle(true);
                setProjectWorkTitle(e.target.value);
                setcurrentTimeEntry({
                  ...currentTimeEntry,
                  title: e.target.value,
                });
                setIsEditing(true);
                if (e.target.value.length == 0) {
                  setIsSelectedTitle([]);
                  setIsDropDownOpen(true);
                } else {
                  setIsDropDownOpen(false);
                }
              } else {
                setWorkTitle(e.target.value);
              }
            }}
            onKeyDown={(e) => {
              if (AddNewTimeEntry == false) {
                if (e.key === "Enter") {
                  if (!isActive && !isPaused && !props.isStartTime) {
                    doHandleStart();
                  }
                }
                if (e.key == "Enter" && isprojectWorkTitle) {
                  debouncedChangeTitleHandler(e.target.value, currentTimeEntry);
                }
                if (e.key === "Escape") {
                  setIsDropDownOpen(false);
                }
              } else {
              }
            }}
            onBlur={(e) => {
              if (AddNewTimeEntry == false) {
                if (e.target.value) {
                  setIsProjectWorkTitle(false);
                  isprojectWorkTitle &&
                    debouncedChangeTitleHandler(
                      e.target.value,
                      currentTimeEntry
                    );
                  setIsEditing(false);
                }
              }
            }}
            onClick={(e) => {
              if (isUserDetails?.userId == "237" || isUserDetails?.userId == 237 || isUserDetails?.userId == "219" || isUserDetails?.userId == 219) {
                let manipulateArr = [];
                newJiraTimeEntryList.map((data, key) => {
                  if (data?.label.toLowerCase()?.includes(e?.target?.value.toLowerCase())) {
                    manipulateArr.push(data);
                  }
                });
                setIsSelectedTitle(manipulateArr);
                setIsDropDownOpen(true);
              }
              else {
                let manipulateArr = [];
                newLastFiveTimeEntryList.map((data, key) => {
                  if (data?.label.toLowerCase()?.includes(e.target.value.toLowerCase())) {
                    manipulateArr.push(data);
                  }
                });
                setIsSelectedTitle(manipulateArr);
                setIsDropDownOpen(true);
              }
            }}
          />
        </div>

        <div style={{ display: 'flex' }}>
          {(parseInt(isUserDetails?.userId) == 237) || (parseInt(isUserDetails?.userId) == 219) &&
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '10px',
              animation: isFetchLatestJiraTimeEntries ? 'rotate 2s linear infinite' : 'none',
              cursor: 'pointer',
            }}
              title="Refresh">
              <RefreshOutlinedIcon
                onClick={() => doHandleClickOnFetchLatestTimeEntries()}
              />
            </div>
          }
          {timer != 0 && (
            <div
              style={{ display: "flex", alignItems: "center", }}
            >
              {isUserDetails && (
                isNotesAccessible(isUserDetails?.userId)

              ) &&
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Add Task Note">
                    <TodayIcon
                      onClick={() => {
                        setDropDownOpen(true)
                        setIsRemarkData(remark)
                      }}
                      style={{
                        margin: "0px 5px 0px 0px",
                        height: "40px",
                        color: "#57646b",
                        cursor: "pointer"
                      }}
                    />
                  </Tooltip>
                </div>
              }
            </div>
          )}
          <div
            ref={wrapperModalRef}
            style={{ width: "60%" }}
            className={
              dropDownOpen ? "t-dropdown-list-remark-open" : "t-dropdown-list-remark-close"
            }>
            <CKEditor
              editor={ClassicEditor}
              data={remark}
              onChange={(event, editor) => handleEditorChange(event, editor)}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                ],
              }}
              onBlur={(e, editor) => {
                if (isRemarkData != remark) {
                  handleSave()
                }
              }}
            />
            {/* <div style={{ margin: "15px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <Button
                onClick={() => handleSave()}
                variant="contained"
                style={{ marginRight: 10, background: "rgba(249, 97, 23, 0.86)", color: "white" }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  handleCancel()
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </div> */}
          </div>
        </div>

        {isSelectedTitle.length > 0 || newLastFiveTimeEntryList.length > 0 ? (
          <div
            ref={wrapperRef}
            className={
              isDropDownOpen ? "t-dropdown-list-open" : "t-dropdown-list-close"
            }
          >
            {isSelectedTitle.length > 0
              ? isSelectedTitle?.map((item, key) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                    className="t-dropdown-item"
                    onClick={(e) => {
                      doHandleOnItemClick(e, item);
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        textOverflow: " ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={item?.label}
                    >
                      {item?.label}
                    </span>
                    <span
                      style={{
                        minWidth: 220,
                      }}
                    >
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "gray",
                          display: "inline-block",
                          textOverflow: " ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {isUserDetails?.userId == "237" || isUserDetails?.userId == 237 || isUserDetails?.userId == "219" || isUserDetails?.userId == 219 ? item?.status : item?.projectCode}
                      </span>
                      <span
                        style={{
                          color: "gray",
                          display: "inline-block",
                          textOverflow: " ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        &nbsp;{item?.projectName}
                      </span>
                    </span>
                  </div>
                );
              })
              : newLastFiveTimeEntryList?.map((item, key) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                    className="t-dropdown-item"
                    onClick={(e) => {
                      doHandleOnItemClick(e, item);
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        textOverflow: " ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.label}
                    </span>
                    <span
                      style={{
                        minWidth: 220,
                      }}
                    >
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "gray",
                          display: "inline-block",
                          textOverflow: " ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {showJiraTickets ? item?.status : item?.projectCode}
                      </span>
                      <span
                        style={{
                          color: "gray",
                          display: "inline-block",
                          textOverflow: " ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        &nbsp;{item?.projectName ?? ""}
                      </span>
                    </span>
                  </div>
                );
              })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CustomInputDropDown;
