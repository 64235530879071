import styled from "styled-components";

const DashboardWrapper = styled.div`
    .date-picker-block {
    .roe-card-style {
      height: 100%;
    }
  }
  
  .react-datepicker__day-name, .react-datepicker__day{
    // margin: 8px !important;
    font-size: 14px !important;
    // font-weight: bold !important;
    outline: none !important;
  }
  .react-datepicker-popper {
    border-radius: 10px !important;
    box-shadow: 0px 0px 15px 1px #0000001a !important;
  }
  .react-datepicker {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: 0px solid !important;
    display: flow-root !important;
    // min-width: 330px !important;
    position:relative !important;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__triangle{
    display: none !important;
  }
 
  .react-datepicker__month-container{
    // border-bottom: 1px solid #EBEBEB!important; 
    min-height:250px !important;
  }
  .custom-datepicker {
    color: black !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    border: 1px solid #ddd !important;
    padding: 8px;
    width: 100%;
    &:focus {
      border: 1px solid #9a9a9a !important;
      box-shadow: none !important;
      outline: 0;
    }
  }
  .custom-calender-class .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name{
    color: red !important;
  }

    @media only screen and (max-width: 1650px) {
      .custom-calender-class{
        position:absolute
      }
  }

 
  .react-datepicker__month-container{
    // border-bottom: 1px solid #EBEBEB!important; 
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    right: 90px !important;
    top:20px !important;
    border-left-color: #808080 !important;
  } 
  .react-datepicker__navigation--previous{
    left: 25px !important;
    top:20px !important;
    border-right-color: #808080 !important;
  }
    .react-datepicker__navigation--next{
    right: 25px !important;
    top:20px !important;
    border-left-color: #808080 !important;
  }
  
  

  .custom-calender-class {
    display: -webkit-inline-flex;
    .react-datepicker__triangle { 
      border-bottom-color: rgba(0, 0, 0, 0.035);
    }

    .react-datepicker__header {
      padding-top: 15px !important;
      background: rgba(255, 255, 255) !important;
      border-bottom: none !important;
      .react-datepicker__current-month {
        color: rgba(0, 0, 0, 0.87) !important;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }
    }

    .react-datepicker__month{
      margin: 5px 15px  !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
      background-color:  #f4590d
    }
  }

   .react-datepicker__day:hover,  .react-datepicker__month-text:hover,  .react-datepicker__quarter-text:hover{
    background-color: #f0f0f0 !important;
    color:black !important
   }
  
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range{
        background-color: #f0f0f0 !important;
        color:black !important
      }
    } 
     
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{
    background-color:  #f4590d;
    color:white !important
  }

  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover{
    border-radius: 0.3rem;
    background-color: #f0f0f0;
    color: black
  } 
 
  .react-datepicker__dayss{
        display: inline-block; 
        padding-bottom: 3px;
        position: relative;
        text-align: center;   
        border-radius: 0.3rem; 
        color: black
      } 
 
      .react-datepicker__dayss::before {
        transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  background: #4caf50 !important;
  bottom: 3px;
  height: 4px;
  content: '';
  width: 4px;
  left:14px;
      } 
 

  .react-datepicker__holidays{
        display: inline-block; 
        padding-bottom: 3px;
        position: relative;
        text-align: center;   
        border-radius: 0.3rem; 
        color: black
      } 
 
      .react-datepicker__holidays::before {
        transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  background: #0000FF !important;
  bottom: 3px;
  height: 4px;
  content: '';
  width: 4px;
  left:14px;
      } 

      

      

.custom-calendar-class {
  position: relative;
}

.react-datepicker__holidays {
  // background-color: #ffcccc;
  border-radius: 50%;
  color: #ff0000;
  position: relative;
}

/* Tooltip Styles */
.tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 9999;
  pointer-events: none;
}

.day-wrapper {
  position: relative;
}







    .container, .myDatePickerWrapper, .container .react-datepicker-wrapper, .container .react-datepicker__input-container {
      .myDatePicker, .myDatePickerWrapper, .container .react-datepicker-wrapper, .container .react-datepicker__input-container {
        background: red;
        width: 100px;
        & :global .react-datepicker__input-container {
          width: 100px;
        }
      }
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      border-left: 1px solid red !important;
      width: 100px !important;
    }

  }
`;

export default DashboardWrapper;
 