import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Checkbox, Fade, FormControlLabel, FormGroup, Modal, Switch, TextField, Typography, emphasize } from '@material-ui/core'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import DateRangePickerWrapper from 'assets/css/dataRangePicker.style';
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "assets/img/error.png";
import { Dropdown, MultiSelect } from 'react-multi-select-component';
import { close } from "helper/constant.jsx"
import { authenticationService } from "services/authentication";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserScheduletModal = ({ isProjectAdd, employee, openModal, projectList, isProjectUpdate, setOpenModal, selectedDay, setEmployeeData, employeeData, projectUpdateData, myTeamsUserList, newEmployeeData, setNewEmployeeData, getProjectList, filterProjectList, setIsProjectUpdate, setIsprojectAdd, doManipulateArray, setStartFiterDate, startFiterDate, filterEndDate }) => {
  const today = new Date()
  const project_date = selectedDay == " " ? null : new Date(selectedDay);


  const [startDate, setStartDate] = useState(project_date);
  const [endDate, setEndDate] = useState(null);
  const [updataStartDate, setUpdataStartDate] = useState(new Date(projectUpdateData?.startDate));
  const [UpdataEndDate, setUpdataEndDate] = useState(new Date(projectUpdateData?.endDate));
  const [pickerOpen, setPickerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [projectName, setProjectName] = useState()
  const [projectId, setProjectId] = useState(projectUpdateData?.projectId);
  const [response, setResponse] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState({
    name: employee?.name,
    id: employee?.id
  })
  const [selectedVersion, setSelectedVersion] = useState()
  const [selectedtype, setSelectedType] = useState("Allocated")
  const [projectProirity, setProjectProirity] = useState("P1")
  const [projectPercentage, setProjectPercentage] = useState("")
  const [clientName, setClientName] = useState(projectUpdateData?.clientName)
  const [updateClientName, setUpdateClientName] = useState()
  const [isAddLoader, setIsAddLoader] = useState(false);
  const [isUpdateLoader, setIsUpdateLoader] = useState(false);
  const [projectClient, setProjectClient] = useState("");
  const [resourceToPool, setResourceToPool] = useState(projectUpdateData?.resourceToPool ? projectUpdateData?.resourceToPool : false)
  const [asBillable, setAsBillable] = useState(projectUpdateData?.priority == "P0" ? true : projectUpdateData?.isBillable ? projectUpdateData?.isBillable : false)
  const [updateBillable, setUpdateBillable] = useState()
  const [projectVersion, setProjectVersion] = useState()
  const [updateProjectName, setUpdateProjectName] = useState(
    {
      label: projectUpdateData?.projectName, value: projectProirity?.projectId
    }
  )
  const [updateProjectPriority, setUpdateProjectPriority] = useState(
    {
      label: projectUpdateData?.priority,
      value: projectUpdateData?.priority
    }
  )
  const [updateProjectPercentage, setUpdateProjectPercentage] = useState(
    {
      label: projectUpdateData?.percentageAllocation == "" ? "" : projectUpdateData?.percentageAllocation,
      value: projectUpdateData?.percentageAllocation == "" ? "" : projectUpdateData?.percentageAllocation
    }
  )

  const [filterProject, setFilterProject] = useState()
  const [filterProjectCode, setFilterProjectCode] = useState()
  // type
  const [updateProjectType, setUpdateProjectType] = useState(
    {
      label: projectUpdateData?.type,
      value: projectProirity?.type
    }
  )
  const [checkPercentageAllocation, setCheckPercentageAllocation] = useState()
  const [possibilities, setPossibilities] = useState([]);

  useEffect(() => {
    //  setSelectedEmployee(employee?.name)
    // doManipulateArray()
    if (selectedEmployee?.id) {
      handleProjectChange()
    }

    if (endDate != "Invalid date") {
      checkPercentage()
    }

    if (UpdataEndDate != "Invalid Date") {
      checkUpdatePercentage()
    }

    // 
  }, [selectedEmployee, asBillable, startDate, endDate, projectPercentage, updataStartDate, UpdataEndDate, resourceToPool])

  // project list
  const handleProjectChange = async () => {
    try {
      const Response = await authenticationService.fetchFilterProjectList(selectedEmployee && selectedEmployee?.id);
      let doProjectCode = [];
      const Code = Response.data.sort(function (a, b) {
        const nameA = a.projectCode;
        const nameB = b.projectCode;
        return nameA - nameB;
      }).map((item) => {
        // setGetProjectCode(Response?.data?.projectCode)
        const data = item?.projectCode
        doProjectCode.push(data)
      }
      )
      setFilterProjectCode(doProjectCode)
      doProjectCode.push(filterProjectCode)
      setFilterProject(Response?.data)
      if (Response && Response?.data) {
        let doGetProjectData = [];
        const groups = Response?.data?.reduce((groups, game) => {
          const groupsName = game?.groups?.groupName;
          if (!groups[groupsName]) {
            groups[groupsName] = [];
          }
          groups[groupsName].push(game);
          return groups;
        }, {});
        const groupArrays = Object.entries(groups)
          .sort(function (a, b) {
            const nameA = a[0].toUpperCase();
            const nameB = b[0].toUpperCase();
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }
            return 0;
          })
          .map((groupName) => {
            let data = {
              label: groupName[0],
              options: groupName[1]
                .sort(function (a, b) {
                  const nameA = a.projectName.toUpperCase();
                  const nameB = b.projectName.toUpperCase();
                  if (nameA > nameB) {
                    return 1;
                  }
                  if (nameA < nameB) {
                    return -1;
                  }
                  return 0;
                })
                .map((item, key) => {
                  return {
                    id: item.id,
                    name: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    label: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    value: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    client: item?.client?.clientName
                  };
                }),
            };
            doGetProjectData.push(data);
          });
        setFilterProject(doGetProjectData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectVersion = async (id) => {
    try {
      const Response = await authenticationService.fetchProjectVersion(id);
      if (Response) {
        // setProjectVersion(Response?.data?.projectVersion)
        // setMyLeave([...new Set([Response?.data, ...myLeave])]);
        let data = [];
        if (Response && Response.data && Response.data.projectVersion) {
          Response.data.projectVersion.forEach((dropdownData) => {
            if (dropdownData?.isOpen == true) {
              data.push({
                name: 'version',
                label: dropdownData.version,
                value: dropdownData.id,
                client: Response?.data?.client?.clientName,
                id: Response?.data?.id,
              });
            }
          });
        }
        setProjectVersion(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  let errorToast = (errMsg) => {
    toast.error(errMsg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const DoCloseModal = () => {
    setCalendarOpen(false)
    setOpenModal(false)
  }
  const colourStyles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "5px",
        height: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f4590d",
      },
    }),
    control: (base, state) => ({
      ...base,
      zIndex: state.isFocused ? "999" : "1",
      background: "#ffffff",
      color: "#000000",
      fontWeight: "bold",
      padding: 20,
      minHeight: 35,
      fontSize: 16,
      fontWeight: 400,
      cursor: "pointer",
      padding: "0px 8px",
      borderColor: "#0000003b",
      boxShadow: null,
      "&:hover": {
        borderColor: "#0000003b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontWeight: "400",
        color: isDisabled
          ? "black"
          : isSelected
            ? "white"
              ? "white"
              : "black"
            : "black",
        cursor: "pointer",
        backgroundColor: isSelected
          ? "#f96117db"
          : isFocused
            ? "#f961173d"
            : undefined,
        background: isDisabled ? "#f0f0f0" : "",
        ":hover": {
          backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
          backgroundColor: isDisabled ? "" : "#f96117db",
          cursor: isDisabled ? "not-allowed" : "pointer",

        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
        },
      };
    },
  };

  const employeeType = [
    { label: "Allocated", name: "Allocated", value: "Allocated" },
    // { label: "Leave", name: "Leave", value: "Leave" },
    // { label: "Holiday", name: "Holiday", value: "Holiday" },
  ]
  const proirity = [
    { label: "P0", name: "P0", value: "P0" },
    { label: "P1", name: "P1", value: "P1" },
    { label: "P2", name: "P2", value: "P2" },
  ]


  // const percentages = [
  //   { label: "25%", name: "25%", value: "25%", isdisabled: checkPercentageAllocation >= 100 || possibilities.includes(25) ? false:true},
  //   { label: "50%", name: "50%", value: "50%", isdisabled: checkPercentageAllocation > 50 || possibilities.includes(50) ? false:true },
  //   { label: "75%", name: "75%", value: "75%", isdisabled: checkPercentageAllocation >= 50 ||  possibilities.includes(75) ? false:true },
  //   { label: "100%", name: "100%", value: "100%", isdisabled: checkPercentageAllocation >= 25 ||  possibilities.includes(100) ? false:true },
  // ]


  const percentages = [
    { label: "25%", name: "25%", value: "25%", isdisabled: checkPercentageAllocation >= 100 },
    { label: "50%", name: "50%", value: "50%", isdisabled: checkPercentageAllocation > 50 },
    { label: "75%", name: "75%", value: "75%", isdisabled: checkPercentageAllocation >= 50 },
    { label: "100%", name: "100%", value: "100%", isdisabled: checkPercentageAllocation >= 25 },
  ]


  // date-range picker

  const dateRangePickerProps = () => {
    return { isRangerHoverAllowed: true };
  };

  const handleSelect = (date) => {
    //onChange is not fired if selecting same date - workaround to fire doHandleDateChange
    handleDateChange(date);
  };

  const handleDateChange = (date) => {
    if (!startDate || (startDate && endDate)) {
      // Set start date if no start date or both start and end dates are selected
      setStartDate(date);
      setEndDate(null); // Reset end date when selecting a new start date
    } else {
      // Set end date when start date is already selected
      setEndDate(date);
      setCalendarOpen(false); // Close the calendar after selecting end date
    }
  };

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  // team 
  const customUserRenderer = (selected, _options) => {
    return selected.length ? getUser(selected, _options) : "Select Employee";
  };

  const getUser = (selected, _options) => {
    if (selected.length == _options.length) {
      return `All users are selected. (${selected.length})`;
    } else if (selected.length >= 3) {
      return `${selected[0].label}, ${selected[1].label}, (+${selected.length -
        2}) more`;
    }
  };

  const handleUserChange = (selected) => {
    //  setSelectedEmployee(selected)
  };

  let payload = {
    startDate: moment(startDate).format('YYYY-MM-DD'),
    dueDate: moment(endDate).format('YYYY-MM-DD'),
    project: filterProjectList ? filterProjectList?.id : projectId,
    version: selectedVersion,
    // clientName: clientName,
    clientName: projectClient,
    percentageAllocation: projectPercentage,
    isBillable: projectProirity == "P0" ? true : asBillable,
    resourceToPool: resourceToPool,
    type: selectedtype,
    user: employee === " " ? selectedEmployee?.id : employee?.id,
    priority: projectProirity,
  };

  let UpdateProjectData = {
    id: projectUpdateData?.id,
    startDate: moment(updataStartDate).format('YYYY-MM-DD'),
    dueDate: moment(UpdataEndDate).format('YYYY-MM-DD'),
    user: employee === " " ? selectedEmployee?.id : employee?.id,
    priority: updateProjectPriority?.value == undefined ? updateProjectPriority : updateProjectPriority?.value,
    type: selectedtype,
    clientName: projectUpdateData?.clientName,
    percentageAllocation: updateProjectPercentage?.value == undefined ? updateProjectPercentage : updateProjectPercentage?.value,
    isBillable: asBillable,
    resourceToPool: resourceToPool,
    project: projectId,
    version: projectUpdateData?.version
  }

  const checkPercentage = () => {
    const moment = require('moment');
    const checkDate = employee && employee?.projects.filter((pro) => moment(startDate, "YYYY-MM-DD").isSame(moment(pro?.startDate).format("YYYY-MM-DD")) || moment(endDate, "YYYY-MM-DD").isSame(moment(pro?.endDate).format("YYYY-MM-DD")) || moment(payload?.startDate, "YYYY-MM-DD").isSame(moment(pro?.endDate).format("YYYY-MM-DD")) || moment(endDate, "YYYY-MM-DD").isSame(moment(pro?.startDate).format("YYYY-MM-DD")) || moment(startDate, "YYYY-MM-DD").isBetween(moment(pro?.startDate).format("YYYY-MM-DD"), moment(pro?.endDate).format("YYYY-MM-DD")) || moment(endDate, "YYYY-MM-DD").isBetween(moment(pro?.startDate).format("YYYY-MM-DD"), moment(pro?.endDate).format("YYYY-MM-DD")) || moment(pro?.startDate, "YYYY-MM-DD").isBetween(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")) || moment(pro?.endDate, "YYYY-MM-DD").isBetween(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")));
    const sum = checkDate.reduce((accumulator, per) => {
      const achievementsValue = parseInt(per?.percentageAllocation.replace('%', ''), 10);
      return accumulator + achievementsValue;
    }, 0);
    setCheckPercentageAllocation(sum)
  }


  // percentage alloction when update project 
  const checkUpdatePercentage = () => {
    const moment = require('moment');
    const checkDate = employee && employee?.projects.filter((pro) => moment(UpdateProjectData?.startDate, "YYYY-MM-DD").isSame(moment(pro?.startDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.dueDate, "YYYY-MM-DD").isSame(moment(pro?.endDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.startDate, "YYYY-MM-DD").isSame(moment(pro?.endDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.dueDate, "YYYY-MM-DD").isSame(moment(pro?.startDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.startDate, "YYYY-MM-DD").isBetween(moment(pro?.startDate).format("YYYY-MM-DD"), moment(pro?.endDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.dueDate, "YYYY-MM-DD").isBetween(moment(pro?.startDate).format("YYYY-MM-DD"), moment(pro?.endDate).format("YYYY-MM-DD")) || moment(pro?.startDate, "YYYY-MM-DD").isBetween(moment(UpdateProjectData?.startDate).format("YYYY-MM-DD"), moment(UpdateProjectData.dueDate).format("YYYY-MM-DD")) || moment(pro?.endDate, "YYYY-MM-DD").isBetween(moment(UpdateProjectData?.startDate).format("YYYY-MM-DD"), moment(UpdateProjectData?.dueDate).format("YYYY-MM-DD")));
    const filteredCheckDate = checkDate.filter(item => item?.id !== UpdateProjectData?.id);
    const updateSum = filteredCheckDate.reduce((accumulator, per) => {
      const achievementsValue = parseInt(per?.percentageAllocation.replace('%', ''), 10);
      return accumulator + achievementsValue;
    }, 0);
    setCheckPercentageAllocation(updateSum)
  }

  // end.....................

  // try................

  // const checkUpdatePercentage = () => {
  //   // debugger
  //   // console.log(UpdateProjectData,"UpdateProjectData")
  //   const percentages = [25, 50, 75, 100];
  //   const moment = require('moment');
  //   const checkDate = employee && employee?.projects.filter((pro) => moment(UpdateProjectData?.startDate, "YYYY-MM-DD").isSame(moment(pro?.startDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.dueDate, "YYYY-MM-DD").isSame(moment(pro?.endDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.startDate, "YYYY-MM-DD").isSame(moment(pro?.endDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.dueDate, "YYYY-MM-DD").isSame(moment(pro?.startDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.startDate, "YYYY-MM-DD").isBetween(moment(pro?.startDate).format("YYYY-MM-DD"), moment(pro?.endDate).format("YYYY-MM-DD")) || moment(UpdateProjectData?.dueDate, "YYYY-MM-DD").isBetween(moment(pro?.startDate).format("YYYY-MM-DD"), moment(pro?.endDate).format("YYYY-MM-DD")) || moment(pro?.startDate, "YYYY-MM-DD").isBetween(moment(UpdateProjectData?.startDate).format("YYYY-MM-DD"), moment(UpdateProjectData.dueDate).format("YYYY-MM-DD")) || moment(pro?.endDate, "YYYY-MM-DD").isBetween(moment(UpdateProjectData?.startDate).format("YYYY-MM-DD"), moment(UpdateProjectData?.dueDate).format("YYYY-MM-DD")));

  //   const filteredCheckDate = checkDate.filter(item => item?.id !== UpdateProjectData?.id);
  //   // console.log(filteredCheckDate,"filteredCheckDate............")
  //   const updateSum = filteredCheckDate.reduce((accumulator, per) => {
  //     const achievementsValue = parseInt(per?.percentageAllocation.replace('%', ''), 10);
  //     return accumulator + achievementsValue;
  //   }, 0);

  //   //  console.log(updateSum,"update sum..............")
  //   console.log(updateSum,"...........")
  //   setCheckPercentageAllocation(updateSum)

  // // possibality checked 
  // //     const calculatedPossibilities = filteredCheckDate.map(project => {
  // //       const projectPossibilities = [];
  // //       percentages.forEach(percentage => {
  // //         if (parseInt(project?.percentageAllocation.replace('%', ''), 10) + percentage <= 100) {
  // //           projectPossibilities.push(percentage);
  // //         }
  // //       });
  // //       console.log(projectPossibilities,"projectPossibilities 1..........")
  // //       return projectPossibilities;
  // //     });
  // //     let commonPossibilities = [];
  // //     if (calculatedPossibilities.length > 0) {
  // //       commonPossibilities = calculatedPossibilities.reduce((prev, current) => prev.filter(value => current.includes(value)));
  // //     }
  // // // console.log(commonPossibilities,"commonPossibilities 2..............")
  // //     setPossibilities(commonPossibilities);

  // // length checked 

  // const calculatedPossibilities = filteredCheckDate.map(project => {
  //   const projectPossibilities = [];
  //   percentages.forEach(percentage => {
  //     if (parseInt(project?.percentageAllocation.replace('%', ''), 10) + percentage <= 100) {
  //       projectPossibilities.push(percentage);
  //     }
  //   });
  //   console.log(projectPossibilities,"projectPossibilities...........")
  //   return projectPossibilities;
  // });

  // let smallestPossibilities = [];
  // if (calculatedPossibilities.length > 0) {
  //   smallestPossibilities = calculatedPossibilities.reduce((prev, current) => 
  //     prev.length < current.length ? prev : current
  //   );
  // }
  // console.log(smallestPossibilities,"smallestPossibilities...........")
  // setPossibilities(smallestPossibilities);

  // }

  // try end...............

  // API integration for add allocation
  const doAddProject = async () => {
    try {
      setIsAddLoader(true)
      const Response = await authenticationService.addProjectEmployeeSchedul(payload);
      setResponse(Response)

      if (Response.code == 200) {
        setIsAddLoader(false)

        if (moment(payload?.startDate).isSameOrAfter(startFiterDate)) {
          const newEmployeeUpdate = newEmployeeData.find((user) => user?.id === Response?.data?.id);
          if (newEmployeeUpdate) {
            // Check if the 'projects' array exists, if not, initialize it
            if (!newEmployeeUpdate.projects) {
              newEmployeeUpdate.projects = [];
            } else {
              // Remove projects with matching oldResourceId
              newEmployeeUpdate.projects = newEmployeeUpdate.projects.filter(
                (project) => !Response?.data?.project?.oldResourceId?.includes(project?.id)
              );
            }
            // Push the new project to the 'projects' array
            newEmployeeUpdate.projects.push(Response?.data?.project);
            // Update the state with the modified newEmployeeData
            setNewEmployeeData([...newEmployeeData]);
          }
          setOpenModal(!openModal)
          setProjectName("")
          setStartDate("")
          setEndDate("")
          setProjectId("")
          setSelectedEmployee("")
          setIsAddLoader(false)
          // setStartFiterDate(startFiterDate)
        }
        setOpenModal(!openModal)
      }
      else {
        errorToast(Response?.message);
        // setOpenModal(!openModal)
      }
    } catch (error) {
      console.log(error);
      // setStartFiterDate(startFiterDate)
      errorToast("Bad Request");
      // setOpenModal(!openModal)
    }
    //  await setStartFiterDate(startFiterDate)
  }

  // API integration for update the allocation
  const doUpdateProject = async () => {
    try {
      setIsUpdateLoader(true)
      const Response = await authenticationService.updateEmployeeSchedulerProject({ UpdateProjectData });
      setResponse(Response)
      if (Response.code == 200) {
        const updatedEmployeeData = newEmployeeData?.map((user) => {

          if (user.id === Response.data.id) {
            const updatedProjects = user?.projects.map((project) => {
              if (project.id === Response?.data?.project.id) {
                return { ...project, ...Response.data.project };
              } else if (Response?.data?.project?.oldResourceId?.includes(project?.id)) {

                return null;
              }
              return project;
            }).filter(project => project !== null);
            return { ...user, projects: updatedProjects };
          }
          return user;
        });
        setNewEmployeeData(updatedEmployeeData);
        setOpenModal(!openModal)
        setProjectName("")
        setStartDate("")
        setEndDate("")
        setProjectId("")
        setSelectedEmployee("")
        setIsUpdateLoader(false)
      }

      if (Response.code != 200) {
        errorToast(Response?.Error);
        // setOpenModal(!openModal)
        setIsUpdateLoader(false)
      }
    } catch (error) {
      console.log(error)
      errorToast(error);
      // setOpenModal(!openModal)
      setIsUpdateLoader(false)
    }
  }

  // for close the modal 
  const doCloseModal = () => {
    setOpenModal(false)
    setIsProjectUpdate(false)
    setIsprojectAdd(false)
  }

  const isButtonDisabled = payload?.user === undefined ||
    payload?.project === undefined && filterProjectList === undefined ||
    payload?.clientName === undefined ||
    payload?.dueDate === "Invalid date" ||
    payload?.percentageAllocation == "" ||
    payload?.startDate === "Invalid date" || !moment(payload?.startDate).isSameOrAfter(moment(), 'day')

  const isUpdateButtonDisabled = UpdateProjectData?.user === undefined ||
    UpdateProjectData?.project === undefined ||
    UpdateProjectData?.clientName === undefined ||
    UpdateProjectData?.dueDate === "Invalid date" ||

    UpdateProjectData?.startDate === "Invalid date" || checkPercentageAllocation + parseInt(UpdateProjectData?.percentageAllocation.replace('%', ''), 10) > 100;

  return (
    <>
      <Modal
        style={{ opacity: "1", }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => {
          DoCloseModal()
        }}
      >
        <Box in={openModal}>
          <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)', borderRadius: '8px', padding: '10px 39px 36px 39px' }}>
            <Box>
              <div style={{ display: "flex", margin: isProjectUpdate ? `1.1rem` : '1.5rem', justifyContent: "space-between", alignItems: "center" }}>
                <div >
                  <div style={{ fontSize: "20px", fontWeight: 700, marginLeft: "-1.2rem" }} id="transition-modalconst">{!isProjectUpdate ? "Add Project Allocation" : "Update Project Allocation"}</div>
                </div>
                <div onClick={() => doCloseModal()} style={{
                  cursor: 'pointer', justifyContent: "end",
                  display: "flex",
                  marginBottom: "20px"
                }}>
                  <img src={close} alt="" height={20} width={20} />
                </div>
              </div>

              {/* {!isProjectUpdate && !moment(payload?.startDate).isSameOrAfter(moment(), 'day') ? (
                <div style={{ color: "red" }}>Kindly choose a start date that is today or later</div>
              ) : null} */}

              {response && response?.code != 200 ?
                <div style={{ color: "red", display: "flex", maxWidth: "25rem " }}>
                  <div style={{ marginTop: "3px", marginRight: "5px" }}><img src={Error} alt='img' height={18} width={18} /></div><span>{response?.message}</span></div> : ''}
              {checkPercentageAllocation > 100 ? (<div style={{ color: "red", display: "flex", maxWidth: "25rem " }}>
                <div style={{ marginTop: "3px", marginRight: "5px" }}><img src={Error} alt='img' height={18} width={18} /></div><span>
                  A maximum percentage is assigned to this date,please change the Percentage OR Date
                </span></div>) : ("")
              }

              <Box>
                <div className="employee_name" style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      width: "100%",
                      background: "none",
                    }}
                  >
                    <span style={{ fontSize: "14px", color: "#000" }}>Employee:</span>
                    {!isProjectAdd ?
                      <>
                        <Select
                          maxMenuHeight={180}
                          styles={colourStyles}
                          placeholder="Select Employee"
                          label="Select Employee"
                          value={myTeamsUserList?.find((user) => user?.id === selectedEmployee?.id)}
                          onChange={(e) => {
                            setSelectedEmployee(e.value);
                            // handleUserChange (e.value)
                          }}

                          isDisabled={true}
                          options={
                            myTeamsUserList &&
                            myTeamsUserList.sort(function (a, b) {
                              const nameA = a?.label?.toUpperCase();
                              const nameB = b?.label?.toUpperCase();
                              if (nameA > nameB) {
                                return 1;
                              }
                              if (nameA < nameB) {
                                return -1;
                              }
                              return 0;
                            })
                          }
                        // value={selectedEmployee}
                        />
                      </>
                      :
                      <Select
                        maxMenuHeight={180}
                        styles={colourStyles}
                        // isClearable={true}
                        placeholder="Select Employee"
                        onChange={(e) => {
                          setSelectedEmployee({
                            name: e?.label,
                            id: e?.value
                          });
                          // handleUserChange (e.value)
                        }}
                        options={
                          myTeamsUserList &&
                          myTeamsUserList.sort(function (a, b) {
                            const nameA = a?.label?.toUpperCase();
                            const nameB = b?.label?.toUpperCase();
                            if (nameA > nameB) {
                              return 1;
                            }
                            if (nameA < nameB) {
                              return -1;
                            }
                            return 0;
                          })
                        }
                      // value={selectedEmployee}
                      />}
                  </div>
                </div>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <span style={{ fontSize: "14px", color: "#000" }}>Project:</span>
                {filterProjectList == undefined ? (<Select
                  maxMenuHeight={180}
                  styles={colourStyles}
                  placeholder={projectUpdateData == undefined ? "Select Project" : `${projectUpdateData?.projectCode} - ${projectUpdateData?.projectName}`}
                  // value={}
                  onChange={(e) => {
                    setProjectClient(e?.client)
                    setProjectId(e.id);
                    handleProjectVersion(e?.id)
                  }}
                  options={filterProject}
                  // isDisabled={moment(new Date()).isAfter(projectUpdateData?.startDate, 'day') ? true : false}
                  isDisabled={isProjectUpdate ? true : false}
                />) :
                  (<Select
                    maxMenuHeight={180}
                    styles={colourStyles}
                    placeholder={filterProjectList?.label}
                    defaultValue={filterProjectList?.value}
                    onChange={(e) => {
                    }}
                  // options={filterProjectList}
                  // isDisabled={true}
                  />)
                }
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <span style={{ fontSize: "14px", color: "#000" }}>Version:</span>
                {filterProjectList == undefined ? (<Select
                  maxMenuHeight={180}
                  styles={colourStyles}
                  placeholder={projectUpdateData == undefined ? "Select Version" : projectUpdateData?.version}
                  // value={ }
                  onChange={(e) => {
                    setSelectedVersion(e?.label)
                    setProjectClient(e?.client)
                    setProjectId(e.id);
                  }}
                  options={projectVersion}

                // isDisabled={!projectId ? true : false}
                />) :
                  (<Select
                    maxMenuHeight={180}
                    styles={colourStyles}
                    placeholder={filterProjectList?.label}
                    defaultValue={filterProjectList?.value}
                    onChange={(e) => {
                    }}
                  // options={filterProjectList}
                  // isDisabled={true}
                  />)
                }
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <span style={{ fontSize: "14px", color: "#000" }}>Client:</span>
                <Box className='t-dropdown-select' style={{ marginTop: "0px" }}>
                  <input
                    type="text"
                    className="t-dropdown-input"
                    placeholder="Client"
                    label="client"
                    style={{
                      paddingLeft: 18,
                    }}
                    value={isProjectUpdate ? projectUpdateData?.clientName : projectClient == undefined ? "" : projectClient}
                    disabled
                  />
                </Box>
              </Box>

              <Box style={{ display: 'flex', marginTop: "20px" }}>
                <div style={{ display: 'flex' }}>
                  {isProjectUpdate ? (
                    <>
                      <Box>
                        <span style={{ fontSize: "14px", color: "#000" }}>Start Date:</span>
                        <DateRangePickerWrapper {...dateRangePickerProps}>
                          <DatePicker
                            selected={updataStartDate}
                            onChange={(date) => setUpdataStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            dateFormatCalendar='dd-MM-yyyy'
                            selectsStart
                            placeholderText='Start Date'
                            minDate={new Date()}
                          />
                        </DateRangePickerWrapper>
                      </Box>

                      <div style={{ padding: "15px" }}></div>
                      <Box>
                        <span style={{ fontSize: "14px", color: "#000" }}>End Date:</span>
                        <DateRangePickerWrapper {...dateRangePickerProps}>
                          <DatePicker
                            selected={UpdataEndDate}
                            minDate={updataStartDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setUpdataEndDate(date)}
                            selectsEnd
                            placeholderText='End Date'
                          />
                        </DateRangePickerWrapper>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <span style={{ fontSize: "14px", color: "#000" }}>Start Date:</span>
                        <DateRangePickerWrapper {...dateRangePickerProps}>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            selectsStart
                            placeholderText='Start Date'
                            minDate={new Date()}
                          />
                        </DateRangePickerWrapper>
                      </Box>

                      <div style={{ padding: "15px" }}></div>
                      <Box>
                        <span style={{ fontSize: "14px", color: "#000" }}>End Date:</span>
                        <DateRangePickerWrapper {...dateRangePickerProps}>
                          <DatePicker
                            selected={endDate}
                            minDate={startDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            placeholderText='End Date'
                          />
                        </DateRangePickerWrapper>
                      </Box>
                    </>
                  )}
                </div>
              </Box>

              <Box style={{ display: 'flex', justifyContent: "end" }}>
                <Box style={{ width: "50%", }}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox style={{ color: resourceToPool ? '#1f9b23' : '', paddingLeft: "1.5rem" }} />}
                      name={'resourceToPool'}
                      checked={resourceToPool}
                      onChange={(e) => { setResourceToPool(!resourceToPool) }}
                      label="Resource to pool"
                    />
                  </FormGroup>
                </Box>
              </Box>

              {
                isProjectUpdate ? (<Box className="project_proirity" style={{ width: "100%", paddingTop: "0.5rem", marginTop: "0px" }}>
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      alignItems: "center",
                      marginTop: "-10px"
                    }}
                  >
                    <span style={{ fontSize: "14px", color: "#000" }}>Percentage:</span>
                    <Select
                      maxMenuHeight={350}
                      styles={colourStyles}
                      placeholder="Percentage"
                      // value={updateProjectPercentage == "" ? "pp":updateProjectPercentage}   
                      defaultValue={updateProjectPercentage}
                      // defaultValue={}
                      isOptionDisabled={(option) => option.isdisabled}
                      // value={projectUpdateData?.percentageAllocation !== null ? updateProjectPercentage : percentages[0]}
                      onChange={(e) => {
                        setUpdateProjectPercentage(e.value);
                      }}
                      options={percentages}
                    />
                  </div>
                </Box>) : (<Box className="project_proirity" style={{ width: "100%", paddingTop: "0.5rem", marginTop: "15px" }}>
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      // marginLeft: "15px",
                      alignItems: "center",
                      marginTop: "-10px"
                    }}
                  >
                    <span style={{ fontSize: "14px", color: "#000" }}>Percentage:</span>
                    <Select
                      maxMenuHeight={350}
                      styles={colourStyles}
                      placeholder="Percentage"
                      // value={isProjectUpdate ? projectUpdateData?.proirity : proirity[1]}
                      onChange={(e) => {
                        setProjectPercentage(e.value);
                      }}
                      isOptionDisabled={(option) => option.isdisabled}
                      options={percentages}
                    />
                  </div>
                </Box>)
              }

              {/* <Box className="employee_type" style={{ width: "100%", paddingTop: "0.5rem", marginTop: "15px" }}>
                <div
                  style={{
                    display: "grid",
                    width: "100%",
                    marginRight: "35px",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: "14px", color: "#000" }}>Type:</span>

                  <Select
                    maxMenuHeight={350}
                    styles={colourStyles}
                    placeholder="Select type"
                    defaultValue={isProjectUpdate ? updateProjectType : employeeType[0]}
                    onChange={(e) => {
                      setSelectedType(e.value);
                    }}
                    options={employeeType}
                    isDisabled={isProjectUpdate ? true : false}
                  />
                </div>
              </Box> */}
              {
                isProjectUpdate ? (<Box className="project_proirity" style={{ width: "100%", paddingTop: "0.5rem", marginTop: "15px" }}>
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px", color: "#000" }}>Proirity:</span>
                    <Select
                      maxMenuHeight={350}
                      styles={colourStyles}
                      placeholder="project proirity"
                      defaultValue={updateProjectPriority}
                      // value={isProjectUpdate ? projectUpdateData?.proirity : proirity[1]}
                      onChange={(e) => {
                        setUpdateProjectPriority(e.value);
                      }}
                      options={proirity}
                    />
                  </div>
                </Box>) : (<Box className="project_proirity" style={{ width: "100%", paddingTop: "0.5rem", marginTop: "15px" }}>
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      // marginLeft: "15px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px", color: "#000" }}>Proirity:</span>

                    <Select
                      maxMenuHeight={350}
                      styles={colourStyles}
                      placeholder="project proirity"
                      defaultValue={proirity[1]}
                      // value={isProjectUpdate ? projectUpdateData?.proirity : proirity[1]}
                      onChange={(e) => {
                        setProjectProirity(e.value);
                      }}
                      options={proirity}
                    />
                  </div>
                </Box>)
              }

              <FormGroup>
                <FormControlLabel control={<Checkbox style={{ color: asBillable ? '#f4590d' : '' }} />}
                  name={'billable'}
                  checked={asBillable}
                  onChange={(e) => { setAsBillable(!asBillable) }}
                  label="Mark this allocation as billable"
                />
              </FormGroup>

            </Box>
            <Box style={{ display: 'flex', justifyContent: "end", paddingTop: "20px" }}>
              {
                isProjectUpdate ? (

                  isUpdateLoader ? (<div
                    style={{
                      width: 115,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress style={{ width: 35, height: 35 }} />{" "}
                  </div>) : (<Button onClick={() => { doUpdateProject() }} type="submit"
                    variant="contained" style={{ color: 'white', textTransform: "capitalize", background: isUpdateButtonDisabled ? "gray" : "#f4590d", }} disabled={isUpdateButtonDisabled}>
                    Request
                  </Button>)
                ) : (
                  isAddLoader ? (
                    <div
                      style={{
                        width: 115,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress style={{ width: 35, height: 35 }} />{" "}
                    </div>) : (<Button onClick={() => { doAddProject() }}
                      type="submit"
                      variant="contained" style={{ color: 'white', textTransform: "capitalize", background: isButtonDisabled ? "gray" : "#f4590d" }} disabled={isButtonDisabled}>
                      {/* */}
                      Request
                    </Button>)
                )
              }
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UserScheduletModal