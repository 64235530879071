import React, { createContext, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
// import { Drawer } from '@material-ui/core';
/* Drawer */
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

// import Toolbar from '@material-ui/core/Toolbar';
import List from "@material-ui/core/List";
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js';
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import HistoryIcon from "@material-ui/icons/History";
import GroupIcon from "@material-ui/icons/Group";
import EventNoteIcon from "@material-ui/icons/EventNote";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { authenticationService } from "../../src/services/authentication";
import { Link, useLocation } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import PinIcon from '@mui/icons-material/Pin';
import DateRangeIcon from '@mui/icons-material/DateRange';

// import UserSchedularIcon from "assets/img/weekly.png";
const drawerWidth = 240;

let ps;
const TimeFormatContext = createContext();

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/panel") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={() => prop.component({ ...prop, TimeFormatContext })}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/panel" to="/panel/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const location = useLocation();
  const [isTimeFormat, setIsTimeFormat] = useState();
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  const [open, setOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const [image, setImage] = React.useState(null);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [DrawerOpen, setDrawerOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState();
  const [memoryUsage, setMemoryUsage] = React.useState();
  const [isFetchingLoading, setIsFetchingLoading] = React.useState(false);
  const [rmpermission, setRmPermission] = useState([])

  React.useEffect(() => {
    doGetuserDetails();
  }, []);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/panel/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setOpen(false);
  };
  const openDrawer = () => {
    setDrawerOpen(true);
    setOpen(true);
  };

  function makeBrand() {
    var name;
    routes.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = rest.props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }

  const doGetuserDetails = async () => {
    try {
      setIsFetchingLoading(true);
      const response = await authenticationService.me();
      setUserDetails(response?.data);
      setRmPermission(response?.data?.permission)

      if (response?.data && response?.data?.memUsage) {
        setMemoryUsage(response?.data.memUsage);
      }
      setIsTimeFormat(response?.data?.timeFormat);
      localStorage.setItem("isTimeFormat", response?.data?.timeFormat);
      setIsFetchingLoading(false);
    } catch (error) {
      console.log(error);
      setIsFetchingLoading(false);
    }
  };

  return (
    <TimeFormatContext.Provider value={{ isTimeFormat, setIsTimeFormat, userDetails }}>
      <div className={classes.wrapper}>
        <Sidebar
          TimeFormatContext={TimeFormatContext}
          routes={routes}
          logoText={"Daily Work Report"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={"color"}
          style={{ color: "#000" }}
          {...rest}
          isDrawerOpen={isDrawerOpen}
          handleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
        />
        <div
          style={{ width: "100%", zIndex: 0 }}
          // style={{ width: !isDrawerOpen && '100%', zIndex: 0 }}
          className={classes.mainPanel}
          ref={mainPanel}
        >
          <Navbar
            TimeFormatContext={TimeFormatContext}
            isDrawerOpen={isDrawerOpen}
            handleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
            routes={routes}
            closeDrawer={closeDrawer}
            openDrawer={openDrawer}
            handleDrawerToggle={handleDrawerToggle}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            memoryUsage={memoryUsage}
            isFetchingLoading={isFetchingLoading}
            doGetMemoryStatus={doGetuserDetails}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <>
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                {/* <div
                    className={classes.toolbar}
                    style={{
                        width: '100%',
                        padding: '11px 0px',
                    }}
                >
                    {theme.direction === 'rtl' ? (
                        <IconButton onClick={handleDrawerOpen}>
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <IconButton onClick={handleDrawerOpen}>
                            {open ? <ChevronLeftIcon /> : <MenuIcon />}
                        </IconButton>
                    )}
                </div> */}

                <List style={{ padding: 0 }}>
                  <Link
                    to={{
                      pathname: "/panel/dashboard",
                    }}
                  >
                    <ListItem
                      component="a"
                      button
                      style={{
                        background:
                          location.pathname == "/panel/dashboard"
                            ? "#eeeeee"
                            : "#ffffff",
                        color:
                          location.pathname == "/panel/dashboard"
                            ? "#f3590d"
                            : "inherit",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname == "/panel/dashboard"
                              ? "#f3590d"
                              : "black",
                        }}
                      >
                        <AddAlarmIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Time Entries"} />
                    </ListItem>
                  </Link>

                  <Link
                    to={{
                      pathname: "/panel/history",
                    }}
                    style={{ color: "black" }}
                  >
                    <ListItem
                      button
                      component="a"
                      style={{
                        background:
                          location.pathname == "/panel/history"
                            ? "#eeeeee"
                            : "#ffffff",
                        color:
                          location.pathname == "/panel/history"
                            ? "#f3590d"
                            : "inherit",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname == "/panel/history"
                              ? "#f3590d"
                              : "inherit",
                        }}
                      >
                        <HistoryIcon />
                      </ListItemIcon>
                      <ListItemText primary={"History"} />
                    </ListItem>
                  </Link>

                  {userDetails?.isReportingManager ? (
                    <Link to="/panel/my-team" style={{ color: "black" }}>
                      <ListItem
                        button
                        component="a"
                        style={{
                          background:
                            location.pathname == "/panel/my-team"
                              ? "#eeeeee"
                              : "#ffffff",
                          color:
                            location.pathname == "/panel/my-team"
                              ? "#f3590d"
                              : "inherit",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            color:
                              location.pathname == "/panel/my-team"
                                ? "#f3590d"
                                : "inherit",
                          }}
                        >
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary={"My Teams"} />
                      </ListItem>
                    </Link>
                  ) : (
                    <></>
                  )}

                  {/* {userDetails?.isReportingManager && rmpermission?.some(e => e.moduleName == "project allocation") ? (
                    <Link to="/panel/user-scheduler" style={{ color: "black" }}>
                      <ListItem
                        button
                        component="a"
                        style={{
                          background:
                            location.pathname == "/panel/user-scheduler"
                              ? "#eeeeee"
                              : "#ffffff",
                          color:
                            location.pathname == "/panel/user-scheduler"
                              ? "#f3590d"
                              : "inherit",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            color:
                              location.pathname == "/panel/user-scheduler"
                                ? "#f3590d"
                                : "inherit",
                          }}
                        >
                          <EventNoteIcon />
                        </ListItemIcon>
                        <ListItemText primary={"User Scheduler"} />
                      </ListItem>
                    </Link>
                  ) : (
                    <></>
                  )} */}
                  <Link to="/panel/report" style={{ color: "black" }}>
                    <ListItem
                      button
                      component="a"
                      style={{
                        background:
                          location.pathname == "/panel/report"
                            ? "#eeeeee"
                            : "#ffffff",
                        color:
                          location.pathname == "/panel/report"
                            ? "#f3590d"
                            : "inherit",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname == "/panel/report"
                              ? "#f3590d"
                              : "inherit",
                        }}
                      >
                        {/* <InsertInvitationIcon /> */}
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Resource Availability"} />
                    </ListItem>
                  </Link>
                  {/* {userDetails?.isReportingManager ? (
                    <Link to="/panel/report" style={{ color: "black" }}>
                      <ListItem
                        button
                        component="a"
                        style={{
                          background:
                            location.pathname == "/panel/report"
                              ? "#eeeeee"
                              : "#ffffff",
                          color:
                            location.pathname == "/panel/report"
                              ? "#f3590d"
                              : "inherit",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            color:
                              location.pathname == "/panel/report"
                                ? "#f3590d"
                                : "inherit",
                          }}
                        >
                          <InsertInvitationIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Resources Availability"} />
                      </ListItem>
                    </Link>
                  ) : (
                    <></>
                  )} */}

                  {/* {userDetails?.isReportingManager ? (
                    <Link to='/panel/allocation-report' style={{ color: "black" }}>
                      <ListItem
                        button
                        component='a'
                        style={{
                          background:
                            location.pathname == "/panel/allocation-report"
                              ? "#eeeeee"
                              : "#ffffff",
                          color:
                            location.pathname == "/panel/allocation-report"
                              ? "#f3590d"
                              : "inherit",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            color:
                              location.pathname == "/panel/allocation-report"
                                ? "#f3590d"
                                : "inherit",
                          }}
                        >
                          <PinIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Resource Allocation Report"} />
                      </ListItem>
                    </Link>
                  ) : (
                    <></>
                  )} */}

                  <Link
                    to={{
                      pathname: "/panel/leave",
                    }}
                    style={{ color: "black" }}
                  >
                    <ListItem
                      button
                      component="a"
                      style={{
                        background:
                          location.pathname == "/panel/leave"
                            ? "#eeeeee"
                            : "#ffffff",
                        color:
                          location.pathname == "/panel/leave"
                            ? "#f3590d"
                            : "inherit",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            location.pathname == "/panel/leave"
                              ? "#f3590d"
                              : "inherit",
                        }}
                      >
                        {/* <HistoryIcon /> */}
                        <DateRangeIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Leave"} />
                    </ListItem>
                  </Link>

                </List>
                {/* <Divider /> */}
                {/* <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List> */}
              </Drawer>
              <div
                className={classes.content}
                style={{
                  paddingLeft: DrawerOpen ? "15rem" : "3.5rem",
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  transition: "all 0.45s ease",
                }}
              >
                <div>{switchRoutes}</div>
              </div>
            </>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {/* {getRoute() ? <Footer /> : null} */}
          {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
        </div>
      </div>
    </TimeFormatContext.Provider>
  );
}
