import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { authenticationService } from "services/authentication";
import TodayIcon from "@material-ui/icons/Today";
import ReportTableShimmer from "components/Shimmer/ReportTableShimmer";
import { downArrow } from "helper/constant";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridItem from "components/Grid/GridItem.js";
import { Bar } from "react-chartjs-2";
import DatePickerWrapper from "../../assets/css/datepicker.style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Chip, Modal, TextField, Tooltip } from "@material-ui/core";
import { close, Notes, sendMail } from "helper/constant.jsx";
import DatePickerFilter from "components/DatePickerFilter/DatePickerFilter";
import { DATE_FILTER_TYPE } from "helper/constant";
import LinearLoader from "components/loader/LinearLoader.jsx";

const MyTeam = () => {
  const [selected, setSelected] = useState([]);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tempDate, SetTempDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [selectionComplete, toggleSelectionComplete] = useState(false);
  const [myTeamsUserList, setMyTeamsUserList] = useState([]);
  const [myTeamsProjectList, setMyTeamsProjectList] = useState([]);
  const [selectedUserObject, setSelectedUserObject] = useState([]);
  const [selectedProjectObject, setSelectedProjectObject] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [groupByData, setGroupByData] = useState([]);
  const [isToggle, setIsToggle] = useState();
  const [totalTime, setTotalTime] = useState(0);
  const [filterClickLoader, setFilterClickLoader] = useState(false);
  const [filterDataArray, setFilterDataArray] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [isTimeEntryDetailsModal, SetIsTimeEntryDetailsModal] = React.useState(
    false
  );
  const [timeEntry, setTimeEntry] = React.useState();
  const [datePickerActiveChip, SetDatePickerActiveChip] = useState(
    DATE_FILTER_TYPE.THISWEEK
  );
  const [isSendMailModal, setIsSendMailModal] = useState(false);
  const [showMailResponse, setShowMailResponse] = useState();

  useEffect(() => {
    getCurrWeekDate();
    doGetMyTeamsUserList();
    doGetMyTeamsProjectList();
    SetDatePickerActiveChip(DATE_FILTER_TYPE.THISWEEK);
    return () => {
      SetTempDate({ startDate: "", endDate: "" });
    };
  }, []);

  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  let errorToast = (errMsg) => {
    toast.error(errMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleCloses = () => SetIsTimeEntryDetailsModal(false);

  const handleMailModalClose = () => setIsSendMailModal(false);

  const selectOptions = [
    { value: "User", label: "User" },
    { value: "Project", label: "Project" },
  ];

  const getUser = (selected, _options) => {
    if (selected.length == _options.length) {
      return `All users are selected. (${selected.length})`;
    } else if (selected.length >= 3) {
      return `${selected[0].label}, ${selected[1].label}, (+${selected.length -
        2}) more`;
    }
  };

  const getProject = (selected, _options) => {
    if (selected.length == _options.length) {
      return `All projects are selected. (${selected.length})`;
    } else if (selected.length >= 3) {
      return `${selected[0].label}, ${selected[1].label}, (+${selected.length -
        2}) more`;
    }
  };

  const customUserRenderer = (selected, _options) => {
    return selected.length ? getUser(selected, _options) : "Select Employee";
  };

  const customProjectRenderer = (selected, _options) => {
    return selected.length ? getProject(selected, _options) : "Select Project";
  };

  const handleUserChange = (selected) => {
    setSelectedUserObject(selected);
  };

  const handleProjectChange = (selected) => {
    setSelectedProjectObject(selected);
  };

  const tooltipUsersText = selectedUserObject.length
    ? selectedUserObject.map((option) => option.label).join(", ")
    : "";

  const tooltipProjectText = selectedProjectObject.length
    ? selectedProjectObject.map((option) => option.label).join(", ")
    : "";

  const doGetMyTeamsUserList = async () => {
    setIsLoader(true);
    try {
      const Response = await authenticationService.fetchMyTeamsUserList();
      if (Response?.data) {
        setMyTeamsUserList([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.id,
              // label: value?.name,
              label: value?.code ? `${value?.code} - ${value.name}` : value?.name
            };
          }),
        ]);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };



  const datePickerProps = () => {
    return { isRangerHoverAllowed: true };
  };
  const doGetMyTeamsProjectList = async () => {
    setIsLoader(true);
    try {
      const Response = await authenticationService.fetchMyTeamsProjectList();
      if (Response?.data) {
        setMyTeamsProjectList([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.id,
              // label: value?.projectName,
              label: value?.projectCode ? `${value?.projectCode} - ${value.projectName}` : value?.projectName
            };
          }),
        ]);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };

  const getCurrWeekDate = (isFetchRecordRequired = true) => {
    let wDate = new Date();
    let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
    let first = wDate.getDate() - dDay + 1;
    let firstDayWeek = new Date(wDate.setDate(first));
    setStartDate(firstDayWeek);
    setEndDate(new Date());
    toggleSelectionComplete(true);
    if (isFetchRecordRequired) {
      doHandleMyTeamsFilter("", firstDayWeek, new Date());
    }
  };

  const getCountTimeEntry = (value) => {
    const data = value?.totalEntries.filter(
      (item, key) => item.endDate != null
    );
    return data.length;
  };

  const doClickOnToday = () => {
    SetDatePickerActiveChip(DATE_FILTER_TYPE.TODAY);
    SetTempDate({
      startDate: new Date(),
      endDate: new Date(),
    });
  };

  const doClickOnYesterday = () => {
    var date = new Date();
    date.setDate(date.getDate() - 1);

    SetDatePickerActiveChip(DATE_FILTER_TYPE.YESTERDAY);
    SetTempDate({
      startDate: date,
      endDate: date,
    });
  };

  const doClickOnThisMonth = () => {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDayOfThisMonth = new Date(y, m, 1);

    SetDatePickerActiveChip(DATE_FILTER_TYPE.THISMONTH);
    SetTempDate({
      startDate: firstDayOfThisMonth,
      endDate: new Date(),
    });
  };

  const doClickOnThisWeek = () => {
    let wDate = new Date();
    let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
    let first = wDate.getDate() - dDay + 1;
    let firstDayWeek = new Date(wDate.setDate(first));

    SetDatePickerActiveChip(DATE_FILTER_TYPE.THISWEEK);
    SetTempDate({
      startDate: firstDayWeek,
      endDate: new Date(),
    });
  };

  const doClickOnLast7Days = () => {
    var date = new Date();
    date.setDate(date.getDate() - 7);

    SetDatePickerActiveChip(DATE_FILTER_TYPE.LAST7DAYS);
    SetTempDate({
      startDate: date,
      endDate: new Date(),
    });
  };

  const doClickOnLastMonth = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

    SetDatePickerActiveChip(DATE_FILTER_TYPE.LASTMONTH);
    SetTempDate({
      startDate: firstDay,
      endDate: lastDay,
    });
  };

  const doClickOnLastWeek = () => {
    let beforeOneWeek = new Date(
      new Date().getTime() - 60 * 60 * 24 * 7 * 1000
    );
    let beforeOneWeek2 = new Date(beforeOneWeek);
    let day = beforeOneWeek.getDay();
    let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
    let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

    SetDatePickerActiveChip(DATE_FILTER_TYPE.LASTWEEK);
    SetTempDate({
      startDate: lastMonday,
      endDate: lastSunday,
    });
  };

  const doClickOnCancel = () => {
    if (tempDate.startDate && tempDate.endDate) {
      SetTempDate({
        startDate,
        endDate,
      });
    } else {
      getCurrWeekDate(false);
    }
    setIsOpen(false);
  };

  const doHandleDatepickerOutsideClick = () => {
    setIsOpen(false);
    if (tempDate.startDate && tempDate.endDate) {
      SetTempDate({
        startDate,
        endDate,
      });

      setIsOpen(false);
    } else {
      getCurrWeekDate(false);
    }
  };

  const doHandleShowDateClick = () => {
    setStartDate(tempDate.startDate);
    setEndDate(tempDate.endDate);
    setIsOpen(false);
  };

  const doHandleMyTeamsFilter = async (selectedValue, startDate, endDate) => {
    setLoadingTableData(true);
    try {
      let userId = [];
      selectedUserObject &&
        selectedUserObject.filter((value, key) => {
          return value.id && userId.push(value?.id);
        });
      let projectId = [];
      selectedProjectObject &&
        selectedProjectObject.filter((value, key) => {
          return value.id && projectId.push(value?.id);
        });
      let object = {
        startDate: moment(startDate).format("DD-MM-YYYY"),
        endDate: moment(endDate).format("DD-MM-YYYY"),
        user: userId,
        project: projectId,
        type:
          selectedValue && selectedValue
            ? selectedValue
            : selectedOptionLabel
              ? selectedOptionLabel
              : "User",
      };

      const response = await authenticationService.filterMyTeamReport(object);
      if (response?.response?.status == 400) {
      }
      if (response?.hasOwnProperty("message") && response?.code == 202) {
        setIsSendMailModal(true);
        setShowMailResponse({ ...object, email: response?.message });
        setLoadingTableData(false);
        
        setGroupByData(response);
      }
      if (response?.hasOwnProperty("message") && response?.code == 400) {
        errorToast(response?.message);
      }
      if (response) {
        setTotalTime(response?.data?.totalTime || "");

        if (response?.data.hasOwnProperty("projectEntries")) {
          setGroupByData(response?.data?.projectEntries || []);
        }
        if (response?.data.hasOwnProperty("userEntries")) {
          setGroupByData(response?.data?.userEntries || []);
        }
        let objec = {
          labels: [],
          datasets: [
            {
              label: "Hours",
              backgroundColor: "rgba(249, 97, 23, 0.7)",
              borderColor: "rgba(249, 97, 23, 0.7)",
              borderWidth: 1,
              hoverBorderColor: "rgba(249, 97, 23, 0.7)",
              data: [],
            },
          ],
        };
        let lables = [];
        let datas = [];
        if (response.data.obj2 && response.data.obj2?.length > 0) {
          response.data.obj2 &&
            response.data.obj2
              .sort(function (a, b) {
                var c = moment.utc(
                  moment(a.date, "DD/MM/YYYY")
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ssZ")
                );
                var d = moment.utc(
                  moment(b.date, "DD/MM/YYYY")
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ssZ")
                );
                return c - d;
              })
              .map((item, key) => {
                lables.push(item.date);
                datas.push(item.time);
              });
          objec = {
            labels: lables,
            datasets: [
              {
                label: "Hours",
                backgroundColor: "rgba(249, 97, 23, 0.7)",
                borderColor: "rgba(249, 97, 23, 0.7)",
                borderWidth: 1,
                // hoverBackgroundColor: color.alpha(0.8).css(),
                hoverBorderColor: "rgba(249, 97, 23, 0.7)",
                data: datas,
              },
            ],
          };
          setFilterDataArray(objec);
        } else {
          objec = {
            labels: [],
            datasets: [
              {
                label: "Hours",
                backgroundColor: "rgba(249, 97, 23, 0.7)",
                borderColor: "rgba(249, 97, 23, 0.7)",
                borderWidth: 1,
                // hoverBackgroundColor: color.alpha(0.8).css(),
                hoverBorderColor: "rgba(249, 97, 23, 0.7)",
                data: [],
              },
            ],
          };
          setFilterDataArray(objec);
        }
      }
      setLoadingTableData(false);
      setFilterClickLoader(false);
    } catch (error) {
      console.log(error);
      setFilterClickLoader(false);
    }
  };

  const data = {
    // labels: [
    //   "January",
    //   "February",
    //   "March",
    //   "April",
    //   "May",
    //   "June",
    //   "July",
    //   "Aug",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    //   "Dec"
    // ],
    // datasets: [
    //   {
    //     label: "Data",
    //     backgroundColor: "#563c91",
    //     borderColor: "#563c91",
    //     borderWidth: 1,
    //     hoverBackgroundColor: color.alpha(0.8).css(),
    //     hoverBorderColor: "#563c91",
    //     data: [65, 59, 80, 81, 56, 65, 59, 80, 81, 56, 90, 71]
    //   }
    // ]
  };

  const handleChangeTableData = (selectedOption) => {
    setIsToggle();
    doHandleMyTeamsFilter(selectedOption.value, startDate, endDate);
    setSelectedOptionLabel(selectedOption.value);
  };
  const doHandleDatePickerOpen = () => {
    setIsOpen(true);
    SetTempDate({
      startDate: startDate,
      endDate: endDate,
    });
  };
  const CustomInputs = ({ startDate, endDate }) => {
    return (
      <div
        className='datepicker'
        style={{
          display: "flex",
          flexDirection: "row",
          border: "1px solid #c6d2d9",
          padding: "0px 12px",
          cursor: "pointer",
          height: "35px",
          width: "14rem",
          borderRadius: "5px",
        }}
        onClick={doHandleDatePickerOpen}
      >
        <div
          style={{
            paddingTop: 0,
            display: "flex",
            alignItems: "center",
            fontSize: 16,
          }}
        >
          <TodayIcon
            style={{
              margin: "0px 5px 0px 0px",
              height: "20px",
              color: "#57646b",
            }}
          />
          <div style={{ color: "#666" }}>
            {`${startDate ? moment(startDate).format("DD/MM/YYYY") : "-"} to ${endDate ? moment(endDate).format("DD/MM/YYYY") : "-"
              }`}
          </div>
        </div>
      </div>
    );
  };

  const doHandleDateChange = (date) => {
    // startDate
    if (!tempDate.startDate && !tempDate.endDate) {
      SetTempDate({ ...tempDate, startDate: date, endDate: null });
    }
    //endDate
    if (tempDate.startDate && !tempDate.endDate) {
      let dt1 = moment(tempDate.startDate, "DD-MM-YYYY")
        .add(-1, "M")
        .toDate();
      let dt2 = moment(date, "DD-MM-YYYY").toDate();
      let diffMonths = (dt2.getFullYear() - dt1.getFullYear()) * 12;
      diffMonths -= dt1.getMonth();
      diffMonths += dt2.getMonth();

      if (diffMonths > 6 && moment(date).isAfter(tempDate.startDate)) {
        errorToast("Please select a range of maximum 6 months.");
        SetTempDate({
          ...tempDate,
          startDate: tempDate.startDate,
          endDate: null,
        });
        return;
      }
      SetTempDate({ ...tempDate, endDate: date });
    }

    if (moment(date).isSame(tempDate.startDate)) {
      SetTempDate({
        ...tempDate,
        startDate: tempDate.startDate,
        endDate: date,
      });
    }

    if (
      tempDate.startDate &&
      !tempDate.endDate &&
      !moment(date).isAfter(tempDate.startDate) &&
      !moment(date).isSame(tempDate.startDate)
    ) {
      SetTempDate({
        ...tempDate,
        startDate: tempDate.startDate,
        endDate: null,
      });
      return;
    }

    if (tempDate.startDate && tempDate.endDate) {
      SetTempDate({ ...tempDate, startDate: date, endDate: null });
    }
  };

  const handleSelect = (date) => {
    //onChange is not fired if selecting same date - workaround to fire doHandleDateChange
    if (
      !selectionComplete &&
      startDate &&
      !endDate &&
      sameDay(date, tempDate.startDate)
    ) {
      doHandleDateChange(date);
    }
  };

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const doHandleTotalSecToHours = (startDate, endDate) => {
    let m1 = new Date(startDate);
    let m2 = new Date(endDate);
    let seconds = Math.abs((m2.getTime() - m1.getTime()) / 1000);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "00h ";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "00m ";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "00s";
    return hDisplay.includes("00h") && mDisplay.includes("00m")
      ? hDisplay + mDisplay
      : hDisplay + mDisplay;
  };

  const getSortingDateArray = (value) => {
    return selectedOptionLabel != "Project"
      ? value?.totalEntries.sort(function (a, b) {
        const dateA = a.startDate;
        const dateB = b.endDate;
        if (dateA > dateB) {
          return 1;
        }
        if (dateA < dateB) {
          return -1;
        }
        return 0;
      })
      : value?.totalEntries;
  };

  return (
    <>
      <Modal
        open={isTimeEntryDetailsModal}
        onClose={handleCloses}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          style={{
            background: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            minHeight: 500,
            bgcolor: "white",
            boxShadow: "0px 2px 16px 3px rgb(84 84 84 / 75%)",
            p: 4,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              padding: "20px 25px",
              borderBottom: "1px solid #d5d5d5",
              fontWeight: 600,
              fontSize: 18,
              color: "#000000",
              // textAlign: "center",
            }}
          >
            <span> Time entry details</span>
            <img
              src={close}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 19,
                right: 20,
              }}
              width={25}
              height={25}
              onClick={handleCloses}
            />
          </div>
          <div style={{ padding: "15px 30px 25px", textAlign: "start" }}>
            <div>
              <span style={{ fontWeight: 600 }}>User name :</span>
              &nbsp; {timeEntry && <span>{timeEntry?.userName}</span>}
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Title :</span>
              &nbsp; {timeEntry && <span>{timeEntry?.title}</span>}
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Start date :</span>
              &nbsp;{" "}
              {timeEntry && (
                <span>
                  {moment(timeEntry?.startDate).format("DD-MM-YYYY HH:mm")}
                </span>
              )}
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>End date :</span>
              &nbsp;{" "}
              {timeEntry && (
                <span>
                  {moment(timeEntry?.endDate).format("DD-MM-YYYY HH:mm")}
                </span>
              )}
            </div>
            <div>
              {/* <h6 style={{textTransform:"capitalize",margin:"10px 0px"}}>
           
            <b>Note</b> </h6> */}
              {timeEntry && (
                <div
                  className='time-details-array'
                  style={{ marginTop: 25, maxHeight: 275, overflow: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      timeEntry?.remark?.length > 0
                        ? timeEntry?.remark
                        : "<p> time details notes not added</p>",
                  }}
                ></div>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Send mail modal */}

      <Modal
        open={isSendMailModal}
        onClose={handleMailModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          style={{
            background: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            // minHeight: 500,
            bgcolor: "white",
            boxShadow: "0px 2px 16px 3px rgb(84 84 84 / 75%)",
            p: 4,
            borderRadius: 5,
          }}
        >
          {/* <div
            style={{
              padding: "20px 25px",
              fontWeight: 600,
              fontSize: 18,
              color: "#000000",
            }}
          >
                      
            <img
              src={close}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 19,
                right: 20,
              }}
              width={25}
              height={25}
              onClick={handleMailModalClose}
            />
          </div> */}
          <div style={{ padding: "15px 30px 25px", textAlign: "start" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  left: -10,
                }}
              >
                <LinearLoader height='200px' width='200px' />
              </div>

              <span
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  display: "flex",
                  marginTop: "5px",
                  color: "gray",
                }}
              >
                You will receive an email of this report (
                {showMailResponse?.startDate} to {showMailResponse?.endDate}) to{" "}
                {showMailResponse?.email} soon. Thank you.
              </span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <button
                  style={{
                    width: "4rem",
                    padding: "10px",
                    margin: "0px 5px",
                    background: "#F4590D",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    color: "#FFF",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "15px",
                  }}
                  onClick={handleMailModalClose}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <div>
        {/* First row component */}
        <div
          style={{
            height: "4rem",
            width: "98%",
            boxShadow:
              "rgb(0 0 0 / 3%) 0px 0.46875rem 2.1875rem, rgb(0 0 0 / 3%) 0px 0.9375rem 1.40625rem, rgb(0 0 0 / 5%) 0px 0.25rem 0.53125rem, rgb(0 0 0 / 3%) 0px 0.125rem 0.1875rem",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            margin: "6rem 0rem 0rem 24px",
            padding: "10px 0px",
            borderRadius: "10px",
            backgroundColor: "#FFF",
          }}
        >
          <div
            style={{
              width: "30%",
              margin: "4px 30px",
              background: "none",
              minWidth: "30%",
            }}
            title={tooltipUsersText}
          >
            <MultiSelect
              isMulti
              autoFocus
              menuIsOpen
              allowCustomValue={true}
              valueRenderer={customUserRenderer}
              onChange={(selected) => handleUserChange(selected)}
              options={
                myTeamsUserList &&
                myTeamsUserList.sort(function (a, b) {
                  const nameA = a?.label?.toUpperCase();
                  const nameB = b?.label?.toUpperCase();
                  if (nameA > nameB) {
                    return 1;
                  }
                  if (nameA < nameB) {
                    return -1;
                  }
                  return 0;
                })
              }
              value={selectedUserObject}
              allowSelectAll={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isLoading={isLoader}
            />
          </div>
          <div
            style={{
              width: "30%",
              margin: "4px 30px",
              background: "none",
              minWidth: "30%",
            }}
            title={tooltipProjectText}
          >
            <MultiSelect
              isMulti
              autoFocus
              menuIsOpen
              allowCustomValue={true}
              valueRenderer={customProjectRenderer}
              onChange={(selected) => handleProjectChange(selected)}
              options={
                myTeamsProjectList &&
                myTeamsProjectList.sort(function (a, b) {
                  const nameA = a?.label?.toUpperCase();
                  const nameB = b?.label?.toUpperCase();
                  if (nameA > nameB) {
                    return 1;
                  }
                  if (nameA < nameB) {
                    return -1;
                  }
                  return 0;
                })
              }
              value={selectedProjectObject}
              allowSelectAll={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isLoading={isLoader}
            />
          </div>

          <div
            style={{
              borderRadius: "5px",
              width: "230px",
              height: "2.5rem",
              margin: "4px 30px",
              display: "flex",
            }}
          >
            <div>
              <DatePickerWrapper {...datePickerProps}>
                <DatePicker
                  selectsRange
                  isRangerHoverAllowed={
                    tempDate.startDate && tempDate.endDate ? true : false
                  }
                  open={isOpen}
                  selected={
                    tempDate.startDate ? tempDate.startDate : new Date()
                  }
                  maxDate={new Date()}
                  onClickOutside={doHandleDatepickerOutsideClick}
                  onInputClick={() => setIsOpen(true)}
                  onChange={doHandleDateChange}
                  onSelect={handleSelect}
                  selectsEnd={true}
                  monthsShown={1}
                  // adjustDateOnChange
                  shouldCloseOnSelect={false}
                  startDate={tempDate.startDate}
                  endDate={tempDate.endDate}
                  className='custom-datepicker'
                  calendarClassName='custom-calender-class'
                  showPreviousMonths
                  disabledKeyboardNavigation
                  popperPlacement='top-end'
                  // popperProps={{
                  //   positionFixed: true // use this to make the popper position: fixed
                  // }}
                  popperProps={{
                    strategy: "fixed", // use this to make the popper position: fixed
                  }}
                  customInput={
                    <CustomInputs
                      className='date-range'
                      startDate={isOpen ? tempDate.startDate : startDate}
                      endDate={isOpen ? tempDate.endDate : endDate}
                    />
                  }
                >
                  <DatePickerFilter
                    doClickOnToday={doClickOnToday}
                    doClickOnThisWeek={doClickOnThisWeek}
                    doClickOnYesterday={doClickOnYesterday}
                    doClickOnThisMonth={doClickOnThisMonth}
                    doClickOnLast7Days={doClickOnLast7Days}
                    doClickOnLastMonth={doClickOnLastMonth}
                    doClickOnLastWeek={doClickOnLastWeek}
                    doClickOnCancel={doClickOnCancel}
                    doHandleMyTeamsFilter={doHandleMyTeamsFilter}
                    isShowAllowed={tempDate.startDate && tempDate.endDate}
                    dateRangeEnabled
                    doHandleShowDateClick={doHandleShowDateClick}
                    datePickerActiveChip={datePickerActiveChip}
                    DATE_FILTER_TYPE={DATE_FILTER_TYPE}
                  />
                </DatePicker>
              </DatePickerWrapper>
            </div>
          </div>

          {filterClickLoader ? (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                width: "15%",
                margin: "0px 10px",
              }}
            >
              <CircularProgress size={30} />
            </div>
          ) : (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                width: "15%",
                margin: "0px 10px",
              }}
            >
              <Button
                style={{
                  background: "#f96117db",
                  fontWeight: "bold",
                  borderRadius: "50px",
                  color: "#FFF",
                  width: "50%",
                }}
                onClick={() => {
                  doHandleMyTeamsFilter(
                    setFilterClickLoader(true),
                    startDate,
                    endDate,
                    setIsToggle()
                  );
                }}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
        <br />

        {/* Chart */}
        <div
          style={{
            boxShadow:
              "rgb(0 0 0 / 3%) 0px 0.46875rem 2.1875rem, rgb(0 0 0 / 3%) 0px 0.9375rem 1.40625rem, rgb(0 0 0 / 5%) 0px 0.25rem 0.53125rem, rgb(0 0 0 / 3%) 0px 0.125rem 0.1875rem",
            borderRadius: "15px",
            border: "1px solid rgb(233, 233, 233)",
            width: "98%",
            margin: "0rem 0rem 0rem 24px",
          }}
        >
          <div
            className='row'
            style={{
              backgroundColor: "rgb(233, 233, 233)",
              margin: "0px 0px",
              padding: "10px 0px",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ margin: "0px 20px" }}>
                <span style={{ fontWeight: "bold" }}>Total:</span>
                <span
                  style={{
                    fontSize: "medium",
                    fontWeight: "lightbold",
                    padding: "0px 0px 0px 5px",
                  }}
                >
                  {totalTime && totalTime}
                </span>
              </div>
            </div>
          </div>
          <div>
            <Bar
              data={filterDataArray && filterDataArray ? filterDataArray : data}
              width={100}
              height={300}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
        <br />

        {/* Drop down and table */}
        <div
          style={{
            boxShadow:
              "rgb(0 0 0 / 3%) 0px 0.46875rem 2.1875rem, rgb(0 0 0 / 3%) 0px 0.9375rem 1.40625rem, rgb(0 0 0 / 5%) 0px 0.25rem 0.53125rem, rgb(0 0 0 / 3%) 0px 0.125rem 0.1875rem",
            borderRadius: "10px",
            border: "1px solid rgb(233, 233, 233)",
            backgroundColor: "#FFF",
            margin: "0rem 0rem 50px 24px",
            width: "98%",
          }}
        >
          {/* Dropdown before table */}
          <div
            className='row'
            style={{
              backgroundColor: "rgb(233, 233, 233)",
              margin: "0px 0px",
              padding: "10px 0px",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              display: "flex",
            }}
          >
            <div style={{ margin: "5px 0px 5px 15px" }}>
              <span style={{ fontWeight: "bold" }}>Group by:</span>
            </div>
            <div style={{ margin: "0rem 1rem" }}>
              <Select
                // value={selectOptions}
                options={selectOptions}
                onChange={handleChangeTableData}
                defaultValue={selectOptions[0]}
              />
            </div>
          </div>

          {/* Table */}
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
            }}
          >
            <table style={{ width: "100%" }}>
              <div>
                <tr
                  style={{ borderBottom: "1px solid #0000001a", width: "100%" }}
                >
                  {selectedOptionLabel != "Project" ? (
                    <>
                      <th
                        style={{
                          padding: "10px 15px",
                          display: "flex",
                          width: "42vw",
                        }}
                      >
                        <div
                          style={{
                            margin: "0px 10px 0px 10px",
                            padding: "5px 8px",
                            visibility: "hidden",
                          }}
                        ></div>
                        User
                      </th>
                      <th style={{ padding: "10px 0px", width: "15vw" }}></th>
                      <th style={{ padding: "10px 0px", width: "14vw" }}></th>
                      <th style={{ padding: "10px 0px", width: "14vw" }}></th>
                      <th
                        style={{
                          padding: "10px 18px 10px 0px",
                          display: "flex",
                          width: "8vw",
                        }}
                      >
                        Duration
                      </th>
                    </>
                  ) : groupByData.hasOwnProperty("message") ? (
                    ""
                  ) : (
                    <>
                      <th
                        style={{
                          padding: "10px 15px",
                          display: "flex",
                          width: "42vw",
                        }}
                      >
                        <div
                          style={{
                            margin: "0px 10px 0px 10px",
                            padding: "5px 8px",
                            visibility: "hidden",
                          }}
                        ></div>
                        Project
                      </th>
                      <th style={{ padding: "10px 0px", width: "40vw" }}></th>
                      <th
                        style={{
                          padding: "10px 0px",
                          display: "flex",
                          width: "12vw",
                        }}
                      >
                        Duration
                      </th>
                    </>
                  )}
                </tr>
              </div>

              {loadingTableData ? (
                <tr
                  style={{
                    borderBottom: "1px solid #0000001a",
                  }}
                >
                  <td colSpan='5'>
                    <ReportTableShimmer />
                  </td>
                </tr>
              ) : (
                <>
                  {groupByData?.length > 0 ? (
                    groupByData &&
                    groupByData
                      ?.sort(function (a, b) {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA > nameB) {
                          return 1;
                        }
                        if (nameA < nameB) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((value, key) => {
                      
                        return (
                          <>
                            <div>
                              <tr
                                style={{
                                  borderBottom: "1px solid #0000001a",
                                  background:
                                    isToggle === key ? "#e7f1ff" : "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (key == isToggle) {
                                    setIsToggle();
                                  } else {
                                    setIsToggle(key);
                                  }
                                }}
                              >
                                <td
                                  style={{
                                    padding: "10px 0px",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "42vw",
                                  }} 
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#FCBFAC",
                                      borderRadius: "3px",
                                      margin: "0px 15px 0px 15px",
                                      padding: "5px 8px",
                                      fontSize: "small",
                                    }}
                                  >
                                    {getCountTimeEntry(value)}
                                  </div>
                                  {
                                    value.code ? `${value?.code} - ${value?.name}`:`${value?.name}`
                                  }
                                </td>
                                {selectedOptionLabel != "Project" ? (
                                  <>
                                    <td style={{ width: "15vw" }}></td>
                                    
                                    <td style={{ width: "14vw" }}></td>
                                    <td style={{ width: "14vw" }}></td>
                                  </>
                                ) : (
                                  <>
                                    <td style={{ width: "40vw" }}></td>
                                  </>
                                )}
                                <td
                                  colSpan='3'
                                  style={{ padding: "10px 0px", width: "8vw" }}
                                >
                                  {value?.time}
                                </td>
                                <td
                                  style={{
                                    // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                    padding: "10px",
                                    width: "3vw",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "0.1rem",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      transform:
                                        isToggle === key
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)",
                                      textAlign: "center",
                                      position: "relative",
                                      top: isToggle === key ? -2 : 4,
                                    }}
                                  >
                                    <img
                                      src={downArrow}
                                      height='20px'
                                      width='20px'
                                    />
                                  </div>
                                </td>
                              </tr>
                            </div>

                            <tr
                              style={{
                                width: "100%",
                                borderBottom: "1px solid #0000001a",
                                display: isToggle === key ? "contents" : "none",
                              }}
                            >
                              <table
                                style={{ width: "100%", display: "contents" }}
                              >
                                <div
                                  style={{
                                    borderBottom: "1px solid #0000001a",
                                  }}
                                >
                                  <tr
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <th
                                      style={{
                                        padding: "10px 0px",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "42vw",
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor: "#FCBFAC",
                                          borderRadius: "3px",
                                          margin: "0px 15px 0px 15px",
                                          padding: "5px 8px",
                                          fontSize: "small",
                                          visibility: "hidden",
                                        }}
                                      >
                                        {getCountTimeEntry(value)}
                                      </div>
                                      Title
                                    </th>

                                    {selectedOptionLabel != "Project" ? (
                                      <>
                                        <th
                                          style={{
                                            padding: "10px 0px",
                                            textAlign: "start",
                                            width: "15vw",
                                          }}
                                        >
                                          Project Name
                                        </th>
                                        <th
                                          style={{
                                            padding: "10px 0px",
                                            textAlign: "start",
                                            width: "14vw",
                                          }}
                                        >
                                          Start Time
                                        </th>
                                        <th
                                          style={{
                                            padding: "10px 0px",
                                            textAlign: "start",
                                            width: "14vw",
                                          }}
                                        >
                                          End Time
                                        </th>
                                      </>
                                    ) : (
                                      <>
                                        <th
                                          style={{
                                            padding: "10px 0px",
                                            textAlign: "start",
                                            width: "40vw",
                                          }}
                                        >
                                          User
                                        </th>
                                      </>
                                    )}

                                    <th
                                      style={{
                                        padding: "10px 0px",
                                        textAlign: "start",
                                        width: "8vw",
                                      }}
                                    >
                                      Duration
                                    </th>
                                  </tr>
                                </div>

                                <div
                                  className='time-details-array'
                                  style={{
                                    maxHeight: "32vh",
                                    width: "94vw",
                                    overflow: "auto",
                                    position: "relative",
                                  }}
                                >
                                  {getSortingDateArray(value).map(
                                    (data, key) => {
                                      if (data.endDate != null) {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              position: "relative",
                                            }}
                                          >
                                            <tr
                                              className={
                                                data?.remark?.length > 0
                                                  ? "tableLastBorder"
                                                  : "cursor-default"
                                              }
                                              onClick={(e) => {
                                                if (data?.remark?.length > 0) {
                                                  SetIsTimeEntryDetailsModal(
                                                    true
                                                  );
                                                  setTimeEntry(data);
                                                }
                                              }}
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              <td
                                                style={{
                                                  padding: "10px 0px",
                                                  display: "flex",
                                                  width: "42vw",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    backgroundColor: "#FCBFAC",
                                                    borderRadius: "3px",
                                                    margin: "0px 15px 0px 15px",
                                                    padding: "5px 8px",
                                                    fontSize: "small",
                                                    visibility: "hidden",
                                                  }}
                                                >
                                                  {getCountTimeEntry(value)}
                                                </div>
                                                <div
                                                  className='twoLineDot'
                                                  title={data?.title}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                      "space-between",
                                                    flex: 2,
                                                  }}
                                                >
                                                  <span> {data?.title} </span>
                                                  <span
                                                    className='note-image'
                                                    title='click to view details'
                                                  >
                                                    <img
                                                      src={Notes}
                                                      style={{
                                                        cursor: "pointer",
                                                        padding: "2px 6px",
                                                        position: "relative",
                                                        top: 3,
                                                      }}
                                                      width={18}
                                                      height={18}
                                                      onClick={handleCloses}
                                                    />
                                                  </span>
                                                </div>
                                              </td>
                                              {selectedOptionLabel !=
                                                "Project" ? (
                                                <>
                                                  <td
                                                    style={{
                                                      padding: "10px 0px",
                                                      width: "15vw",
                                                    }}
                                                  >
                                                    {data?.project?.projectName}
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: "10px 0px",
                                                      width: "14vw",
                                                    }}
                                                  >
                                                    {moment(
                                                      data?.startDate
                                                    ).format(
                                                      "DD/MM/YYYY HH:mm "
                                                    )}
                                                  </td>

                                                  <td
                                                    style={{
                                                      padding: "10px 0px",
                                                      width: "14vw",
                                                    }}
                                                  >
                                                    {moment(
                                                      data?.endDate
                                                    ).format(
                                                      "DD/MM/YYYY HH:mm "
                                                    )}
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  <td
                                                    style={{
                                                      padding: "10px 0px",
                                                      width: "40vw",
                                                    }}
                                                  >
                                                    {data?.userName}
                                                  </td>
                                                </>
                                              )}
                                              <td
                                                style={{
                                                  padding: "10px 0px",
                                                  width: "8vw",
                                                }}
                                              >
                                                {doHandleTotalSecToHours(
                                                  data?.startDate,
                                                  data?.endDate
                                                )}
                                              </td>
                                            </tr>
                                            <div
                                              style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display: "flex",
                                                marginLeft: "-1rem",
                                                // position: "relative",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <div
                                                className='time-entry-border-bottom'
                                                style={{
                                                  width: "89vw",
                                                  // height: 54,
                                                  borderBottom:
                                                    "1px solid rgb(231, 241, 255)",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              </table>
                            </tr>
                          </>
                        );
                      })
                  ) : groupByData.hasOwnProperty("message") ? (
                    <>
                      <tr>
                        <td
                          colSpan='5'
                          style={{
                            textAlign: "center",
                            height: 200,
                            color: "#212529",
                            fontWeight: 700,
                          }}
                        >
                          You will receive an email of this report (
                          {showMailResponse?.startDate} to{" "}
                          {showMailResponse?.endDate}) to {groupByData.message}{" "}
                          soon. Thank you.
                          {/* Result send on your mail */}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td
                          colSpan='5'
                          style={{
                            textAlign: "center",
                            height: 200,
                            color: "#212529",
                            fontWeight: 700,
                          }}
                        >
                          Data Not Found
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default MyTeam;
