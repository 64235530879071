import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
    container: {
        border: "0px solid rgba(255, 255, 255, 1)",
        // padding: "10px",
        margin: "-15px 0px 0px 0px",
        borderRadius: "4px"
    },
    line: {
        width: "100%",
        height: "55px",
        alignSelf: "center"
    }
};
class TotalShimmer extends Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.container} >
                    <Shimmer>
                        <div className={classes.line} />
                        <br />
                    </Shimmer>
                </div>
            </>
        );
    }
}

export default injectSheet(StyleSheet)(TotalShimmer);