import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { eyeSlash, eye, icon } from 'helper/constant.jsx';
import { authenticationService } from '../../services/authentication';
import { notify } from 'react-notify-toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/img/reactlogo.png';

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
};
const useStyles = makeStyles(styles);

const ResetPassword = ({ props }) => {
    const classes = useStyles();
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [passwordDetails, setPasswordDetails] = useState({
        newPassword: '',
        confirmPassword: '',
    });

    const handleShowTwoChange = () => {
        setShowTwo(!showTwo);
    };
    const handleShowThreeChange = () => {
        setShowThree(!showThree);
    };

    let successToast = successMsg => {
        toast.success(successMsg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };
    let errorToast = errMsg => {
        toast.error(errMsg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };

    const onChangePassword = e => {
        const target = e.target;
        const value = target.value;
        const name = target.id;

        setPasswordDetails({
            ...passwordDetails,
            [name]: value,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setIsLoader(true);
            let token = props.location.pathname.slice(15);
            const Response = await authenticationService.resetPassword(
                passwordDetails,
                token
            );
            if (Response.data) {
                // notify.show(Response?.data?.message ? Response.data.message : "Password Reset successfully" , 'success');
                successToast(
                    Response?.data?.message
                        ? Response.data.message
                        : 'Password Reset successfully'
                );
                setPasswordDetails({
                    newPassword: '',
                    confirmPassword: '',
                });
                window.localStorage.removeItem('currentUser');
                setTimeout(() => {
                    window.location.replace('/');
                }, 1000);
            }
            if (Response.response.data) {
                // notify.show(Response.response.data.message, 'error');
                errorToast(Response.response.data.message);
            }
            setIsLoader(false);
        } catch (e) {
            console.log(e);
            setIsLoader(false);
        }
    };

    return (
        <div align="center">
            <form
                onKeyDown={e => {
                    if (
                        props.location.pathname.slice(0, 14) == '/resetPassword'
                    ) {
                        if (
                            passwordDetails.Password != '' &&
                            passwordDetails.confirmPassword != ''
                        ) {
                            if (e.keyCode === 13) {
                                handleSubmit(e);
                            }
                        }
                    }
                }}
                tabIndex={0}
            >
                <GridContainer
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <GridItem
                        xs={11}
                        sm={11}
                        md={3}
                        lg={3}
                        style={{ marginTop: 100 }}
                    >
                        <Card>
                            <div style={{ marginTop: '20px' }}>
                                <img src={logo} />
                                <h4 style={{ fontWeight: 'bold', margin: 5 }}>
                                    Set New Password For
                                    <span style={{ color: '#f4590d' }}>
                                        <br />
                                        Daily Work Report
                                    </span>
                                </h4>
                            </div>
                            <CardBody>
                                <div className="">
                                    <div className="form-container">
                                        <form
                                            className="plr-60"
                                            // onSubmit={handleForgotPassword}
                                            style={{
                                                marginBottom: '40px',
                                                margin: '0px 40px',
                                            }}
                                        >
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <CustomInput
                                                    style={{ margin: 0 }}
                                                    name="newPassword"
                                                    labelText="Password"
                                                    id="newPassword"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    onChange={onChangePassword}
                                                    // error={passwordErr}
                                                    type={
                                                        showTwo
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={
                                                        passwordDetails.newPassword
                                                            ? passwordDetails.newPassword
                                                            : ''
                                                    }
                                                />
                                                <img
                                                    src={
                                                        showTwo ? eyeSlash : eye
                                                    }
                                                    width={20}
                                                    height={20}
                                                    alt="show password"
                                                    style={{
                                                        position: 'absolute',
                                                        top: 35,
                                                        right: 2,
                                                    }}
                                                    onClick={
                                                        handleShowTwoChange
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <CustomInput
                                                    style={{ margin: 0 }}
                                                    name="confirmPassword"
                                                    labelText="Confirm Password"
                                                    id="confirmPassword"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    onChange={onChangePassword}
                                                    // error={passwordErr}
                                                    type={
                                                        showThree
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={
                                                        passwordDetails.confirmPassword
                                                            ? passwordDetails.confirmPassword
                                                            : ''
                                                    }
                                                />
                                                <img
                                                    src={
                                                        showThree
                                                            ? eyeSlash
                                                            : eye
                                                    }
                                                    width={20}
                                                    height={20}
                                                    alt="show password"
                                                    style={{
                                                        position: 'absolute',
                                                        top: 35,
                                                        right: 2,
                                                    }}
                                                    onClick={
                                                        handleShowThreeChange
                                                    }
                                                />
                                            </div>
                                            {isLoader ? (
                                                <CircularProgress
                                                    size={28}
                                                    style={{
                                                        margin:
                                                            '40px 0px 0px 25px',
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        margin: '20px 0px',
                                                    }}
                                                >
                                                    <Button
                                                        disabled={
                                                            passwordDetails
                                                                .newPassword
                                                                .length <= 0 ||
                                                            passwordDetails
                                                                .confirmPassword
                                                                .length <= 0
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={e =>
                                                            handleSubmit(e)
                                                        }
                                                        color="info"
                                                        type="submit"
                                                        className="btn form-button"
                                                        style={{
                                                            borderRadius:
                                                                '50px',
                                                            width: '100%',
                                                            height: '40px',
                                                            fontSize: '14px',
                                                            color: 'white',
                                                            fontWeight: '600',
                                                            disply: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',

                                                            fontWeight: '800',
                                                            fontSize: '16px',
                                                            background:
                                                                '#f96117db',
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        Submit{' '}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M13.3 17.275q-.3-.3-.288-.725t.313-.725L16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.3-.713t.3-.712q.3-.3.713-.3t.712.3L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.6 4.6q-.275.275-.687.275t-.713-.3Z"
                                                            />
                                                        </svg>
                                                    </Button>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </CardBody>
                            <CardFooter align="center"></CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
            <ToastContainer />
        </div>
    );
};

export default ResetPassword;
