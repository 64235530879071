import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

function AppWrappar({ props, children }) {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        doCheckRedirectedRoute();
    }, []);

    const doCheckRedirectedRoute = async () => {
        const token = new URLSearchParams(location.search).get("token");
        let data = await JSON.parse(localStorage.getItem("currentUser"));
        if (data?.data?.isRepotingManager == false) {
            history.push("/panel/dashboard");
        }
        if (!!token) {
            if (!!data) {
                if (!!data?.data?.access_token) {
                    data.data.access_token = token;
                    window.location.reload();
                    history.push("/panel/user-scheduler");
                }
            } else {
                data = {
                    code: 200,
                    data: {
                        access_token: token,
                        isRepotingManager: true,
                    },
                    status: "success",
                };
            }
            localStorage.setItem("currentUser", JSON.stringify(data));
            window.location.reload();
            history.push("/panel/user-scheduler");
        }
    };
    return <>{children}</>;
}

export default AppWrappar;
