import { Box, Button, Input, Modal, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { authenticationService } from "services/authentication";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerWrapper from "../../assets/css/datepicker.style";
import DatePickerFilter from "components/DatePickerFilter/DatePickerFilter";
import { DATE_FILTER_TYPE } from "helper/constant";
import { downArrow } from "helper/constant";
import { close } from "helper/constant.jsx"
import { async } from 'rxjs';
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Grid, TextField, FormControl, InputLabel } from '@mui/material';
import CircularProgress from "@material-ui/core/CircularProgress";
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import UserSchedulerShimmer from "components/Shimmer/UserSchedulerShimmer";
import ExportReport from "assets/img/export.png";

const ResourceAllocationReport = () => {
    const UPPER_DIV_HEIGHT = 238;
    const [height, setHeight] = useState(window.innerHeight - UPPER_DIV_HEIGHT);
    const [selectedFilterClient, setSelectedFilterClient] = useState();
    const [selectedReport, setSelectedReport] = useState({ label: "Resource Allocation", value: "Resource Deployment" });
    const [resourceAllocationReport, setResourceAllocationReport] = useState([]);
    const [monthlyReport, setMonthlyReport] = useState([]);
    const [resourceAllocationCount, setResourceAllocationCount] = useState([]);
    const [grandTotal, setGrandTotal] = useState([])
    const [keysState, setKeysState] = useState([]);
    const [isUserSchedulerData, setIsUserSchedulerData] = useState(false);
    const [noAllocation, setNoAllocation] = useState();
    const [isToggle, setIsToggle] = useState();
    const [is2ndToggle, setIs2ndToggle] = useState();
    const [sendReport, setSendReport] = useState();
    const [selectedRMObject, setSelectedRMObject] = useState([]);
    const [RMList, setRMList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [getClient, setGetClient] = useState([]);
    const [getData, setGetData] = useState([]);
    const [tempDate, SetTempDate] = useState({
        startDate: "",
        endDate: "",
    });
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectionComplete, toggleSelectionComplete] = useState(false);
    const [datePickerActiveChip, SetDatePickerActiveChip] = useState(
        DATE_FILTER_TYPE.THISWEEK
    );
    const [currentDate, setCurrentDate] = useState([]);
    const [getTechCode, setGetTechCode] = useState([]);
    const [allTechnologies, setAllTechnologies] = useState([]);
    const [matchedRowIndex, setMatchedRowIndex] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [fullDay, setFullDay] = useState('8.5');
    const [quarterly, setQuarterly] = useState('6');
    const [halfDay, setHalfDay] = useState('4.5');
    const [twentyFivePercent, setTwentyFivePercent] = useState('2.5');
    const [monthlyCount, setMonthlyCount] = useState('176');
    const [monthlyFTE, setMonthlyFTE] = useState('176');
    const [rate, setRate] = useState('10');
    const [USDINR, setUSDINR] = useState('80');

    const [isAddLoader, setIsAddLoader] = useState(false);
    const [dayOfMonth, setDayOfMonth] = useState()

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - UPPER_DIV_HEIGHT);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        doClickOnThisMonth()
        handleClientChange()
        handleTechnologyChange()
        countOfMonthDays()
    }, [])

    const report = [
        { label: "Resource Allocation", name: "Resource Deployment", value: "Resource Deployment" },
        { label: "Productivity Summary", name: "Monthly Summary", value: "Monthly Summary" },
    ];

    let successToast = (successMsg) => {
        toast.success(successMsg, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    let errorToast = errMsg => {
        toast.error(errMsg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };
    document.addEventListener("DOMContentLoaded", function () {
        const exportBtn = document.getElementById("exportBtn");
        if (exportBtn) {
            exportBtn.addEventListener("click", function () {
                const table = document.getElementById("myTable");
                if (table) {
                    const rows = table.querySelectorAll("tr");
                    const processedRows = new Set(); // To store processed row identifiers
                    let expandedRowProcessed = false; // Flag to track if expanded row has been processed

                    // Create a new HTML document
                    const exportWindow = window.open();
                    exportWindow.document.write("<html><head><title>Table Data</title>");
                    exportWindow.document.write("<style>table, th, td { border: 1px solid black; border-collapse: collapse; padding: 5px; }</style>");
                    exportWindow.document.write("</head><body>");

                    // Create a new table
                    const exportTable = exportWindow.document.createElement("table");

                    rows.forEach(function (row) {
                        const cols = row.querySelectorAll("td, th");
                        const rowIdentifier = Array.from(cols).map(col => col.textContent.trim()).join("|");
                        if (!processedRows.has(rowIdentifier)) {
                            if (row.classList.contains("expanded-row")) {
                                if (!expandedRowProcessed) {
                                    const exportRow = exportWindow.document.createElement("tr");
                                    cols.forEach(function (col) {
                                        const exportCell = exportWindow.document.createElement("td");
                                        exportCell.textContent = col.textContent;
                                        exportRow.appendChild(exportCell);
                                    });
                                    exportTable.appendChild(exportRow);
                                    expandedRowProcessed = true;
                                }
                            } else {
                                const exportRow = exportWindow.document.createElement("tr");
                                cols.forEach(function (col) {
                                    const exportCell = exportWindow.document.createElement("td");
                                    exportCell.textContent = col.textContent;
                                    exportRow.appendChild(exportCell);
                                });
                                exportTable.appendChild(exportRow);
                            }
                            processedRows.add(rowIdentifier);
                        }
                    });

                    // Append the table to the new HTML document
                    exportWindow.document.body.appendChild(exportTable);
                    exportWindow.document.write("</body></html>");

                    // Close the new window after exporting CSV data
                    // setTimeout(function () {
                    //     exportWindow.close();
                    // }, 1000); // Adjust the delay as needed
                } else {
                    console.error("Element with ID 'myTable' not found.");
                }
            });
        } else {
            console.error("Element with ID 'exportBtn' not found.");
        }
    });



    const countOfMonthDays = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        let totalWeekdays = 0;
        for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
            const currentDay = new Date(year, month, day);
            if (currentDay.getDay() !== 0 && currentDay.getDay() !== 6) {
                totalWeekdays++;
            }
        }
        setDayOfMonth(totalWeekdays)
    }

    const colourStyles = {
        menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
                width: "5px",
                height: "0px",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#f4590d",
            },
        }),
        control: (base, state) => ({
            ...base,
            zIndex: state.isFocused ? "999" : "1",
            background: "#ffffff",
            // background: '#efefef',
            color: "#000000",
            fontWeight: "bold",
            padding: 20,
            // match with the menu
            height: 40,
            minHeight: 35,
            fontSize: 16,
            fontWeight: 400,
            cursor: "pointer",
            padding: "0px 8px",
            // border: 'none',
            // outline: 'none',
            // Overwrittes the different states of border
            borderColor: "#0000003b",
            background: state.isDisabled ? "#f0f0f0" : "",
            // Removes weird border around container
            boxShadow: null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: "#0000003b",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            return {
                ...styles,
                fontWeight: "400",
                color: isDisabled
                    ? "black"
                    : isSelected
                        ? "white"
                            ? "white"
                            : "black"
                        : "black",
                cursor: "pointer",
                backgroundColor: isSelected
                    ? "#f96117db"
                    : isFocused
                        ? "#f961173d"
                        : undefined,
                background: isDisabled ? "#f0f0f0" : "",
                ":hover": {
                    backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
                },
                ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
                },
            };
        },
    };

    const datePickerProps = () => {
        return { isRangerHoverAllowed: true };
    };

    const doHandleDatepickerOutsideClick = () => {
        setIsOpen(false);
        if (tempDate.startDate && tempDate.endDate) {
            SetTempDate({
                startDate,
                endDate,
            });

            setIsOpen(false);
        } else {
            // getCurrWeekDate(false);
            // doClickOnThisMonth(false)
        }
    };

    const doHandleDateChange = (date) => {
        // startDate
        if (!tempDate.startDate && !tempDate.endDate) {
            SetTempDate({ ...tempDate, startDate: date, endDate: null });
        }
        //endDate
        if (tempDate.startDate && !tempDate.endDate) {
            let dt1 = moment(tempDate.startDate, "YYYY-MM-DD")
                .add(-1, "M")
                .toDate();
            let dt2 = moment(date, "YYYY-MM-DD").toDate();
            let diffMonths = (dt2.getFullYear() - dt1.getFullYear()) * 12;
            diffMonths -= dt1.getMonth();
            diffMonths += dt2.getMonth();

            if (diffMonths > 6 && moment(date).isAfter(tempDate.startDate)) {
                // errorToast("Please select a range of maximum 6 months.");
                SetTempDate({
                    ...tempDate,
                    startDate: tempDate.startDate,
                    endDate: null,
                });
                return;
            }
            SetTempDate({ ...tempDate, endDate: date });
        }

        if (moment(date).isSame(tempDate.startDate)) {
            SetTempDate({
                ...tempDate,
                startDate: tempDate.startDate,
                endDate: date,
            });
        }

        if (
            tempDate.startDate &&
            !tempDate.endDate &&
            !moment(date).isAfter(tempDate.startDate) &&
            !moment(date).isSame(tempDate.startDate)
        ) {
            SetTempDate({
                ...tempDate,
                startDate: tempDate.startDate,
                endDate: null,
            });
            return;
        }

        if (tempDate.startDate && tempDate.endDate) {
            SetTempDate({ ...tempDate, startDate: date, endDate: null });
        }
    };

    const handleSelect = (date) => {
        //onChange is not fired if selecting same date - workaround to fire doHandleDateChange
        if (
            !selectionComplete &&
            startDate &&
            !endDate &&
            sameDay(date, tempDate.startDate)
        ) {
            doHandleDateChange(date);
        }
    };

    const doClickOnToday = () => {
        SetDatePickerActiveChip(DATE_FILTER_TYPE.TODAY);
        SetTempDate({
            startDate: new Date(),
            endDate: new Date(),
        });
    };
    const doClickOnThisWeek = () => {
        let wDate = new Date();
        let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
        let first = wDate.getDate() - dDay + 1;
        let firstDayWeek = new Date(wDate.setDate(first));
        setStartDate(firstDayWeek);
        setEndDate(new Date());

        SetDatePickerActiveChip(DATE_FILTER_TYPE.THISWEEK);
        SetTempDate({
            startDate: firstDayWeek,
            endDate: new Date(),
        });
        doGetDate(firstDayWeek, new Date(wDate.getFullYear(), wDate.getDay() - dDay + 1))

    };

    const doClickOnYesterday = () => {
        var date = new Date();
        date.setDate(date.getDate() - 1);

        SetDatePickerActiveChip(DATE_FILTER_TYPE.YESTERDAY);
        SetTempDate({
            startDate: date,
            endDate: date,
        });
    };

    const doClickOnCurrentMonth = (isFetchRecordRequired = true) => {
        let date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        let firstDayOfThisMonth = new Date(y, m, 1);
        setStartDate(firstDayOfThisMonth);
        setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
        // toggleSelectionComplete(true);
        if (isFetchRecordRequired) {
            //   doHandleMyTeamsFilter("", firstDayWeek, new Date());
        }
        SetDatePickerActiveChip(DATE_FILTER_TYPE.THISMONTH);
        SetTempDate({
            startDate: firstDayOfThisMonth,
            endDate: new Date(),
        });
        doGetDate(firstDayOfThisMonth, new Date(date.getFullYear(), date.getMonth() + 1, 0))

        // handleSchedulerUserFilterList(firstDayOfThisMonth, new Date(date.getFullYear(), date.getMonth() + 1, 0))
        // doGetDate(firstDayWeek, new Date(wDate.getFullYear(), wDate.getDay() - dDay + 1))
        // handleResourceAllocationReport(firstDayOfThisMonth, new Date(), selectedFilterClient)
    };

    const doClickOnThisMonth = (isFetchRecordRequired = false) => {
        let date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        let firstDayOfThisMonth = new Date(y, m, 1);
        setStartDate(firstDayOfThisMonth);
        // setEndDate(new Date());
        setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
        // toggleSelectionComplete(true);
        if (isFetchRecordRequired) {
            //   doHandleMyTeamsFilter("", firstDayWeek, new Date());
        }
        SetDatePickerActiveChip(DATE_FILTER_TYPE.THISMONTH);
        SetTempDate({
            startDate: firstDayOfThisMonth,
            // endDate: new Date(),
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0)
        });
        handleResourceAllocationReport(firstDayOfThisMonth, new Date(date.getFullYear(), date.getMonth() + 1, 0), selectedFilterClient)
        // handleResourceAllocationCountReport(firstDayOfThisMonth, new Date(date.getFullYear(), date.getMonth() + 1, 0))
        // handleMonthlyReport(firstDayOfThisMonth, new Date(date.getFullYear(), date.getMonth() + 1, 0), selectedFilterClient)
    };

    const doClickOnLast7Days = () => {
        var date = new Date();
        date.setDate(date.getDate() - 7);

        SetDatePickerActiveChip(DATE_FILTER_TYPE.LAST7DAYS);
        SetTempDate({
            startDate: date,
            endDate: new Date(),
        });
    };
    const doClickOnLastMonth = () => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

        SetDatePickerActiveChip(DATE_FILTER_TYPE.LASTMONTH);
        SetTempDate({
            startDate: firstDay,
            endDate: lastDay,
        });
    };
    const doClickOnLastWeek = () => {
        let beforeOneWeek = new Date(
            new Date().getTime() - 60 * 60 * 24 * 7 * 1000
        );
        let beforeOneWeek2 = new Date(beforeOneWeek);
        let day = beforeOneWeek.getDay();
        let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
        let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
        let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

        SetDatePickerActiveChip(DATE_FILTER_TYPE.LASTWEEK);
        SetTempDate({
            startDate: lastMonday,
            endDate: lastSunday,
        });
    };
    const doClickOnCancel = () => {
        if (tempDate.startDate && tempDate.endDate) {
            SetTempDate({
                // startDate,
                // endDate,
                startDate: startDate,
                endDate: endDate,
            });
        } else {
            doClickOnThisMonth(false)
        }
        setIsOpen(false);
    };
    const sameDay = (d1, d2) => {
        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    };

    const doHandleShowDateClick = () => {
        setStartDate(tempDate.startDate);
        setEndDate(tempDate.endDate);
        setIsOpen(false);
    };

    const doGetDate = async (startDate, endDate) => {

        const start_date = await startDate
        const end_date = await endDate

        const date = new Date(start_date); // Initialize date with startDate
        let all_days = [];
        while (date <= end_date) {
            let d =
                date.getFullYear() +
                "-" +
                (date.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                date.getDate().toString().padStart(2, "0");
            all_days.push(d); // Push the date to the all_days array directly
            date.setDate(date.getDate() + 1);
        }
        setCurrentDate(all_days)
    }

    const CustomInputs = ({ startDate, endDate }) => {
        return (
            <div
                className='datepicker'
                style={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    height: "35px",
                    justifyContent: "center",
                    width: "13rem",
                    borderRadius: "5px",
                }}
                onClick={() => { doHandleDatePickerOpen(); }}
            >
                <div
                    style={{
                        paddingTop: 0,
                        display: "flex",
                        alignItems: "center",
                        fontSize: 16,
                    }}
                >

                    <div style={{ color: "#666" }}>
                        {`${startDate ? moment(startDate).format("DD/MM/YYYY") : "-"} to ${endDate ? moment(endDate).format("DD/MM/YYYY") : "-"
                            }`}
                    </div>
                </div>
            </div>
        );
    };

    const doHandleDatePickerOpen = () => {
        setIsOpen(true);
        SetTempDate({
            startDate: startDate,
            endDate: endDate,
        });
    };

    // List of technology
    const handleTechnologyChange = async () => {
        try {
            const Response = await authenticationService.fetchMyTeamsTechList();
            if (Response?.data) {
                setGetTechCode([
                    ...Response?.data.map((value, key) => {
                        return {
                            id: value?.id,
                            value: value?.id,
                            label: value?.technologyName,
                        };
                    }),
                ]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // API integration of filter date, employee, project, technology
    const handleResourceAllocationReport = async (startDate, endDate, clientId, type) => {
        try {
            let object = {
                "startDate": moment(startDate).format('YYYY-MM-DD'),
                "endDate": moment(endDate).format('YYYY-MM-DD'),
                "client": clientId == undefined && clientId?.value == null ? [] : [clientId?.value],
                "type": selectedReport?.value == "Resource Deployment" ? 1 : 2,
            }
            setIsUserSchedulerData(true);
            // setIsUserSchedulerData(true)
            const Response = await authenticationService.fetchReport(object);
            if (Response) {
                if (selectedReport?.value == "Resource Deployment") {
                    setResourceAllocationReport(Response?.data?.data)
                    setGrandTotal(Response?.data?.grandTotals)
                    setMonthlyReport('')
                }
                else {
                    setMonthlyReport(Response?.data)
                    setResourceAllocationReport('')
                    setGrandTotal('')
                }
            }

        } catch (error) {
            console.log(error);
            // setIsUserSchedulerData(false)
        }
        setIsUserSchedulerData(false)
    };

    // const handleMonthlyReport = async (startDate, endDate, clientId, type) => {
    //     try {
    //         let object = {
    //             "startDate": moment(startDate).format('YYYY-MM-DD'),
    //             "endDate": moment(endDate).format('YYYY-MM-DD'),
    //             "client": clientId == undefined && clientId?.value == null ? [] : [clientId?.value],
    //             "type": 2,
    //         }
    //         // setIsUserSchedulerData(true)
    //         const Response = await authenticationService.fetchReport(object);
    //         if (Response) {
    //             setMonthlyReport(Response?.data)
    //             // setGrandTotal(Response?.data?.grandTotals)
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         // setIsUserSchedulerData(false)
    //     }
    //     // setIsUserSchedulerData(false)
    // };

    const handleResourceAllocationCountReport = async (startDate, endDate) => {
        try {
            let object = {
                "startDate": moment(startDate).format('YYYY-MM-DD'),
                "endDate": moment(endDate).format('YYYY-MM-DD'),
            }
            // setIsUserSchedulerData(true)
            const Response = await authenticationService.fetchResourceAllocationClientCount(object);
            if (Response) {
                setResourceAllocationCount(Response?.data)
            }

        } catch (error) {
            console.log(error);
            // setIsUserSchedulerData(false)
        }
        // setIsUserSchedulerData(false)
    };


    const handleClientChange = async () => {
        try {
            const Response = await authenticationService.fetchMyTeamsClientList();
            if (Response?.data) {
                setGetClient([
                    ...Response?.data.map((value, key) => {
                        return {
                            id: value?.id,
                            value: value?.id,
                            label: value?.clientName,
                        };
                    }),
                ]);
            }
        } catch (error) {
            console.log(error);
        }
    };


    // const employeeCountByClientAndTechnology = {};

    // // Iterate over each response item
    // resourceAllocationReport.forEach(client => {
    //     // console.log(client,"cccc")
    //     // Iterate over each project
    //     client.projects.forEach(project => {
    //         // console.log(project,"project...")
    //         // Iterate over each employee in the project
    //         project.employees.forEach(employee => {
    //             // console.log(employee,"employe...")
    //             const { technologyId } = employee;

    //             // console.log(technologyId,"technologyId......")
    //             // Initialize employee count for client and technology if it doesn't exist
    //             if (!employeeCountByClientAndTechnology[client?.clientId]) {
    //                 employeeCountByClientAndTechnology[client?.clientId] = {};
    //             }
    //             if (!employeeCountByClientAndTechnology[client?.clientId][technologyId]) {
    //                 employeeCountByClientAndTechnology[client?.clientId][technologyId] = 0;
    //             }

    //             // Increment employee count for client and technology
    //             employeeCountByClientAndTechnology[client.clientId][technologyId]++;
    //         });
    //     });
    // });

    // const employeeCountArray = [];
    // // Iterate over each client
    // Object.keys(employeeCountByClientAndTechnology).forEach(clientId => {
    //     const clientEmployeeCounts = [];
    //     // Iterate over each technology within the client
    //     Object.keys(employeeCountByClientAndTechnology[clientId]).forEach(technologyId => {
    //         // Get the employee count for the current client and technology
    //         const employeeCount = employeeCountByClientAndTechnology[clientId][technologyId];

    //         // Push an object containing technology ID and employee count to the client's array
    //         clientEmployeeCounts.push({
    //             technologyId: technologyId,
    //             employeeCount: employeeCount
    //         });
    //     });
    //     employeeCountArray.push({
    //         clientId: clientId,
    //         employeeCounts: clientEmployeeCounts
    //     });
    // });

    // function calculateTotalClient(employeeCountByClientAndTechnology) {
    //     const totalClient = [];

    //     // Iterate over each client
    //     Object.keys(employeeCountByClientAndTechnology).forEach(clientId => {
    //         // Iterate over each technology within the client
    //         Object.keys(employeeCountByClientAndTechnology[clientId]).forEach(technologyId => {
    //             // Get the employee count for the current client and technology
    //             const employeeCount = employeeCountByClientAndTechnology[clientId][technologyId];

    //             // Update totalClient with the employee count for each technologyId
    //             const index = totalClient.findIndex(item => item.technologyId === technologyId);
    //             if (index === -1) {
    //                 // If technologyId not found, add it to totalClient array
    //                 totalClient.push({ technologyId: technologyId, totalEmployeeCount: employeeCount });
    //             } else {
    //                 // If technologyId found, update its totalEmployeeCount
    //                 totalClient[index].totalEmployeeCount += employeeCount;
    //             }
    //         });
    //     });

    //     return totalClient;
    // }

    // // Example usage:
    // const totalClientArray = calculateTotalClient(employeeCountByClientAndTechnology);

    // Array to store all clients with their projects
    // const allClientsWithProjects = [];
    // // Iterate over each response item
    // resourceAllocationReport.forEach(client => {
    //     const clientObject = {
    //         clientId: client.clientId,
    //         clientName: client.clientName,
    //         totalEmployees: client.totalEmployees,
    //         clientFTE: client.clientFTE,
    //         projects: [],
    //         employeeCountData: [],// Initialize the employeeCount array
    //         // totalClient: totalClientArray
    //     };

    //     // // Check if the client's clientId matches the specified clintID
    //     // if (client?.clientId == client?.clientId) {
    //     //     // Find the corresponding employeeCountArray item
    //     //     const matchingEmployeeCount = employeeCountArray?.find(item => item?.clientId == client?.clientId);
    //     //     if (matchingEmployeeCount && Array.isArray(matchingEmployeeCount.employeeCounts)) {
    //     //         // If the employeeCounts array exists, assign it to clientObject.employeeCount
    //     //         clientObject.employeeCountData = matchingEmployeeCount?.employeeCounts;
    //     //     } else {
    //     //         console.error('Invalid employeeCounts array for client ID 1:', matchingEmployeeCount);
    //     //     }
    //     // }

    //     // Iterate over each project
    //     // client.projects.forEach(project => {
    //     //     // Create a project object
    //     //     const projectObject = {
    //     //         projectId: project.projectId,
    //     //         projectName: project.projectName,
    //     //         projectCode: project.projectCode,
    //     //         totalEmployees: project.totalEmployees,
    //     //         projectFTE: project?.projectFTE,
    //     //         technologies: []
    //     //     };

    //     // Iterate over each employee in the project
    //     // project.employees.forEach(employee => {
    //     //     // Get the technologyId and technologyName of the employee
    //     //     const { technologyId, technologyName } = employee;

    //     //     // Find or create the technology object within the project
    //     //     let technology = projectObject.technologies.find(tech => tech.technologyId === technologyId);
    //     //     if (!technology) {
    //     //         technology = {
    //     //             technologyId,
    //     //             technologyName,
    //     //             employees: [],
    //     //             employeeLength: 0, // Initialize employee length to 0,
    //     //             // teqTotal: employeeCountArray,
    //     //         };
    //     //         projectObject.technologies.push(technology);
    //     //     }

    //     //     // Push the employee to the employees array of the corresponding technology
    //     //     technology.employees.push(employee);

    //     //     // Create a Set to store unique user IDs
    //     //     const uniqueUserIds = new Set();

    //     //     // Iterate over employees array to count unique user IDs
    //     //     technology.employees.forEach(emp => {
    //     //         uniqueUserIds.add(emp.userId);
    //     //     });

    //     //     // Update the employeeLength based on the number of unique user IDs
    //     //     technology.employeeLength = uniqueUserIds.size;
    //     // });

    //     // // Update the employeeLength for each technology
    //     // projectObject.technologies.forEach(tech => {
    //     //     tech.employeeLength = tech.employees.length;
    //     // });

    //     // Push the project object to the projects array of the client
    //     // clientObject.projects.push(projectObject);
    // });

    // Push the client object encapsulated in an array to the array of all clients
    // allClientsWithProjects.push(clientObject);
    // });


    // const totalProjects = project.employees.forEach(employee => {
    //     // Get the technologyId and technologyName of the employee
    //     const { technologyId, technologyName } = employee;

    //     // Find or create the technology object within the project
    //     let technology = projectObject.technologies.find(tech => tech.technologyId === technologyId);
    //     if (!technology) {
    //         technology = {
    //             technologyId,
    //             technologyName,
    //             employees: [],
    //             employeeLength: 0, // Initialize employee length to 0,
    //             // teqTotal: employeeCountArray,
    //         };
    //         projectObject.technologies.push(technology);
    //     }

    //     // Push the employee to the employees array of the corresponding technology
    //     technology.employees.push(employee);

    //     // Create a Set to store unique user IDs
    //     const uniqueUserIds = new Set();

    //     // Iterate over employees array to count unique user IDs
    //     technology.employees.forEach(emp => {
    //         uniqueUserIds.add(emp.userId);
    //     });

    //     // Update the employeeLength based on the number of unique user IDs
    //     technology.employeeLength = uniqueUserIds.size;
    // });

    const TotalClinetsCount = () => {
        // const Index = getTechCode.map((item, key) => {
        //     return totalClientArray?.filter((getdataId, index) => {
        //         return item?.id == getdataId?.technologyId ? { ...getdataId } : ""
        //     })
        // })
        // return Index.map((item, key) => {
        //     return <td style={{ border: "1px solid #0000001a", padding: "5px", textAlign: "center", fontWeight: "600" }}>{item[0]?.hasOwnProperty("totalEmployeeCount") ? item[0]?.totalEmployeeCount : "0"}</td>
        // })
        return (<></>)
    }

    // const ClientsOfLength = (data) => {
    //     const Index = getTechCode.map((item, key) => {
    //         return data?.data?.employeeCountData?.filter((getdataId, index) => {
    //             return item?.id == getdataId?.technologyId ? { ...getdataId } : ""
    //         })
    //     })
    //     return Index.map((item, key) => {
    //         return <td style={{ border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{item[0]?.hasOwnProperty("employeeCount") ? item[0]?.employeeCount : "0"}</td>
    //     })
    // }


    // const ClientsOfLength = ({ data, IndexOfMainDiv }) => {
    //     const flattenPreserveEmpty = arr => {
    //         return arr?.map(innerArr => {
    //             if (Array?.isArray(innerArr) && innerArr?.length === 0) {
    //                 return {};
    //             } else if (Array?.isArray(innerArr)) {
    //                 return innerArr?.map(subArr => subArr?.length === 0 ? {} : subArr).flat();
    //             } else {
    //                 return innerArr;
    //             }
    //         });
    //     };
    //     const NewArray = resourceAllocationCount.map((item, key) => {
    //         return getTechCode?.map((getdataId, index) => {
    //             return item?.technologies?.filter((data, i) => {
    //                 return data.technologyId == getdataId?.id && data
    //             })
    //         })
    //     })
    //     const flattenedArray = flattenPreserveEmpty(NewArray);
    //     return flattenedArray.map((data, newIndex) => {
    //         return newIndex == IndexOfMainDiv ? data.map((newArrayData, i) => {
    //             return newArrayData?.hasOwnProperty("count") ?
    //                 <td style={{ border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>
    //                     {getTechCode?.map((getdataId, index) => {
    //                         return newArrayData.technologyId == getdataId?.id ? newArrayData?.count : ""
    //                     })
    //                     }</td> : <td style={{ border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>0</td>

    //         }) : ""
    //     })
    // }

    // const EmployeeOfLength = (data) => {
    //     const Index = getTechCode.map((item, key) => {
    //         return data?.data?.technologies?.filter((getdataId, index) => {
    //             return item?.id == getdataId?.technologyId ? { ...getdataId } : ""
    //         })
    //     })
    //     // console.log(Index, "getdataId");
    //     // console.log(Index, "Index");
    //     // return Index.map((item, key) => {
    //     //     return <td style={{ border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{item[0]?.hasOwnProperty("employeeLength") ? item[0]?.employeeLength : "0"}</td>
    //     // })
    //     return (<></>)
    // }

    const EmployeeOfLength = (data) => {
        const Index = getTechCode.map((item, key) => {
            return data?.data?.technologies?.filter((getdataId, index) => {
                return item?.id == getdataId?.technologyId ? { ...getdataId } : ""
            })
        })
        // console.log(Index, "getdataId");
        // console.log(Index, "Index");
        // return Index.map((item, key) => {
        //     return <td style={{ border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{item[0]?.hasOwnProperty("employeeLength") ? item[0]?.employeeLength : "0"}</td>
        // })
        return (<></>)
    }

    const EmployeeOfDetails = (datas) => {
        const Index = getTechCode.map((item, key) => {
            return datas?.datas?.technologies?.filter((getdataId, index) => {
                return item?.id == getdataId?.technologyId ? { ...getdataId } : ""
            })
        });

        const UpdatedArray = Index.map((item, key) => {
            return item[0]?.employees.map((data, key) => {
                return { ...data }
            })
        })
        return UpdatedArray.map((item, key) => {
            return <td style={{ border: "1px solid #0000001a" }}>{item.length > 0 ? item.map((values, key) => {
                return <div style={{ border: "1px solid #0000001a", margin: "-2px", padding: "0.8rem" }}>
                    <div>{values?.code} - {values?.name}</div>
                    <div>
                        {moment(values?.startDate).format(
                            'DD-MM-YYYY'
                        )} -  {moment(values?.dueDate).format(
                            'DD-MM-YYYY'
                        )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{values?.percentageAllocation}</div>
                        <div>{values?.totalWorkingHours} hrs</div>
                    </div>
                </div>
            }) : ""}</td>
        })
    }

    const renderHeaderRow = () => {
        return (
            <tr>
                {getTechCode.map((header, index) => (
                    <th key={index}>{header?.label}</th>
                ))}
            </tr>
        );
    };

    function doHandleDownloadingResourceAllocation(content) {
        // if (!contentType) contentType = 'application/octet-stream';
        var a = document.createElement("a");
        var blob = new Blob([content]);
        a.href = window.URL.createObjectURL(blob);
        a.download = `resource_allocation&Productivity_${moment(
            new Date()
        ).format("DD-MM-YYYY")}.xlsx`;
        a.click();
    }

    const handleOnClickExportDownloadCSV = async (startDate, endDate, clientId, type) => {
        // setFileExportWithOutPenaltyEmployeeLoader(true);
        // setExportLoader(true);
        let object = {
            "startDate": moment(startDate).format('YYYY-MM-DD'),
            "endDate": moment(endDate).format('YYYY-MM-DD'),
            "client": clientId == undefined && clientId?.value == null ? [] : [clientId?.value],
            "type": 3,
        }
        try {

            // const axiosA = axios.CancelToken;
            // cancelSource.current = axiosA.source();
            const response = await authenticationService.fetchReport(
                object
                // cancelSource.current.token
            );
            const { status, data, error } = response;
            if (status == false || status == 400) {
                if (data.message == "Cancelled") {
                    // setSelectedDatevalue("");
                    // setFileExportWithOutPenaltyEmployeeLoader(false);
                }
            }
            if (status && data) {
                doHandleDownloadingResourceAllocation(data);
                // doHandleDownloadingCSVFile(data);
            }
            // setSelectedDatevalue("");
            // setFileExportWithOutPenaltyEmployeeLoader(false);
            // setExportLoader(false);
        } catch (error) {
            // setSelectedDatevalue("");
            // setFileExportWithOutPenaltyEmployeeLoader(false);
            // setExportLoader(false);
            console.log(error);
        }
    };
    const techMatch = getTechCode.map((e) => e.id)
    // console.log(techMatch, "techMatch")

    // Integrate Get Setting Api
    const doHandleGetSetting = async (type) => {

        try {
            const Response = await authenticationService.fetchResourceAllocationSetting(selectedReport?.value == "Resource Deployment" ? 1 : 2);
            if (Response?.data) {


                if (type == 1) {


                    const { hundredPercentage, seventyFivePercentage, fiftyPercentage, twentyFivePercentage, oneMonthFTE } = Response?.data
                    setFullDay(hundredPercentage ? hundredPercentage : '8.5')
                    setQuarterly(seventyFivePercentage ? seventyFivePercentage : '6')
                    setHalfDay(fiftyPercentage ? fiftyPercentage : '4.5')
                    setTwentyFivePercent(twentyFivePercentage ? twentyFivePercentage : '2.5')
                    setMonthlyCount(oneMonthFTE ? oneMonthFTE : '176')
                }
                else {
                    const { oneMonthFTE, rate, usdInr } = Response?.data
                    setMonthlyFTE(oneMonthFTE ? oneMonthFTE : '176')
                    setRate(rate ? rate : '10')
                    setUSDINR(usdInr ? usdInr : '80')
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const doHandleAddSetting = async () => {
        try {

            const resource = {
                "twentyFivePercentage": twentyFivePercent,
                "fiftyPercentage": halfDay,
                "seventyFivePercentage": quarterly,
                "hundredPercentage": fullDay,
                "oneMonthFTE": monthlyCount,
                "type": 1
            }
            const productivity = {
                "oneMonthFTE": monthlyFTE,
                "rate": rate,
                "usdInr": USDINR,
                "type": 2
            }
            setIsAddLoader(true)
            const Response = await authenticationService.addResourceAllocationSetting(selectedReport?.value == "Resource Deployment" ? resource : productivity)
            if (Response.code == 200) {
                setIsAddLoader(false)
                setOpenModal(!openModal);
                setFullDay(Response?.data?.hundredPercentage)
                setQuarterly(Response?.data?.seventyFivePercentage)
                setHalfDay(Response?.data?.fiftyPercentage)
                setTwentyFivePercent(Response?.data?.twentyFivePercentage)
                setMonthlyCount('')
                setIsAddLoader(false)
                successToast('Resource allocation setting Added successfully')
            } else {
                errorToast(Response?.message);
            }

        } catch (error) {
            console.log(error)
            errorToast("Bad Request");
        }
    }



    const doCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <div style={{ margin: "0 30px" }}>
                <div style={{
                    background: "white", padding: " 5px 15px", borderRadius: "10px", marginTop: "6rem",
                }}>
                    <div className="scheduler_filter" style={{ display: "flex", marginBottom: "10px", marginTop: "0.5rem", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex" }}>
                            <div className='date_picker' style={{ display: "flex" }}>
                                <div
                                    style={{
                                        borderRadius: "5px",
                                        // width: "230px",
                                        height: "2.5rem",
                                        // margin: "4px 30px",
                                        marginRight: "10px",
                                        display: "flex",
                                        alignItems: 'center',
                                        border: "1px solid #0000003b"
                                    }}
                                >
                                    <i
                                        className='fa fa-angle-left '
                                        style={{
                                            margin: "0px 12px",
                                            color: "#57646b",
                                            fontSize: "20px",
                                            // padding: "7.5px 0px",
                                        }}
                                    />
                                    <div>
                                        <DatePickerWrapper {...datePickerProps}>
                                            <DatePicker
                                                selectsRange
                                                isRangerHoverAllowed={
                                                    tempDate.startDate && tempDate.endDate ? true : false
                                                }
                                                open={isOpen}
                                                selected={
                                                    new Date()
                                                }
                                                // maxDate={new Date()}
                                                onClickOutside={doHandleDatepickerOutsideClick}
                                                onInputClick={() => setIsOpen(true)}
                                                onChange={doHandleDateChange}
                                                onSelect={handleSelect}
                                                selectsEnd={true}
                                                // minDate={minDate}
                                                monthsShown={1}
                                                // adjustDateOnChange
                                                shouldCloseOnSelect={false}
                                                startDate={tempDate.startDate}
                                                endDate={tempDate.endDate}
                                                className='custom-datepicker'
                                                calendarClassName='custom-calender-class'
                                                showPreviousMonths
                                                disabledKeyboardNavigation
                                                popperPlacement='top-start'
                                                // popperProps={{
                                                //   positionFixed: true // use this to make the popper position: fixed
                                                // }}
                                                popperProps={{
                                                    strategy: "fixed", // use this to make the popper position: fixed
                                                }}
                                                customInput={
                                                    <CustomInputs
                                                        className='date-range'
                                                        startDate={isOpen ? tempDate.startDate : startDate}
                                                        endDate={isOpen ? tempDate.endDate : endDate}
                                                    />
                                                }
                                            >
                                                <DatePickerFilter
                                                    doClickOnToday={doClickOnToday}
                                                    doClickOnThisWeek={doClickOnThisWeek}
                                                    doClickOnYesterday={doClickOnYesterday}
                                                    doClickOnThisMonth={doClickOnThisMonth}
                                                    doClickOnLast7Days={doClickOnLast7Days}
                                                    doClickOnLastMonth={doClickOnLastMonth}
                                                    doClickOnLastWeek={doClickOnLastWeek}
                                                    doClickOnCancel={doClickOnCancel}
                                                    // doHandleMyTeamsFilter={doHandleMyTeamsFilter}
                                                    isShowAllowed={tempDate.startDate && tempDate.endDate}
                                                    dateRangeEnabled
                                                    doHandleShowDateClick={doHandleShowDateClick}
                                                    datePickerActiveChip={datePickerActiveChip}
                                                    DATE_FILTER_TYPE={DATE_FILTER_TYPE}
                                                // selectedFilterPercentage={selectedFilterPercentage}
                                                // selectedNoAllocation={selectedNoAllocation}
                                                />
                                            </DatePicker>
                                        </DatePickerWrapper>
                                    </div>
                                    <i
                                        className='fa fa-angle-right '
                                        style={{
                                            margin: "0px 12px",
                                            color: "#57646b",
                                            fontSize: "20px",
                                            // padding: "7.5px 0px",
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "grid",
                                    width: "13rem",
                                    marginRight: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <Select
                                    maxMenuHeight={350}
                                    isClearable
                                    styles={colourStyles}
                                    placeholder="Select Client"
                                    value={selectedFilterClient}
                                    onChange={(e, value) => {
                                        //   setProjectId(e);
                                        setSelectedFilterClient(e, value)
                                    }}
                                    options={getClient &&
                                        getClient.sort(function (a, b) {
                                            const nameA = a?.label?.toUpperCase();
                                            const nameB = b?.label?.toUpperCase();
                                            if (nameA > nameB) {
                                                return 1;
                                            }
                                            if (nameA < nameB) {
                                                return -1;
                                            }
                                            return 0;
                                        })}
                                />
                            </div>

                            <div
                                style={{
                                    display: "grid",
                                    width: "19 rem",
                                    marginRight: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <Select
                                    maxMenuHeight={350}
                                    // isClearable
                                    styles={colourStyles}
                                    placeholder="Select"
                                    value={selectedReport}
                                    onChange={(e, value) => {
                                        //   setProjectId(e);
                                        setSelectedReport(e, value)
                                    }}
                                    options={report}
                                />
                            </div>

                            <div
                                style={{
                                    alignItems: "center",
                                    // display: "flex",
                                    // // justifyContent: "center",
                                    // width: "5%",
                                    margin: "0px 7px",
                                }}
                            >
                                <Button
                                    style={{
                                        background: "#f96117db",
                                        // fontWeight: "bold",
                                        borderRadius: "10px",
                                        color: "#FFF",
                                        // width: "30%",
                                        padding: '0.5rem 3rem',
                                        textTransform: "none"
                                    }}
                                    onClick={() => {
                                        // filterDayShow(startDate, endDate); handleSchedulerUserFilterList(startDate, endDate, selectedFilterEmployee?.id, selectedFilterProject, selectedFilterTechnology, selectedFilterPercentage, selectedFilterClient);
                                        // setOpenModal(true);
                                        handleResourceAllocationReport(startDate, endDate, selectedFilterClient, selectedReport)
                                    }}
                                >
                                    Apply
                                </Button>

                                {/* <button id="exportBtn">Export to CSV</button> */}
                            </div>

                        </div>

                        <div style={{ display: "flex" }}>

                            <div
                                style={{
                                    alignItems: "center",
                                    // display: "flex",
                                    // // justifyContent: "center",
                                    // width: "5%",
                                    margin: "0px 7px",
                                }}
                            >
                                <Button
                                    style={{
                                        background: "#f96117db",
                                        // fontWeight: "bold",
                                        borderRadius: "10px",
                                        color: "#FFF",
                                        // width: "30%",
                                        padding: '0.5rem 0.5rem',
                                        textTransform: "none"
                                    }}
                                    onClick={() => {
                                        doHandleGetSetting(selectedReport?.value == "Resource Deployment" ? 1 : 2,)
                                        setOpenModal(true);
                                    }}
                                >
                                    {/* <SettingsIcon /> */}
                                    {/* <SettingsBackupRestoreIcon /> */}
                                    <ManageHistoryIcon />
                                </Button>

                            </div>

                            <div
                            // style={{ marginTop: "0.3rem", marginRight: "1rem" }}
                            >
                                <Button
                                    className="new-allocation"
                                    style={{
                                        // background: "#f96117db",
                                        // fontWeight: "bold",
                                        borderRadius: "10px",
                                        color: "#193CE8",
                                        width: "10rem",
                                        textTransform: "capitalize",
                                        padding: "0.5rem",
                                        fontSize: "14px",
                                        // border: "1px solid #3ca38a",
                                        // padding:"0px 10px 0px 10px"
                                    }}
                                    onClick={() => {
                                        // setIsprojectAdd(true); doOpanModal(" ", " ", " ")
                                        handleOnClickExportDownloadCSV(startDate, endDate, selectedFilterClient);
                                    }}
                                >
                                    <span
                                        style={{ padding: "0px 0.8rem 0px 0.1rem", marginTop: "5px" }}
                                    >
                                        <img
                                            src={ExportReport}
                                            alt=""
                                            width={"20px"}
                                            height={"20px"}
                                        />
                                    </span>

                                    <span
                                        style={{ padding: "0px 0.3rem 0rem  0rem", fontSize: "16px" }}
                                    >
                                        Export Report
                                    </span>
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>

                {isUserSchedulerData ? (<div style={{}}>
                    <UserSchedulerShimmer />
                </div>) : (<>
                    <div style={{ background: "white", marginTop: "20px", padding: "20px", borderRadius: "10px", display: resourceAllocationReport ? "block" : " none" }}>
                        <div
                            className='user_list'
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "left",
                                overflowX: "scroll",
                                minWidth: "90%",
                                maxHeight: `${height}px`,
                            }}
                        >
                            <table id="myTable" style={{ width: "100%", borderCollapse: "collapse" }}>
                                <tr
                                    style={{ width: "100%", background: "#f2f2f2" }}
                                >
                                    <th style={{
                                        padding: "10px 0px",
                                        border: "1px solid #0000001a",
                                        position: "sticky",
                                        top: 0,
                                        backgroundColor: "#f2f2f2",
                                        zIndex: 10,
                                        // left: 0
                                        //  width: "10vw"
                                    }}></th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "8rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // left: 0
                                            // width: "5vw",
                                        }}
                                    >
                                        Client
                                    </th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Head count/FTE
                                    </th>
                                    {getTechCode.map((item) => {
                                        return (<><th style={{
                                            padding: "10px",
                                            border: "1px solid #0000001a",
                                            minWidth: "9rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "8vw"
                                        }}>{item?.label}</th></>)
                                    })}
                                </tr>

                                {/* <tr>

                                The Table indicates Technology wise head count
                            </tr> */}

                                <tr>
                                    <td style={{
                                        // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                        // width: "3vw",
                                        // display: "flex",
                                        // justifyContent: "center",
                                        // alignItems: "center",
                                        padding: "1rem",
                                        marginLeft: "0.1rem",
                                        border: "1px solid #0000001a",
                                        fontWeight: "600",
                                        textAlign: "center",
                                        border: "1px solid #e7e7e7",
                                        // zIndex: 10,
                                        // position: "sticky",
                                        // left: 0
                                    }}>Total</td>
                                    <td
                                        style={{
                                            // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                            // width: "3vw",
                                            // display: "flex",
                                            // justifyContent: "center",
                                            // alignItems: "center",
                                            marginLeft: "0.1rem",
                                            border: "1px solid #0000001a",
                                            textAlign: "center",
                                            // zIndex: 99,
                                            // position: "sticky",
                                            // left: 0
                                        }}
                                    > -
                                    </td>

                                    <td
                                        style={{
                                            // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                            // width: "3vw",
                                            display: "flex",
                                            justifyContent: "center",
                                            // alignItems: "center",
                                            // marginLeft: "0.1rem",
                                            padding: "1rem",
                                            fontWeight: "600",
                                            zIndex: 10,
                                            left: 0,

                                        }}
                                    >
                                        {`${grandTotal[0]?.grandTotalOfEmployee}/${grandTotal[0]?.grandFteOfEmp.toFixed(2)}`
                                        }
                                    </td>
                                    {/* {<TotalClinetsCount> </TotalClinetsCount>} */}
                                    {/* <td
                                    style={{
                                        // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                        // width: "3vw",
                                        display: "flex",
                                        justifyContent: "center",
                                        // alignItems: "center",
                                        // marginLeft: "0.1rem",
                                        padding: "1rem",
                                        fontWeight: "600",
                                        zIndex: 10,
                                        left: 0,
                                        border: "1px solid #0000001a",
                                    }}
                                >
                                    {
                                        // console.log(grandTotal[0]?.grandTotalEmpHours)
                                        // console.log()
                                        `${grandTotal[0]?.grandTotalOfEmployee}/${grandTotal[0]?.grandFteOfEmp.toFixed(2)}`
                                    }
                                </td> */}

                                    {grandTotal[0]?.totalTechnologies.map((projects) => {
                                        return <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center", fontWeight: 600 }}>
                                            {/* {projects?.count} */}
                                            {`${projects?.count}/${projects?.techFTE.toFixed(2)}`}
                                        </td>
                                    })}
                                </tr>

                                {resourceAllocationReport && resourceAllocationReport.map((data, key) => {
                                    return (
                                        <>
                                            <tr
                                                style={{
                                                    background:
                                                        isToggle === key ? "#e7f1ff" : "white",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    if (key == isToggle) {
                                                        setIsToggle();
                                                    } else {
                                                        setIsToggle(key);
                                                    }
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                                        // width: "3vw",
                                                        // display: "flex",
                                                        // justifyContent: "center",
                                                        // alignItems: "center",
                                                        marginLeft: "0.1rem",
                                                        border: "1px solid #0000001a",
                                                        // zIndex: 10,
                                                        // position: "sticky",
                                                        // left: 0
                                                    }}
                                                >
                                                    {" "}
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                transform:
                                                                    isToggle === key
                                                                        ? "rotate(180deg)"
                                                                        : "rotate(0deg)",
                                                                textAlign: "center",
                                                                position: "relative",
                                                                top: isToggle === key ? -2 : 4, padding: "10px",
                                                            }}
                                                        >
                                                            <img
                                                                src={downArrow}
                                                                height='20px'
                                                                width='20px'
                                                            />

                                                        </div>
                                                        <span style={{ marginLeft: '10px', fontWeight: 600 }}>
                                                            {key + 1}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td
                                                    style={{
                                                        padding: "10px",
                                                        // display: "flex",
                                                        // alignItems: "center",
                                                        border: "1px solid #0000001a",
                                                        // zIndex: 99,
                                                        // position: "sticky",
                                                        // left: 0
                                                        // width: "10vw",
                                                    }}
                                                >
                                                    {data?.clientName}
                                                </td>

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center", fontWeight: 600 }}>{data?.
                                                    totalEmployees}/{data?.clientFTE.toFixed(2)}</td>

                                                {data?.technologies.map((projects) => {
                                                    return <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{projects?.
                                                        totalEmployees}/{projects?.technologyFTE.toFixed(2)}</td>
                                                })}
                                                {/* {
                                                // <ClientOfLength data={data.projects}></ClientOfLength>
                                                <ClientsOfLength data={data} IndexOfMainDiv={key}></ClientsOfLength>
                                            } */}
                                                {/* {getTechCode.map((item) => {
                    return (
                        <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px" }}>-</td>)
                })} */}
                                            </tr>
                                            <tr
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #0000001a",
                                                    display: isToggle === key ? "contents" : "none",
                                                }}
                                            >
                                                {data?.projects
                                                    ?.map((datas, value) => {
                                                        return (
                                                            <>

                                                                <tr
                                                                    style={{
                                                                        width: "100%", background:
                                                                            is2ndToggle === value ? "#e7f1ff" : "white",
                                                                        cursor: "pointer",
                                                                        color: "#2986ce"
                                                                    }}
                                                                    onClick={() => {
                                                                        if (value == is2ndToggle) {
                                                                            setIs2ndToggle();
                                                                        } else {
                                                                            setIs2ndToggle(value);
                                                                        }
                                                                    }}
                                                                >
                                                                    <td
                                                                        style={{
                                                                            // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                                                            padding: "10px",
                                                                            // width: "3vw",
                                                                            // display: "flex",
                                                                            // justifyContent: "center",
                                                                            // alignItems: "center",
                                                                            marginRight: "0.1rem",
                                                                            border: "1px solid #0000001a"
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                            <div
                                                                                style={{
                                                                                    // transform:
                                                                                    //     is2ndToggle === value
                                                                                    //         ? "rotate(180deg)"
                                                                                    //         : "rotate(0deg)",
                                                                                    textAlign: "center",
                                                                                    position: "relative",
                                                                                    top: is2ndToggle === value ? -2 : 4,
                                                                                }}
                                                                            >
                                                                                {/* <img
                                                                                    src={downArrow}
                                                                                    height='20px'
                                                                                    width='20px'
                                                                                /> */}
                                                                            </div>
                                                                            <span style={{ marginLeft: '10px' }}>
                                                                                {key + 1}.{value + 1}
                                                                            </span>
                                                                        </div>
                                                                    </td>


                                                                    <td
                                                                        style={{
                                                                            padding: "10px",
                                                                            // display: "flex",
                                                                            border: "1px solid #0000001a"
                                                                            // width: "10vw",
                                                                        }}
                                                                    >
                                                                        {datas?.projectCode} - {datas?.projectName}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            padding: "10px",
                                                                            border: "1px solid #0000001a",
                                                                            padding: "5px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                        {datas?.totalEmployees}/{datas?.projectFTE.toFixed(2)}
                                                                    </td>

                                                                    {datas?.technologies.map((projects) => {
                                                                        return <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{projects?.
                                                                            totalEmployees}/{projects?.technologyFTE.toFixed(2)}</td>
                                                                    })}

                                                                </tr>
                                                                <tr
                                                                    style={{
                                                                        border: "1px solid #0000001a",
                                                                        display: is2ndToggle === value ? "contents" : "none",
                                                                    }}
                                                                >
                                                                    <td style={{
                                                                        padding: "10px 0px",
                                                                        border: "1px solid #0000001a"
                                                                    }}></td>
                                                                    <td
                                                                        style={{
                                                                            padding: "10px 15px",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        {/* {datas?.projectName} */}
                                                                        Team
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px 0px",
                                                                        border: "1px solid #0000001a"
                                                                    }}></td>
                                                                    {
                                                                        <EmployeeOfDetails datas={datas}></EmployeeOfDetails>
                                                                    }
                                                                    {/* <td style={{}}>
                                                                    {datas?.technologies.map((employee) => {
                                                                        console.log(employee, "employee");
                                                                        return employee?.employees.map((datas) => {
                                                                            console.log(datas, "datas123");
                                                                            return <div style={{ border: "1px solid #0000001a", padding: "10px" }}>
                                                                                <div>{datas?.name}</div>
                                                                                <div>
                                                                                    {moment(datas?.startDate).format(
                                                                                        'DD-MM-YYYY'
                                                                                    )} -  {moment(datas?.dueDate).format(
                                                                                        'DD-MM-YYYY'
                                                                                    )}
                                                                                </div>
                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                    <div>{datas?.percentageAllocation}</div>
                                                                                    <div>{datas?.totalWorkingHours} hrs</div>
                                                                                </div>
                                                                            </div>
                                                                        })

                                                                    })}
                                                                </td> */}
                                                                    <tr>
                                                                    </tr>
                                                                </tr>
                                                                {/* })} */}

                                                            </>

                                                        )
                                                    })}
                                                <tr>
                                                </tr>
                                            </tr>
                                        </>
                                    )
                                })
                                }
                            </table>
                        </div>
                    </div>
                </>)}


                {isUserSchedulerData ? (<div style={{}}>
                    <UserSchedulerShimmer />
                </div>) : (<>
                    <div style={{ background: "white", marginTop: "20px", padding: "20px", borderRadius: "10px", display: monthlyReport ? "block" : " none" }}>
                        <div
                            className='user_list'
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "left",
                                overflowX: "scroll",
                                minWidth: "90%",
                                maxHeight: `${height}px`,
                            }}
                        >
                            <table id="myTable" style={{ width: "100%", borderCollapse: "collapse" }}>
                                <tr
                                    style={{ width: "100%", background: "#f2f2f2" }}
                                >
                                    <th style={{
                                        padding: "10px 0px",
                                        border: "1px solid #0000001a",
                                        position: "sticky",
                                        top: 0,
                                        backgroundColor: "#f2f2f2",
                                        zIndex: 10,
                                        // left: 0
                                        //  width: "10vw"
                                    }}>#</th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Client
                                    </th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Total Hours
                                    </th>

                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Equivalent FTE
                                    </th>

                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        USD K
                                    </th>

                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        INR Lacs
                                    </th>
                                </tr>

                                {/* <tr>

                                The Table indicates Technology wise head count
                            </tr> */}


                                {monthlyReport && monthlyReport?.map((data, key) => {
                                    return (
                                        <>
                                            <tr
                                                style={{
                                                    background:
                                                        isToggle === key ? "#e7f1ff" : "white",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    if (key == isToggle) {
                                                        setIsToggle();
                                                    } else {
                                                        setIsToggle(key);
                                                    }
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                                        // width: "3vw",
                                                        // display: "flex",
                                                        // justifyContent: "center",
                                                        // alignItems: "center",
                                                        marginLeft: "0.1rem",
                                                        border: "1px solid #0000001a",
                                                        // zIndex: 10,
                                                        // position: "sticky",
                                                        // left: 0
                                                    }}
                                                >
                                                    {" "}
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                transform:
                                                                    isToggle === key
                                                                        ? "rotate(180deg)"
                                                                        : "rotate(0deg)",
                                                                textAlign: "center",
                                                                position: "relative",
                                                                top: isToggle === key ? -2 : 4, padding: "10px",
                                                            }}
                                                        >
                                                            <img
                                                                src={downArrow}
                                                                height='20px'
                                                                width='20px'
                                                            />

                                                        </div>
                                                        <span style={{ marginLeft: '10px', fontWeight: 600 }}>
                                                            {key + 1}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td
                                                    style={{
                                                        padding: "10px",
                                                        // display: "flex",
                                                        // alignItems: "center",
                                                        border: "1px solid #0000001a",
                                                        // zIndex: 99,
                                                        // position: "sticky",
                                                        // left: 0
                                                        // width: "10vw",
                                                    }}
                                                >
                                                    {data?.clientName}
                                                </td>

                                                <td
                                                    style={{
                                                        padding: "10px",
                                                        // display: "flex",
                                                        // alignItems: "center",
                                                        border: "1px solid #0000001a",
                                                        textAlign: "center"
                                                        // zIndex: 99,
                                                        // position: "sticky",
                                                        // left: 0
                                                        // width: "10vw",
                                                    }}
                                                >
                                                    {data?.totalHours.toFixed(2)}
                                                </td>

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{data?.equivalentFTE.toFixed(2)}</td>
                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{data?.usd.toFixed(2)}</td>
                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{data?.inr.toFixed(2)}</td>

                                                {/* {data?.technologies.map((projects) => {
                                                return <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{projects?.
                                                    totalEmployees}/{projects?.technologyFTE.toFixed(2)}</td>
                                            })} */}
                                                {/* {
                                                // <ClientOfLength data={data.projects}></ClientOfLength>
                                                <ClientsOfLength data={data} IndexOfMainDiv={key}></ClientsOfLength>
                                            } */}
                                                {/* {getTechCode.map((item) => {
                    return (
                        <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px" }}>-</td>)
                })} */}
                                            </tr>
                                            <tr
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #0000001a",
                                                    display: isToggle === key ? "contents" : "none",
                                                }}
                                            >
                                                {data?.projects
                                                    ?.map((datas, value) => {
                                                        return (
                                                            <>

                                                                <tr
                                                                    style={{
                                                                        width: "100%", background:
                                                                            is2ndToggle === value ? "#e7f1ff" : "white",
                                                                        cursor: "pointer",
                                                                        color: "#2986ce"
                                                                    }}
                                                                // onClick={() => {
                                                                //     if (value == is2ndToggle) {
                                                                //         setIs2ndToggle();
                                                                //     } else {
                                                                //         setIs2ndToggle(value);
                                                                //     }
                                                                // }}
                                                                >
                                                                    <td
                                                                        style={{
                                                                            // padding: isToggle === key ? "10px 0px" : "10px 0px",
                                                                            padding: "10px",
                                                                            // width: "3vw",
                                                                            // display: "flex",
                                                                            // justifyContent: "center",
                                                                            // alignItems: "center",
                                                                            marginRight: "0.1rem",
                                                                            border: "1px solid #0000001a"
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                            <div
                                                                                style={{
                                                                                    // transform:
                                                                                    //     is2ndToggle === value
                                                                                    //         ? "rotate(180deg)"
                                                                                    //         : "rotate(0deg)",
                                                                                    textAlign: "center",
                                                                                    position: "relative",
                                                                                    top: is2ndToggle === value ? -2 : 4,
                                                                                }}
                                                                            >
                                                                                {/* <img
                                                                                    src={downArrow}
                                                                                    height='20px'
                                                                                    width='20px'
                                                                                /> */}
                                                                            </div>
                                                                            <span style={{ marginLeft: '10px' }}>
                                                                                {key + 1}.{value + 1}
                                                                            </span>
                                                                        </div>
                                                                    </td>


                                                                    <td
                                                                        style={{
                                                                            padding: "10px",
                                                                            // display: "flex",
                                                                            border: "1px solid #0000001a"
                                                                            // width: "10vw",
                                                                        }}
                                                                    >
                                                                        {datas?.projectCode} - {datas?.projectName}
                                                                    </td>

                                                                    <td
                                                                        style={{
                                                                            padding: "10px",
                                                                            border: "1px solid #0000001a",
                                                                            padding: "5px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                        {datas?.totalHours.toFixed(2)}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            padding: "10px",
                                                                            border: "1px solid #0000001a",
                                                                            padding: "5px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                        {datas?.equivalentFTE.toFixed(2)}
                                                                    </td>

                                                                    <td
                                                                        style={{
                                                                            padding: "10px",
                                                                            border: "1px solid #0000001a",
                                                                            padding: "5px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                        {datas?.usd.toFixed(2)}
                                                                    </td>

                                                                    <td
                                                                        style={{
                                                                            padding: "10px",
                                                                            border: "1px solid #0000001a",
                                                                            padding: "5px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                        {datas?.inr.toFixed(2)}
                                                                    </td>

                                                                    {/* {datas?.technologies.map((projects) => {
                                                                    return <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{projects?.
                                                                        totalEmployees}/{projects?.technologyFTE.toFixed(2)}</td>
                                                                })} */}

                                                                </tr>

                                                                {/* })} */}

                                                            </>

                                                        )
                                                    })}
                                                <tr>
                                                </tr>
                                            </tr>
                                        </>
                                    )
                                })
                                }
                            </table>
                        </div>
                    </div>
                </>)}



            </div >
            <Modal
                style={{ opacity: "1" }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={() => {
                    doCloseModal();
                }}
            >
                <Box in={openModal}>


                    {!monthlyReport ? (<div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "#fff",
                            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
                            borderRadius: "8px",
                            padding: "50px 39px 36px 39px",
                            width: "25%",
                        }}
                    >
                        <Box>
                            <div onClick={() => doCloseModal()} style={{
                                cursor: 'pointer',
                                display: "flex",
                                justifyContent: "end",
                                marginBottom: "20px"
                            }}>
                                <img src={close} alt="" height={20} width={20} />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField fullWidth label="100% (Full Day)" id="fullWidth" size="small" defaultValue='8.5' value={fullDay} onChange={(e) => { setFullDay(e.target.value); setMonthlyCount(e.target.value * dayOfMonth) }} />
                                </Grid>
                                <Grid item xs={12} md={6}>

                                    <TextField fullWidth label="75% ( 3/4th day) " id="fullWidth" size="small" defaultValue='6' value={quarterly} onChange={(e) => setQuarterly(e.target.value)} />

                                </Grid>
                                <Grid item xs={12} md={6}>

                                    <TextField fullWidth label="50% (Half Day)" id="fullWidth" size="small" defaultValue='4.5' value={halfDay} onChange={(e) => setHalfDay(e.target.value)} />

                                </Grid>
                                <Grid item xs={12} md={6}>

                                    <TextField fullWidth label="25% ( 1/4th day)" id="fullWidth" size="small" defaultValue='2.5' value={twentyFivePercent} onChange={(e) => setTwentyFivePercent(e.target.value)} />

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div style={{ marginBottom: '10px' }}>
                                        <Typography variant="caption" color="textSecondary" style={{ marginBottom: '2px' }} >* Note: Below is Used to Calculate FTE (Man Month)</Typography>
                                    </div>
                                    <div>
                                        <TextField fullWidth disabled label="Monthly Count/ FTE" id="fullWidth" size="small" defaultValue='176' value={fullDay * dayOfMonth} onChange={(e) => { setMonthlyCount(e.target.value) }} />
                                    </div>

                                </Grid>
                            </Grid>

                        </Box>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {isAddLoader ?
                                (<div>
                                    <CircularProgress style={{ width: 30, height: 30, marginTop: '15px' }} />{" "}
                                </div>) : (<Button style={{ background: 'rgba(249, 97, 23, 0.86)', color: 'white', marginTop: '15px', textTransform: "none" }} onClick={() => {
                                    doHandleAddSetting();
                                    handleResourceAllocationReport(startDate, endDate, selectedFilterClient)
                                }} >Apply</Button>)
                            }

                        </div>
                    </div >) : (<div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "#fff",
                            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
                            borderRadius: "8px",
                            padding: "50px 39px 36px 39px",
                            width: "25%",
                        }}
                    >
                        <Box>
                            <div onClick={() => doCloseModal()} style={{
                                cursor: 'pointer',
                                display: "flex",
                                justifyContent: "end",
                                marginBottom: "20px"
                            }}>
                                <img src={close} alt="" height={20} width={20} />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField fullWidth label="Monthly Hours for FTE" id="fullWidth" size="small" defaultValue='176' value={monthlyFTE} onChange={(e) => { setMonthlyFTE(e.target.value) }} />
                                </Grid>
                                <Grid item xs={12} md={12} style={{ display: "flex" }}>

                                    <TextField fullWidth label="Rate" id="fullWidth" size="small" defaultValue='10' value={rate} onChange={(e) => setRate(e.target.value)} />
                                    <div style={{
                                        marginLeft: "10px", width: "4rem",
                                        alignItems: "center",
                                        display: "flex"
                                    }}>In USD</div>
                                </Grid>
                                <Grid item xs={12} md={12} style={{ display: "flex" }}>
                                    <TextField fullWidth label="USD/INR" id="fullWidth" size="small" defaultValue='80' value={USDINR} onChange={(e) => setUSDINR(e.target.value)} />
                                    <div style={{
                                        marginLeft: "10px", width: "4rem",
                                        alignItems: "center",
                                        display: "flex"
                                    }}>In INR</div>
                                </Grid>

                            </Grid>

                        </Box>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {isAddLoader ?
                                (<div>
                                    <CircularProgress style={{ width: 30, height: 30, marginTop: '15px' }} />{" "}
                                </div>) : (<Button style={{ background: 'rgba(249, 97, 23, 0.86)', color: 'white', marginTop: '15px', textTransform: "none" }} onClick={() => {
                                    doHandleAddSetting();
                                    handleResourceAllocationReport(startDate, endDate, selectedFilterClient)
                                }} >Apply</Button>)
                            }

                        </div>
                    </div >)}

                </Box >
            </Modal >
            <ToastContainer />
        </>
    )
}

export default ResourceAllocationReport