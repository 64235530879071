import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import { authenticationService } from "../../services/authentication";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import TotalShimmer from "components/Shimmer/TotalShimmer";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  padding_none: {
    padding: 0,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 270,
  },
}));

const ProjectRequests = () => {
  const classes = useStyles();
  const messagesEndRef = useRef(null);

  const [isProjectDetailsLoad, setIsProjectDetailsLoad] = useState(false);
  const [requestedProjectResult, setRequestedProjectResult] = useState([]);

  useEffect(() => {
    doHandleRequestedProjectDetails();
  }, []);

  const doHandleRequestedProjectDetails = async () => {
    try {
      setIsProjectDetailsLoad(true);

      const response = await authenticationService.fetchRequestedProjectDetails();

      if (response?.data) {
        setRequestedProjectResult(response?.data);
        setIsProjectDetailsLoad(false);
      }
    } catch (error) {
      console.log(error);
      setIsProjectDetailsLoad(false);
    }
  };

  console.log(requestedProjectResult);
  return (
    <>
      <div className="history_spacing">
        <div style={{ fontSize: "20px", margin: "0px 0px 15px 0px" }}>
          My Project Access request
        </div>

        {isProjectDetailsLoad ? (
          <GridContainer align="center">
            <GridItem xs={12} sm={12} md={12} lg={12}>
              {/* <CircularProgress /> */}
              <div style={{ marginTop: 40 }}>
                <TotalShimmer />
                <br></br>
                <TotalShimmer />
                <br></br>
                <TotalShimmer />
                <br></br>
                <TotalShimmer />
                <br></br>
                <TotalShimmer />
                <br></br>
                <TotalShimmer />
                <br></br>
              </div>
            </GridItem>
          </GridContainer>
        ) : requestedProjectResult.length === 0 ? (
          <GridContainer>
            <Card>
              <CardBody
                style={{ padding: "0px 15px 15px 15px", textAlign: "center" }}
              >
                <h3>No Request Found !</h3>
              </CardBody>
            </Card>
          </GridContainer>
        ) : (
          <GridContainer>
            {/* <GridItem   xs={12} sm={12} md={12} > */}
            <Card style={{ marginTop: 0 }}>
              <CardBody>
                <Paper className={classes.root} elevation={0}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Project Name</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Email Sent At</TableCell>
                          {/* <TableCell>Column 4</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {requestedProjectResult.map((item) => {
                          return item.actions.map((action) => {
                            console.log("action", action);
                            return (
                              <TableRow>
                                <TableCell>{action.projectName}</TableCell>
                                {/* <TableCell>{action.requestStatus}</TableCell> */}
                                <TableCell>
                                  <Chip
                                    label={action?.requestStatus.toUpperCase()}
                                    style={{
                                      backgroundColor:
                                        action?.requestStatus == "pending"
                                          ? "#FFD54F"
                                          : action?.requestStatus == "approved"
                                          ? "#4caf50"
                                          : action?.requestStatus == "rejected"
                                          ? "#fe5757"
                                          : "",
                                      color: "#000",
                                      width: 100,
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  {moment(action.createdAt).format(
                                    "D-MMM-YYYY hh:mm A"
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          });
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                {/* {dailyReport.length == 0 && (
                                  <GridContainer align="center">
                                      <GridItem xs={12} sm={12} md={12} lg={12}>
                                          <div
                                              style={{
                                                  marginTop: "5%",
                                                  marginBottom: "5%",
                                              }}
                                          >
                                              No record to display
                                          </div>
                                      </GridItem>
                                  </GridContainer>
                              )} */}
              </CardBody>
            </Card>
            {/* </GridItem> */}
          </GridContainer>
        )}
      </div>
    </>

    // ""
  );
};

export default ProjectRequests;
