
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { eyeSlash, eye, icon, linkExpired } from 'helper/constant.jsx';
import { authenticationService } from '../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/developer.css';
import logo from 'assets/img/reactlogo.png';
import { useHistory } from 'react-router-dom';
const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
};
const useStyles = makeStyles(styles);
const GeneratePassword = ({ props }) => {
    const history = useHistory()
    const classes = useStyles();
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [passwordDetails, setPasswordDetails] = useState({
        password: '',
        confirmPassword: '',
    });
    const [tokenStatus, setTokenStatus] = useState();
    useEffect(() => {
        let generatePasswordToken = props.location.pathname.slice(15);
        var splitUrl = generatePasswordToken.split('&email=');
        console.log(props.location.pathname, "props.location.pathname.slice(15);");
        console.log(splitUrl, "props.location.pathname.slice(15);");
        doVerifyAddPasswordToken(splitUrl[0]);
        setEmail(splitUrl[1])
    }, []);
    const handleShowTwoChange = () => {
        setShowTwo(!showTwo);
    };
    const handleShowThreeChange = () => {
        setShowThree(!showThree);
    };
    let successToast = successMsg => {
        toast.success(successMsg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };
    let errorToast = errMsg => {
        toast.error(errMsg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };
    const onChangePassword = e => {
        const target = e.target;
        const value = target.value;
        const name = target.id;
        setPasswordDetails({
            ...passwordDetails,
            [name]: value,
        });
    };
    const doVerifyAddPasswordToken = async generatePasswordToken => {
        try {
            const Response = await authenticationService.verifyAddPasswordToken(
                generatePasswordToken
            );
            setTokenStatus(Response);
        } catch (error) {
            console.log(error);
        }
    };
    const handleSubmit = async e => {
        e.preventDefault();
        if (
            passwordDetails?.password?.length >= 8 &&
            passwordDetails?.confirmPassword?.length >= 8
        ) {
            try {
                setIsLoader(true);
                let token = props.location.pathname.slice(15);
                const Response = await authenticationService.addPassword(
                    passwordDetails,
                    token
                );
                if (Response.data) {
                    successToast(
                        Response?.data?.message
                            ? Response.data.message
                            : 'Password generate successfully'
                    );
                    setPasswordDetails({
                        password: '',
                        confirmPassword: '',
                    });
                    window.localStorage.removeItem('currentUser');
                    localStorage.setItem('useremail', email)
                    setTimeout(() => {
                        // window.localStorage.setItem('userEmail',email) 
                        // window.location.replace('/');
                        console.log(email)
                        history.push({
                            pathname: '/',
                            state: {
                                email: email,
                            },
                        })
                    }, 200);
                }
                if (Response?.message) {
                    errorToast(Response?.message);
                }
                setIsLoader(false);
            } catch (e) {
                console.log(e);
                setIsLoader(false);
            }
        } else {
            if (
                passwordDetails?.password?.length < 8 ||
                passwordDetails?.confirmPassword?.length < 8
            ) {
                errorToast(
                    'Password must be longer than or equal to 8 characters'
                );
            }
        }
    };
    return (
        <div align="center">
            {tokenStatus?.data?.status === true ? (
                <form
                    onKeyDown={e => {
                        if (
                            props.location.pathname.slice(0, 15) ==
                            '/register-user'
                        ) {
                            if (
                                passwordDetails.password != '' &&
                                passwordDetails.confirmPassword != ''
                            ) {
                                if (e.keyCode === 13) {
                                    handleSubmit(e);
                                }
                            }
                        }
                    }}
                    tabIndex={0}
                >
                    <GridContainer
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <GridItem
                            xs={11}
                            sm={11}
                            md={3}
                            lg={3}
                            style={{ marginTop: 100 }}
                        >
                            <Card>
                                <div style={{ marginTop: '20px' }}>
                                    <img src={logo} />
                                    <h4
                                        style={{
                                            fontWeight: 'bold',
                                            margin: 5,
                                        }}
                                    >
                                        Generate New Password For
                                        <span style={{ color: '#f4590d' }}>
                                            <br />
                                            Daily Work Report
                                        </span>
                                    </h4>
                                </div>
                                <CardBody>
                                    <div className="">
                                        <div className="form-container">
                                            <form
                                                className="plr-60"
                                                style={{
                                                    marginBottom: '40px',
                                                    margin: '0px 40px',
                                                }}
                                            >
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >

                                                    <CustomInput
                                                        style={{ margin: 0, disabled: "true", }}
                                                        name="email"
                                                        labelText="Email"
                                                        id="email"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        value={email}

                                                    />
                                                </div>
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <CustomInput
                                                        style={{ margin: 0 }}
                                                        name="password"
                                                        labelText="Password"
                                                        id="password"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        onChange={
                                                            onChangePassword
                                                        }
                                                        type={
                                                            showTwo
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        value={
                                                            passwordDetails.password
                                                                ? passwordDetails.password
                                                                : ''
                                                        }
                                                    />
                                                    <img
                                                        src={
                                                            showTwo
                                                                ? eyeSlash
                                                                : eye
                                                        }
                                                        width={20}
                                                        height={20}
                                                        alt="show password"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: 35,
                                                            right: 2,
                                                        }}
                                                        onClick={
                                                            handleShowTwoChange
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <CustomInput
                                                        style={{ margin: 0 }}
                                                        name="confirmPassword"
                                                        labelText="Confirm Password"
                                                        id="confirmPassword"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        onChange={
                                                            onChangePassword
                                                        }
                                                        type={
                                                            showThree
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        value={
                                                            passwordDetails.confirmPassword
                                                                ? passwordDetails.confirmPassword
                                                                : ''
                                                        }
                                                    />
                                                    <img
                                                        src={
                                                            showThree
                                                                ? eyeSlash
                                                                : eye
                                                        }
                                                        width={20}
                                                        height={20}
                                                        alt="show password"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: 35,
                                                            right: 2,
                                                        }}
                                                        onClick={
                                                            handleShowThreeChange
                                                        }
                                                    />
                                                </div>
                                                {isLoader ? (
                                                    <CircularProgress
                                                        size={28}
                                                        style={{
                                                            margin:
                                                                '40px 0px 0px 25px',
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            margin: '20px 0px',
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={
                                                                passwordDetails
                                                                    .password
                                                                    .length <=
                                                                    5 ||
                                                                    passwordDetails
                                                                        .confirmPassword
                                                                        .length <= 5
                                                                    ? true
                                                                    : false
                                                            }
                                                            onClick={e =>
                                                                handleSubmit(e)
                                                            }
                                                            color="info"
                                                            type="submit"
                                                            className="btn form-button"
                                                            style={{
                                                                borderRadius:
                                                                    '50px',
                                                                width: '100%',
                                                                height: '40px',
                                                                fontSize:
                                                                    '14px',
                                                                color: 'white',
                                                                fontWeight:
                                                                    '600',
                                                                disply: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                fontWeight:
                                                                    '800',
                                                                fontSize:
                                                                    '16px',
                                                                background:
                                                                    '#f96117db',
                                                                textTransform:
                                                                    'capitalize',
                                                            }}
                                                        >
                                                            Submit{' '}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                style={{
                                                                    marginLeft:
                                                                        '10px',
                                                                }}
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M13.3 17.275q-.3-.3-.288-.725t.313-.725L16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.3-.713t.3-.712q.3-.3.713-.3t.712.3L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.6 4.6q-.275.275-.687.275t-.713-.3Z"
                                                                />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter align="center"></CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </form>
            ) : (
                <>
                    {tokenStatus?.data?.status === false ? (
                        <GridContainer
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <GridItem
                                xs={11}
                                sm={11}
                                md={4}
                                lg={4}
                                style={{ marginTop: 170 }}
                            >
                                <Card>
                                    {/* <CardHeader color="info" style={{ textAlign: "center" }}>
                                <h4 className={classes.cardTitleWhite}>Daily Work Report</h4>
                            </CardHeader> */}
                                    <CardBody>
                                        <div>
                                            <div
                                                className="form-container"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <img src={icon} />
                                                <img
                                                    src={linkExpired}
                                                    style={{
                                                        margin: '1rem 0rem',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        color: 'red',
                                                        fontSize: '25px',
                                                    }}
                                                >
                                                    {tokenStatus?.data?.message}
                                                </div>
                                                <div
                                                    style={{
                                                        margin: '20px 40px',
                                                    }}
                                                >
                                                    <Button
                                                        href={`/login`}
                                                        color="info"
                                                        type="submit"
                                                        className="btn form-button"
                                                        style={{
                                                            padding:
                                                                '12px 30px',
                                                            borderRadius:
                                                                '50px',
                                                            width: '100%',
                                                            height: '40px',
                                                            fontSize: '14px',
                                                            color: 'white',
                                                            fontWeight: '600',
                                                            disply: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            fontWeight: '800',
                                                            fontSize: '16px',
                                                            background:
                                                                '#f96117db',
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            marginR
                                                            style={{
                                                                marginRight:
                                                                    '5px',
                                                            }}
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="m10.875 19.3l-6.6-6.6q-.15-.15-.213-.325T4 12q0-.2.063-.375t.212-.325l6.6-6.6q.275-.275.688-.287t.712.287q.3.275.313.688T12.3 6.1L7.4 11h11.175q.425 0 .713.288t.287.712q0 .425-.287.713t-.713.287H7.4l4.9 4.9q.275.275.288.7t-.288.7q-.275.3-.7.3t-.725-.3Z"
                                                            />
                                                        </svg>
                                                        Back To Login{' '}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter align="center"></CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    ) : (
                        <>
                            <GridContainer align="center">
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CircularProgress
                                        style={{ marginTop: '3rem' }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </>
                    )}
                </>
            )}
            <ToastContainer />
        </div>
    );
};
export default GeneratePassword;