import { logout } from "helper/constant";
import { DATE_FILTER_TYPE } from "helper/constant";
import React from "react";
import { CalendarContainer } from "react-datepicker";

const DatePickerFilter = (props) => {
  const {
    doClickOnToday,
    doClickOnThisWeek,
    doClickOnYesterday,
    doClickOnThisMonth,
    doClickOnLast7Days,
    doClickOnLastMonth,
    doClickOnLastWeek,
    doClickOnCancel,
    dateRangeEnabled,
    doHandleShowDateClick,
    isShowAllowed,
    datePickerActiveChip,
    selectedFilterPercentage
  } = props;
  const [defaultValue, setDefasultValue] = React.useState();

  const ACTIVE_COLOR = {
    background: "#f96117db",
    color: "#FFF",
  };
  const DEFAULT_COLOR = {
    background: "#fff",
    color: "#000",
  };

  const DISABLED_COLOR = {
    background: "gray"
  }

  return (
    <div
      style={{
        width: "250px",
        display: "inline-flex",
        borderLeft: "1px solid #e7e7e7",
        margin: "10px",
      }}
    >
      <CalendarContainer>
        <div
          style={{
            textAlign: "center",
            color: "gray",
            fontWeight: "bold",
            //   width: '20vw',
            width: "250px",
            paddingTop: "5px",
          }}
        >
          Filter by Period
        </div>

        <div
          style={{
            margin: "10px 20px 0px 20px",
            borderBottom: "1px solid #e7e7e7",
          }}
        ></div>

        <div
          style={{
            margin: "12px 15px 0px 15px",
            minHeight: "18vh",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <button
              className='calendarFilterButton'
              style={
                datePickerActiveChip == DATE_FILTER_TYPE.TODAY
                  ? ACTIVE_COLOR
                  : DEFAULT_COLOR
              }
              onClick={doClickOnToday}
            >
              Today
            </button>
            <button
              className='calendarFilterButton'
              style={
                datePickerActiveChip == DATE_FILTER_TYPE.YESTERDAY
                  ? ACTIVE_COLOR
                  : DEFAULT_COLOR
              }
              onClick={doClickOnYesterday}
              disabled={selectedFilterPercentage?.value == "0"}
            >
              Yesterday
            </button>
          </div>

          {dateRangeEnabled && (
            <div style={{ display: "flex" }}>
              <button
                className='calendarFilterButton'
                style={
                  datePickerActiveChip == DATE_FILTER_TYPE.THISWEEK
                    ? ACTIVE_COLOR
                    : DEFAULT_COLOR
                }
                onClick={doClickOnThisWeek}
              >
                This Week
              </button>
              <button
                className='calendarFilterButton'
                style={
                  datePickerActiveChip == DATE_FILTER_TYPE.THISMONTH
                    ? ACTIVE_COLOR
                    : DEFAULT_COLOR
                }
                onClick={doClickOnThisMonth}
              >
                This Month
              </button>
            </div>
          )}
          {dateRangeEnabled && (
            <div style={{ display: "flex" }}>
              <button
                className='calendarFilterButton'
                style={
                  datePickerActiveChip == DATE_FILTER_TYPE.LAST7DAYS
                    ? ACTIVE_COLOR
                    : DEFAULT_COLOR
                }
                onClick={doClickOnLast7Days}
              >
                Last 7 Days
              </button>
              <button
                className='calendarFilterButton'
                style={
                  datePickerActiveChip == DATE_FILTER_TYPE.LASTMONTH
                    ? ACTIVE_COLOR
                    : DEFAULT_COLOR
                }
                onClick={doClickOnLastMonth}
                disabled={selectedFilterPercentage?.value == "0"}
              >
                Last Month
              </button>
            </div>
          )}
          {dateRangeEnabled && (
            <div>
              <button
                className='calendarFilterButton'
                style={
                  datePickerActiveChip == DATE_FILTER_TYPE.LASTWEEK
                    ? ACTIVE_COLOR
                    : DEFAULT_COLOR
                }
                onClick={doClickOnLastWeek}
                disabled={selectedFilterPercentage?.value == "0"}
              >
                Last Week
              </button>
            </div>
          )}
        </div>

        <div
          style={{
            margin: "0px 20px 15px 20px",
            borderTop: "1px solid #e7e7e7",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            margin: "10px 20px",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              width: "5rem",
              padding: "7px",
              margin: "0px 5px",
              background: "transparent",
              color: "#808080",
              border: "none",
              borderRadius: "5px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={doClickOnCancel}
          >
            Cancel
          </button>
          <button
            style={{
              width: "6rem",
              padding: "7px",
              margin: "0px 5px",
              background: "#f96117db",
              fontWeight: "bold",
              borderRadius: "5px",
              color: "#FFF",
              border: "none",
              cursor: isShowAllowed ? "pointer" : "not-allowed",
            }}
            disabled={!isShowAllowed}
            onClick={() => {
              if (isShowAllowed) {
                doHandleShowDateClick();
              }
            }}
          >
            Select date
          </button>
        </div>
      </CalendarContainer >
    </div >
  );
};

export default DatePickerFilter;
