export const edit = require("assets/img/edit.png");
export const bin = require("assets/img/bin.png");

export const eyeSlash = require("assets/img/eye-slash.svg");
export const eye = require("assets/img/eye.svg");
export const icon = require("assets/img/favicon.ico");
export const close = require("assets/img/close.png");
export const linkExpired = require("assets/img/link_expired.png"); 
export const downArrow = require("assets/img/downArrow.svg");
export const Notes = require("assets/img/notes.png");
export const profileAvatarText = require("assets/img/profile_text.png");
export const sendMail = require("assets/img/send.png");
export const logout = require("assets/img/logout.svg");
export const profile = require("assets/img/profile.svg");
export const setting = require("assets/img/setting.svg");
export const timer = require("assets/img/timer.svg");
export const projectRequest = require("assets/img/projectRequest.png");
export const DATE_FILTER_TYPE = {
    THISWEEK: 'THISWEEK',
    LASTWEEK: 'LASTWEEK',
    THISMONTH: 'THISMONTH',
    TODAY: 'TODAY',
    YESTERDAY: 'YESTERDAY',
    LASTMONTH: 'LASTMONTH',
    LAST7DAYS: 'LAST7DAYS'
}