import { Box, Button, Modal, IconButton, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { authenticationService } from "services/authentication";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { close } from "helper/constant.jsx";
import { Tooltip, Typography } from '@mui/material';
import CircularProgress from "@material-ui/core/CircularProgress";
import UserSchedulerShimmer from "components/Shimmer/UserSchedulerShimmer";
import DateRangePickerWrapper from 'assets/css/dataRangePicker.style';
import { MultiSelect } from "react-multi-select-component";
import Edit from "@material-ui/icons/Edit";
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
// import Delete from "@material-ui/icons/Delete";

const Leave = () => {
    const UPPER_DIV_HEIGHT = 238;
    const [height, setHeight] = useState(window.innerHeight - UPPER_DIV_HEIGHT);
    const [isUserSchedulerData, setIsUserSchedulerData] = useState();
    const [isToggle, setIsToggle] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startCompOffDate, setStartCompOffDate] = useState();
    const [endCompOffDate, setEndCompOffDate] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [openCompOffModal, setOpenCompOffModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openCompOffCancelModal, setOpenCompOffCancelModal] = useState(false);
    const [isAddLoader, setIsAddLoader] = useState(false);
    const [myLeave, setMyLeave] = useState()
    const [myCompOff, setMyCompOff] = useState()
    const [leaveType, setLeaveType] = useState();
    const [dayType, setDayType] = useState();
    const [dayTypeCompOff, setDayTypeCompOff] = useState();
    const [isLeaveUpdate, setIsLeaveUpdate] = useState(false);
    const [isCompOffUpdate, setIsCompOffUpdate] = useState(false);
    const [leaveDetails, setLeaveDetails] = useState();
    const [compOffDetails, setCompOffDetails] = useState();
    const [leaveUpdateData, setLeaveUpdateData] = useState();
    const [compOffUpdateData, setCompOffUpdateData] = useState();
    const [updateStartDate, setUpdateStartDate] = useState();
    const [updateEndDate, setUpdateEndDate] = useState();
    const [updateStartCompOffDate, setUpdateStartCompOffDate] = useState();
    const [updateEndCompOffDate, setUpdateEndCompOffDate] = useState();
    const [selectedFilterReportingManager, setSelectedFilterReportingManager] = useState();
    const [updateLeaveType, setUpdateLeaveType] = useState()
    const [updateDayType, setUpdateDayType] = useState()
    const [updateDayTypeCompOff, setUpdateDayTypeCompOff] = useState()
    const [selectedUserObject, setSelectedUserObject] = useState([]);
    const [selectedRm, setSelectedRm] = useState([])
    const [isLoader, setIsLoader] = useState(false);
    const [myTeamsUserList, setMyTeamsUserList] = useState([]);
    const [leaveUpdateDetails, setLeaveUpdateDetails] = useState()
    const [compOffUpdateDetails, setCompOffUpdateDetails] = useState()
    const [leaveCancelReason, setLeaveCancelReason] = useState()
    const [compOffCancelReason, setCompOffCancelReason] = useState()
    const [leaveCancelId, setLeaveCancelId] = useState()
    const [compOffCancelId, setCompOffCancelId] = useState()
    const [selectDayHalf, setSelectDayHalf] = useState()
    const [myProfile, setMyProfile] = useState()
    const [yearOptions, setYearOptions] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [leaveBalance, setLeaveBalance] = useState({})

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 225,
                marginTop: 50,
            },
        },
    };

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - UPPER_DIV_HEIGHT);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        // doClickOnThisMonth()
        // handleClientChange()
        // handleTechnologyChange()
        // countOfMonthDays()

        doGetMyTeamsUserList()
        handleProfile()
    }, [])

    useEffect(() => {
        const options = getYearOptions();
        setYearOptions(options);
        const currentYearOption = options.find(option => option.value === new Date().getFullYear());
        setSelectedYear(currentYearOption);
        handleMyLeave(currentYearOption?.value); // Call handleMyLeave with the default current year
    }, []);

    const getYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;

        return [
            { label: `Jan ${currentYear} - Dec ${currentYear}`, name: `Jan ${currentYear} - Dec ${currentYear}`, value: currentYear },
            { label: `Jan ${previousYear} - Dec ${previousYear}`, name: `Jan ${previousYear} - Dec ${previousYear}`, value: previousYear },
        ];
    };

    const doCloseModal = () => {
        setOpenModal(false);
        setDayType()
        setLeaveDetails("")
        setStartDate()
        setEndDate()
        setUpdateStartDate("")
        setUpdateEndDate("")
        setLeaveUpdateData("")
        setIsLeaveUpdate("")
        setLeaveUpdateData("")
        setUpdateLeaveType("")
        setUpdateDayType("")
        setSelectDayHalf()
        setLeaveUpdateDetails("")
        setSelectedUserObject([])
        setIsAddLoader(false)
        setLeaveType('')
        setUpdateLeaveType("")
    };

    const doCloseCompOffModal = () => {
        setOpenCompOffModal(false);
        setDayTypeCompOff()
        setCompOffDetails("")
        setStartCompOffDate()
        setEndCompOffDate()
        setUpdateStartCompOffDate("")
        setUpdateEndCompOffDate("")
        setUpdateDayTypeCompOff("")
        setCompOffUpdateData("")
        setIsCompOffUpdate("")
        setCompOffUpdateDetails("")
        // setLeaveUpdateData("")
        // setUpdateLeaveType("")
        // setSelectDayHalf()
        // setSelectedUserObject([])
        // setIsAddLoader(false)
        // setLeaveType('')
        // setUpdateLeaveType("")
    };

    const doCloseCancelModal = () => {
        setOpenCancelModal(false);
        setLeaveCancelReason()
        setIsAddLoader(false)
    };

    const doCloseCompOffCancelModal = () => {
        setOpenCompOffCancelModal(false);
        setCompOffCancelReason()
        setIsAddLoader(false)
    };

    const dateRangePickerProps = () => {
        return { isRangerHoverAllowed: true };
    };

    let successToast = (successMsg) => {
        toast.success(successMsg, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    let errorToast = errMsg => {
        toast.error(errMsg, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };

    // Function to calculate date difference for updating leave
    const calculateDateDifference = (start, end) => {
        if (!start || !end) return 0;

        const startDay = new Date(start); // Convert start date to Date object
        const endDay = new Date(end); // Convert end date to Date object

        // Calculate the time difference in milliseconds and convert to days
        const timeDifference = endDay.getTime() - startDay.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24); // Convert ms to days

        return dayDifference + 1; // Add 1 to include both start and end dates
    };

    // Calculate the number of selected days for applying leave
    const selectedDays = calculateDateDifference(startDate, endDate);

    const selectedCompOffDays = calculateDateDifference(startCompOffDate, endCompOffDate);

    // Calculate the number of selected days for updating leave
    const updatedSelectedDays = calculateDateDifference(updateStartDate, updateEndDate);
    const updatedSelectedCompOffDays = calculateDateDifference(updateStartCompOffDate, updateEndCompOffDate);
    const privilegeLeave = Number(leaveBalance?.privilegeLeave) || 0;
    const customeFestivalLeave = Number(leaveBalance?.customeFestivalLeave) || 0;
    const bereavementLeave = Number(leaveBalance?.bereavementLeave) || 0;
    const compOffsLeave = Number(leaveBalance?.compOffs) || 0;

    const LeaveTypeOps = [
        {
            label: `Privilege Leave(${privilegeLeave})`,
            name: "Privilege Leave",
            value: 1,
            isdisabled: ((leaveBalance?.privilegeLeave == 0.75 || leaveBalance?.privilegeLeave == 0.5 || leaveBalance?.privilegeLeave == 0.25) && selectedDays == 1) ? false :
                (selectedDays > privilegeLeave || updatedSelectedDays > privilegeLeave)
        },
        {
            label: `Custom Festival Leave(${customeFestivalLeave})`,
            name: "Custom Festival Leave",
            value: 2,
            isdisabled: ((leaveBalance?.customeFestivalLeave == 0.75 || leaveBalance?.customeFestivalLeave == 0.5 || leaveBalance?.customeFestivalLeave == 0.25) && selectedDays == 1) ? false :
                (selectedDays > customeFestivalLeave || updatedSelectedDays > customeFestivalLeave)


            // selectedDays > (leaveBalance?.customeFestivalLeave || 0) || updatedSelectedDays > (leaveBalance?.customeFestivalLeave || 0)
        },
        {
            label: `Bereavement Leave(${bereavementLeave})`,
            name: "Bereavement Leave",
            value: 3,
            isdisabled: ((leaveBalance?.bereavementLeave == 0.75 || leaveBalance?.bereavementLeave == 0.5 || leaveBalance?.bereavementLeave == 0.25) && selectedDays == 1) ? false :
                (selectedDays > bereavementLeave || updatedSelectedDays > bereavementLeave)
        },
        {
            label: "Leave Without Pay(∞)",
            name: "Leave Without Pay",
            value: 4,
            isdisabled: (privilegeLeave != 0)
        },
        {
            label: `Comp Offs(${compOffsLeave})`,
            name: "Comp Offs",
            value: 5,
            isdisabled: (compOffsLeave == 0)
        },
    ];

    const DayType = [
        {
            label: "Full Day",
            name: "Full Day",
            value: 1,
            isdisabled:
                ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Privilege Leave" &&
                    (leaveBalance?.privilegeLeave == 0.75 || leaveBalance?.privilegeLeave == 0.5 || leaveBalance?.privilegeLeave == 0.25)) ||
                ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Custom Festival Leave" &&
                    (leaveBalance?.customeFestivalLeave == 0.75 || leaveBalance?.customeFestivalLeave == 0.5 || leaveBalance?.customeFestivalLeave == 0.25)) ||
                ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Bereavement Leave" &&
                    (leaveBalance?.bereavementLeave == 0.75 || leaveBalance?.bereavementLeave == 0.5 || leaveBalance?.bereavementLeave == 0.25))
        },
        {
            label: "Half Day",
            name: "Half Day",
            value: 2,
            isdisabled: (moment.utc(startDate).format() != moment.utc(endDate).format() ||
                isLeaveUpdate && (moment.utc(updateStartDate).format() != moment.utc(updateEndDate).format())) ||
                ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Privilege Leave" &&
                    (leaveBalance?.privilegeLeave == 0.25)) ||
                ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Custom Festival Leave" &&
                    (leaveBalance?.customeFestivalLeave == 0.25)) ||
                ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Bereavement Leave" &&
                    (leaveBalance?.bereavementLeave == 0.25))
        },
        {
            label: "Quarter Day",
            name: "Quarter Day",
            value: 3,
            isdisabled: moment.utc(startDate).format() !== moment.utc(endDate).format() ||
                (isLeaveUpdate && moment.utc(updateStartDate).format() !== moment.utc(updateEndDate).format()) ||
                ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Comp Offs")
        },
    ];

    const CompOffDayType = [
        {
            label: "Full Day",
            name: "Full Day",
            value: 1,
            // isdisabled:
            //     ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Privilege Leave" &&
            //         (leaveBalance?.privilegeLeave == 0.75 || leaveBalance?.privilegeLeave == 0.5 || leaveBalance?.privilegeLeave == 0.25)) ||
            //     ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Custom Festival Leave" &&
            //         (leaveBalance?.customeFestivalLeave == 0.75 || leaveBalance?.customeFestivalLeave == 0.5 || leaveBalance?.customeFestivalLeave == 0.25)) ||
            //     ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Bereavement Leave" &&
            //         (leaveBalance?.bereavementLeave == 0.75 || leaveBalance?.bereavementLeave == 0.5 || leaveBalance?.bereavementLeave == 0.25))
        },
        {
            label: "Half Day",
            name: "Half Day",
            value: 2,
            // isdisabled: (moment.utc(startDate).format() != moment.utc(endDate).format() ||
            //     isLeaveUpdate && (moment.utc(updateStartDate).format() != moment.utc(updateEndDate).format())) ||
            //     ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Privilege Leave" &&
            //         (leaveBalance?.privilegeLeave == 0.25)) ||
            //     ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Custom Festival Leave" &&
            //         (leaveBalance?.customeFestivalLeave == 0.25)) ||
            //     ((isLeaveUpdate ? updateLeaveType?.name : leaveType?.name) === "Bereavement Leave" &&
            //         (leaveBalance?.bereavementLeave == 0.25))
        },
    ];


    const leaveTypeOps = [
        { label: "First Half", name: "First Half", value: 0 },
        { label: "Second Half", name: "Second Half", value: 1 }
    ]

    const colourStyles = {
        menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
                width: "5px",
                height: "0px",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#f4590d",
            },
        }),
        control: (base, state) => ({
            ...base,
            zIndex: state.isFocused ? "999" : "1",
            background: "#ffffff",
            // background: '#efefef',
            color: "#000000",
            fontWeight: "bold",
            padding: 20,
            // match with the menu
            height: 40,
            minHeight: 35,
            fontSize: 16,
            fontWeight: 400,
            cursor: "pointer",
            padding: "0px 8px",
            // border: 'none',
            // outline: 'none',
            // Overwrittes the different states of border
            borderColor: "#0000003b",
            background: state.isDisabled ? "#f0f0f0" : "",
            // Removes weird border around container
            boxShadow: null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: "#0000003b",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            return {
                ...styles,
                fontWeight: "400",
                color: isDisabled
                    ? "black"
                    : isSelected
                        ? "white"
                            ? "white"
                            : "black"
                        : "black",
                cursor: "pointer",
                backgroundColor: isSelected
                    ? "#f96117db"
                    : isFocused
                        ? "#f961173d"
                        : undefined,
                background: isDisabled ? "#f0f0f0" : "",
                ":hover": {
                    backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
                },
                ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
                },
            };
        },
    };

    const handleInputChange = e => {
        const { name, value } = e;
        isLeaveUpdate
            ? setLeaveUpdateData({
                ...leaveUpdateData,
                [name]: value.trimStart()
            })
            : setLeaveDetails({
                ...leaveDetails,
                [name]: value.trimStart(),
            });
    };

    const handleCompOffInputChange = e => {
        const { name, value } = e;
        isCompOffUpdate
            ? setCompOffUpdateData({
                ...compOffUpdateData,
                [name]: value.trimStart()
            })
            : setCompOffDetails({
                ...compOffDetails,
                [name]: value.trimStart(),
            });
    };

    const handleInputReason = e => {
        const { name, value } = e;
        setLeaveCancelReason({
            ...leaveCancelReason,
            [name]: value.trimStart()
        })
    };

    const handleInputCompOffReason = e => {
        const { name, value } = e;
        setCompOffCancelReason({
            ...compOffCancelReason,
            [name]: value.trimStart()
        })
    };

    const handleMyLeave = async (selectedYear) => {
        try {
            const Response = await authenticationService.fetchMyLeave(selectedYear);
            if (Response) {
                setMyLeave(Response?.data?.data)
                setLeaveBalance(Response?.data?.leaveBalance)
                // setMyLeave([...new Set([Response?.data, ...myLeave])]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleProfile = async () => {
        try {
            const Response = await authenticationService.me();
            if (Response) {
                setMyProfile(Response?.data?.leaveBalance)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handledeleteLeave = async (id) => {
        try {
            let object = {
                "cancleReason": leaveCancelReason?.CancelReason
            };
            const Response = await authenticationService.deleteLeave(id, object);
            if (Response.code == 200) {
                setMyLeave(
                    myLeave?.map((value, key) => {
                        return value.id == id ? { ...value, isDeleted: Response?.data?.isDeleted, requestStatus: Response?.data?.requestStatus } : value;
                    })
                );
            } else {
                errorToast(Response?.Error);
            }
            setOpenCancelModal(false)
        } catch (error) {
            errorToast(error);
            console.log(error);
        }
    };

    const handledeleteCompOff = async (id) => {
        try {
            let object = {
                "cancleReason": compOffCancelReason?.CancelReason
            };
            const Response = await authenticationService.deleteCompOff(id, object);
            if (Response.code == 200) {
                setMyLeave(
                    myLeave?.map((value, key) => {
                        return value.id == id ? { ...value, isDeleted: Response?.data?.isDeleted, requestStatus: Response?.data?.requestStatus } : value;
                    })
                );
            } else {
                errorToast(Response?.Error);
            }
            setOpenCompOffCancelModal(false)
        } catch (error) {
            errorToast(error);
            console.log(error);
        }
    };

    const doGetMyTeamsUserList = async () => {
        setIsLoader(true);
        try {
            const Response = await authenticationService.fetchAllReportingManagerList();
            if (Response?.data) {
                setMyTeamsUserList([
                    ...Response?.data.map((value, key) => {
                        return {
                            id: value?.id,
                            value: value?.id,
                            // label: value?.name,
                            label: value?.code ? `${value?.code} - ${value.name}` : value?.name
                        };
                    }),
                ]);
            }
            setIsLoader(false);
        } catch (error) {
            setIsLoader(false);
            console.log(error);
        }
    };

    const handleLeaveUpdate = async (id) => {
        let rmId = [];
        selectedUserObject &&
            selectedUserObject.filter((value, key) => {
                return value.id && rmId.push(value?.id);
            });
        try {
            let object = {
                "startDate": moment(updateStartDate).format('YYYY-MM-DD'),
                "endDate": moment(updateEndDate).format('YYYY-MM-DD'),
                "leaveType": updateLeaveType?.value,
                "dayType": updateDayType?.value,
                "note": leaveUpdateData?.note,
                "leaveHalfType": updateDayType?.value == 1 ? null : selectDayHalf?.value,
                "notify": rmId
            }
            setIsUserSchedulerData(true);
            setIsAddLoader(true)

            const Response = await authenticationService.updateLeave(id, object);
            if (Response.code == 200) {
                if (leaveUpdateDetails?.id) {
                    setMyLeave(
                        myLeave?.map((value, key) => {
                            return value?.id == id ? { ...Response?.data, id: leaveUpdateDetails?.id } : value;
                        })
                    );
                }
                doCloseModal()
                setIsUserSchedulerData(false);
            }
            if (Response.code == 400) {
                errorToast(Response?.message);
                // doCloseModal()
                setIsUserSchedulerData(false);
                setIsAddLoader(false)
            }
        } catch (error) {
            console.log(error);
            setIsUserSchedulerData(false);
            setIsAddLoader(false)

        }
    };

    const handleCompOffUpdate = async (id) => {
        try {
            let object = {
                "startDate": moment(updateStartCompOffDate).format('YYYY-MM-DD'),
                "endDate": moment(updateEndCompOffDate).format('YYYY-MM-DD'),
                "isHalfDay": updateDayTypeCompOff?.value == 1 ? false : updateDayTypeCompOff?.value == 2 ? true : null,
                "note": compOffUpdateData?.note,
            }
            setIsUserSchedulerData(true);
            setIsAddLoader(true)

            const Response = await authenticationService.updateCompOff(id, object);
            if (Response.code == 200) {
                if (compOffUpdateDetails?.id) {
                    setMyLeave(
                        myLeave?.map((value, key) => {
                            return value?.id == id ? { ...Response?.data, id: compOffUpdateDetails?.id } : value;
                        })
                    );
                }
                setOpenCompOffModal(false)

                successToast(Response?.message);
                setIsUserSchedulerData(false);
            }
            if (Response.code == 400) {
                // doCloseModal()
                errorToast(Response?.message);
                setIsUserSchedulerData(false);
                setIsAddLoader(false)
            }
        } catch (error) {
            console.log(error);
            setIsUserSchedulerData(false);
            setIsAddLoader(false)

        }
    };

    const handleCompOffApply = async (e) => {
        e.preventDefault()
        try {
            // let rmId = [];
            // await selectedUserObject && selectedUserObject.filter((value, key) => {
            //     return value?.id && rmId?.push(value?.id);
            // });
            let payload = await {
                startDate: moment(startCompOffDate).format(),
                endDate: moment(endCompOffDate).format(),
                isHalfDay: dayTypeCompOff?.value === 1 ? false : dayTypeCompOff?.value === 2 ? true : null,
                // leaveType: leaveType?.value,
                // leaveHalfType: selectDayHalf?.value == undefined ? null : selectDayHalf?.value,
                note: compOffDetails?.note,
                // notify: rmId
            }
            // setIsUserSchedulerData(true);
            setIsAddLoader(true)
            const Response = await authenticationService.applyCompOff(payload);
            if (Response.code != 400) {
                // setResourceAllocationReport(Response?.data)
                setIsAddLoader(false)

                setMyLeave([...new Set([Response?.data, ...myLeave])]);
                // doCloseCompOffModal()
                setOpenCompOffModal(false)
            } else {
                setIsAddLoader(false)

                errorToast(Response?.message);
                setOpenCompOffModal(false)
            }
        } catch (error) {
            console.log(error);
            setIsAddLoader(false)
        }
        setIsUserSchedulerData(false)
        setIsAddLoader(false)
    };

    const handleLeaveApply = async (e) => {
        e.preventDefault()
        try {
            let rmId = [];
            await selectedUserObject && selectedUserObject.filter((value, key) => {
                return value?.id && rmId?.push(value?.id);
            });
            let payload = await {
                startDate: moment(startDate).format(),
                endDate: moment(endDate).format(),
                dayType: dayType?.value,
                leaveType: leaveType?.value,
                leaveHalfType: selectDayHalf?.value == undefined ? null : selectDayHalf?.value,
                note: leaveDetails?.note,
                notify: rmId
            }
            // setIsUserSchedulerData(true);
            setIsAddLoader(true)
            const Response = await authenticationService.applyLeave(payload);
            if (Response.code != 400) {
                // setResourceAllocationReport(Response?.data)
                setIsAddLoader(false)

                setMyLeave([...new Set([Response?.data, ...myLeave])]);
                doCloseModal()
            } else {
                setIsAddLoader(false)

                errorToast(Response?.message);
            }
        } catch (error) {
            console.log(error);
            setIsAddLoader(false)
        }
        setIsUserSchedulerData(false)
        setIsAddLoader(false)
    };

    // const leaveCount = myLeave?.forEach(item => {
    //     const startDate = moment(item.startDate);
    //     const endDate = moment(item.endDate);
    //     const differenceInDays = endDate.diff(startDate, 'days') + 1;

    //     console.log(`ID: ${item.id}, Difference in Days: ${differenceInDays}`);
    // });


    // // Array to store leave counts for each item
    // const leaveCounts = [];

    // // Calculate leave count for each item
    // myLeave?.forEach(item => {
    //     const startDate = moment(item.startDate);
    //     const endDate = moment(item.endDate);

    //     // Calculate the difference in days
    //     const differenceInDays = endDate.diff(startDate, 'days') + 1; // Adding 1 for the same day

    //     // Store leave count for the current item
    //     leaveCounts.push({ id: item.id, leaveCount: differenceInDays });
    // });

    // // Output leave counts for each item
    // leaveCounts.forEach(item => {
    //     console.log(`ID: ${item.id}, Leave Count: ${item.leaveCount}`);
    // });

    // // Now you can access leave counts outside of the loop, for example:
    // const leaveCountForItemWithID9 = leaveCounts.find(item => item.id === 9);   

    const dataOption = () => {
        const optionsWithSelected = myTeamsUserList.map((item) => {
            return {
                ...item,
                value: item.id,
                // selected: selectedUserObject.some(selected => selected.id == item.id),
            };
        });
        setSelectedUserObject(optionsWithSelected)
        return optionsWithSelected
    }

    const optionsWithSelected = myTeamsUserList.map((item) => {
        return {
            ...item,
            value: item.id,
            // selected: selectedUserObject.some(selected => selected.id == item.id),
        };
    });

    const handleUserChange = (option) => {
        setSelectedUserObject(option);
    };
    const tooltipUsersText = selectedUserObject.length
        ? selectedUserObject.map((option) => option.label).join(", ")
        : "";

    const getUser = (selected, _options) => {
        if (selected.length == _options.length) {
            return `All users are selected. (${selected.length})`;
        } else if (selected.length >= 3) {
            return `${selected[0].label}, ${selected[1].label}, (+${selected.length -
                2}) more`;
        }
    };

    const customUserRenderer = (selected, _options) => {
        return selected.length ? getUser(selected, _options) : "Select Employee";
    };

    const isButtonDisabled = startDate == undefined || endDate == undefined || dayType == undefined || leaveDetails?.note == undefined || (dayType?.value != 1 && selectDayHalf?.value == undefined) || leaveType?.value == undefined ? true : false

    const isCompOffButtonDisabled = startCompOffDate == undefined || endCompOffDate == undefined || dayTypeCompOff == undefined || compOffDetails?.note == undefined ? true : false

    const isButtonCancelDisabled = leaveCancelReason?.CancelReason == undefined || leaveCancelReason?.CancelReason == "" ? true : false

    const isButtonCompOffCancelDisabled = compOffCancelReason?.CancelReason == undefined || compOffCancelReason?.CancelReason == "" ? true : false

    const isUpdateButtonDisabled =
        moment(leaveUpdateDetails?.startDate).format("DD-MM-YYYY") != moment(updateStartDate).format("DD-MM-YYYY") ||
            moment(leaveUpdateDetails?.endDate).format("DD-MM-YYYY") != moment(updateEndDate).format("DD-MM-YYYY")
            && leaveUpdateDetails?.leaveType != updateLeaveType?.name && updateLeaveType?.name != undefined
            && updateDayType?.name != leaveUpdateDetails?.dayType && updateDayType?.name != undefined
            && (updateDayType?.value != 1 && (selectDayHalf?.value != undefined && selectDayHalf?.value != null))
            || (leaveUpdateDetails?.leaveHalf != selectDayHalf?.name && selectDayHalf?.value != undefined)
            || (updateDayType?.value == 1 && selectDayHalf == null)
            || (updateDayType?.name != leaveUpdateDetails?.dayType && (leaveUpdateDetails?.leaveHalf != selectDayHalf?.name && selectDayHalf?.value != undefined || selectDayHalf?.value != null))
            || (leaveUpdateData?.note != "" && leaveUpdateData?.note != leaveUpdateDetails?.note)
            || !_.isEqual(leaveUpdateDetails?.notify, selectedUserObject)
            ? false : true

    return (
        <>
            <div style={{ margin: "0 30px" }}>
                <div style={{
                    background: "white", padding: " 5px 15px", borderRadius: "10px", marginTop: "6rem",
                }}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "20px" }}>
                        <div style={{ height: "3rem", marginBottom: "10px", marginTop: "0.5rem", alignItems: "center", justifyContent: "start", display: "flex" }}>
                            {/* <div style={{ borderRadius: "50%", border: "10px solid #2986CE", height: "4rem", width: "4rem" }}></div> */}

                            <div style={{ display: "flex" }}>
                                <div style={{ fontSize: "18px", marginBottom: "5px" }}>Privilege Leave:</div>
                                <div style={{ display: "flex", fontSize: "24px", marginLeft: "5px", justifyContent: "center", fontWeight: 500 }}>{leaveBalance?.privilegeLeave}</div>
                            </div>

                            <div style={{ marginLeft: "30px", display: "flex" }}>
                                <div style={{ fontSize: "18px", marginBottom: "5px" }}>Custom Festival Leave:</div>
                                <div style={{ display: "flex", fontSize: "24px", marginLeft: "5px", justifyContent: "center", fontWeight: 500 }}>{leaveBalance?.customeFestivalLeave}</div>
                            </div>

                            <div style={{ marginLeft: "30px", display: "flex" }}>
                                <div style={{ fontSize: "18px", marginBottom: "5px" }}>Bereavement Leave:</div>
                                <div style={{ display: "flex", fontSize: "24px", marginLeft: "5px", justifyContent: "center", fontWeight: 500 }}>{leaveBalance?.bereavementLeave}</div>
                            </div>

                            <div style={{ marginLeft: "30px", display: "flex" }}>
                                <div style={{ fontSize: "18px", marginBottom: "5px" }}>LWP:</div>
                                <div style={{ display: "flex", fontSize: "24px", marginLeft: "5px", justifyContent: "center", fontWeight: 500 }}>{leaveBalance?.leaveWithOutPay}</div>
                            </div>

                            <div style={{ marginLeft: "30px", display: "flex" }}>
                                <div style={{ fontSize: "18px", marginBottom: "5px" }}>Comp Offs:</div>
                                <div style={{ display: "flex", fontSize: "24px", marginLeft: "5px", justifyContent: "center", fontWeight: 500 }}>{leaveBalance?.compOffs}</div>
                            </div>
                            {/* <div style={{ marginLeft: "30px", display: "flex" }}>
                                <div style={{ fontSize: "18px", marginBottom: "5px" }}>Sandwich Leave:</div>
                                <div style={{ display: "flex", fontSize: "24px", marginLeft: "5px", justifyContent: "center", fontWeight: 500 }}>{leaveBalance?.sandwichLeaves}</div>
                            </div> */}
                        </div>

                        <div className="scheduler_filter" style={{ display: "flex", marginBottom: "10px", marginTop: "0.5rem", alignItems: "center", justifyContent: "end" }}>
                            <div style={{ display: "flex" }}>
                                <div
                                    style={{
                                        display: "grid",
                                        width: "16rem",
                                        marginRight: "10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <Select
                                        maxMenuHeight={350}
                                        isSearchable={false}
                                        // isClearable
                                        styles={colourStyles}
                                        placeholder="Select months"
                                        value={selectedYear}
                                        onChange={(e) => {
                                            setSelectedYear(e);
                                            handleMyLeave(e?.value);
                                        }}
                                        options={yearOptions}
                                    />
                                </div>
                                {/* 
                                <div style={{
                                    marginRight:"1.5rem"
                                    
                                }}>
                                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={selectedYear}
                                            onChange={(e) => {
                                                  setSelectedYear(e?.target?.value);
                                                  handleMyLeave(e?.target?.value);
                                            }}
                                            autoWidth
                                            label="Year"
                                            MenuProps={MenuProps}
                                        >
                                            {yearOptions.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name?.value}
                                                //  style={getStyles(name, personName, theme)}
                                                >
                                                    {name?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div> */}
                                <div
                                    style={{
                                        alignItems: "center",
                                        // display: "flex",
                                        // // justifyContent: "center",
                                        // width: "5%",leave_request_ketan
                                        margin: "0px 7px",
                                    }}
                                >
                                    <Button
                                        style={{
                                            background: "#f96117db",
                                            // fontWeight: "bold",
                                            borderRadius: "10px",
                                            color: "#FFF",
                                            // width: "30%",
                                            padding: '0.5rem 2rem',
                                            textTransform: "none"
                                        }}
                                        onClick={() => {
                                            setOpenModal(true);
                                            setLeaveUpdateData("")
                                            setLeaveUpdateDetails("")
                                        }}
                                    >
                                        Request Leave
                                    </Button>
                                </div>

                                <div
                                    style={{
                                        alignItems: "center",
                                        // display: "flex",
                                        // // justifyContent: "center",
                                        // width: "5%",leave_request_ketan
                                        margin: "0px 7px",
                                    }}
                                >
                                    <Button
                                        style={{
                                            background: "#f96117db",
                                            // fontWeight: "bold",
                                            borderRadius: "10px",
                                            color: "#FFF",
                                            // width: "30%",
                                            padding: '0.5rem 1rem',
                                            textTransform: "none",
                                            // marginTop: "10px"
                                        }}
                                        onClick={() => {
                                            setOpenCompOffModal(true);

                                            // setLeaveUpdateData("")
                                            // setLeaveUpdateDetails("")
                                        }}
                                    >
                                        Request Comp Off
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{color: "#f93939" ,marginLeft:"1rem" ,marginBottom: "0.5rem"}}>Note: Below table shows leave applied in the system from the 1st august.</div> */}
                </div>

                {isUserSchedulerData ? (<div style={{}}>
                    <UserSchedulerShimmer />
                </div>) : (<>
                    <div style={{ background: "white", marginTop: "20px", padding: "20px", borderRadius: "10px", display: "block" }}>
                        <div
                            className='user_list'
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "left",
                                overflowX: "scroll",
                                minWidth: "90%",
                                maxHeight: `${height}px`,
                            }}
                        >
                            <table id="myTable" style={{ width: "100%", borderCollapse: "collapse" }}>
                                <tr
                                    style={{ width: "100%", background: "#f2f2f2" }}
                                >
                                    <th style={{
                                        // padding: "10px 0px",
                                        border: "1px solid #0000001a",
                                        position: "sticky",
                                        top: 0,
                                        backgroundColor: "#f2f2f2",
                                        zIndex: 10,
                                        // left: 0
                                        //  width: "10vw"
                                    }}>Leave Dates</th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "8rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // left: 0
                                            // width: "5vw",
                                        }}
                                    >
                                        Leave Type
                                    </th>

                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Requested By
                                    </th>

                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Action Taken on
                                    </th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Leave Note
                                    </th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Status
                                    </th>
                                    <th
                                        style={{
                                            padding: "10px 0px",
                                            border: "1px solid #0000001a",
                                            minWidth: "7rem",
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#f2f2f2",
                                            zIndex: 10,
                                            // width: "5vw",
                                        }}
                                    >
                                        Actions
                                    </th>
                                </tr>
                                {/* <tr>
                                The Table indicates Technology wise head count
                            </tr> */}

                                {myLeave && myLeave.map((data, key) => {
                                    return (
                                        <>
                                            <tr
                                                style={{
                                                    background:
                                                        isToggle === key ? "#e7f1ff" : "white",
                                                    cursor: "pointer",
                                                    border: "1px solid #e7e7e7"
                                                }}
                                            >
                                                {/* <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center", width: "15rem" }}>
                                                    {data?.startDate == data?.endDate ? <div>
                                                        {moment(data?.startDate).format('MMM DD, YYYY')} - {moment(data?.endDate).format('MMM DD, YYYY')}
                                                        {
                                                            data?.leaveHalf != null ? <span style={{ fontSize: "16px", color: "#919191", paddingLeft: "5px" }}>({data?.leaveHalf})</span> : ""
                                                        }
                                                    </div>
                                                        :
                                                        ` ${moment(data?.startDate).format('MMM DD, YYYY')} - ${moment(data?.endDate).format('MMM DD, YYYY')}`
                                                    }
                                                </td> */}

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", textAlign: "center", width: "15rem" }}>
                                                    <div>
                                                        {moment(data?.startDate).format('MMM DD, YYYY')} - {moment(data?.endDate).format('MMM DD, YYYY')}
                                                    </div>
                                                    <div style={{ textAlign: "start", paddingLeft: "20px" }}>
                                                        {data?.leaveHalf != null ? (
                                                            <span style={{ fontSize: "16px", color: "#919191" }}>
                                                                ({data?.leaveHalf})
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {data.dayType == "Full Day" ? <span style={{ fontSize: "16px", color: "#919191" }}>
                                                            ({moment(data?.endDate).diff(moment(data?.startDate), 'days') + 1} day{moment(data?.endDate).diff(moment(data?.startDate), 'days') + 1 > 1 ? 's' : ''})
                                                        </span> : ""}
                                                    </div>
                                                </td>

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center", width: "15rem" }}>
                                                    {/* <div style={{ textTransform: "capitalize" }}>{data?.leaveType}</div> */}
                                                    {/* <div style={{}}>{moment(data?.createdAt).format('DD-MM-YYYY')}</div> */}
                                                    <div>{data?.leaveType}</div>
                                                    <div style={{ fontSize: "13px", color: "#919191" }}>
                                                        Requested on {moment(data?.createdAt).utc().local().format('MMM DD, YYYY hh:mm A')}
                                                    </div>

                                                </td>

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center", width: "15rem" }}>{data?.
                                                    user?.name}</td>

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{data?.actionTakenOn != null && moment(data?.actionTakenOn).format('MMM DD,YYYY hh:mm')}</td>

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center" }}>{data?.
                                                    note}</td>

                                                <td style={{ padding: "15px", border: "1px solid #0000001a", padding: "5px", textAlign: "center", width: "15rem" }}>
                                                    {data?.isDeleted ? <div style={{ color: "#727272", fontWeight: "700" }}>
                                                        Cancelled
                                                        <div style={{ fontSize: "13px", color: "#919191" }}>By {data?.user?.name}</div>
                                                    </div>
                                                        : <><div style={{ textTransform: "capitalize" }}>
                                                            {/* <span></span>  */}
                                                            {data?.requestStatus == "pending" ? <span style={{ color: "#f96117db", fontWeight: "800" }}>{data?.requestStatus}</span> : ""}
                                                            {data?.requestStatus == "rejected" ? <span style={{ color: "#f93939", fontWeight: "800" }}>{data?.requestStatus}</span> : ""}
                                                            {data?.requestStatus == "approved" ? <span style={{ color: "#43a047", fontWeight: "800" }}>{data?.requestStatus}</span> : ""}
                                                        </div>
                                                            {data?.requestStatus != "pending" && <div style={{ fontSize: "13px", color: "#919191" }}>By {data?.actionTakenBy?.name}</div>}</>
                                                    }
                                                </td>

                                                <td style={{ padding: "15px", paddingTop: "1.5rem", padding: "5px", display: "flex", justifyContent: "center", cursor: data?.requestStatus == "rejected" ? "default" : "pointer" }}>
                                                    <div style={{ display: "flex", border: "none" }}>
                                                        <div onClick={() => {
                                                            if (data?.leaveType === 'compOffRequest') {
                                                                setOpenCompOffModal(true);
                                                                setUpdateStartCompOffDate(new Date(data?.startDate).toISOString().split('T')[0])
                                                                setUpdateEndCompOffDate(new Date(data?.endDate).toISOString().split('T')[0])
                                                                setUpdateDayTypeCompOff({
                                                                    value: data?.dayType == "Full Day" ? 1 : data?.dayType || data?.dayType == "Half Day" ? 2 : data?.dayType || data?.dayType == "Quarter Day" ? 3 : data?.dayType,
                                                                    name: data?.dayType,
                                                                    label: data?.dayType
                                                                })
                                                                setCompOffUpdateData(data);
                                                                setIsCompOffUpdate(true);
                                                                setCompOffUpdateDetails(data)
                                                            } else {
                                                                setIsLeaveUpdate(true);
                                                                setOpenModal(true);
                                                                setLeaveUpdateData(data);
                                                                setLeaveUpdateDetails(data);
                                                                setUpdateStartDate(new Date(data?.startDate).toISOString().split('T')[0])
                                                                setUpdateEndDate(new Date(data?.endDate).toISOString().split('T')[0])
                                                                setSelectDayHalf({
                                                                    value: data?.leaveHalf,
                                                                    name: data?.leaveHalf,
                                                                    label: data?.leaveHalf
                                                                });
                                                                setUpdateDayType({
                                                                    value: data?.dayType == "Full Day" ? 1 : data?.dayType || data?.dayType == "Half Day" ? 2 : data?.dayType || data?.dayType == "Quarter Day" ? 3 : data?.dayType,
                                                                    name: data?.dayType,
                                                                    label: data?.dayType
                                                                })
                                                                setSelectedUserObject(data?.notify)
                                                                setSelectedRm(data?.notify)
                                                                setUpdateLeaveType({
                                                                    // "Leave Without Pay"
                                                                    value: data?.leaveType == "Privilege Leave" ? 1 : data?.leaveType || data?.leaveType ==
                                                                        "Custome Festival Leave" ? 2 : data?.leaveType || data?.leaveType == "Bereavement Leave" ? 3 : data?.leaveType || data?.leaveType == "Leave Without Pay" ? 4 : data?.leaveType,
                                                                    name: data?.leaveType,
                                                                    label: data?.leaveType
                                                                })
                                                            }

                                                        }}
                                                            // style={{ visibility: data?.requestStatus == "rejected" ? "hidden" : "visible", }}
                                                            style={{ visibility: (new Date(data.endDate) < new Date() && data?.requestStatus != "pending") || data?.requestStatus == "rejected" || data?.requestStatus == "cancelled" ? "hidden" : "visible", border: "none" }}
                                                        >
                                                            <Tooltip
                                                                id="tooltip-top"
                                                                title="Edit"
                                                                placement="bottom"
                                                            >
                                                                <IconButton aria-label="Edit" style={{ color: "#3271d1" }}>
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </div>
                                                        <div
                                                            // onClick={() => {
                                                            //     // setIsLeaveUpdate(true);
                                                            //     // setOpenModal(true);
                                                            //     // setLeaveData(data?.id)
                                                            //     // handledeleteLeave(data?.id)
                                                            //     setOpenCancelModal(true);
                                                            //     setLeaveCancelId(data?.id);
                                                            // }}

                                                            onClick={() => {
                                                                if (data?.leaveType === 'compOffRequest') {
                                                                    setCompOffCancelId(data?.id);
                                                                    setOpenCompOffCancelModal(true);
                                                                } else {
                                                                    // setIsLeaveUpdate(true);
                                                                    // setOpenModal(true);
                                                                    // setLeaveData(data?.id)
                                                                    // handledeleteLeave(data?.id)
                                                                    setOpenCancelModal(true);
                                                                    setLeaveCancelId(data?.id);
                                                                }
                                                            }}

                                                            style={{ visibility: (new Date(data.endDate) < new Date() && data?.requestStatus != "pending") || data?.requestStatus == "rejected" || data?.requestStatus == "cancelled" ? "hidden" : "visible", }}
                                                        >
                                                            <Tooltip
                                                                id="tooltip-top"
                                                                title="Cancel"
                                                                placement="bottom"
                                                            >
                                                                <IconButton aria-label="Delete" style={{ color: "#f23e41" }}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                                }
                            </table>
                        </div>
                    </div>
                </>)}
            </div>
            {/* Update Leave */}
            <Modal
                style={{ opacity: "1", }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={() => {
                    doCloseModal()
                }}
            >
                <Box in={openModal}>
                    <div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "#fff",
                            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
                            borderRadius: "8px",
                            padding: "50px 39px 36px 39px",
                            // width: "25%",
                        }}
                    >
                        <Box>
                            <div style={{
                                display: "flex",
                                //  margin: isProjectUpdate ? `1.1rem` : '1.5rem',
                                justifyContent: "space-between", alignItems: "center"
                            }}>
                                <div >
                                    <div style={{ fontSize: "20px", fontWeight: 700 }} id="transition-modalconst">
                                        {!isLeaveUpdate ? "Request" : "Update"} Leave
                                    </div>
                                </div>
                                <div onClick={() => doCloseModal()} style={{
                                    cursor: 'pointer', justifyContent: "end",
                                    display: "flex",
                                    marginBottom: "20px"
                                }}>
                                    <img src={close} alt="" height={20} width={20} />
                                </div>
                            </div>

                            <Box style={{ display: 'flex', marginTop: "20px" }}>
                                <div style={{ display: 'flex' }}>
                                    {isLeaveUpdate ? (
                                        <>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>Start Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={new Date(updateStartDate)}
                                                        onChange={(date) => {
                                                            setUpdateStartDate(date);
                                                            setUpdateLeaveType(null);
                                                            setUpdateDayType(null);
                                                            setSelectDayHalf(null);
                                                        }}

                                                        dateFormat="dd/MM/yyyy"
                                                        dateFormatCalendar='dd-MM-yyyy'
                                                        // selectsStart
                                                        placeholderText='Start Date'
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>

                                            <div>
                                                <div style={{ height: "1.5rem" }}></div>
                                                <div style={{ marginLeft: "10px", border: "1px solid #aeaeae", padding: "7px", borderRadius: "5px" }}>
                                                    {updateStartDate && updateEndDate ? `${updatedSelectedDays} day${updatedSelectedDays > 1 ? 's' : ''}` : "0 days"}

                                                </div>
                                            </div>

                                            <div style={{ padding: "15px" }}></div>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>End Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={new Date(updateEndDate)}
                                                        minDate={new Date(updateStartDate)}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={(date) => (setUpdateEndDate(date), setUpdateLeaveType(null), setUpdateDayType(null), setSelectDayHalf(null))}
                                                        // selectsEnd
                                                        placeholderText='End Date'
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>Start Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => (setStartDate(date), setDayType(""), setLeaveType(""), setEndDate(""), setSelectDayHalf(""))}
                                                        dateFormat="dd/MM/yyyy"
                                                        selectsStart
                                                        placeholderText='Start Date'
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>

                                            <div>
                                                <div style={{ height: "1.5rem" }}></div>
                                                <div style={{ marginLeft: "10px", padding: "7px", border: "1px solid #aeaeae", borderRadius: "5px" }}>
                                                    {startDate && endDate
                                                        ? dayType?.label == "Half Day"
                                                            ? "0.5 Day"
                                                            : `${selectedDays} day${selectedDays === 1 ? '' : 's'}`
                                                        : "0 days"}
                                                </div>
                                            </div>

                                            <div style={{ padding: "5px" }}></div>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>End Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={endDate}
                                                        minDate={startDate}
                                                        onChange={(date) => (setEndDate(date), setDayType(""), setLeaveType(""), setSelectDayHalf(""))}
                                                        dateFormat="dd/MM/yyyy"
                                                        selectsEnd
                                                        placeholderText='End Date'
                                                        disabled={!startDate}
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>
                                        </>
                                    )}
                                </div>
                            </Box>
                            {(isLeaveUpdate
                                // ? (updateStartDate && updateEndDate && updatedSelectedDays > privilegeLeave && privilegeLeave > 0)
                                // : (startDate && endDate && selectedDays > privilegeLeave && privilegeLeave > 0)
                                ? (updateStartDate && updateEndDate && updatedSelectedDays > privilegeLeave && privilegeLeave > 0 &&
                                    !(selectedDays === 1 && (privilegeLeave === 0.5 || privilegeLeave === 0.25 || privilegeLeave === 0.75)))
                                : (startDate && endDate && selectedDays > privilegeLeave && privilegeLeave > 0 &&
                                    !(selectedDays === 1 && (privilegeLeave === 0.5 || privilegeLeave === 0.25 || privilegeLeave === 0.75)))
                            ) && (
                                    <Box style={{ marginTop: '5px', color: 'red', fontSize: '14px' }}>
                                        * Apply {privilegeLeave} days of PL first, then use other leave types for the remaining days.
                                    </Box>
                                )}

                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Leave Type:</span>
                                {isLeaveUpdate ? <Select
                                    maxMenuHeight={180}
                                    styles={colourStyles}
                                    isSearchable={false}
                                    placeholder="Select Leave Type"
                                    value={updateLeaveType}
                                    // isDisabled={(updatedSelectedDays > privilegeLeave && privilegeLeave > 0) ? true : false}
                                    isDisabled={
                                        (updatedSelectedDays > privilegeLeave && privilegeLeave > 0) &&
                                        !(selectedDays == 1 && (privilegeLeave == 0.5 || privilegeLeave == 0.25 || privilegeLeave == 0.75))}

                                    onChange={(value) => {
                                        // setUpdateDayType(e, value)
                                        // setSelectDayHalf(null)
                                        setUpdateLeaveType(value)
                                        setUpdateDayType(null)
                                        // setDayType()
                                        // setSelectDayHalf()
                                    }}
                                    options={LeaveTypeOps}
                                    isOptionDisabled={(option) => option.isdisabled}
                                /> :
                                    <Select
                                        maxMenuHeight={180}
                                        styles={colourStyles}
                                        placeholder="Select Leave Type"
                                        isSearchable={false}
                                        // isDisabled={startDate == undefined || endDate == undefined || (selectedDays > privilegeLeave && privilegeLeave > 0) ? true : false}
                                        isDisabled={
                                            startDate === undefined ||
                                            endDate === undefined ||
                                            ((selectedDays > privilegeLeave && privilegeLeave > 0) &&
                                                !(selectedDays === 1 && (privilegeLeave === 0.5 || privilegeLeave === 0.25 || privilegeLeave === 0.75)))
                                        }
                                        value={leaveType}
                                        onChange={(value) => {
                                            setLeaveType(value)
                                            setDayType("")
                                            setSelectDayHalf("")
                                        }}
                                        // isDisabled={startDate == undefined || endDate == undefined ? true : false}
                                        options={LeaveTypeOps}
                                        isOptionDisabled={(option) => option.isdisabled}
                                    />}
                            </Box>

                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Day Type:</span>
                                {isLeaveUpdate ? <Select
                                    maxMenuHeight={180}
                                    isSearchable={false}
                                    styles={colourStyles}
                                    placeholder="Select Day Type"
                                    value={updateDayType}
                                    onChange={(e, value) => {
                                        setUpdateDayType(e, value)
                                        setSelectDayHalf(null)
                                    }}
                                    options={DayType}
                                    isOptionDisabled={(option) => option.isdisabled}
                                /> :
                                    <Select
                                        maxMenuHeight={180}
                                        styles={colourStyles}
                                        isSearchable={false}
                                        placeholder="Select Day Type"
                                        isDisabled={leaveType?.value == undefined ? true : false}
                                        value={dayType}
                                        onChange={(e, value) => {
                                            setDayType(e, value)
                                        }}
                                        // isDisabled={startDate == undefined || endDate == undefined ? true : false}
                                        options={DayType}
                                        isOptionDisabled={(option) => option.isdisabled}
                                    />}
                            </Box>

                            <Box style={{ marginTop: "20px", }}>
                                {dayType?.name == "Quarter Day" || dayType?.name == "Half Day" || updateDayType?.name == "Quarter Day" || updateDayType?.name == "Half Day" ?
                                    <Select
                                        maxMenuHeight={180}
                                        isSearchable={false}
                                        styles={colourStyles}
                                        placeholder="Leave half"
                                        value={isLeaveUpdate ? selectDayHalf : selectDayHalf}
                                        onChange={(e, value) => {
                                            setSelectDayHalf(e, value)
                                        }}
                                        options={leaveTypeOps}

                                    />
                                    : ""}
                                {dayType?.name == "Quarter Day" && selectDayHalf?.name === "First Half" ? (
                                    <div style={{ color: "red", marginTop: "10px " }}>Time: 10:00 AM to 12:00 AM</div>
                                ) : dayType?.name == "Quarter Day" && selectDayHalf?.name === "Second Half" ? (
                                    <div style={{ color: "red", marginTop: "10px " }}>Time: 4:30 PM to 6:30 PM</div>
                                ) : null}
                            </Box>

                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Reason:</span>
                                <Box className='t-dropdown-select' style={{ marginTop: "0px" }}>
                                    <input
                                        type="text"
                                        className="t-dropdown-input"
                                        placeholder="Reason"
                                        style={{
                                            paddingLeft: 18,
                                        }}
                                        name="note"
                                        onChange={(e) => {
                                            handleInputChange(e.target)
                                        }}
                                        // value={isProjectUpdate ? projectUpdateData?.clientName : projectClient == undefined ? "" : projectClient}
                                        value={isLeaveUpdate ? leaveUpdateData?.note : leaveDetails?.note}
                                    // disabled
                                    />
                                </Box>
                            </Box>

                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Notify:</span>
                                <div
                                    style={{
                                        width: "100%",
                                        // margin: "4px 30px",
                                        background: "none",
                                        // minWidth: "30%",
                                    }}
                                    title={tooltipUsersText}
                                >
                                    <MultiSelect
                                        isMulti
                                        autoFocus
                                        menuIsOpen
                                        displayValue="label"
                                        allowCustomValue={true}
                                        valueRenderer={customUserRenderer}
                                        onChange={(selected) => handleUserChange(selected)}
                                        // options={
                                        //     myTeamsUserList &&
                                        //     myTeamsUserList.sort(function (a, b) {
                                        //         const nameA = a?.label?.toUpperCase();
                                        //         const nameB = b?.label?.toUpperCase();
                                        //         if (nameA > nameB) {
                                        //             return 1;
                                        //         }
                                        //         if (nameA < nameB) {
                                        //             return -1;
                                        //         }
                                        //         return 0;
                                        //     })
                                        // }
                                        options={optionsWithSelected}
                                        value={selectedUserObject}
                                        allowSelectAll={true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={true}
                                        isLoading={isLoader}
                                    />
                                </div>
                            </Box>
                        </Box>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {isAddLoader ?
                                (<div>
                                    <CircularProgress style={{ width: 30, height: 30, marginTop: '15px' }} />{" "}
                                </div>) : (isLeaveUpdate ? <Button style={{ background: isUpdateButtonDisabled ? "gray" : "#f4590d", color: 'white', marginTop: '15px', textTransform: "none" }}
                                    disabled={isUpdateButtonDisabled}
                                    onClick={(e) => {
                                        // doHandleAddSetting();
                                        // handleResourceAllocationReport(startDate, endDate, selectedFilterClient)

                                        handleLeaveUpdate(leaveUpdateData?.id, startDate, endDate, leaveType, dayType, selectedFilterReportingManager)
                                    }}
                                >
                                    Update
                                </Button> :
                                    <Button style={{ background: isButtonDisabled ? "gray" : "#f4590d", color: 'white', marginTop: '15px', textTransform: "none" }}
                                        disabled={isButtonDisabled}
                                        onClick={(e) => {
                                            // doHandleAddSetting();
                                            // handleResourceAllocationReport(startDate, endDate, selectedFilterClient)
                                            handleLeaveApply(e)
                                            // { isLeaveUpdate ? handleLeaveUpdate(leaveUpdateData?.id, startDate, endDate, leaveType, dayType, selectedFilterReportingManager) : handleLeaveApply(e) }
                                        }}
                                    >
                                        {/* {isLeaveUpdate ? "Update" : "Apply"} */}
                                        Request
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                style={{ opacity: "1", }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openCompOffModal}
                onClose={() => {
                    doCloseCompOffModal()
                }}
            >
                <Box in={openCompOffModal}>
                    <div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "#fff",
                            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
                            borderRadius: "8px",
                            padding: "50px 39px 36px 39px",
                            // width: "25%",
                        }}
                    >
                        <Box>
                            <div style={{
                                display: "flex",
                                //  margin: isProjectUpdate ? `1.1rem` : '1.5rem',
                                justifyContent: "space-between", alignItems: "center"
                            }}>
                                <div >
                                    <div style={{ fontSize: "20px", fontWeight: 700 }} id="transition-modalconst">
                                        {!isCompOffUpdate ? "Request" : "Update"} Leave
                                    </div>
                                </div>
                                <div onClick={() => doCloseCompOffModal()} style={{
                                    cursor: 'pointer', justifyContent: "end",
                                    display: "flex",
                                    marginBottom: "20px"
                                }}>
                                    <img src={close} alt="" height={20} width={20} />
                                </div>
                            </div>

                            <Box style={{ display: 'flex', marginTop: "20px" }}>
                                <div style={{ display: 'flex' }}>
                                    {isCompOffUpdate ? (
                                        <>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>Start Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={new Date(updateStartCompOffDate)}
                                                        onChange={(date) => {
                                                            setUpdateStartCompOffDate(date);
                                                            setUpdateLeaveType(null);
                                                            setUpdateDayType(null);
                                                            setSelectDayHalf(null);
                                                        }}

                                                        dateFormat="dd/MM/yyyy"
                                                        dateFormatCalendar='dd-MM-yyyy'
                                                        // selectsStart
                                                        placeholderText='Start Date'
                                                        maxDate={new Date()}
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>

                                            <div>
                                                <div style={{ height: "1.5rem" }}></div>
                                                <div style={{ marginLeft: "10px", border: "1px solid #aeaeae", padding: "7px", borderRadius: "5px" }}>
                                                    {updateStartCompOffDate && updateEndCompOffDate ? `${updatedSelectedCompOffDays} day${updatedSelectedCompOffDays > 1 ? 's' : ''}` : "0 days"}
                                                </div>
                                            </div>

                                            <div style={{ padding: "15px" }}></div>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>End Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={new Date(updateEndCompOffDate)}
                                                        minDate={new Date(updateStartCompOffDate)}
                                                        maxDate={new Date()}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={(date) => (setUpdateEndCompOffDate(date), setUpdateDayTypeCompOff(null))}
                                                        // selectsEnd
                                                        placeholderText='End Date'
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>Start Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={startCompOffDate}
                                                        onChange={(date) => (setStartCompOffDate(date), setDayTypeCompOff(""), setEndCompOffDate(""))}
                                                        dateFormat="dd/MM/yyyy"
                                                        selectsStart
                                                        placeholderText='Start Date'
                                                        maxDate={new Date()}
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>

                                            <div>
                                                <div style={{ height: "1.5rem" }}></div>
                                                <div style={{ marginLeft: "10px", padding: "7px", border: "1px solid #aeaeae", borderRadius: "5px" }}>
                                                    {startCompOffDate && endCompOffDate ? `${selectedCompOffDays} day${selectedCompOffDays === 1 ? '' : 's'}` : "0 days"}
                                                </div>
                                            </div>

                                            <div style={{ padding: "5px" }}></div>
                                            <Box>
                                                <span style={{ fontSize: "14px", color: "#000" }}>End Date:</span>
                                                <DateRangePickerWrapper {...dateRangePickerProps}>
                                                    <DatePicker
                                                        selected={endCompOffDate}
                                                        minDate={startCompOffDate}
                                                        onChange={(date) => (setEndCompOffDate(date), setDayTypeCompOff(""))}
                                                        dateFormat="dd/MM/yyyy"
                                                        selectsEnd
                                                        placeholderText='End Date'
                                                        disabled={!startCompOffDate}
                                                        maxDate={new Date()}
                                                    />
                                                </DateRangePickerWrapper>
                                            </Box>
                                        </>
                                    )}
                                </div>
                            </Box>
                            {/* {(isLeaveUpdate
                                // ? (updateStartDate && updateEndDate && updatedSelectedDays > privilegeLeave && privilegeLeave > 0)
                                // : (startDate && endDate && selectedDays > privilegeLeave && privilegeLeave > 0)
                                ? (updateStartDate && updateEndDate && updatedSelectedDays > privilegeLeave && privilegeLeave > 0 &&
                                    !(selectedDays === 1 && (privilegeLeave === 0.5 || privilegeLeave === 0.25 || privilegeLeave === 0.75)))
                                : (startDate && endDate && selectedDays > privilegeLeave && privilegeLeave > 0 &&
                                    !(selectedDays === 1 && (privilegeLeave === 0.5 || privilegeLeave === 0.25 || privilegeLeave === 0.75)))
                            ) && (
                                    <Box style={{ marginTop: '5px', color: 'red', fontSize: '14px' }}>
                                        * Apply {privilegeLeave} days of PL first, then use other leave types for the remaining days.
                                    </Box>
                                )} */}

                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Day Type:</span>
                                {isCompOffUpdate ? <Select
                                    maxMenuHeight={180}
                                    isSearchable={false}
                                    styles={colourStyles}
                                    placeholder="Select Day Type"
                                    value={updateDayTypeCompOff}
                                    onChange={(e, value) => {
                                        setUpdateDayTypeCompOff(e, value)
                                    }}
                                    options={DayType}
                                    isOptionDisabled={(option) => option.isdisabled}
                                /> :
                                    <Select
                                        maxMenuHeight={180}
                                        styles={colourStyles}
                                        isSearchable={false}
                                        placeholder="Select Day Type"
                                        // isDisabled={leaveType?.value == undefined ? true : false}
                                        value={dayTypeCompOff}
                                        onChange={(e, value) => {
                                            setDayTypeCompOff(e, value)
                                        }}
                                        // isDisabled={startDate == undefined || endDate == undefined ? true : false}
                                        options={CompOffDayType}
                                        isOptionDisabled={(option) => option.isdisabled}
                                    />}
                            </Box>

                            {/* <Box style={{ marginTop: "20px", }}>
                                {dayType?.name == "Quarter Day" || dayType?.name == "Half Day" || updateDayType?.name == "Quarter Day" || updateDayType?.name == "Half Day" ?
                                    <Select
                                        maxMenuHeight={180}
                                        isSearchable={false}
                                        styles={colourStyles}
                                        placeholder="Leave half"
                                        value={isLeaveUpdate ? selectDayHalf : selectDayHalf}
                                        onChange={(e, value) => {
                                            setSelectDayHalf(e, value)
                                        }}
                                        options={leaveTypeOps}

                                    />
                                    : ""}
                                {dayType?.name == "Quarter Day" && selectDayHalf?.name === "First Half" ? (
                                    <div style={{ color: "red", marginTop: "10px " }}>Time: 10:00 AM to 12:00 AM</div>
                                ) : dayType?.name == "Quarter Day" && selectDayHalf?.name === "Second Half" ? (
                                    <div style={{ color: "red", marginTop: "10px " }}>Time: 4:30 PM to 6:30 PM</div>
                                ) : null}
                            </Box> */}

                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Reason:</span>
                                <Box className='t-dropdown-select' style={{ marginTop: "0px" }}>
                                    <input
                                        type="text"
                                        className="t-dropdown-input"
                                        placeholder="Reason"
                                        style={{
                                            paddingLeft: 18,
                                        }}
                                        name="note"
                                        onChange={(e) => {
                                            handleCompOffInputChange(e.target)
                                        }}
                                        // value={isProjectUpdate ? projectUpdateData?.clientName : projectClient == undefined ? "" : projectClient}
                                        value={isCompOffUpdate ? compOffUpdateData?.note : compOffDetails?.note}
                                    // disabled
                                    />
                                </Box>
                            </Box>

                        </Box>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {isAddLoader ?
                                (<div>
                                    <CircularProgress style={{ width: 30, height: 30, marginTop: '15px' }} />{" "}
                                </div>) : (isCompOffUpdate ? <Button style={{ background: isUpdateButtonDisabled ? "gray" : "#f4590d", color: 'white', marginTop: '15px', textTransform: "none" }}
                                    disabled={isUpdateButtonDisabled}
                                    onClick={(e) => {
                                        // doHandleAddSetting();
                                        // handleResourceAllocationReport(startDate, endDate, selectedFilterClient)
                                        handleCompOffUpdate(compOffUpdateData?.id, startCompOffDate, endCompOffDate, dayTypeCompOff)
                                    }}
                                >
                                    Update
                                </Button> :
                                    <Button style={{ background: isCompOffButtonDisabled ? "gray" : "#f4590d", color: 'white', marginTop: '15px', textTransform: "none" }}
                                        disabled={isCompOffButtonDisabled}
                                        onClick={(e) => {
                                            // doHandleAddSetting();
                                            // handleResourceAllocationReport(startDate, endDate, selectedFilterClient)
                                            handleCompOffApply(e)
                                            setDayTypeCompOff()
                                            setCompOffDetails("")
                                            setStartCompOffDate()
                                            setEndCompOffDate()
                                            // { isLeaveUpdate ? handleLeaveUpdate(leaveUpdateData?.id, startDate, endDate, leaveType, dayType, selectedFilterReportingManager) : handleLeaveApply(e) }
                                        }}
                                    >
                                        {/* {isLeaveUpdate ? "Update" : "Apply"} */}
                                        Request
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* delete Leave */}

            <Modal
                style={{ opacity: "1", }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openCancelModal}
                onClose={() => {
                    doCloseCancelModal()
                }}
            >
                <Box in={openCancelModal}>
                    <div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "#fff",
                            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
                            borderRadius: "8px",
                            padding: "30px 39px 36px 39px",
                            // width: "25%",
                        }}
                    >
                        <Box>
                            <div style={{
                                display: "flex",
                                //  margin: isProjectUpdate ? `1.1rem` : '1.5rem',
                                justifyContent: "space-between", alignItems: "center"
                            }}>
                                <div >
                                    <div style={{ fontSize: "20px", fontWeight: 700 }} id="transition-modalconst">
                                        Cancel Leave
                                    </div>
                                </div>
                                <div onClick={() => doCloseCancelModal()} style={{
                                    cursor: 'pointer', justifyContent: "end",
                                    display: "flex",
                                    marginBottom: "20px"
                                }}>
                                    <img src={close} alt="" height={20} width={20} />
                                </div>
                            </div>
                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Reason:</span>
                                <Box className='t-dropdown-select' style={{ marginTop: "0px", width: "20rem" }}>
                                    <input
                                        type="text"
                                        className="t-dropdown-input"
                                        placeholder="Reason"
                                        style={{
                                            paddingLeft: 18,
                                        }}
                                        name="CancelReason"
                                        onChange={(e) => {
                                            handleInputReason(e.target)
                                        }}
                                    // value={isProjectUpdate ? projectUpdateData?.clientName : projectClient == undefined ? "" : projectClient}
                                    // value={isLeaveUpdate ? leaveUpdateData?.note : leaveDetails?.note}
                                    // disabled
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {isAddLoader ?
                                (<div>
                                    <CircularProgress style={{ width: 30, height: 30, marginTop: '15px' }} />{" "}
                                </div>) : (
                                    <Button style={{ background: isButtonCancelDisabled ? "gray" : "#f4590d", color: 'white', marginTop: '15px', textTransform: "none" }}
                                        disabled={isButtonCancelDisabled}
                                        onClick={(e) => {
                                            // doHandleAddSetting();
                                            // handleResourceAllocationReport(startDate, endDate, selectedFilterClient)
                                            handledeleteLeave(leaveCancelId, e)
                                            // { isLeaveUpdate ? handleLeaveUpdate(leaveUpdateData?.id, startDate, endDate, leaveType, dayType, selectedFilterReportingManager) : handleLeaveApply(e) }
                                        }}
                                    >
                                        {/* {isLeaveUpdate ? "Update" : "Apply"} */}
                                        Submit
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                </Box >
            </Modal >

            <Modal
                style={{ opacity: "1", }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openCompOffCancelModal}
                onClose={() => {
                    doCloseCompOffCancelModal()
                }}
            >
                <Box in={openCompOffCancelModal}>
                    <div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "#fff",
                            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
                            borderRadius: "8px",
                            padding: "30px 39px 36px 39px",
                            // width: "25%",
                        }}
                    >
                        <Box>
                            <div style={{
                                display: "flex",
                                //  margin: isProjectUpdate ? `1.1rem` : '1.5rem',
                                justifyContent: "space-between", alignItems: "center"
                            }}>
                                <div >
                                    <div style={{ fontSize: "20px", fontWeight: 700 }} id="transition-modalconst">
                                        Cancel CompOff
                                    </div>
                                </div>
                                <div onClick={() => doCloseCompOffCancelModal()} style={{
                                    cursor: 'pointer', justifyContent: "end",
                                    display: "flex",
                                    marginBottom: "20px"
                                }}>
                                    <img src={close} alt="" height={20} width={20} />
                                </div>
                            </div>
                            <Box style={{ marginTop: "20px" }}>
                                <span style={{ fontSize: "14px", color: "#000" }}>Reason:</span>
                                <Box className='t-dropdown-select' style={{ marginTop: "0px", width: "20rem" }}>
                                    <input
                                        type="text"
                                        className="t-dropdown-input"
                                        placeholder="Reason"
                                        style={{
                                            paddingLeft: 18,
                                        }}
                                        name="CancelReason"
                                        onChange={(e) => {
                                            handleInputCompOffReason(e.target)
                                        }}
                                    // value={isProjectUpdate ? projectUpdateData?.clientName : projectClient == undefined ? "" : projectClient}
                                    // value={isLeaveUpdate ? leaveUpdateData?.note : leaveDetails?.note}
                                    // disabled
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {isAddLoader ?
                                (<div>
                                    <CircularProgress style={{ width: 30, height: 30, marginTop: '15px' }} />{" "}
                                </div>) : (
                                    <Button style={{ background: isButtonCompOffCancelDisabled ? "gray" : "#f4590d", color: 'white', marginTop: '15px', textTransform: "none" }}
                                        disabled={isButtonCompOffCancelDisabled}
                                        onClick={(e) => {
                                            // doHandleAddSetting();
                                            // handleResourceAllocationReport(startDate, endDate, selectedFilterClient)
                                            handledeleteCompOff(compOffCancelId, e)
                                            // { isLeaveUpdate ? handleLeaveUpdate(leaveUpdateData?.id, startDate, endDate, leaveType, dayType, selectedFilterReportingManager) : handleLeaveApply(e) }
                                        }}
                                    >
                                        {/* {isLeaveUpdate ? "Update" : "Apply"} */}
                                        Submit
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                </Box >
            </Modal >
            <ToastContainer />
        </>
    )
}

export default Leave

