// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import WorkHistory from "views/WorkHistory/WorkHistory";
import Profile from "views/Profile/Profile";
import MyTeam from "views/MyTeam/MyTeam";
import UserScheduler from "views/UserScheduler/UserScheduler";
import ResourceAllocationReport from "views/ResourceAllocationReport/ResourceAllocationReport";
import Report from "views/Report/Report";
import ProjectRequests from "views/ProjectRequests/ProjectRequests";
// import Leave from "views/Leaves/Leave";
import Leave  from "views/Leaves/Leave";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/panel",
  },
  {
    path: "/history",
    name: "History",
    rtlName: "",
    icon: "content_paste",
    component: WorkHistory,
    layout: "/panel",
  },
  {
    path: "/my-team",
    name: "MyTeam",
    rtlName: "",
    icon: "content_paste",
    component: MyTeam,
    layout: "/panel",
  },
  {
    path: "/user-scheduler",
    name: "UserScheduler",
    rtlName: "",
    icon: "content_paste",
    component: UserScheduler,
    layout: "/panel",
  },
  {
    path: "/report",
    name: "Report",
    rtlName: "",
    icon: "content_paste",
    component: Report,
    layout: "/panel",
  },
  {
    path: "/allocation-report",
    name: "ResourceAllocationReport",
    rtlName: "",
    icon: "content_paste",
    component: ResourceAllocationReport,
    layout: "/panel",
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "",
    icon: "content_paste",
    component: Profile,
    layout: "/panel",
  },
  {
    path: "/projectRequests",
    name: "ProjectRequests",
    rtlName: "",
    icon: "content_paste",
    component: ProjectRequests,
    layout: "/panel",
  },
  {
    path: "/leave",
    name: "Leaves",
    rtlName: "",
    icon: "content_paste",
    component: Leave,
    layout: "/panel",
  },
];

export default dashboardRoutes;
