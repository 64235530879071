// import React from 'react'
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePickerWrapper from "../../assets/css/datepicker.style";
import User from "assets/img/user.png";
import Plus from "assets/img/plus.png";
import Plusgrey from "assets/img/plus_grey.png";
import update from "assets/img/edit.png";
import deletee from "assets/img/bin.png";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { DATE_FILTER_TYPE } from "helper/constant";
import TodayIcon from "@material-ui/icons/Today";
// import projectUpdateDetails from "./ProjectDetailsData";
import UserScheduletModal from "components/UserScheduler/UserScheduletModal";
import Select from "react-select";

import { stubFalse } from "lodash";
import { authenticationService } from "services/authentication";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer } from "react-toastify";
import UserSchedulerShimmer from "components/Shimmer/UserSchedulerShimmer";
import ExportReport from "assets/img/export.png";
import Avatar from "@mui/material/Avatar";
import DatePickerFilter from "components/DatePickerFilter/DatePickerFilter";
import { Tooltip } from "@mui/material";

// ----------------------------------------------------------------------------

const UserScheduler = () => {
  const UPPER_DIV_HEIGHT = 238;
  const DATESTRINGFORMAT = "YYYY-MM-DD";
  const [employeeData, setEmployeeData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tempDate, SetTempDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectionComplete, toggleSelectionComplete] = useState(false);
  const [currentDate, setCurrentDate] = useState([]);
  const [newEmployeeData, setNewEmployeeData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [isProjectUpdate, setIsProjectUpdate] = useState(false);
  const [projectUpdateData, setProjectUpdateData] = useState();
  const [projectList, setProjectList] = useState([]);
  const [myTeamsUserList, setMyTeamsUserList] = useState([]);
  const [selectedUserObject, setSelectedUserObject] = useState([]);
  const [height, setHeight] = useState(window.innerHeight - UPPER_DIV_HEIGHT);
  const [getProjectCode, setGetProjectCode] = useState([]);
  const [getTechCode, setGetTechCode] = useState([]);
  const [getDepartment, setGetDepartment] = useState([]);
  const [getReportingManager, setGetReportingManager] = useState([]);
  const [getClient, setGetClient] = useState([]);
  const [isTooltipHovered, setTooltipHovered] = useState(false);
  // date picker
  const [isOpen, setIsOpen] = useState(false);
  const [datePickerActiveChip, SetDatePickerActiveChip] = useState(
    DATE_FILTER_TYPE.THISWEEK
  );
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [selectedFilterEmployee, setSelectedFilterEmpolyee] = useState();
  const [getProjectList, setGetProjectList] = useState();
  const [selectedFilterProject, setSelectedFilterProject] = useState();
  const [selectedFilterTechnology, setSelectedFilterTechnology] = useState();
  const [selectedFilterDepartment, setSelectedFilterDepartment] = useState();
  const [
    selectedFilterReportingManager,
    setSelectedFilterReportingManager,
  ] = useState();
  const [selectedFilterPercentage, setSelectedFilterPercentage] = useState();
  const [selectedFilterClient, setSelectedFilterClient] = useState();
  const [selectedFilterMonths, setSelectedFilterMonths] = useState();
  const [filterProjectList, setFilterProjectList] = useState([]);
  const [isUserSchedulerData, setIsUserSchedulerData] = useState(false);
  const [changeIndexValue, setChangeIndexValue] = useState(0);
  const [isProjectAdd, setIsprojectAdd] = useState(false);
  const [totalWidth, setTotalWidth] = useState({
    tableWidth: "",
    employeWidth: "",
    dayWidth: "",
  });

  const [totalFilterDays, setTotalFilterDay] = useState(30);
  const [modalOpen, setModalOpen] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState(null);
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [projectEndDate, setProjectEndDate] = useState(null);
  const [projectName, setProjectName] = useState(null)
  const [projectEmployeeName, setProjectEmployeeName] = useState(null)
  const [holidayList, setHolidayList] = useState([])



  // ----------------------------------------------------------------------------

  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  let errorToast = (errMsg) => {
    toast.error(errMsg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  // document.addEventListener("DOMContentLoaded", function() {
  //     // Your code here employee-list
  //     console.log(document.querySelector("#user_list").offsetWidth, "total width");
  //     console.log(document.querySelector("#employee-list").offsetWidth, "total width");

  // });

  // console.log(width);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - UPPER_DIV_HEIGHT);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    doClickOnCurrentMonth();
    handleProjectChange();
    handleTechnologyChange();
    handleDepartmentChange();
    handleReportingManagerChange();
    handleClientChange();
    doGetMyTeamsUserList();
    SetDatePickerActiveChip(DATE_FILTER_TYPE.THISMONTH);
    setSelectedFilterPercentage(selectedFilterPercentage);

    const currentMonth = moment().format("MMMM");
    const defaultMonthOption = OptionMonth.find(
      (option) => option.value === currentMonth
    );
    setSelectedFilterMonths(defaultMonthOption);
  }, []);

  useEffect(() => {
    doManipulateArray(newEmployeeData);
    return () => {
      SetTempDate({ startDate: "", endDate: "" });
    };
  }, [newEmployeeData]);

  const doToggleModalVisibility = () => {
    setOpenModal(!openModal);
  };

  const doOpanModal = (employee, day, project) => {
    setOpenModal(!openModal);
    setSelectedEmployee(employee);
    setSelectedDay(day ? day : null);
    setProjectUpdateData(project);
  };

  const percentages = [
    { label: "25%", name: "25%", value: "25%" },
    { label: "50%", name: "50%", value: "50%" },
    { label: "75%", name: "75%", value: "75%" },
    { label: "100%", name: "100%", value: "100%" },
    {
      label: "Partial Allocation",
      name: "Partial Allocation",
      value: "1",
      isdisabled: moment(new Date()).isAfter(endDate, "day") ? true : false,
    },
    {
      label: "No Allocation",
      name: "No Allocation",
      value: "0",
      // isdisabled: moment(new Date()).isAfter(endDate, "day") ? true : false,
    },
  ];

  const OptionMonth = [
    { label: "January", name: "January", value: "January" },
    { label: "February", name: "February", value: "February" },
    { label: "March", name: "March", value: "March" },
    { label: "April", name: "April", value: "April" },
    { label: "May", name: "May", value: "May" },
    { label: "June", name: "June", value: "June" },
    { label: "July", name: "July", value: "July" },
    { label: "August", name: "August", value: "August" },
    { label: "September", name: "September", value: "September" },
    { label: "October", name: "October", value: "October" },
    { label: "November", name: "November", value: "November" },
    { label: "December", name: "December", value: "December" },
  ];
  // const months = [
  //     { label: "January", name: "January", value: "January" },
  //     { label: "February", name: "February", value: "February" },
  //     { label: "March", name: "March", value: "March" },
  //     { label: "April", name: "April", value: "April" },
  //     { label: "May", name: "May", value: "May" },
  //     { label: "June", name: "June", value: "June" },
  //     { label: "July", name: "July", value: "July" },
  //     { label: "August", name: "August", value: "August" },
  //     { label: "September", name: "September", value: "September" },
  //     { label: "October", name: "October", value: "October" },
  //     { label: "November", name: "November", value: "November" },
  //     { label: "December", name: "December", value: "December" }
  // ];




  const doGetDate = async (startDate, endDate) => {
    const start_date = await startDate;
    const end_date = await endDate;

    const date = new Date(start_date); // Initialize date with startDate
    let all_days = [];
    while (date <= end_date) {
      let d =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date
          .getDate()
          .toString()
          .padStart(2, "0");
      all_days.push(d); // Push the date to the all_days array directly
      date.setDate(date.getDate() + 1);
    }
    DoGetHoliday(start_date, end_date)
    setCurrentDate(all_days);
  };


  const DoGetHoliday = async (startDate, endDate) => {
    const start_date = await moment(startDate).format("YYYY-MM-DD");
    const end_date = await moment(endDate).format("YYYY-MM-DD");
    try {
      const Response = await authenticationService.fetchHolidays(start_date, end_date);

      setHolidayList(Response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const filterDayShow = (startDate, endDate) => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    // const  daysCount = Math.floor((endDate - startDate) / millisecondsPerDay) + 1;
    setTotalFilterDay(
      Math.floor((endDate - startDate) / millisecondsPerDay) + 1
    );
    const change_index = moment(new Date(startDate)).format("DD") - 1;
    const start_date = startDate;
    const end_date = endDate;
    const date = new Date(start_date); // Initialize date with startDate
    let all_days = [];
    while (date <= end_date) {
      let d =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date
          .getDate()
          .toString()
          .padStart(2, "0");
      all_days.push(d); // Push the date to the all_days array directly
      date.setDate(date.getDate() + 1);
    }
    setCurrentDate(all_days);
    setChangeIndexValue(Number(moment(new Date(startDate)).format("DD")) - 1);
  };

  const customUserRenderer = (selected, _options) => {
    return selected.length ? getUser(selected, _options) : "Select Employee";
  };

  const getUser = (selected, _options) => {
    if (selected.length == _options.length) {
      return `All users are selected. (${selected.length})`;
    } else if (selected.length >= 3) {
      return `${selected[0].label}, ${selected[1].label}, (+${selected.length -
        2}) more`;
    }
  };

  const handleUserChange = (selected) => {
    setSelectedUserObject(selected);
  };

  const colourStyles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "5px",
        height: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f4590d",
      },
    }),

    control: (base, state) => ({
      ...base,
      zIndex: state.isFocused ? "999" : "1",
      background: "#ffffff",
      // background: '#efefef',
      color: "#000000",
      fontWeight: "bold",
      padding: 20,
      // match with the menu
      height: 40,
      minHeight: 35,
      fontSize: 16,
      fontWeight: 400,
      cursor: "pointer",
      padding: "0px 8px",
      // border: 'none',
      // outline: 'none',
      // Overwrittes the different states of border
      borderColor: "#0000003b",
      background: state.isDisabled ? "#f0f0f0" : "",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#0000003b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontWeight: "400",
        color: isDisabled
          ? "black"
          : isSelected
            ? "white"
              ? "white"
              : "black"
            : "black",
        cursor: "pointer",
        backgroundColor:
          isSelected && data.value === "1" // Check if the option is selected and its value is "1"
            ? "#43a047"
            : isSelected
              ? "#f96117db"
              : isFocused
                ? "#f961173d"
                : undefined,
        background: isDisabled ? "#f0f0f0" : "",
        ":hover": {
          backgroundColor:
            isFocused && data.value === "1"
              ? "#43a047"
              : "#f96117db" || (isFocused && isSelected)
                ? "#f96117db"
                : "f961173d",
          // backgroundColor: isSelected && data.value === "1" ? "green" : (isFocused && isSelected ? "#f96117db" : "#f961173d"),
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
        },
      };
    },
  };

  const datePickerProps = () => {
    return { isRangerHoverAllowed: true };
  };

  const getCurrWeekDate = (isFetchRecordRequired = true) => {
    let wDate = new Date();
    let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
    let first = wDate.getDate() - dDay + 1;
    let firstDayWeek = new Date(wDate.setDate(first));
    setStartDate(firstDayWeek);
    setEndDate(new Date());
    toggleSelectionComplete(true);
    if (isFetchRecordRequired) {
      //   doHandleMyTeamsFilter("", firstDayWeek, new Date());
    }
  };

  const doHandleShowDateClick = () => {
    setStartDate(tempDate.startDate);
    setEndDate(tempDate.endDate);
    setIsOpen(false);
  };

  const doHandleDatepickerOutsideClick = () => {
    setIsOpen(false);
    if (tempDate.startDate && tempDate.endDate) {
      SetTempDate({
        startDate,
        endDate,
      });

      setIsOpen(false);
    } else {
      // getCurrWeekDate(false);
      // doClickOnThisMonth(false)
    }
  };

  const doHandleDateChange = (date) => {
    // startDate
    if (!tempDate.startDate && !tempDate.endDate) {
      SetTempDate({ ...tempDate, startDate: date, endDate: null });
    }
    //endDate
    if (tempDate.startDate && !tempDate.endDate) {
      let dt1 = moment(tempDate.startDate, "YYYY-MM-DD")
        .add(-1, "M")
        .toDate();
      let dt2 = moment(date, "YYYY-MM-DD").toDate();
      let diffMonths = (dt2.getFullYear() - dt1.getFullYear()) * 12;
      diffMonths -= dt1.getMonth();
      diffMonths += dt2.getMonth();

      if (diffMonths > 6 && moment(date).isAfter(tempDate.startDate)) {
        // errorToast("Please select a range of maximum 6 months.");
        SetTempDate({
          ...tempDate,
          startDate: tempDate.startDate,
          endDate: null,
        });
        return;
      }
      SetTempDate({ ...tempDate, endDate: date });
    }

    if (moment(date).isSame(tempDate.startDate)) {
      SetTempDate({
        ...tempDate,
        startDate: tempDate.startDate,
        endDate: date,
      });
    }

    if (
      tempDate.startDate &&
      !tempDate.endDate &&
      !moment(date).isAfter(tempDate.startDate) &&
      !moment(date).isSame(tempDate.startDate)
    ) {
      SetTempDate({
        ...tempDate,
        startDate: tempDate.startDate,
        endDate: null,
      });
      return;
    }

    if (tempDate.startDate && tempDate.endDate) {
      SetTempDate({ ...tempDate, startDate: date, endDate: null });
    }
  };

  const handleSelect = (date) => {
    //onChange is not fired if selecting same date - workaround to fire doHandleDateChange
    if (
      !selectionComplete &&
      startDate &&
      !endDate &&
      sameDay(date, tempDate.startDate)
    ) {
      doHandleDateChange(date);
    }
  };

  // for open the datepicker modal
  const doHandleDatePickerOpen = () => {
    setIsOpen(true);
    SetTempDate({
      startDate: startDate,
      endDate: endDate,
    });
  };

  // datepicker input
  const CustomInputs = ({ startDate, endDate }) => {
    return (
      <div
        className="datepicker"
        style={{
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          height: "35px",
          justifyContent: "center",
          width: "13rem",
          borderRadius: "5px",
        }}
        onClick={() => {
          doHandleDatePickerOpen();
        }}
      >
        <div
          style={{
            paddingTop: 0,
            display: "flex",
            alignItems: "center",
            fontSize: 16,
          }}
        >
          {/* <TodayIcon
                        style={{
                            margin: "0px 5px 0px 0px",
                            height: "20px",
                            color: "#57646b",
                        }}
                    /> */}
          <div style={{ color: "#666" }}>
            {`${startDate ? moment(startDate).format("DD/MM/YYYY") : "-"} to ${endDate ? moment(endDate).format("DD/MM/YYYY") : "-"
              }`}
          </div>
        </div>
      </div>
    );
  };

  const doClickOnToday = () => {
    SetDatePickerActiveChip(DATE_FILTER_TYPE.TODAY);
    SetTempDate({
      startDate: new Date(),
      endDate: new Date(),
    });
  };
  const doClickOnThisWeek = () => {
    let wDate = new Date();
    let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
    let first = wDate.getDate() - dDay + 1;
    let firstDayWeek = new Date(wDate.setDate(first));

    SetDatePickerActiveChip(DATE_FILTER_TYPE.THISWEEK);
    SetTempDate({
      startDate: firstDayWeek,
      endDate: new Date(),
    });
  };

  const doClickOnYesterday = () => {
    var date = new Date();
    date.setDate(date.getDate() - 1);

    SetDatePickerActiveChip(DATE_FILTER_TYPE.YESTERDAY);
    SetTempDate({
      startDate: date,
      endDate: date,
    });
  };

  const doClickOnCurrentMonth = (isFetchRecordRequired = true) => {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDayOfThisMonth = new Date(y, m, 1);
    setStartDate(firstDayOfThisMonth);
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    // toggleSelectionComplete(true);
    if (isFetchRecordRequired) {
      //   doHandleMyTeamsFilter("", firstDayWeek, new Date());
    }
    SetDatePickerActiveChip(DATE_FILTER_TYPE.THISMONTH);
    SetTempDate({
      startDate: firstDayOfThisMonth,
      endDate: new Date(),
    });
    doGetDate(
      firstDayOfThisMonth,
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    );
    // DoGetHoliday(
    //   firstDayOfThisMonth,
    //   new Date(date.getFullYear(), date.getMonth() + 1, 0)
    // )
    handleSchedulerUserFilterList(
      firstDayOfThisMonth,
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    );
  };

  const doClickOnThisMonth = (isFetchRecordRequired = false) => {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDayOfThisMonth = new Date(y, m, 1);
    setStartDate(firstDayOfThisMonth);
    // setEndDate(new Date());
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    // toggleSelectionComplete(true);
    if (isFetchRecordRequired) {
      //   doHandleMyTeamsFilter("", firstDayWeek, new Date());
    }
    SetDatePickerActiveChip(DATE_FILTER_TYPE.THISMONTH);
    SetTempDate({
      startDate: firstDayOfThisMonth,
      // endDate: new Date(),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });
  };

  const doClickOnLast7Days = () => {
    var date = new Date();
    date.setDate(date.getDate() - 7);

    SetDatePickerActiveChip(DATE_FILTER_TYPE.LAST7DAYS);
    SetTempDate({
      startDate: date,
      endDate: new Date(),
    });
  };
  const doClickOnLastMonth = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

    SetDatePickerActiveChip(DATE_FILTER_TYPE.LASTMONTH);
    SetTempDate({
      startDate: firstDay,
      endDate: lastDay,
    });
  };
  const doClickOnLastWeek = () => {
    let beforeOneWeek = new Date(
      new Date().getTime() - 60 * 60 * 24 * 7 * 1000
    );
    let beforeOneWeek2 = new Date(beforeOneWeek);
    let day = beforeOneWeek.getDay();
    let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
    let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

    SetDatePickerActiveChip(DATE_FILTER_TYPE.LASTWEEK);
    SetTempDate({
      startDate: lastMonday,
      endDate: lastSunday,
    });
  };
  const doClickOnCancel = () => {
    if (tempDate.startDate && tempDate.endDate) {
      SetTempDate({
        // startDate,
        // endDate,
        startDate: startDate,
        endDate: endDate,
      });
    } else {
      // getCurrWeekDate(false);
      doClickOnThisMonth(false);
    }
    setIsOpen(false);
  };
  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  // list of employee
  const doGetMyTeamsUserList = async () => {
    // setIsLoader(true);
    try {
      const Response = await authenticationService.fetchMyTeamsUserList();
      if (Response?.data) {
        setMyTeamsUserList([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.id,
              // label: value?.name,
              label: value?.code ? `${value?.code} - ${value.name}` : value?.name
            };
          }),
        ]);
      }
      //   setIsLoader(false);
    } catch (error) {
      //   setIsLoader(false);
      console.log(error);
    }
  };

  // list of projects
  const handleProjectChange = async () => {
    try {
      const Response = await authenticationService.fetchProjectListResourceAllocation();

      let doProjectCode = [];
      const Code = Response.data
        .sort(function (a, b) {
          const nameA = a.projectCode;
          const nameB = b.projectCode;
          return nameA - nameB;
        })
        .map((item) => {
          // setGetProjectCode(Response?.data?.projectCode)
          const data = item?.projectCode;
          doProjectCode.push(data);
        });
      setGetProjectCode(doProjectCode);
      doProjectCode.push(getProjectCode);
      setGetProjectList(Response?.data);
      if (Response && Response?.data) {
        let doGetProjectData = [];
        const groups = Response?.data?.reduce((groups, game) => {
          const groupsName = game?.groups?.groupName;
          if (!groups[groupsName]) {
            groups[groupsName] = [];
          }
          groups[groupsName].push(game);
          return groups;
        }, {});
        const groupArrays = Object.entries(groups)
          .sort(function (a, b) {
            const nameA = a[0].toUpperCase();
            const nameB = b[0].toUpperCase();
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }
            return 0;
          })
          .map((groupName) => {
            let data = {
              label: groupName[0],
              options: groupName[1]
                .sort(function (a, b) {
                  const nameA = a.projectName.toUpperCase();
                  const nameB = b.projectName.toUpperCase();
                  if (nameA > nameB) {
                    return 1;
                  }
                  if (nameA < nameB) {
                    return -1;
                  }
                  return 0;
                })
                .map((item, key) => {
                  return {
                    id: item.id,
                    name: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    label: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    value: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    client: item?.client?.clientName,
                  };
                }),
            };
            doGetProjectData.push(data);
          });
        setProjectList(doGetProjectData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // List of technology
  const handleTechnologyChange = async () => {
    try {
      const Response = await authenticationService.fetchMyTeamsTechList();
      if (Response?.data) {
        setGetTechCode([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.id,
              label: value?.technologyName,
            };
          }),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // List of Department
  const handleDepartmentChange = async () => {
    try {
      const Response = await authenticationService.fetchDepartmentList();
      if (Response?.data) {
        setGetDepartment([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.id,
              label: value?.departmentName,
            };
          }),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // List of Reporting Manager
  const handleReportingManagerChange = async () => {
    try {
      const Response = await authenticationService.fetchReportingManagerList();
      if (Response?.data) {
        setGetReportingManager([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.id,
              // label: value?.name,
              label: value?.code ? `${value?.code} - ${value.name}` : value?.name
            };
          }),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // List of Client
  const handleClientChange = async () => {
    try {
      const Response = await authenticationService.fetchMyTeamsClientList();
      if (Response?.data) {
        setGetClient([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.id,
              label: value?.clientName,
            };
          }),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // API integration of filter date, employee, project, technology
  const handleSchedulerUserFilterList = async (
    startDate,
    endDate,
    employeeId,
    projectId,
    techId,
    percentageValue,
    clientId,
    departmentId,
    RMId
  ) => {
    try {
      let object = {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        dueDate: moment(endDate).format("YYYY-MM-DD"),
        user: employeeId == undefined && employeeId == null ? [] : [employeeId],
        project:
          projectId == undefined && projectId?.id == null
            ? []
            : [projectId?.id],
        technology:
          techId == undefined && techId?.id == null ? [] : [techId?.id],
        department:
          departmentId == undefined && departmentId?.id == null
            ? []
            : [departmentId?.id],
        reportingManger:
          RMId == undefined && RMId?.id == null ? [] : [RMId?.id],
        percentageAllocation:
          percentageValue == undefined && percentageValue?.value == null
            ? []
            : [percentageValue?.value],
        client:
          clientId == undefined && clientId?.value == null
            ? []
            : [clientId?.value],
      };
      setIsUserSchedulerData(true);
      const Response = await authenticationService.fetchFilterSchedulerTeamsList(
        object
      );
      if (Response) {

        setNewEmployeeData(Response?.data[0]?.employee);
        setFilterProjectList(projectId);
      } else {
        setFilterProjectList(null);
      }
    } catch (error) {
      console.log(error);
      setNewEmployeeData([]);
      // setIsUserSchedulerData(false)
    }
    setIsUserSchedulerData(false);
  };

  // for export the csv
  function doHandleDownloadingResourceAllocation(content) {
    // if (!contentType) contentType = 'application/octet-stream';
    var a = document.createElement("a");
    var blob = new Blob([content]);
    a.href = window.URL.createObjectURL(blob);
    a.download = `Resource allocation_${moment(new Date()).format(
      "DD-MM-YYYY"
    )}.xlsx`;
    a.click();
  }

  // Download API integration
  const handleOnClickExportDownloadCSV = async (
    employeeId,
    projectId,
    techId,
    percentageValue,
    clientId,
    departmentId,
    reportingManagerId
  ) => {
    // setFileExportWithOutPenaltyEmployeeLoader(true);
    // setExportLoader(true);
    try {
      let object = {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        dueDate: moment(endDate).format("YYYY-MM-DD"),
        user: employeeId == undefined && employeeId == null ? [] : [employeeId],
        project:
          projectId == undefined && projectId?.id == null
            ? []
            : [projectId?.id],
        technology:
          techId == undefined && techId?.id == null ? [] : [techId?.id],
        percentageAllocation:
          percentageValue == undefined && percentageValue?.value == null
            ? []
            : [percentageValue?.value],
        client:
          clientId == undefined && clientId?.value == null
            ? []
            : [clientId?.value],
        department:
          departmentId == undefined && departmentId?.value == null
            ? []
            : [departmentId?.value],
        reportingManger:
          reportingManagerId == undefined && reportingManagerId?.value == null
            ? []
            : [reportingManagerId?.value],

        type: "Download",
      };
      // const axiosA = axios.CancelToken;
      // cancelSource.current = axiosA.source();
      const response = await authenticationService.fetchFilterSchedulerTeamsList(
        object
        // cancelSource.current.token
      );
      const { status, data, error } = response;
      if (status == false || status == 400) {
        if (data.message == "Cancelled") {
          // setSelectedDatevalue("");
          // setFileExportWithOutPenaltyEmployeeLoader(false);
        }
      }
      if (status && data) {
        doHandleDownloadingResourceAllocation(data);
        // doHandleDownloadingCSVFile(data);
      }
      // setSelectedDatevalue("");
      // setFileExportWithOutPenaltyEmployeeLoader(false);
      // setExportLoader(false);
    } catch (error) {
      // setSelectedDatevalue("");
      // setFileExportWithOutPenaltyEmployeeLoader(false);
      // setExportLoader(false);
      console.log(error);
    }
  };

  // Function to handle opening/closing of modal and setting project ID
  const handleModal = (projectId, startDate, endDate, ProjectName, EmployeeName) => {
    setProjectIdToDelete(projectId);
    setProjectStartDate(startDate);
    setProjectEndDate(endDate);
    setProjectName(ProjectName)
    setProjectEmployeeName(EmployeeName)
    setModalOpen(!modalOpen);
  };

  // delete API integration

  // if (response.data) {
  //   setRequestData(
  //           requestData?.map((value, key) => {
  //               return value?.id == response.data?.id ? { ...value, isPending: response?.data?.isPending } : value;
  //           })
  //       )
  //       handleCloseProjectRequestModal()
  //   }


  const handledeleteSchedulerUserList = async (id) => {
    try {
      const Response = await authenticationService.deleteAssignProject(id);
      if (Response.code == 200) {
        // successToast("project successfully deleted");
        // const deleteEmployeeData = newEmployeeData.map((user) => {
        //   const deleteProjects = user.projects
        //     .map((project) => {console.log(project,"555")})
        //   return { ...user, projects: deleteProjects };
        // });
        // setNewEmployeeData(deleteEmployeeData);



        // setNewEmployeeData(
        //  newEmployeeData?.map((value, key) => {
        //     value?.projects?.map((item)=>{
        //       debugger
        //       return item?.id == id ? { ...item, isPending: Response?.data?.isPending } : item;
        //     })
        //     })
        // )

        setNewEmployeeData(
          newEmployeeData?.map((value, key) => {
            return {
              ...value,
              projects: value.projects.map((item) => {
                return item.id == id ? { ...item, isPending: Response?.data?.isPending } : item;
              })
            };
          })
        );




        setModalOpen(false); // Close the modal after deletion
      } else {
        errorToast(Response?.Error);
      }
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };

  // shows the months name
  function getStartAndEndDates(e, monthName) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = months.indexOf(e?.value);
    if (monthIndex === -1) {
      return "Invalid month name";
    }

    const year = new Date().getFullYear();
    const startDate = new Date(year, monthIndex, 1);
    const endDate =
      monthIndex === 11
        ? new Date(year + 1, 0, 0)
        : new Date(year, monthIndex + 1, 0);
    doGetDate(startDate, endDate);
    // DoGetHoliday(startDate, endDate)
    setStartDate(startDate);
    setEndDate(endDate);
    handleSchedulerUserFilterList(
      startDate,
      endDate,
      selectedFilterEmployee?.id,
      selectedFilterProject,
      selectedFilterTechnology,
      selectedFilterPercentage,
      selectedFilterClient,
      selectedFilterDepartment,
      selectedFilterReportingManager
    );
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    setTotalFilterDay(
      Math.floor((endDate - startDate) / millisecondsPerDay) + 1
    );
  }

  // check the dates, in that dates, project exist or not
  const enumerateDaysBetweenDates = async (
    startDate,
    endDate,
    existingDatesArr = []
  ) => {
    try {
      let dates = [];
      let responsePayload = {
        isExist: false,
        dates: [],
      };
      let currDate = moment(startDate).startOf("day");
      let lastDate = moment(endDate).startOf("day");

      const DATESTRINGFORMAT = "YYYY-MM-DD";
      let isExist = existingDatesArr.includes(
        currDate.format(DATESTRINGFORMAT)
      );
      if (isExist) {
        responsePayload.isExist = true;
      }

      dates.push(currDate.format(DATESTRINGFORMAT));

      while (currDate.add(1, "days").diff(lastDate) < 0) {
        let dateString = currDate.format(DATESTRINGFORMAT);
        dates.push(dateString);
        isExist = existingDatesArr.includes(dateString);
        if (isExist) {
          responsePayload.isExist = true;
        }
      }

      dates.push(lastDate.format(DATESTRINGFORMAT));

      responsePayload.dates = dates;

      return responsePayload;
    } catch (error) {
      console.log(error);
    }
  };

  // days count and if dates is exist then push the project in new tr
  const doManipulateArray = async (newEmployeeData) => {
    let resources = [];
    let projects = [];
    for (let i = 0; i < newEmployeeData?.length; i++) {
      const emp = newEmployeeData[i];
      const array = emp && emp?.projects;
      projects.push(array);
      if (array) {
        let dateParsed = [];
        let projectArrayByTr = [
          {
            tr: [],
          },
        ];
        for (let index = 0; index < array?.length; index++) {
          const proj = array[index];
          const tempDates = await enumerateDaysBetweenDates(
            proj.startDate,
            proj.endDate,
            dateParsed
          );
          const projectStartDate = new Date(proj.startDate);

          const projectDueDate = new Date(proj?.endDate);
          const projectdueDateValue = projectDueDate?.getDate();
          const projectstartDateeeee = new Date(startDate);
          const dateDiff =
            projectStartDateValue - projectstartDateeeee.getDate();

          // Assuming proj.startDate is a valid date string
          const projectStartDateValue = new Date(proj.startDate);
          const projectstartDateee = new Date(startDate);
          const projectEndDateValues = new Date(proj?.endDate);
          const projectEndDateee = new Date(endDate);

          // Extract day and month from projectStartDateValue
          const projectStartDay = projectStartDateValue.getDate();
          const projectEndDay = projectEndDateValues.getDate();
          const projectStartMonth = projectStartDateValue.getMonth();
          const projectEndMonth = projectEndDateValues.getMonth();

          // Extract day and month from projectstartDateee
          const startDay = projectstartDateee.getDate();
          const endDay = projectEndDateee.getDate();
          const startMonth = projectstartDateee.getMonth();
          const endMonth = projectEndDateee.getMonth();

          // Calculate the difference in days while comparing day and month
          let differenceInDays;

          const parseStartDate = new Date(proj.startDate);
          const parseEndDate = new Date(proj.endDate);
          const parseFilterStartDate = new Date(startDate);
          const parseFilterEndDate = new Date(endDate);

          // get the difference in days because because the difference, if difference is negative then remove that form difference days

          // check if project startmonth and filterstartmonth
          if (projectStartMonth === startMonth) {
            differenceInDays = projectStartDay - startDay;
          }
          // check if project endmonth and filterendmonth
          else if (projectEndMonth === endMonth) {
            differenceInDays = projectEndDay - endDay;
          } else {
            // If months are different, adjust for month-end differences and possible year change

            const daysInStartMonth = new Date(
              2024,
              projectStartMonth + 1,
              0
            ).getDate();
            const remainingDaysInStartMonth =
              daysInStartMonth - projectStartDay;
            const daysFromStartOfMonthToEnd = startDay;

            let daysInMonthsInBetween = 0;
            // check the filterstartdate and startdate, which is bigger, ans well as check the enddate

            if (
              parseFilterStartDate > projectStartDate &&
              parseFilterEndDate > projectDueDate
            ) {
              differenceInDays =
                (remainingDaysInStartMonth +
                  daysFromStartOfMonthToEnd +
                  daysInMonthsInBetween) * -1;
            } else if (
              parseFilterStartDate < projectStartDate &&
              parseFilterEndDate < projectDueDate
            ) {
              differenceInDays =
                remainingDaysInStartMonth +
                daysFromStartOfMonthToEnd +
                daysInMonthsInBetween;
            } else {
              differenceInDays =
                remainingDaysInStartMonth +
                daysFromStartOfMonthToEnd +
                daysInMonthsInBetween;
            }
          }

          const projectDueDateee = new Date(endDate);
          const projectEndDateValue = projectDueDateee?.getDate();
          const millisecondsPerDay = 1000 * 60 * 60 * 24;

          let daysCountEndDate;
          let daysCount;
          if (differenceInDays < 0) {
            parseFilterEndDate.setHours(0, 0, 0, 0);
            parseStartDate.setHours(0, 0, 0, 0);
            parseFilterStartDate.setHours(0, 0, 0, 0);
            parseEndDate.setHours(0, 0, 0, 0);
            // daysCountEndDate = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;

            if (
              parseFilterStartDate > parseStartDate &&
              projectStartMonth !== startMonth
            ) {
              if (projectEndMonth !== endMonth) {
                // daysCountEndDate = Math.floor((parseEndDate - parseFilterStartDate) / millisecondsPerDay) + 1 + differenceInDays;
                daysCountEndDate = projectEndDay - startDay + 1;
                daysCount = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
              }
              else {
                daysCountEndDate = Math.floor((parseFilterEndDate - parseFilterStartDate) / millisecondsPerDay) + 1 + differenceInDays;
                daysCount = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
              }
            } else {
              if (projectEndMonth == endMonth && projectStartMonth == endMonth) {

                daysCountEndDate = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
                daysCount = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
              } else {
                daysCountEndDate = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
                daysCount = Math.floor((parseEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
              }
            }
            // daysCountEndDate = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
            // daysCount = Math.floor((parseEndDate - parseStartDate) / millisecondsPerDay) + 1 + differenceInDays;
            // daysCount = projectdueDateValue - projectStartDateValue + 1 + differenceInDays;
          } else {
            parseFilterEndDate.setHours(0, 0, 0, 0);
            parseStartDate.setHours(0, 0, 0, 0);
            parseFilterStartDate.setHours(0, 0, 0, 0);
            parseEndDate.setHours(0, 0, 0, 0);
            if (
              parseFilterStartDate > parseStartDate &&
              projectStartMonth !== startMonth
            ) {
              daysCountEndDate =
                Math.floor(
                  (parseFilterEndDate - parseFilterStartDate) /
                  millisecondsPerDay
                ) + 1;
            } else {
              daysCountEndDate =
                Math.floor(
                  (parseFilterEndDate - parseStartDate) / millisecondsPerDay
                ) + 1;
            }
            // daysCountEndDate = Math.floor((parseFilterEndDate - parseStartDate) / millisecondsPerDay) + 1;
            daysCount =
              Math.floor((parseEndDate - parseStartDate) / millisecondsPerDay) +
              1;

            // daysCountEndDate = projectEndDateValue - projectStartDateValue + 1
            // daysCount = projectdueDateValue - projectStartDateValue + 1;
          }

          const filterProjectStartDate = moment(startDate).format(
            "YYYY-MM-DDTHH:mm:ssZ"
          );
          const filterProjectEndDate = moment(endDate).format(
            "YYYY-MM-DDTHH:mm:ssZ"
          );

          const projectStartDateValuee = new Date(proj.startDate);

          dateParsed =
            (await dateParsed?.length) > 0
              ? [...dateParsed, ...tempDates?.dates]
              : [...tempDates?.dates];
          if (tempDates.isExist == true) {
            await projectArrayByTr.push({
              tr: [
                {
                  ...proj,
                  daysLeft: daysCount,
                  filterDaysLeft: daysCountEndDate,
                  filterStartDate: filterProjectStartDate,
                  filterEndDate: filterProjectEndDate,
                },
              ],
            });
          } else {
            projectArrayByTr[0].tr.push({
              ...proj,
              daysLeft: daysCount,
              filterDaysLeft: daysCountEndDate,
              filterStartDate: filterProjectStartDate,
              filterEndDate: filterProjectEndDate,
            });
          }
        }
        resources.push({ ...emp, mProjects: projectArrayByTr });
      }
    }
    setEmployeeData(resources);
    setProjects(projects);
  };

  // check the project start date and month

  const getProjectsForDay = (employee, day, month) => {
    const empMproject = employee.mProjects.map((item) => {
      const projectsForDays = item.tr.filter((project) => {
        const projectStartDate = new Date(project.startDate);
        const projectFilterStartDate = new Date(project.filterStartDate);

        const projectStartDay = projectStartDate.getDate();
        const projectStartMonth = projectStartDate.getMonth() + 1;
        const projectFilterStartDay = projectFilterStartDate.getDate();
        const projectFilterStartMonth = projectFilterStartDate.getMonth() + 1;

        if (
          (projectStartDate > projectFilterStartDate &&
            projectStartMonth === month &&
            projectStartDay === day) ||
          (projectFilterStartDate > projectStartDate &&
            projectFilterStartMonth === month &&
            projectFilterStartDay === day)
        ) {
          return true;
        }
        return false;
      });
      return projectsForDays;
    });
    return empMproject;
  };

  const getProjectsForDayss = (employee, day, month) => {
    const empMproject = employee.mProjects.map((item) => {
      const projectsForDays = item.tr.filter((project) => {
        const projectStartDate = new Date(project.startDate);
        const projectEndDate = new Date(project.endDate);

        const projectFilterStartDate = new Date(project.filterStartDate);
        const projectFilterEndDate = new Date(project.filterEndDate);

        const projectStartDay = projectStartDate.getDate();
        const projectStartMonth = projectStartDate.getMonth() + 1;
        const projectEndDay = projectEndDate.getDate();

        const projectEndMonth = projectEndDate.getMonth() + 1;

        const projectFilterStartDay = projectFilterStartDate.getDate();
        const projectFilterStartMonth = projectFilterStartDate.getMonth() + 1;
        const projectFilterEndDay = projectFilterEndDate.getDate();

        const projectFilterEndMonth = projectFilterEndDate.getMonth() + 1;

        // Check if the project's start date falls within the given day and month
        const isProjectStartWithinDate =
          projectStartMonth === month && projectStartDay === day;
        // Check if the project's end date falls within the given day and month
        const isProjectEndWithinDate =
          projectEndMonth === month && projectEndDay === day;
        // Check if the project's filter start date falls within the given day and month
        const isProjectFilterStartWithinDate =
          projectFilterStartMonth === month && projectFilterStartDay === day;
        // Check if the project's filter end date falls within the given day and month
        const isProjectFilterEndWithinDate =
          projectFilterEndMonth === month && projectFilterEndDay === day;

        // Return true if any of the conditions are met
        if (
          (projectStartDate > projectFilterStartDate &&
            isProjectStartWithinDate) ||
          (projectEndDate > projectFilterEndDate && isProjectEndWithinDate) ||
          (projectFilterStartDate > projectStartDate &&
            isProjectFilterStartWithinDate) ||
          (projectFilterEndDate > projectEndDate &&
            isProjectFilterEndWithinDate)
        ) {
          return true;
        }
        return false;
      });
      return projectsForDays;
    });
    // console.log(empMproject, "empMproject");
    return empMproject;
  };

  // var tooltipSpan = document.getElementById('tooltip-span');

  const handleMouseMove = (e) => {
    const { clientX, clientY, target } = e;
    const rect = target.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;
    if (!isTooltipHovered) {
      setTooltipPosition({ x: offsetX, y: offsetY });
    }
  };

  const handleTooltipMouseEnter = () => {
    setTooltipHovered(true);
  };

  const handleTooltipMouseLeave = () => {
    setTooltipHovered(false);
  };
  // window.onmousemove = function (e) {

  //     var x = e.clientX;
  //     // y = e.clientY;
  //     // tooltipSpan.style.top = (y + -300) + 'px';
  //     tooltipSpan.style.left = (x + -500) + 'px';
  // };

  // -----------------------------------------------------------------------------

  // background color

  const ProjectColors = [
    // "#c4edff",
    // "#c8a2c8",
    // "#c2d0ff",
    // "#d9c5ff",
    // "#efa2d6",
    // "#dbcabc",
    // "#c3bddb",
    // "#bddadb",
    // "#d9bddb",
    // "#bdc6db",
    // "#dbbddb",
    // "#dbd9bd",
    // "#D1453B",

    // "#fff2d7",
    // "#ffeae6",
    // "#fedcd1",
    // "#ededed",
    // "#d2fcff",
    // "#ffeeee",
    // "#b8fbe4",
    // "#cbe4f9",
    // "#f9ebdf",
    // "#ffd8e0",
    // "#ffdffe",
    // "#dfffc9",
    // "#ffcfe5",
    // "#ffbbc5",
    // "#fffae6",
    // "#f7e3cc",
    // "#d4f5ee",
    // "#b8dfdf",
    // "#f7dbd7",
    // "#cad9e9",
    // "#ffd5d6",

    "#FFEEEE",
    "#FFEBF3",
    "#DCF4FF",
    "#D9FFF9",
    "#DDFBD6",
    "#FCFBC6",
    "#FFF2D7",
    "#FFEAE6",
    "#FEDCD1",
    "#D2FCFF",
    "#FFEEEE",
    "#B8FBE4",
    "#CBE4F9",
    "#F9EBDF",
    "#FFD8E0",
    "#FFDFFE",
    "#DFFFC9",
    "#FFCFE5",
    "#FFBBC5",
    "#FFFAE6",
    "#F7E3CC",
    "#D4F5EE",
    "#B8DFDF",
    "#F7DBD7",
    "#FFD5D6",
    "#BDE7FF",
    "#9FEED9",
    "#E1DCFE",
    "#ABE2FB",
    "#ABFFE9",
    "#FFD7F1",
    "#F8F39F",
    "#B5C8F3",
    "#BBF8BD",
    "#9CCBEB",
    "#F2DDC3",
    "#EECCC4",
    "#F9DB8C",
    "#B5D6E5",
    "#FFC3A8",
    "#E9ECC9",
    "#FFD5CE",
  ];

  const projectMap = [];
  getProjectCode.forEach((code, index) => {
    const colorIndex = index % ProjectColors.length;
    projectMap.push(`${code}: ${ProjectColors[colorIndex]}`);
  });

  const minDate =
    selectedFilterPercentage?.value === "0" ? moment().toDate() : null;

  return (
    <>
      <div style={{ margin: "0 30px", overflowX: "hidden" }}>
        <div
          style={{
            background: "white",
            padding: " 5px 15px",
            borderRadius: "10px",
            marginTop: "1rem",
          }}
        >
          <div
            className="scheduler_filter"
            style={{
              display: "flex",
              marginBottom: "10px",
              marginTop: "0.5rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              {/* ================ date range picker ========================== */}
              <div className="date_picker" style={{ display: "flex" }}>
                <div
                  style={{
                    borderRadius: "5px",
                    // width: "230px",
                    height: "2.5rem",
                    // margin: "4px 30px",
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #0000003b",
                  }}
                >
                  <i
                    className="fa fa-angle-left "
                    style={{
                      margin: "0px 12px",
                      color: "#57646b",
                      fontSize: "20px",
                      // padding: "7.5px 0px",
                    }}
                  />
                  <div>
                    <DatePickerWrapper {...datePickerProps}>
                      <DatePicker
                        selectsRange
                        isRangerHoverAllowed={
                          tempDate.startDate && tempDate.endDate ? true : false
                        }
                        open={isOpen}
                        selected={new Date()}
                        // maxDate={new Date()}
                        onClickOutside={doHandleDatepickerOutsideClick}
                        onInputClick={() => setIsOpen(true)}
                        onChange={doHandleDateChange}
                        onSelect={handleSelect}
                        selectsEnd={true}
                        minDate={minDate}
                        monthsShown={1}
                        // adjustDateOnChange
                        shouldCloseOnSelect={false}
                        startDate={tempDate.startDate}
                        endDate={tempDate.endDate}
                        className="custom-datepicker"
                        calendarClassName="custom-calender-class"
                        showPreviousMonths
                        disabledKeyboardNavigation
                        popperPlacement="top-start"
                        // popperProps={{
                        //   positionFixed: true // use this to make the popper position: fixed
                        // }}
                        popperProps={{
                          strategy: "fixed", // use this to make the popper position: fixed
                        }}
                        customInput={
                          <CustomInputs
                            className="date-range"
                            startDate={isOpen ? tempDate.startDate : startDate}
                            endDate={isOpen ? tempDate.endDate : endDate}
                          />
                        }
                      >
                        <DatePickerFilter
                          doClickOnToday={doClickOnToday}
                          doClickOnThisWeek={doClickOnThisWeek}
                          doClickOnYesterday={doClickOnYesterday}
                          doClickOnThisMonth={doClickOnThisMonth}
                          doClickOnLast7Days={doClickOnLast7Days}
                          doClickOnLastMonth={doClickOnLastMonth}
                          doClickOnLastWeek={doClickOnLastWeek}
                          doClickOnCancel={doClickOnCancel}
                          // doHandleMyTeamsFilter={doHandleMyTeamsFilter}
                          isShowAllowed={tempDate.startDate && tempDate.endDate}
                          dateRangeEnabled
                          doHandleShowDateClick={doHandleShowDateClick}
                          datePickerActiveChip={datePickerActiveChip}
                          DATE_FILTER_TYPE={DATE_FILTER_TYPE}
                          selectedFilterPercentage={selectedFilterPercentage}
                        // selectedNoAllocation={selectedNoAllocation}
                        />
                      </DatePicker>
                    </DatePickerWrapper>
                  </div>
                  <i
                    className="fa fa-angle-right "
                    style={{
                      margin: "0px 12px",
                      color: "#57646b",
                      fontSize: "20px",
                      // padding: "7.5px 0px",
                    }}
                  />
                </div>
              </div>
              {/* date range picker end*/}

              {/* =============== dropdown of employee ============== */}
              <div className="employee_name">
                <div
                  style={{
                    display: "grid",
                    width: "13rem",
                    marginRight: "10px",
                    alignItems: "center",
                  }}
                >
                  <Select
                    maxMenuHeight={350}
                    isClearable
                    styles={colourStyles}
                    placeholder="Select Employee"
                    value={selectedFilterEmployee}
                    onChange={(e, value) => {
                      //   setProjectId(e);
                      setSelectedFilterEmpolyee(e, value);
                    }}
                    isDisabled={
                      selectedFilterTechnology
                    }
                    options={
                      myTeamsUserList &&
                      myTeamsUserList.sort(function (a, b) {
                        const nameA = a?.label?.toUpperCase();
                        const nameB = b?.label?.toUpperCase();
                        if (nameA > nameB) {
                          return 1;
                        }
                        if (nameA < nameB) {
                          return -1;
                        }
                        return 0;
                      })
                    }
                  />
                </div>
              </div>

              {/* ============== dropdown of project =============== */}
              <div className="employee_project">
                <div
                  style={{
                    display: "grid",
                    width: "13rem",
                    marginRight: "10px",
                    alignItems: "center",
                  }}
                >
                  <Select
                    maxMenuHeight={350}
                    isClearable
                    styles={colourStyles}
                    placeholder="Select Project"
                    value={selectedFilterProject}
                    isDisabled={
                      selectedFilterTechnology
                    }
                    onChange={(e, value) => {
                      //   setProjectId(e);
                      setSelectedFilterProject(e, value);
                    }}
                    options={projectList}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  /* justify-content: center; */
                  alignItems: "center",
                  textAlign: "center",
                  marginRight: "7px",
                }}
              >
                OR
              </div>

              {/* =============== dropdown of technology =============== */}
              <div className="employee_technology">
                <div
                  style={{
                    display: "grid",
                    width: "14rem",
                    marginRight: "10px",
                    alignItems: "center",
                  }}
                >
                  <Select
                    maxMenuHeight={350}
                    isClearable
                    styles={colourStyles}
                    placeholder="Select Technology"
                    value={selectedFilterTechnology}
                    onChange={(e, value) => {
                      //   setProjectId(e);
                      setSelectedFilterTechnology(e, value);
                    }}
                    isDisabled={selectedFilterEmployee || selectedFilterProject}
                    options={
                      getTechCode &&
                      getTechCode.sort(function (a, b) {
                        const nameA = a?.label?.toUpperCase();
                        const nameB = b?.label?.toUpperCase();
                        if (nameA > nameB) {
                          return 1;
                        }
                        if (nameA < nameB) {
                          return -1;
                        }
                        return 0;
                      })
                    }
                  />
                </div>
              </div>

              {/* =============== apply button ============== */}
              <div
                style={{
                  alignItems: "center",
                  // display: "flex",
                  // // justifyContent: "center",
                  // width: "5%",
                  margin: "0px 7px",
                }}
              >
                <Button
                  style={{
                    background: "#f96117db",
                    // fontWeight: "bold",
                    borderRadius: "10px",
                    color: "#FFF",
                    // width: "30%",
                    padding: "0.5rem 3rem",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    filterDayShow(startDate, endDate);
                    DoGetHoliday(startDate, endDate)
                    handleSchedulerUserFilterList(
                      startDate,
                      endDate,
                      selectedFilterEmployee?.id,
                      selectedFilterProject,
                      selectedFilterTechnology,
                      selectedFilterPercentage,
                      selectedFilterClient,
                      selectedFilterDepartment,
                      selectedFilterReportingManager
                    );
                  }}
                >
                  Apply
                </Button>
              </div>

              {/* ============= clear filter button ============== */}
              <div
                style={{
                  alignItems: "center",
                  // display: "flex",
                  // // justifyContent: "center",
                  // width: "12%",
                  marginRight: "8px",
                }}
              >
                <Button
                  style={{
                    // background: "#f96117db",
                    // fontWeight: "bold",
                    borderRadius: "10px",
                    color: "#000",
                    // width: "50%",
                    padding: "0.5rem 1.5rem",
                    textTransform: "none",
                    border: "1px solid #f96117db",
                    color: "#f96117db",
                    width: "7.4rem",
                  }}
                  onClick={() => {
                    setSelectedFilterEmpolyee(null);
                    setSelectedFilterProject(null);
                    setSelectedFilterTechnology(null);
                    setSelectedFilterDepartment(null);
                    setSelectedFilterReportingManager(null);
                    setSelectedFilterClient(null);
                    setSelectedFilterPercentage(null);
                    const currentMonth = moment().format("MMMM");
                    const defaultMonthOption = OptionMonth.find(
                      (option) => option.value === currentMonth
                    );
                    setSelectedFilterMonths(defaultMonthOption);
                    // setSelectedFilterMonths();
                    doClickOnCurrentMonth("");
                    setTotalFilterDay(30);
                  }}
                >
                  Clear Filter
                </Button>
              </div>
            </div>

            {/* ============= export report button =============== */}
            <div
            // style={{ marginTop: "0.3rem", marginRight: "1rem" }}
            >
              <Button
                className="new-allocation"
                style={{
                  // background: "#f96117db",
                  // fontWeight: "bold",
                  borderRadius: "10px",
                  color: "#193CE8",
                  width: "10rem",
                  textTransform: "capitalize",
                  padding: "0.5rem",
                  fontSize: "14px",
                  // border: "1px solid #3ca38a",
                  // padding:"0px 10px 0px 10px"
                }}
                onClick={() => {
                  // setIsprojectAdd(true); doOpanModal(" ", " ", " ")
                  handleOnClickExportDownloadCSV(
                    selectedFilterEmployee?.id,
                    selectedFilterProject,
                    selectedFilterTechnology,
                    selectedFilterPercentage,
                    selectedFilterClient,
                    selectedFilterDepartment,
                    selectedFilterReportingManager
                  );
                }}
              >
                <span
                  style={{ padding: "0px 0.8rem 0px 0.1rem", marginTop: "5px" }}
                >
                  <img
                    src={ExportReport}
                    alt=""
                    width={"20px"}
                    height={"20px"}
                  />
                </span>

                <span
                  style={{ padding: "0px 0.3rem 0rem  0rem", fontSize: "16px" }}
                >
                  Export Report
                </span>
              </Button>
            </div>
          </div>

          {/* ================ shows all months ================= */}
          {/* <div>
                        {OptionMonth.map((item) => {
                            return (
                                <Button
                                    style={{
                                        borderRadius: "10px",
                                        // padding: '0.5rem 2rem',
                                        textTransform: "none",
                                        border: "1px solid",
                                        color: item == moment(startDate).format('MMM') ? "#f96117db" : "#aeaeae",
                                        width: "fit-content",
                                        marginRight: "0.5rem",
                                        marginBottom: "0.5rem"

                                    }}
                                    onClick={() => {
                                        getStartAndEndDates(item)
                                    }}
                                >
                                    {item}
                                </Button>
                            )
                        })}
                    </div> */}

          <div style={{ display: "flex", marginBottom: "10px" }}>
            <div>
              <div
                style={{
                  display: "grid",
                  width: "11rem",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Select
                  maxMenuHeight={350}
                  // isClearable
                  styles={colourStyles}
                  placeholder="Select months"
                  // value={selectedFilterMonths}
                  value={selectedFilterMonths}
                  onChange={(e, value) => {
                    getStartAndEndDates(e, value);
                    setSelectedFilterMonths(e, value);
                  }}
                  options={OptionMonth}
                />
              </div>
            </div>

            <div className="employee_technology">
              <div
                style={{
                  display: "grid",
                  width: "14rem",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Select
                  maxMenuHeight={350}
                  isClearable
                  styles={colourStyles}
                  placeholder="Select Percentage"
                  value={selectedFilterPercentage}
                  isOptionDisabled={(option) => option.isdisabled}
                  onChange={(e, value) => {
                    //   setProjectId(e);
                    setSelectedFilterPercentage(e, value);
                  }}
                  options={percentages}
                />
              </div>
            </div>

            <div className="employee_technology">
              <div
                style={{
                  display: "grid",
                  width: "14rem",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Select
                  maxMenuHeight={350}
                  isClearable
                  styles={colourStyles}
                  placeholder="Select Client"
                  value={selectedFilterClient}
                  onChange={(e, value) => {
                    //   setProjectId(e);
                    setSelectedFilterClient(e, value);
                  }}
                  options={
                    getClient &&
                    getClient.sort(function (a, b) {
                      const nameA = a?.label?.toUpperCase();
                      const nameB = b?.label?.toUpperCase();
                      if (nameA > nameB) {
                        return 1;
                      }
                      if (nameA < nameB) {
                        return -1;
                      }
                      return 0;
                    })
                  }
                />
              </div>
            </div>

            {/* ============== dropdown of department =============== */}
            <div>
              <div
                style={{
                  display: "grid",
                  width: "13.5rem",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Select
                  maxMenuHeight={350}
                  isClearable
                  styles={colourStyles}
                  placeholder="Select Department"
                  value={selectedFilterDepartment}
                  onChange={(e, value) => {
                    //   setProjectId(e);
                    setSelectedFilterDepartment(e, value);
                  }}
                  // isDisabled={
                  //   selectedFilterEmployee ||
                  //   selectedFilterProject ||
                  //   selectedFilterTechnology ||
                  //   selectedFilterReportingManager
                  // }
                  options={
                    getDepartment &&
                    getDepartment.sort(function (a, b) {
                      const nameA = a?.label?.toUpperCase();
                      const nameB = b?.label?.toUpperCase();
                      if (nameA > nameB) {
                        return 1;
                      }
                      if (nameA < nameB) {
                        return -1;
                      }
                      return 0;
                    })
                  }
                />
              </div>
            </div>

            {/* ============== dropdown of Reporting manager =============== */}
            <div>
              <div
                style={{
                  display: "grid",
                  width: "13rem",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Select
                  maxMenuHeight={350}
                  isClearable
                  styles={colourStyles}
                  placeholder="Select RM"
                  value={selectedFilterReportingManager}
                  onChange={(e, value) => {
                    //   setProjectId(e);
                    setSelectedFilterReportingManager(e, value);
                  }}
                  // isDisabled={
                  //   selectedFilterEmployee ||
                  //   selectedFilterProject ||
                  //   selectedFilterDepartment ||
                  //   selectedFilterTechnology
                  // }
                  options={
                    getReportingManager &&
                    getReportingManager.sort(function (a, b) {
                      const nameA = a?.label?.toUpperCase();
                      const nameB = b?.label?.toUpperCase();
                      if (nameA > nameB) {
                        return 1;
                      }
                      if (nameA < nameB) {
                        return -1;
                      }
                      return 0;
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div
                    style={{
                        display: "grid",
                        width: "100%",
                        // marginLeft: "15px",
                        alignItems: "center",
                        marginTop: "-10px"
                    }}
                >
                    <Select
                        maxMenuHeight={350}
                        styles={colourStyles}
                        placeholder="Percentage"
                        // value={isProjectUpdate ? projectUpdateData?.proirity : proirity[1]}
                        //   onChange={(e) => {
                        //     setProjectPercentage(e.value);
                        //   }}
                        //   isOptionDisabled={(option) => option.isdisabled}
                        options={percentages}
                    />
                </div> */}

        <div
          className={totalFilterDays > 10 ? "user_list" : "user_list"}
          style={{
            border: "1px solid #e7e7e7",
            marginTop: "12px",
            position: "relative",
            maxHeight: `${height}px`,
            minHeight: `${height}px`,
            width: totalFilterDays > 10 ? "" : "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: totalFilterDays > 10 ? "" : "100%",
            }}
          >
            <div style={{ width: totalFilterDays > 10 ? "" : "100%" }}>
              <table
                className="table-container"
                style={{
                  boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  width: totalFilterDays > 10 ? "" : "100%",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        color: "#000",
                        left: "0",
                        border: "1px solid #e7e7e7",
                        position: "sticky",
                        fontWeight: 500,
                        width: totalFilterDays > 10 ? "3%" : "3%",
                      }}
                    >
                      #
                    </th>
                    <th
                      style={{
                        color: "#000",
                        position: "sticky",
                        left: "0",
                        border: "1px solid #e7e7e7",
                        fontWeight: 500,
                        width: totalFilterDays > 10 ? "" : "10%",
                      }}
                    >
                      Employee
                    </th>
                    {currentDate &&
                      currentDate?.map((item, index) => {
                        return (
                          <>
                            <th
                              key={index}
                              style={{
                                minWidth: totalFilterDays > 10 ? "55px" : "",
                                width: totalFilterDays > 10 ? "" : "",
                                // background: `${moment(item).format("ddd") == "Sat" || moment(item).format("ddd") == "Sun" ? "#898989" : ""}`
                              }}
                            >
                              <div
                                style={{
                                  color: "#000",
                                  height: "3.3rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "14px",
                                    display: "flex",
                                    flexDirection: "column",
                                    fontWeight: 500,
                                  }}
                                >
                                  <span style={{ textTransform: "uppercase", }}>
                                    {moment(item).format("ddd")}
                                  </span>
                                  <span
                                    style={{
                                      color: "#6b6b6b",
                                      marginTop: "-5px",
                                    }}
                                  >
                                    {moment(item).format("MMM DD")}
                                  </span>
                                </p>
                              </div>
                              <div style={{ display: "flex", justifyContent: "center" }}>


                                {moment(item).format("ddd") == "Sat" || moment(item).format("ddd") == "Sun" ?
                                  <div style={{ background: "red", height: "7px", width: "7px", borderRadius: "50%", marginRight: '5px' }}>

                                  </div> : ""

                                }
                                {
                                  holidayList.map((holi) => {
                                    return (
                                      moment(holi.holidayDate).format("YYYY-MM-DD") == item ?
                                        <Tooltip disableFocusListener disableTouchListener title={holi.holidayName}>
                                          <Box style={{ background: "#8735a0", height: "7px", width: "7px", borderRadius: "50%", cursor: "pointer" }}>
                                          </Box>
                                        </Tooltip>
                                        : ""
                                    )
                                  })
                                }
                              </div>
                            </th>
                          </>
                        );
                      })}
                  </tr>
                </thead>
                {employeeData &&
                  employeeData?.map((employee, employeeIndex) => {
                    const { mProjects } = employee;
                    // const hasProjects = mProjects && mProjects.length > 0;
                    const result = Math.round((mProjects?.length + 1) / 2);
                    return (
                      <>
                        {isUserSchedulerData ? (
                          <div style={{}}>
                            <UserSchedulerShimmer />
                          </div>
                        ) : (
                          <>
                            {mProjects?.map((projecy, indec) => {
                              const singleProject = projecy.tr;
                              const employeeProjects = currentDate?.map(
                                (day, dayIndex) => {
                                  const getCurrentMonth = () => {
                                    const currentDateMonth = new Date(day);
                                    return currentDateMonth.getMonth() + 1; // Adding 1 to match human-readable months (1-12)
                                  };
                                  const currentMonth = getCurrentMonth();
                                  let projectData;
                                  if (totalFilterDays > 10) {
                                    projectData = getProjectsForDay(
                                      employee,
                                      dayIndex + 1 + changeIndexValue,
                                      currentMonth
                                    );
                                    if (projectData?.length > 0) {
                                      return (
                                        <>
                                          <td
                                            key={dayIndex}
                                            className="project-cell table-container project-row"
                                            style={{ height: "4rem" }}
                                          >
                                            <div
                                              style={{
                                                position: "relative",
                                              }}
                                            >
                                              {singleProject.map(
                                                (project, projIndex) => {

                                                  if (
                                                    project?.startDate >=
                                                    project?.filterStartDate
                                                  ) {
                                                    const datesss = new Date(
                                                      day
                                                    );
                                                    if (
                                                      new Date(
                                                        project?.startDate
                                                      ).getDate() ==
                                                      datesss.getDate() &&
                                                      new Date(
                                                        project?.startDate
                                                      ).getMonth() +
                                                      1 ===
                                                      currentMonth
                                                    ) {
                                                      const matchingColour = projectMap?.find(
                                                        (item) =>
                                                          item?.startsWith(
                                                            `${project?.projectCode}:`
                                                          )
                                                      );
                                                      if (matchingColour) {
                                                        const color = matchingColour.split(
                                                          ": "
                                                        )[1];
                                                        return (
                                                          <>
                                                            <td
                                                              key={`${dayIndex}-${projIndex}`}
                                                              className="project-cell project-details project-row"
                                                              style={{
                                                                //  backgroundColor: color,
                                                                // background: "#c4edff",
                                                                // width: "200px",
                                                                width:
                                                                  (project?.daysLeft <
                                                                    project?.filterDaysLeft
                                                                    ? project?.daysLeft
                                                                    : project?.filterDaysLeft) *
                                                                  65 -
                                                                  10,
                                                                // width: "65px",
                                                                // width:cellWidth,
                                                                position:
                                                                  "absolute",
                                                                height:
                                                                  "1.1rem",
                                                                border: "none",
                                                                left: "5px",
                                                                // marginLeft:"4px",
                                                                // backgroundColor: project?.isBillable != true ? "white" : color,
                                                                color: project?.isPending != true ? "black" : "white",
                                                                // isBillable
                                                                backgroundColor:
                                                                  project?.isPending == true ? "#900c3f" : color &&
                                                                    project?.projectCode == "3036" ? "#f47777" : color &&
                                                                      project.isBillable == false ? "white" : color &&
                                                                        project?.isTraining == true ? project?.projectColor : color,
                                                                border:
                                                                  project?.isBillable !=
                                                                    true &&
                                                                    project?.isTraining !=
                                                                    true
                                                                    ? "1px solid black"
                                                                    : "none",

                                                                // paddingLeft:"3px"
                                                              }}
                                                            >
                                                              <div
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  setIsProjectUpdate(
                                                                    true
                                                                  );
                                                                  setIsprojectAdd(
                                                                    false
                                                                  );
                                                                  doOpanModal(
                                                                    employee,
                                                                    day,
                                                                    project
                                                                  );
                                                                }}
                                                                style={{
                                                                  marginTop:
                                                                    "-3px",
                                                                  fontSize:
                                                                    "12px",
                                                                }}
                                                                onMouseMove={
                                                                  handleMouseMove
                                                                }
                                                              >
                                                                {
                                                                  project?.projectCode
                                                                }{" "}
                                                                {project?.daysLeft >
                                                                  4 &&
                                                                  project?.filterDaysLeft >
                                                                  4
                                                                  ? project?.projectName
                                                                  : ""}{" "}
                                                                {project?.daysLeft >
                                                                  1 &&
                                                                  project?.filterDaysLeft >
                                                                  1
                                                                  ? project?.priority
                                                                  : ""}{" "}
                                                                {project?.daysLeft >
                                                                  1 &&
                                                                  project?.filterDaysLeft >
                                                                  1
                                                                  ? project?.percentageAllocation
                                                                  : ""}
                                                                {/* ({moment(project.startDate).format("DD-MM-YYYY")} -  {moment(project.endDate).format("DD-MM-YYYY")}) */}
                                                                {/* <div onClick={() => { handledeleteSchedulerUserList(project?.id) }}
                                                                                                                        style={{ justifyContent: "center", display: "flex", alignItems: "center", padding: "0.6rem", cursor: "pointer" }}>
                                                                                                                        <img src={deletee} alt='img' height={20} width={20} />
                                                                                                                    </div> */}
                                                                {/* <span>{moment(project?.startDate).format(DATESTRINGFORMAT)}</span> - <span>{moment(project?.endDate).format(DATESTRINGFORMAT)}</span> */}
                                                              </div>
                                                              <div
                                                                className="project-tooltiptext"
                                                                style={{
                                                                  position:
                                                                    "absolute",
                                                                  zIndex:
                                                                    "9999",
                                                                  textAlign:
                                                                    "start",
                                                                  paddingLeft:
                                                                    "2rem",
                                                                  left:
                                                                    tooltipPosition.x +
                                                                    -100,
                                                                }}
                                                                onMouseEnter={
                                                                  handleTooltipMouseEnter
                                                                }
                                                                onMouseLeave={
                                                                  handleTooltipMouseLeave
                                                                }
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    Employee
                                                                    name:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    employee?.name
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    Employee
                                                                    code:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    employee?.code
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    Project
                                                                    name:{" "}
                                                                  </span>
                                                                  {
                                                                    project?.projectName
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    Project
                                                                    code:{" "}
                                                                  </span>
                                                                  {
                                                                    project?.projectCode
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    Priority:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    project?.priority
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    Client:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    project?.clientName
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    {" "}
                                                                    Percentage:{" "}
                                                                  </span>
                                                                  {
                                                                    project?.percentageAllocation
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    {" "}
                                                                    Start date:{" "}
                                                                  </span>
                                                                  {moment(
                                                                    project?.startDate
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )}
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    {" "}
                                                                    End date:{" "}
                                                                  </span>
                                                                  {moment(
                                                                    project?.endDate
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )}
                                                                </span>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "end",
                                                                  }}
                                                                >
                                                                  <div
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      setIsProjectUpdate(
                                                                        true
                                                                      );
                                                                      setIsprojectAdd(
                                                                        false
                                                                      );
                                                                      doOpanModal(
                                                                        employee,
                                                                        day,
                                                                        project
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      padding:
                                                                        "0.6rem",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={
                                                                        update
                                                                      }
                                                                      alt="img"
                                                                      height={
                                                                        20
                                                                      }
                                                                      width={20}
                                                                    />
                                                                  </div>
                                                                  <div
                                                                    onClick={() => {
                                                                      // handledeleteSchedulerUserList(
                                                                      //   project?.id
                                                                      // );
                                                                      handleModal(
                                                                        project.id,
                                                                        project.startDate,
                                                                        project.endDate,
                                                                        project?.projectName,
                                                                        employee?.name

                                                                      );
                                                                    }}
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      padding:
                                                                        "0.6rem",
                                                                      cursor:
                                                                        "pointer",
                                                                      display: moment(
                                                                        new Date()
                                                                      ).isAfter(
                                                                        project?.startDate,
                                                                        "day"
                                                                      )
                                                                        ? "none"
                                                                        : "block",
                                                                      marginTop:
                                                                        "8px",
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={
                                                                        deletee
                                                                      }
                                                                      alt="img"
                                                                      height={
                                                                        20
                                                                      }
                                                                      width={20}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </>
                                                        );
                                                      }
                                                      return null;
                                                    }
                                                  } else {
                                                    const datesss = new Date(
                                                      day
                                                    );
                                                    // if (new Date(project?.filterStartDate).getDate() == dayIndex + 1 + changeIndexValue) {
                                                    if (
                                                      new Date(
                                                        project?.filterStartDate
                                                      ).getDate() ==
                                                      datesss.getDate() &&
                                                      new Date(
                                                        project?.filterStartDate
                                                      ).getMonth() +
                                                      1 ===
                                                      currentMonth
                                                    ) {
                                                      const matchingColour = projectMap.find(
                                                        (item) =>
                                                          item.startsWith(
                                                            `${project?.projectCode}:`
                                                          )
                                                      );
                                                      if (matchingColour) {
                                                        const color = matchingColour.split(
                                                          ": "
                                                        )[1];
                                                        return (
                                                          <>
                                                            <td
                                                              key={`${dayIndex}-${projIndex}`}
                                                              className="project-cell project-details project-row"
                                                              style={{
                                                                //  backgroundColor: color,
                                                                // background: "#c4edff",
                                                                // width: "200px",
                                                                width:
                                                                  (project?.daysLeft <
                                                                    project?.filterDaysLeft
                                                                    ? project?.daysLeft
                                                                    : project?.filterDaysLeft) *
                                                                  65 -
                                                                  10,
                                                                // width:cellWidth,
                                                                position:
                                                                  "absolute",
                                                                height:
                                                                  "1.1rem",
                                                                border: "none",
                                                                left: "5px",
                                                                // backgroundColor: project?.isBillable != true ? "none" : color 111,
                                                                color: project?.isPending != true ? " " : "white" && project?.isBillable ==
                                                                  true
                                                                  ? "white" : "",
                                                                backgroundColor:
                                                                  project?.isPending == true ? "#900c3f" : color &&
                                                                    project?.projectCode == "3036" ? "#f47777" : color &&
                                                                      project.isBillable == false ? "white" : color &&
                                                                        project?.isTraining == true ? project?.projectColor : color,
                                                                border:
                                                                  project?.isBillable !=
                                                                    true &&
                                                                    project?.isTraining !=
                                                                    true
                                                                    ? "1px solid black"
                                                                    : "none",
                                                              }}
                                                            >
                                                              <div
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  setIsProjectUpdate(
                                                                    true
                                                                  );
                                                                  setIsprojectAdd(
                                                                    false
                                                                  );
                                                                  doOpanModal(
                                                                    employee,
                                                                    day,
                                                                    project
                                                                  );
                                                                }}
                                                                style={{
                                                                  marginTop:
                                                                    "-3px",
                                                                  fontSize:
                                                                    "12px",
                                                                }}
                                                                onMouseMove={
                                                                  handleMouseMove
                                                                }
                                                              >
                                                                {/* {project?.projectCode} - {project?.daysLeft > 4 && project?.filterDaysLeft > 4 ? project?.projectName : ""} - {project?.priority} - {project?.percentageAllocation} */}
                                                                {
                                                                  project?.projectCode
                                                                }{" "}
                                                                {project?.daysLeft >
                                                                  4 &&
                                                                  project?.filterDaysLeft >
                                                                  4
                                                                  ? project?.projectName
                                                                  : ""}{" "}
                                                                {project?.daysLeft >
                                                                  1 &&
                                                                  project?.filterDaysLeft >
                                                                  1
                                                                  ? project?.priority
                                                                  : ""}{" "}
                                                                {project?.daysLeft >
                                                                  1 &&
                                                                  project?.filterDaysLeft >
                                                                  1
                                                                  ? project?.percentageAllocation
                                                                  : ""}
                                                                {/* {project?.endDateGap > 0 ? project?.endDateGap : ""} */}
                                                                {/* ({moment(project.startDate).format("DD-MM-YYYY")} -  {moment(project.endDate).format("DD-MM-YYYY")}) */}
                                                                {/* <div onClick={() => { handledeleteSchedulerUserList(project?.id) }}
                                                                                                             style={{ justifyContent: "center", display: "flex", alignItems: "center", padding: "0.6rem", cursor: "pointer" }}>
                                                                                                             <img src={deletee} alt='img' height={20} width={20} />
                                                                                                         </div> */}
                                                                {/* <span>{moment(project?.startDate).format(DATESTRINGFORMAT)}</span> - <span>{moment(project?.endDate).format(DATESTRINGFORMAT)}</span> */}
                                                              </div>
                                                              <div
                                                                className="project-tooltiptext"
                                                                id="tooltip-span"
                                                                style={{
                                                                  position:
                                                                    "absolute",
                                                                  zIndex:
                                                                    "9999",
                                                                  textAlign:
                                                                    "start",
                                                                  paddingLeft:
                                                                    "2rem",
                                                                  left:
                                                                    tooltipPosition.x +
                                                                    -100,
                                                                }}
                                                                onMouseEnter={
                                                                  handleTooltipMouseEnter
                                                                }
                                                                onMouseLeave={
                                                                  handleTooltipMouseLeave
                                                                }
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    Employee
                                                                    name:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    employee?.name
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span className="tooltip-details">
                                                                    Employee
                                                                    code:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    employee?.code
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    Project
                                                                    name:{" "}
                                                                  </span>
                                                                  {
                                                                    project?.projectName
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    Project
                                                                    code:{" "}
                                                                  </span>
                                                                  {
                                                                    project?.projectCode
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    Priority:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    project?.priority
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    Client:{" "}
                                                                  </span>{" "}
                                                                  {
                                                                    project?.clientName
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Percentage:{" "}
                                                                  </span>
                                                                  {
                                                                    project?.percentageAllocation
                                                                  }
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Start date:{" "}
                                                                  </span>
                                                                  {moment(
                                                                    project?.startDate
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )}
                                                                </span>
                                                                <br />
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontWeight: 600,
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    End date:{" "}
                                                                  </span>
                                                                  {moment(
                                                                    project?.endDate
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )}
                                                                </span>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "end",
                                                                  }}
                                                                >
                                                                  <div
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      setIsProjectUpdate(
                                                                        true
                                                                      );
                                                                      setIsprojectAdd(
                                                                        false
                                                                      );
                                                                      doOpanModal(
                                                                        employee,
                                                                        day,
                                                                        project
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      padding:
                                                                        "0.6rem",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={
                                                                        update
                                                                      }
                                                                      alt="img"
                                                                      height={
                                                                        20
                                                                      }
                                                                      width={20}
                                                                    />
                                                                  </div>
                                                                  {/* <div onClick={() => { handledeleteSchedulerUserList(project?.id) }}
                                                                                                                        style={{ justifyContent: "center", display: "flex", alignItems: "center", padding: "0.6rem", cursor: "pointer" }}>
                                                                                                                        <img src={deletee} alt='img' height={20} width={20} />
                                                                                                                    </div> */}
                                                                  <div
                                                                    onClick={() => {
                                                                      // handledeleteSchedulerUserList(
                                                                      //   project?.id
                                                                      // );

                                                                      handleModal(
                                                                        project.id,
                                                                        project.startDate,
                                                                        project.endDate,
                                                                        project?.projectName,
                                                                        employee?.name

                                                                      );
                                                                    }}
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      padding:
                                                                        "0.6rem",
                                                                      cursor:
                                                                        "pointer",
                                                                      display: moment(
                                                                        new Date()
                                                                      ).isAfter(
                                                                        project?.startDate,
                                                                        "day"
                                                                      )
                                                                        ? "none"
                                                                        : "block",
                                                                      marginTop:
                                                                        "8px",
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={
                                                                        deletee
                                                                      }
                                                                      alt="img"
                                                                      height={
                                                                        20
                                                                      }
                                                                      width={20}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </>
                                                        );
                                                      }
                                                      return null;
                                                    }
                                                  }
                                                }
                                              )}
                                            </div>
                                          </td>
                                        </>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={dayIndex}
                                          className="project-cell"
                                        ></td>
                                      );
                                    }
                                  } else {
                                    projectData = getProjectsForDayss(
                                      employee,
                                      dayIndex + 1 + changeIndexValue,
                                      currentMonth
                                    );

                                    if (projectData?.length > 0) {
                                      return (
                                        <>
                                          <td
                                            key={dayIndex}
                                            className="project-cell table-container project-row"
                                            style={{ height: "4rem" }}
                                          >
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              {singleProject.map(
                                                (project, projIndex) => {
                                                  const projectStartDate = new Date(
                                                    project.startDate
                                                  );
                                                  const projectEndDate = new Date(
                                                    project.endDate
                                                  );
                                                  const currentDate = new Date(
                                                    day
                                                  );

                                                  // Check if the current day falls within the range of the project's start and end dates
                                                  if (
                                                    currentDate >=
                                                    projectStartDate &&
                                                    currentDate <=
                                                    projectEndDate
                                                  ) {
                                                    // Display project details
                                                    const matchingColour = projectMap.find(
                                                      (item) =>
                                                        item.startsWith(
                                                          `${project.projectCode}:`
                                                        )
                                                    );

                                                    if (matchingColour) {
                                                      const color = matchingColour.split(
                                                        ": "
                                                      )[1];
                                                      return (
                                                        <td
                                                          key={`${dayIndex}-${projIndex}`}
                                                          className="project-cell project-details project-row"
                                                          style={{
                                                            width: "100%",
                                                            position:
                                                              "absolute",
                                                            height: "1.1rem",
                                                            border: "none",
                                                            backgroundColor:
                                                              project.isTraining ==
                                                                true
                                                                ? project.projectColor
                                                                : color &&
                                                                  project.isBillable !=
                                                                  true
                                                                  ? "white"
                                                                  : color,
                                                            border:
                                                              project.isBillable !=
                                                                true &&
                                                                project.isTraining !=
                                                                true
                                                                ? "1px solid black"
                                                                : "none",
                                                          }}
                                                        >
                                                          <div
                                                            onMouseMove={
                                                              handleMouseMove
                                                            }
                                                          >
                                                            {
                                                              project.projectCode
                                                            }
                                                          </div>
                                                          <div
                                                            className="project-tooltiptext"
                                                            id="tooltip-span"
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              zIndex: "9999",
                                                              textAlign:
                                                                "start",
                                                              paddingLeft:
                                                                "2rem",
                                                              left:
                                                                tooltipPosition.x +
                                                                -100,
                                                            }}
                                                            onMouseEnter={
                                                              handleTooltipMouseEnter
                                                            }
                                                            onMouseLeave={
                                                              handleTooltipMouseLeave
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                Employee name:{" "}
                                                              </span>{" "}
                                                              {employee?.name}
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span className="tooltip-details">
                                                                Employee
                                                                code:{" "}
                                                              </span>{" "}
                                                              {
                                                                employee?.code
                                                              }
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                Project name:{" "}
                                                              </span>
                                                              {
                                                                project?.projectName
                                                              }
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                Project code:{" "}
                                                              </span>
                                                              {
                                                                project?.projectCode
                                                              }
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                Priority:{" "}
                                                              </span>{" "}
                                                              {
                                                                project?.priority
                                                              }
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                Client:{" "}
                                                              </span>{" "}
                                                              {
                                                                project?.clientName
                                                              }
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                {" "}
                                                                Percentage:{" "}
                                                              </span>
                                                              {
                                                                project?.percentageAllocation
                                                              }
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                {" "}
                                                                Start date:{" "}
                                                              </span>
                                                              {moment(
                                                                project?.startDate
                                                              ).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                            </span>
                                                            <br />
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                {" "}
                                                                End date:{" "}
                                                              </span>
                                                              {moment(
                                                                project?.endDate
                                                              ).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                            </span>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "end",
                                                              }}
                                                            >
                                                              <div
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  setIsProjectUpdate(
                                                                    true
                                                                  );
                                                                  setIsprojectAdd(
                                                                    false
                                                                  );
                                                                  doOpanModal(
                                                                    employee,
                                                                    day,
                                                                    project
                                                                  );
                                                                }}
                                                                style={{
                                                                  justifyContent:
                                                                    "center",
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  padding:
                                                                    "0.6rem",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                <img
                                                                  src={update}
                                                                  alt="img"
                                                                  height={20}
                                                                  width={20}
                                                                />
                                                              </div>
                                                              {/* <div onClick={() => { handledeleteSchedulerUserList(project?.id) }}
                                                                                                                        style={{ justifyContent: "center", display: "flex", alignItems: "center", padding: "0.6rem", cursor: "pointer" }}>
                                                                                                                        <img src={deletee} alt='img' height={20} width={20} />
                                                                                                                    </div> */}
                                                              <div
                                                                onClick={() => {
                                                                  // handledeleteSchedulerUserList(
                                                                  //   project?.id
                                                                  // );

                                                                  handleModal(
                                                                    project.id,
                                                                    project.startDate,
                                                                    project.endDate,
                                                                    project?.projectName,
                                                                    employee?.name

                                                                  );
                                                                }}
                                                                style={{
                                                                  justifyContent:
                                                                    "center",
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  padding:
                                                                    "0.6rem",
                                                                  cursor:
                                                                    "pointer",
                                                                  display: moment(
                                                                    new Date()
                                                                  ).isAfter(
                                                                    project?.startDate,
                                                                    "day"
                                                                  )
                                                                    ? "none"
                                                                    : "block",
                                                                  marginTop:
                                                                    "8px",
                                                                }}
                                                              >
                                                                <img
                                                                  src={deletee}
                                                                  alt="img"
                                                                  height={20}
                                                                  width={20}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {/* Project details */}
                                                          {/* Your project details content goes here */}
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  return null;
                                                }
                                              )}
                                            </div>
                                          </td>
                                        </>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={dayIndex}
                                          className="project-cell"
                                        ></td>
                                      );
                                    }
                                  }
                                }
                              );

                              // employee's information
                              const renderFirstRow = () => (
                                <tr
                                  key={employeeIndex}
                                  style={{
                                    borderRight: "1px solid #e7e7e7",
                                  }}
                                >
                                  {indec === 0 && (
                                    <>
                                      <td
                                        style={{
                                          border: "1px solid #e7e7e7",
                                          zIndex: "10",
                                          position: "sticky",

                                          /* z-index: 99; */
                                          backgroundColor: "#fff",
                                          textAlign: "center",
                                        }}
                                        rowSpan={mProjects?.length + 1}
                                      >
                                        {employeeIndex + 1}
                                      </td>
                                    </>
                                  )}
                                  {indec === 0 && (
                                    <>
                                      {/* <td>{employeeIndex + 1}</td> */}
                                      <td
                                        style={{
                                          border: "1px solid #e7e7e7",
                                          zIndex: "10",
                                          maxWidth: "11rem",
                                          position: "sticky",
                                          left: 0,
                                          /* z-index: 99; */
                                          backgroundColor: "#fff",
                                        }}
                                        rowSpan={mProjects?.length + 1}
                                      >
                                        <div
                                          style={{
                                            width: "1rem",
                                            minWidth: "12rem",
                                            // padding: "0.5rem",
                                            display: "flex",
                                            // alignItems: "start",
                                            background: "",
                                            // marginLeft: "10px",
                                            // justifyContent: "center"
                                            maxWidth: "100%",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            alignItems: "start",
                                            marginTop: "8px",
                                            height: "4rem",
                                          }}
                                        >
                                          <div
                                            className="avtar"
                                            style={{ margin: "0px 5px" }}
                                          >
                                            {/* <img src={User} alt='img' height={40} width={40} /> */}
                                            {/* <Avatar {...stringAvatar(employee?.name)} style={{backgroundColor:"#f4590d"}} /> */}

                                            <Avatar
                                              src="/broken-image.jpg"
                                              style={{
                                                backgroundColor: "#fff",
                                                height: "35px",
                                                width: "35px",
                                                fontSize: "1rem",
                                                border: "1px solid #f96117db",
                                                color: "#f96117db",
                                              }}
                                            >
                                              {/* {employee?.name && employee?.name.toUpperCase().includes(' ') ? `${employee?.name && employee?.name.toUpperCase().split(' ')[0][0]}${employee?.name && employee?.name.toUpperCase().split(' ')[1][0]}` : `${employee?.name && employee?.name.toUpperCase().split(' ')[0][0]}`} */}
                                              {employee?.name &&
                                                (employee?.name
                                                  .toUpperCase()
                                                  .includes(" ")
                                                  ? `${employee?.name
                                                    .trim()
                                                    .toUpperCase()
                                                    .split(/\s+/) // Split the name by one or more whitespace characters
                                                    .map((part) => part[0]) // Extract the first letter of each part
                                                    .join("")}` // Join the initials
                                                  : `${employee?.name
                                                    .toUpperCase()
                                                    .split(" ")[0][0]
                                                  }`)}
                                            </Avatar>
                                          </div>

                                          <div
                                            style={{
                                              marginRight: "-6px",
                                              marginLeft: "5px",
                                              marginBottom: "5px",
                                              overflow: "hidden",
                                              maxWidth: "8rem",
                                              height: "4rem",
                                            }}
                                          >
                                            <div
                                              className="user_name"
                                              style={{
                                                fontWeight: "500",
                                                color: "#000",
                                                fontSize: "14px",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                maxWidth: "11rem",
                                              }}
                                              title={employee.name}
                                            >
                                              {employee.name}
                                            </div>
                                            <div
                                              className="role"
                                              style={{
                                                fontWeight: "100",
                                                fontSize: "13px",
                                                color: "#5b5c5b",
                                                marginTop: "-5px",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                maxWidth: "11rem",
                                              }}
                                            >
                                              {employee?.technologies}
                                            </div>
                                            <div
                                              className="role"
                                              style={{
                                                fontWeight: "100",
                                                fontSize: "13px",
                                                color: "#5b5c5b",
                                                marginTop: "-5px",
                                              }}
                                            >
                                              {employee?.reportingManager}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </>
                                  )}
                                  {employeeProjects}
                                </tr>
                              );

                              // plus icon
                              const plus = currentDate?.map((day, dayIndex) => {
                                {
                                  return (
                                    // <tr>
                                    <td
                                      key={dayIndex}
                                      className="project-cell table-container project-row"
                                    >
                                      <div style={{ position: "relative" }}>
                                        {!moment(day).isSameOrAfter(
                                          moment(),
                                          "day"
                                        ) ? (
                                          <div
                                            // onClick={() => { setIsProjectUpdate(false); doOpanModal(employee, day) }}
                                            // style={{position: "absolute",
                                            //     /* top: -1.5rem; */
                                            //     bottom: "0.4rem",
                                            //     left: "2.5rem"}}
                                            style={{
                                              justifyContent: "end",
                                              display: "flex",
                                              alignItems: "end",
                                              padding: "0px 0.3rem",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <img
                                              src={Plusgrey}
                                              alt="img"
                                              height={15}
                                              width={15}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => {
                                              setIsProjectUpdate(false);
                                              doOpanModal(employee, day);
                                            }}
                                            // style={{position: "absolute",
                                            //     /* top: -1.5rem; */
                                            //     bottom: "0.4rem",
                                            //     left: "2.5rem"}}
                                            style={{
                                              justifyContent: "end",
                                              display: "flex",
                                              alignItems: "end",
                                              padding: "0px 0.3rem",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <img
                                              src={Plus}
                                              alt="img"
                                              height={15}
                                              width={15}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </td>

                                    // <td key={dayIndex} className="project-cell table-container project-row" >
                                    //     <div style={{ position: "relative" }}>

                                    //         <div
                                    //             onClick={() => { setIsProjectUpdate(false); doOpanModal(employee, day) }}
                                    //             // style={{position: "absolute",
                                    //             //     /* top: -1.5rem; */
                                    //             //     bottom: "0.4rem",
                                    //             //     left: "2.5rem"}}
                                    //             style={{
                                    //                 justifyContent: "end",
                                    //                 display: "flex", alignItems: "end", padding: "0px 0.3rem", cursor: "pointer"
                                    //             }}
                                    //         ><img src={Plus} alt='img' height={15} width={15} /></div>
                                    //     </div>

                                    // </td>

                                    // </tr>
                                  );
                                }
                                // }
                              });

                              // render the plus icon row
                              const renderSecondRow = () => (
                                <tr
                                  className="plus-icon-row"
                                  key={employeeIndex + 1}
                                >
                                  {plus}
                                </tr>
                              );

                              return (
                                <>
                                  {renderFirstRow()}
                                  {indec == mProjects.length - 1 &&
                                    renderSecondRow()}
                                </>
                              );
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
              </table>
            </div>
          </div>
        </div>
        {/* user list */}
        {/* =============== user list end ====================== */}
      </div >

      {openModal && (
        <UserScheduletModal
          openModal={openModal}
          projectList={projectList}
          setOpenModal={doToggleModalVisibility}
          onClose={doToggleModalVisibility}
          employee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          newEmployeeData={newEmployeeData}
          setNewEmployeeData={setNewEmployeeData}
          selectedDay={selectedDay}
          setEmployeeData={setEmployeeData}
          employeeData={employeeData}
          isProjectUpdate={isProjectUpdate}
          projectUpdateData={projectUpdateData}
          setMyTeamsUserList={setMyTeamsUserList}
          myTeamsUserList={myTeamsUserList}
          isProjectAdd={isProjectAdd}
          doManipulateArray={doManipulateArray}
          filterProjectList={filterProjectList}
          getProjectList={getProjectList}
          setIsProjectUpdate={setIsProjectUpdate}
          setIsprojectAdd={setIsprojectAdd}
          setStartFiterDate={setStartDate}
          startFiterDate={startDate}
          filterEndDate={endDate}
        />
      )}

      {
        modalOpen && (
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                background: "white",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999, // Ensure the modal appears above other content
                width: 400,
                bgcolor: "white",
                boxShadow: "0px 2px 16px 3px rgb(84 84 84 / 75%)",
                p: 4,
                borderRadius: 5,
              }}
            >
              <div style={{ padding: "30px 25px", textAlign: "center" }}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "#424242",
                    fontSize: 30,
                    padding: "0px 0px 20px 0px",
                  }}
                >
                  Are you sure?
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  style={{ color: "#7e7e7e", padding: "0px 0px 20px 0px" }}
                >
                  Do you want to remove {projectEmployeeName}'s allocation from {projectName} project
                  starting from{" "}
                  <b style={{ color: "black" }}>
                    {moment(projectStartDate).format("DD-MM-YYYY")}
                  </b>{" "}
                  <span>until</span>{" "}
                  <b style={{ color: "black" }}>
                    {moment(projectEndDate).format("DD-MM-YYYY")}?
                  </b>
                </Typography>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{ marginRight: 30, background: "#999", padding: "10px" }}
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ background: "rgb(255, 84, 84)", padding: "10px" }}
                    onClick={() => {
                      handledeleteSchedulerUserList(projectIdToDelete);
                      setModalOpen(false);
                    }}
                  >
                    Request
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        )
      }

      <ToastContainer />
    </>
  );
};

export default UserScheduler;