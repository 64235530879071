import React from 'react';
import ReactDOM from 'react-dom';
import Notifications, { notify } from 'react-notify-toast';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { currentUserSubject } from './consonants';
import { Offline, Online } from 'react-detect-offline';
import NoInternet from './views/NoInternet/NoInternet';
import { CookiesProvider } from 'react-cookie';

// core components
import Admin from 'layouts/Admin.js';

import 'assets/css/material-dashboard-react.css?v=1.8.0';
import Login from 'views/Login/Login';
import ForgotPassword from 'views/ForgotPassword/ForgotPassword';
import ResetPassword from 'views/ResetPassword/ResetPassword';
import GeneratePassword from 'views/GeneratePassword/GeneratePassword';
import AlreadyLoggedIn from 'views/AlreadyLoggedIn/AlreadyLoggedIn';
import AppWrappar from 'components/wrappar/AppWrappar';

const hist = createBrowserHistory();

function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                currentUserSubject.value ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function PublicRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !currentUserSubject.value ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/panel',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function AlreadyLoggedInRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                currentUserSubject.value ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname:
                                rest.computedMatch.params &&
                                    rest.computedMatch.params.token?.length > 0
                                    ? `/register-user/${rest.computedMatch.params.token}`
                                    : '/register-user/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function RegisterUserRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !currentUserSubject.value ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname:
                                rest.computedMatch.params &&
                                    rest.computedMatch.params.token?.length > 0
                                    ? `/already-logged-in/${rest.computedMatch.params.token}`
                                    : '/already-logged-in',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}


ReactDOM.render(
    <React.Fragment>
        <CookiesProvider>
            <Notifications />
            <Router history={hist}>
                <AppWrappar>
                    <Switch>
                        <PublicRoute path="/login">
                            <Login />
                        </PublicRoute>
                        <PublicRoute path="/forgotPassword">
                            <ForgotPassword />
                        </PublicRoute>
                        <PublicRoute path="/resetPassword/:token">
                            <ResetPassword props={hist} />
                        </PublicRoute>
                        <RegisterUserRoute path="/register-user/:token">
                            <GeneratePassword props={hist} />
                        </RegisterUserRoute>
                        <AlreadyLoggedInRoute path="/already-logged-in/:token">
                            <AlreadyLoggedIn props={hist} />
                        </AlreadyLoggedInRoute>
                        <PrivateRoute path="/panel">
                            <Admin path="/panel" props={hist} />
                        </PrivateRoute>
                        <Redirect from="/" to="/panel" />
                    </Switch>
                </AppWrappar>
            </Router>
            <NoInternet />
        </CookiesProvider>
    </React.Fragment>,
    document.getElementById('root')
);
