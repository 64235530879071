import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import { authenticationService } from "../../services/authentication";
import { notify } from "react-notify-toast";
import TextField from "@material-ui/core/TextField";
import { currentUserSubject } from "../../consonants";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardFooter from "components/Card/CardFooter.js";
import Person from "@material-ui/icons/Person";
import CardAvatar from "components/Card/CardAvatar.js";
import { Divider } from "@material-ui/core";
import { edit, bin, profileAvatarText } from "helper/constant";
import ImageUploading from "react-images-uploading";
import defaultImage from "../../assets/img/faces/default.png";
import Switch from "@material-ui/core/Switch";
import { eyeSlash, eye } from "helper/constant.jsx";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Avatar } from "@material-ui/core";
import LoginWrapper from "views/Login/LoginWrapper.style";
import ProfileShimmer from "components/Shimmer/ProfileShimmer";
import { publish } from "helper/events/events";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

let successToast = (successMsg) => {
  toast.success(successMsg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
let errorToast = (errMsg) => {
  toast.error(errMsg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export default function Profile({ props }) {
  const classes = useStyles();
  const [load, setLoad] = React.useState(false);
  const [fetchLoad, setFetchLoad] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [clockifyUser, setClockifyUser] = React.useState(null);
  const [isReportingManager, setIsReportingManager] = React.useState("USER");
  const [imageAvailable, setimageAvailable] = React.useState(false);
  const [isImageUpload, setIsImageUpload] = React.useState(false);
  const [isImageRemove, setIsImageRemove] = React.useState(false);
  const [isImageDeleted, setisImageDeleted] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isTimeFormateChecked, setIsTimeFormateChecked] = React.useState(false);

  React.useEffect(() => {
    getUserProfileData()
  }, []);


  const getUserProfileData = async () => {
    setFetchLoad(true);
    return authenticationService
      .me()
      .then((result) => {
        setFetchLoad(false);
        setUser(result.data);
        setIsTimeFormateChecked(
          result && result?.data?.timeFormat == "24" ? 24 : 12
        );
        // localStorage.setItem(
        //   "isTimeFormat",
        //   result?.data?.timeFormat == "12" ? 12 : 24
        // );
      })
      .catch((error) => {
        setFetchLoad(false);
        if (error == "TypeError: Failed to fetch") {
          error = "Internal server error";
        }
        errorToast(error);
        console.log("error", error);
      });
  }

  const onImageChange = async (imageList, addUpdateIndex) => {
    if (imageList[0]?.file?.type == "image/jpeg" || imageList[0]?.file?.type == "image/jpg" || imageList[0]?.file?.type == "image/png") {
      setIsImageUpload(true);
      try {
        const Response = await authenticationService.uploadProfilePicture({
          image: imageList[0],
        });
        if (Response) {
          setImages(Response.image);
          setUser({
            ...user,
            userMeta: Response,
          });
          publish("profileUpdate", {
            email: Response?.user?.email,
            isReportingManager: Response?.user?.isRepotingManager,
            name: Response?.user?.name,
            timeFormat: Response?.user?.timeFormat,
            userMeta: { filename: Response.filename, id: Response.id, key: Response.key, location: Response.location, size: Response.size },
          });

        }
      } catch (error) {
        console.log(error);
        setIsImageUpload(false);
      }
      // data for submit
      // console.log(imageList, addUpdateIndex);
      setImages(imageList);
      setIsImageUpload(false);
    }
    else {
      errorToast("File extension not supported");
    }
  };

  const removeProfileImage = async () => {
    try {
      setIsImageRemove(true)
      const Response = await authenticationService.deleteProfilePicture();
      if (Response.error) {
        setIsImageRemove(false)
        console.log(Response.error);
      }
      else {
        setIsImageRemove(false)
        setimageAvailable(false);
        setisImageDeleted(true);
        setUser({
          ...user,
          userMeta: null,
        });
        publish("profileUpdate", {
          ...user,
          userMeta: null,
        });
      }
    } catch (error) {
      setIsImageRemove(false)
      console.log(error);
    }
  };

  const onSubmit = () => {
    setLoad(true);
    let isReportingManagerFlag = isReportingManager === "USER" ? false : true;
    return authenticationService
      .changeStatus(isReportingManagerFlag)
      .then((result) => {
        successToast("profile updated successfully");
        setLoad(false);
        setUser(result.user);
        result.user.isReportingManager
          ? setIsReportingManager("REPORTING_MANAGER")
          : setIsReportingManager("USER");
      })
      .catch((error) => {
        setLoad(false);
        if (error == "TypeError: Failed to fetch") {
          error = "Internal server error";
        }
        errorToast(error);
        console.log("error", error);
      });
  };
  const handleChangeRadio = (event) => {
    setIsReportingManager(event.target.value);
  };

  const fontColor = {
    style: { color: "#71797E" },
  };

  const handleShowOneChange = () => {
    setShowOne(!showOne);
  };
  const handleShowTwoChange = () => {
    setShowTwo(!showTwo);
  };
  const handleShowThreeChange = () => {
    setShowThree(!showThree);
  };

  const onChangePassword = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.id;

    setPasswordDetails({
      ...passwordDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      passwordDetails.oldPassword.length > 0 &&
      passwordDetails.newPassword.length > 0 &&
      passwordDetails.confirmPassword.length > 0
    ) {
      try {
        setIsLoader(true);
        const Response = await authenticationService.changePassword(
          passwordDetails
        );
        if (Response && Response?.data) {
          setIsLoader(false);
          setPasswordDetails({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          successToast("Password changed successfully");
        }
        setIsLoader(false);
        errorToast(Response?.response?.data?.message);
      } catch (e) {
        setIsLoader(false);
        console.log(e);
      }
    } else {
      setIsLoader(false);
      errorToast("Please fill password details");
    }
  };

  const handleTimeFormateChange = async (e) => {
    try {
      const Response = await authenticationService.updateTimeFormat();
      if (Response.error) {
        console.log(Response.error);
        errorToast(Response.error);
      } else {
        setIsTimeFormateChecked(
          Response && Response?.data?.timeFormat == "24" ? 24 : 12
        );
        // localStorage.setItem(
        //   "isTimeFormat",
        //   Response && Response?.data?.timeFormat == "12" ? 12 : 24
        // );
        successToast("Time format updated");
      }
    } catch (error) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      {fetchLoad ? (
        <div
          style={{
            height: "calc(100vh - 75px)",
            display: "flex",
            justifyContent: "center",
            marginTop: 160
          }}
        >
          <ProfileShimmer />
        </div>
      ) : (
        <React.Fragment>
          <GridContainer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={7} md={5} lg={4}>
              <React.Fragment>
                <div style={{ fontWeight: 700, marginTop: 130 }}>  </div>
                <Card style={{ marginTop: 5, width: 480 }}>
                  <CardBody align="center" style={{ height: "380px" }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div style={{ height: 139 }}>
                          {isImageUpload || isImageRemove ? (
                            <div
                              style={{
                                backgroundColor: "#E5E4E2",
                                height: 130,
                                width: 130,
                                objectFit: "cover",
                                borderRadius: "50%",
                                position: "relative",
                                top: "-70px",
                              }}
                            >
                              <CircularProgress
                                style={{ margin: "50px 0px 0px 0px" }}
                                size={30}
                              />
                            </div>
                          ) : (
                            <>
                              {user?.userMeta != null ? (
                                <>
                                  <img
                                    src={user.userMeta?.location}
                                    alt="..."
                                    style={{
                                      height: 130,
                                      width: 130,
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      border: "1px solid #e9e9e9",
                                      position: "relative",
                                      top: "-70px",
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Avatar
                                      style={{
                                        background: "#f4590d",
                                        color: "white",
                                        fontWeight: "bold",
                                        height: 130,
                                        width: 130,
                                        fontSize: 45,
                                        border: "1px solid #e9e9e9",
                                        position: "relative",
                                        top: "-70px",
                                        display:
                                          window.innerWidth > 959 ? "" : "none",
                                      }}
                                    >
                                      {user?.name
                                        ? user?.name?.includes(" ")
                                          ? user?.name
                                            ?.split(" ")[0]
                                            .charAt(0)
                                            .toUpperCase() +
                                          user?.name
                                            ?.split(" ")[1]
                                            .charAt(0)
                                            .toUpperCase()
                                          : user?.name?.charAt(0).toUpperCase()
                                        : null}
                                    </Avatar>
                                    <img
                                      src={profileAvatarText}
                                      alt="..."
                                      style={{
                                        width: 225,
                                        objectFit: "cover",
                                        position: "absolute",
                                        top: -80,
                                        right: -15
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <ImageUploading
                          // multiple
                          // value={images}
                          onChange={onImageChange}
                          // maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            <>
                              <span
                                className="hover-underline underlinetextStyle cursor-pointer "
                                onClick={onImageUpload}
                                style={{
                                  backgroundColor: "white",
                                  padding: "8px 15px",
                                  borderRadius: "50px",
                                  position: "relative",
                                  top: "-50px",
                                  fontSize: 13,
                                  border: "1px solid #d1d1d1",
                                  cursor: "pointer",
                                  color: "#4e4e4e"
                                }}
                              >
                                Upload a photo…
                              </span>
                              <br></br>
                              <span
                                className="hover-underline underlinetextStyle cursor-pointer "
                                style={{
                                  backgroundColor: "white",
                                  padding: "3px 5px",
                                  position: "relative",
                                  top: "-30px",
                                  cursor: "pointer",
                                  fontSize: 13,
                                  color: "#198cf0",
                                  textDecoration: "underline",
                                  visibility: user?.userMeta == null ? "hidden" : "visible"
                                }}
                                onClick={() => {
                                  removeProfileImage();
                                }}
                              >
                                Remove my avatar and show my initials instead
                              </span>
                            </>
                          )}
                        </ImageUploading>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          disabled
                          style={{ width: "80%" }}
                          id="outlined-basic"
                          label="Name"
                          // value={clockifyUser ? clockifyUser.name : currentUserSubject.value.name}
                          value={user ? user.name : ""}
                          inputProps={fontColor}
                          InputLabelProps={fontColor}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: 30 }}
                      >
                        <TextField
                          disabled
                          style={{ width: "80%" }}
                          id="outlined-basic"
                          label="Email"
                          // value={currentUserSubject.value.email}
                          value={user ? user.email : ""}
                          inputProps={fontColor}
                          InputLabelProps={fontColor}
                        />
                      </GridItem>
                    </GridContainer>

                  </CardBody>
                </Card>
              </React.Fragment>
              {/* )} */}
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={7} md={5} lg={4}>
              {/* {fetchLoad ? (
                  <CircularProgress
                    style={{ marginLeft: "50%", marginTop: "8%", marginBottom: "8%" }}
                  />
                ) : ( */}
              <React.Fragment>
                <div style={{ fontWeight: 700 }}> Password</div>
                <Card style={{ marginTop: 5, width: 480 }}>
                  <CardBody align="center" style={{ height: "370px" }}>
                    <GridContainer
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <form
                        style={{ width: "100%", padding: "15px 40px" }}
                      // onSubmit={handleForgotPassword}
                      >
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <LoginWrapper>
                            <CustomInput
                              style={{ margin: 0 }}
                              labelText="Old Password"
                              name="oldPassword"
                              id="oldPassword"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={
                                passwordDetails.oldPassword
                                  ? passwordDetails.oldPassword
                                  : ""
                              }
                              onChange={onChangePassword}
                              // error={passwordErr}
                              type={showOne ? "text" : "password"}
                            />
                            <img
                              src={showOne ? eyeSlash : eye}
                              width={20}
                              height={20}
                              alt="show password"
                              style={{
                                position: "absolute",
                                top: 38,
                                right: 2,
                              }}
                              onClick={handleShowOneChange}
                            />
                          </LoginWrapper>
                        </div>
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <LoginWrapper>
                            <CustomInput
                              style={{ margin: 0 }}
                              name="newPassword"
                              labelText="New Password"
                              id="newPassword"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={
                                passwordDetails.newPassword
                                  ? passwordDetails.newPassword
                                  : ""
                              }
                              onChange={onChangePassword}
                              // error={passwordErr}
                              type={showTwo ? "text" : "password"}
                            />
                            <img
                              src={showTwo ? eyeSlash : eye}
                              width={20}
                              height={20}
                              alt="show password"
                              style={{
                                position: "absolute",
                                top: 38,
                                right: 2,
                              }}
                              onClick={handleShowTwoChange}
                            />
                          </LoginWrapper>
                        </div>
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <LoginWrapper>
                            <CustomInput
                              style={{ margin: 0 }}
                              name="confirmPassword"
                              labelText="Confirm Password"
                              id="confirmPassword"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={
                                passwordDetails.confirmPassword
                                  ? passwordDetails.confirmPassword
                                  : ""
                              }
                              onChange={onChangePassword}
                              // error={passwordErr}
                              type={showThree ? "text" : "password"}
                            />
                            <img
                              src={showThree ? eyeSlash : eye}
                              width={20}
                              height={20}
                              alt="show password"
                              style={{
                                position: "absolute",
                                top: 38,
                                right: 2,
                              }}
                              onClick={handleShowThreeChange}
                            />
                          </LoginWrapper>
                        </div>
                        {isLoader ? (
                          <div
                            style={{
                              borderRadius: "50px",
                              width: "60%",
                              height: "40px",
                              fontSize: "14px",
                              color: "white",
                              fontWeight: "600",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "800",
                              fontSize: "16px",
                              background: "#f4590d",
                              textTransform: "capitalize",
                              marginTop: 40,
                            }}
                          >
                            <CircularProgress
                              style={{
                                height: 30,
                                width: 30,
                                color: "white",
                              }}
                            />
                          </div>
                        ) : (
                          <Button
                            onClick={(e) => handleSubmit(e)}
                            // color="info"
                            type="submit"
                            className="btn form-button"
                            style={{
                              width: "60%",
                              borderRadius: "50px",
                              height: "40px",
                              fontSize: "14px",
                              color: "white",
                              fontWeight: "600",
                              background: "#f4590d",
                              marginTop: 40,
                              textTransform: "capitalize",
                            }}
                          >
                            Change Password
                          </Button>
                        )}
                      </form>
                    </GridContainer>
                  </CardBody>
                </Card>
              </React.Fragment>
              {/* )} */}
            </GridItem>
          </GridContainer>
          <ToastContainer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
