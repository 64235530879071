import React, { useEffect } from 'react';
import { useState } from 'react';
// import noInternet from 'assets/img/download.png';

function NoInternet() {
    const [isOnline, setisOnline] = useState();
    // React.useEffect(() => {
    //     setisOnline(navigator.onLine);
    // }, [navigator.onLine]);
    useEffect(() => {
        window.addEventListener('online', handleNetworkChange);
        window.addEventListener('offline', handleNetworkChange);

        return () => {
            window.removeEventListener('online', handleNetworkChange);
            window.removeEventListener('offline', handleNetworkChange);
        };
    }, []);
    const handleNetworkChange = () => {
        setisOnline(navigator.onLine);
    };

    if (isOnline == false) {
        return (
            <div
                style={{
                    position: 'fixed',
                    bottom: '10px',
                    right: '10px',
                    height: '100px',
                    width: '100px',
                    background: '#000000',
                    borderRadius: '20px',
                    border: '1px solid transparent',
                    color: 'white',
                    opacity: 0.9,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* <img
                src={``}
                style="height: 40px; width: 40px; margin-bottom: 5px;"
            /> */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                >
                    <path fill="currentColor" d="M19 18h2v2h-2zm0-8h2v6h-2z" />
                    <path
                        fill="currentColor"
                        d="M12 4C7.31 4 3.07 5.9 0 8.98L12 21l5-5.01V8h5.92C19.97 5.51 16.16 4 12 4z"
                    />
                </svg>
                Offline
            </div>
        );
    }
    return <></>;
}

export default NoInternet;
