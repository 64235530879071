import { Box, Button, Modal } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ExportReport from "assets/img/export.png";
import { authenticationService } from "services/authentication";
import { MultiSelect } from "react-multi-select-component";
import { close } from "helper/constant.jsx";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Report = () => {
  const [selectedFilterMonths, setSelectedFilterMonths] = useState();
  const [noAllocation, setNoAllocation] = useState();
  const [sendReport, setSendReport] = useState();
  const [selectedRMObject, setSelectedRMObject] = useState([]);
  const [RMList, setRMList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [userDetails, setUserDetails] = React.useState();
  const [isFetchingLoading, setIsFetchingLoading] = React.useState(false);
  const [myAllocation, SetMyAllocation] = useState();

  React.useEffect(() => {
    doGetuserDetails();
  }, []);

  useEffect(() => {
    const currentMonth = moment().format("MM");

    const defaultMonthOption = OptionMonth.find(
      (option) => option.value === currentMonth
    );
    setSelectedFilterMonths(defaultMonthOption);
    handleNoAllocation(defaultMonthOption?.value);
    handleMyAllocation(defaultMonthOption?.value);
    doGetRMList();
  }, []);

  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const doCloseModal = () => {
    setOpenModal(false);
    setSelectedRMObject([]);
  };

  // shows the months name
  function getStartAndEndDates(e, monthName) {
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];

    const monthIndex = months.indexOf(e?.value);
    if (monthIndex === -1) {
      return "Invalid month name";
    }

    const year = new Date().getFullYear();
    const startDate = new Date(year, monthIndex, 1);
    const endDate =
      monthIndex === 11
        ? new Date(year + 1, 0, 0)
        : new Date(year, monthIndex + 1, 0);
    // doGetDate(startDate, endDate)
    // setStartDate(startDate)
    // setEndDate(endDate)
    handleNoAllocation(e?.value);
    handleMyAllocation(e?.value);
  }

  const handleRMChange = (selected) => {
    setSelectedRMObject(selected);
  };

  const getUser = (selected, _options) => {
    if (selected.length == _options.length) {
      return `All users are selected. (${selected.length})`;
    } else if (selected.length >= 3) {
      return `${selected[0].label}, ${selected[1].label}, (+${selected.length -
        2}) more`;
    }
  };

  const customUserRenderer = (selected, _options) => {
    return selected.length
      ? getUser(selected, _options)
      : "Select Reporting Manager";
  };

  const tooltipUsersText = selectedRMObject.length
    ? selectedRMObject.map((option) => option.label).join(", ")
    : "";

  const colourStyles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "5px",
        height: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f4590d",
      },
    }),
    control: (base, state) => ({
      ...base,
      zIndex: state.isFocused ? "999" : "1",
      background: "#ffffff",
      // background: '#efefef',
      color: "#000000",
      fontWeight: "bold",
      padding: 20,
      // match with the menu
      height: 40,
      minHeight: 35,
      fontSize: 16,
      fontWeight: 400,
      cursor: "pointer",
      padding: "0px 8px",
      // border: 'none',
      // outline: 'none',
      // Overwrittes the different states of border
      borderColor: "#0000003b",
      background: state.isDisabled ? "#f0f0f0" : "",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#0000003b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontWeight: "400",
        color: isDisabled
          ? "black"
          : isSelected
            ? "white"
              ? "white"
              : "black"
            : "black",
        cursor: "pointer",
        backgroundColor: isSelected
          ? "#f96117db"
          : isFocused
            ? "#f961173d"
            : undefined,
        background: isDisabled ? "#f0f0f0" : "",
        ":hover": {
          backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
        },
      };
    },
  };

  const OptionMonth = [
    { label: "January", name: "January", value: "01" },
    { label: "February", name: "February", value: "02" },
    { label: "March", name: "March", value: "03" },
    { label: "April", name: "April", value: "04" },
    { label: "May", name: "May", value: "05" },
    { label: "June", name: "June", value: "06" },
    { label: "July", name: "July", value: "07" },
    { label: "August", name: "August", value: "08" },
    { label: "September", name: "September", value: "09" },
    { label: "October", name: "October", value: "10" },
    { label: "November", name: "November", value: "11" },
    { label: "December", name: "December", value: "12" },
  ];

  const doGetRMList = async () => {
    setIsLoader(true);
    try {
      const Response = await authenticationService.fetchReportingManagerList();
      if (Response?.data) {
        setRMList([
          ...Response?.data.map((value, key) => {
            return {
              id: value?.id,
              value: value?.email,
              label: value?.name,
            };
          }),
        ]);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };

  const handleSendReport = async (month) => {
    try {
      let rmId = [];
      selectedRMObject &&
        selectedRMObject.filter((value, key) => {
          return value.value && rmId.push(value?.value);
        });
      let object = {
        emails: rmId,
        month: month,
      };
      // setIsUserSchedulerData(true)
      const Response = await authenticationService.doSendReport(object);
      if (Response) {
        setSendReport(Response?.data);
        successToast("Mail sent successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // API integration of availability report
  const handleNoAllocation = async (month) => {
    try {
      const Response = await authenticationService.fetchNoAllocationList(month);
      if (Response) {
        setNoAllocation(Response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // API integration of My-Allocation report
  const handleMyAllocation = async (month) => {
    try {
      const Response = await authenticationService.fetchMyAllocationList(month);
      if (Response) {
        SetMyAllocation(Response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function doHandleDownloadingResourceAllocation(content) {
    // if (!contentType) contentType = 'application/octet-stream';
    var a = document.createElement("a");
    var blob = new Blob([content]);
    a.href = window.URL.createObjectURL(blob);
    a.download = `available_resource_report_${moment(new Date()).format(
      "DD-MM-YYYY"
    )}.xlsx`;
    a.click();
  }

  const handleOnClickExportDownloadCSV = async (month) => {
    // setFileExportWithOutPenaltyEmployeeLoader(true);
    // setExportLoader(true);
    try {
      // const axiosA = axios.CancelToken;
      // cancelSource.current = axiosA.source();
      const response = await authenticationService.downloadCSVNoAllocation(
        month
        // cancelSource.current.token
      );
      const { status, data, error } = response;
      if (status == false || status == 400) {
        if (data.message == "Cancelled") {
          // setSelectedDatevalue("");
          // setFileExportWithOutPenaltyEmployeeLoader(false);
        }
      }
      if (status && data) {
        doHandleDownloadingResourceAllocation(data);
        // doHandleDownloadingCSVFile(data);
      }
      // setSelectedDatevalue("");
      // setFileExportWithOutPenaltyEmployeeLoader(false);
      // setExportLoader(false);
    } catch (error) {
      // setSelectedDatevalue("");
      // setFileExportWithOutPenaltyEmployeeLoader(false);
      // setExportLoader(false);
      console.log(error);
    }
  };

  const doGetuserDetails = async () => {
    try {
      setIsFetchingLoading(true);
      const response = await authenticationService.me();
      setUserDetails(response?.data);

      setIsFetchingLoading(false);
    } catch (error) {
      console.log(error);
      setIsFetchingLoading(false);
    }
  };

  return (
    <>
      {userDetails?.isReportingManager ? (
        <>
          <div style={{ margin: "20px 30px" }}>
            <div
              style={{
                background: "white",
                padding: " 5px 15px",
                borderRadius: "10px",
                marginTop: "6rem",
              }}
            >
              <div
                className="scheduler_filter"
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  marginTop: "0.5rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    width: "13rem",
                    marginRight: "10px",
                    alignItems: "center",
                  }}
                >
                  <Select
                    maxMenuHeight={350}
                    // isClearable
                    styles={colourStyles}
                    placeholder="Select months"
                    // value={selectedFilterMonths}
                    value={selectedFilterMonths}
                    onChange={(e, value) => {
                      getStartAndEndDates(e, value);
                      setSelectedFilterMonths(e, value);
                    }}
                    options={OptionMonth}
                  />
                </div>

                {/* <div style={{ display: "flex" }}>
                  <div
                    style={{
                      alignItems: "center",
                      // display: "flex",
                      // // justifyContent: "center",
                      // width: "5%",
                      margin: "0px 7px",
                    }}
                  >
                    <Button
                      style={{
                        background: "#f96117db",
                        // fontWeight: "bold",
                        borderRadius: "10px",
                        color: "#FFF",
                        // width: "30%",
                        padding: "0.5rem 3rem",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        // filterDayShow(startDate, endDate); handleSchedulerUserFilterList(startDate, endDate, selectedFilterEmployee?.id, selectedFilterProject, selectedFilterTechnology, selectedFilterPercentage, selectedFilterClient);
                        setOpenModal(true);
                      }}
                    >
                      Send Mail
                    </Button>
                  </div>

                  <div
                  // style={{ marginTop: "0.3rem", marginRight: "1rem" }}
                  >
                    <Button
                      className="new-allocation"
                      style={{
                        // background: "#f96117db",
                        // fontWeight: "bold",
                        borderRadius: "10px",
                        color: "#193CE8",
                        width: "10rem",
                        textTransform: "capitalize",
                        padding: "0.5rem",
                        fontSize: "14px",
                        // border: "1px solid #3ca38a",
                        // padding:"0px 10px 0px 10px"
                      }}
                      onClick={() => {
                        // setIsprojectAdd(true); doOpanModal(" ", " ", " ")
                        handleOnClickExportDownloadCSV(
                          selectedFilterMonths?.value
                        );
                      }}
                    >
                      <span
                        style={{
                          padding: "0px 0.8rem 0px 0.1rem",
                          marginTop: "5px",
                        }}
                      >
                        <img
                          src={ExportReport}
                          alt=""
                          width={"20px"}
                          height={"20px"}
                        />
                      </span>

                      <span
                        style={{
                          padding: "0px 0.3rem 0rem  0rem",
                          fontSize: "16px",
                        }}
                      >
                        Export Report
                      </span>
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>

            <div
              style={{
                background: "white",
                marginTop: "20px",
                padding: "20px",
                borderRadius: "10px",
                margin: "20px 0px",
              }}
            >
              <div style={{ marginTop: "-2rem" }}>
                <h5>My Allocation</h5>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    background: "#ffcfbb",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "10%",
                      fontWeight: 600,
                    }}
                  >
                    #
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Project Name
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Reporting Manager
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Allocated Dates
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    % Allocation
                  </div>
                </div>
                <div>
                  {myAllocation?.map((item, index) => {
                    return (
                      <>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "10%",
                            }}
                          >
                            {index + 1}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.projectName}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.reportingManager}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.allocatedDates != null
                              ? item?.allocatedDates?.map((data) => {
                                return (
                                  <>
                                    <div>
                                      {data && data.date == null
                                        ? ""
                                        : data.date}
                                    </div>
                                  </>
                                );
                              })
                              : "-"}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.allocatedDates != null
                              ? item?.allocatedDates?.map((data) => {
                                return (
                                  <>
                                    <div>
                                      {data &&
                                        data.percentageAllocation == null
                                        ? ""
                                        : data.percentageAllocation}
                                    </div>
                                  </>
                                );
                              })
                              : "-"}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div
              style={{
                background: "white",
                marginTop: "20px",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div>
                <h5 style={{ marginTop: "0.5rem" }}>My Team Availability (List of partial or full availability)</h5>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    background: "#f4f6f8",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "10%",
                      fontWeight: 600,
                    }}
                  >
                    #
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Employee
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Reporting Manager
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Technology
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Available From
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Project Name
                  </div>
                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    Allocated Dates
                  </div>

                  <div
                    style={{
                      border: "1px solid #0000003b",
                      height: "auto",
                      padding: "0.5rem",
                      width: "100%",
                      fontWeight: 600,
                    }}
                  >
                    % Allocation
                  </div>
                </div>
                <div>
                  {noAllocation?.map((item, index) => {
                  
                    return (
                      <>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "10%",
                            }}
                          >
                            {index + 1}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                           {item?.code} - {item?.name}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.reportingManager}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.technology}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.availableFrom == null ? "-" : item?.availableFrom}
                          </div>

                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.project != null
                              ? item?.project?.map((data) => {
                                return (
                                  <>
                                    <div>
                                      {data?.projectCode} - {data?.projectName}
                                    </div>
                                  </>
                                );
                              })
                              : "-"}
                          </div>
                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.allocatedDates != null
                              ? item?.allocatedDates?.map((data) => {
                                return (
                                  <>
                                    <div>
                                      {data && data == null ? "" : data}
                                    </div>
                                  </>
                                );
                              })
                              : "-"}
                          </div>


                          <div
                            style={{
                              border: "1px solid #0000003b",
                              height: "auto",
                              padding: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {item?.percentageAllocation != null
                              ? item?.percentageAllocation?.map((data) => {
                                return (
                                  <>
                                    <div>
                                      {data && data == null ? "" : data}
                                    </div>
                                  </>
                                );
                              })
                              : "-"}
                          </div>

                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <div style={{ margin: "0 30px" }}>
          <div
            style={{
              background: "white",
              padding: " 5px 15px",
              borderRadius: "10px",
              marginTop: "6rem",
            }}
          >
            <div
              className="scheduler_filter"
              style={{
                display: "flex",
                marginBottom: "10px",
                marginTop: "0.5rem",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "grid",
                  width: "13rem",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Select
                  maxMenuHeight={350}
                  // isClearable
                  styles={colourStyles}
                  placeholder="Select months"
                  // value={selectedFilterMonths}
                  value={selectedFilterMonths}
                  onChange={(e, value) => {
                    getStartAndEndDates(e, value);
                    setSelectedFilterMonths(e, value);
                  }}
                  options={OptionMonth}
                />
              </div>

              {/* <div style={{ display: "flex" }}>
              <div
                style={{
                  alignItems: "center",
                  // display: "flex",
                  // // justifyContent: "center",
                  // width: "5%",
                  margin: "0px 7px",
                }}
              >
                <Button
                  style={{
                    background: "#f96117db",
                    // fontWeight: "bold",
                    borderRadius: "10px",
                    color: "#FFF",
                    // width: "30%",
                    padding: "0.5rem 3rem",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    // filterDayShow(startDate, endDate); handleSchedulerUserFilterList(startDate, endDate, selectedFilterEmployee?.id, selectedFilterProject, selectedFilterTechnology, selectedFilterPercentage, selectedFilterClient);
                    setOpenModal(true);
                  }}
                >
                  Send Mail
                </Button>
              </div>

              <div
              // style={{ marginTop: "0.3rem", marginRight: "1rem" }}
              >
                <Button
                  className="new-allocation"
                  style={{
                    // background: "#f96117db",
                    // fontWeight: "bold",
                    borderRadius: "10px",
                    color: "#193CE8",
                    width: "10rem",
                    textTransform: "capitalize",
                    padding: "0.5rem",
                    fontSize: "14px",
                    // border: "1px solid #3ca38a",
                    // padding:"0px 10px 0px 10px"
                  }}
                  onClick={() => {
                    // setIsprojectAdd(true); doOpanModal(" ", " ", " ")
                    handleOnClickExportDownloadCSV(selectedFilterMonths?.value);
                  }}
                >
                  <span
                    style={{
                      padding: "0px 0.8rem 0px 0.1rem",
                      marginTop: "5px",
                    }}
                  >
                    <img
                      src={ExportReport}
                      alt=""
                      width={"20px"}
                      height={"20px"}
                    />
                  </span>

                  <span
                    style={{
                      padding: "0px 0.3rem 0rem  0rem",
                      fontSize: "16px",
                    }}
                  >
                    Export Report
                  </span>
                </Button>
              </div>
            </div> */}
            </div>
          </div>

          <div
            style={{
              background: "white",
              marginTop: "20px",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div style={{ marginTop: "-2rem" }}>
              <h5>My Allocation</h5>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  background: "#f4f6f8",
                }}
              >
                <div
                  style={{
                    border: "1px solid #0000003b",
                    height: "auto",
                    padding: "0.5rem",
                    width: "10%",
                    fontWeight: 600,
                  }}
                >
                  #
                </div>
                <div
                  style={{
                    border: "1px solid #0000003b",
                    height: "auto",
                    padding: "0.5rem",
                    width: "100%",
                    fontWeight: 600,
                  }}
                >
                  Project Name
                </div>
                <div
                  style={{
                    border: "1px solid #0000003b",
                    height: "auto",
                    padding: "0.5rem",
                    width: "100%",
                    fontWeight: 600,
                  }}
                >
                  Reporting Manager
                </div>
                {/* <div
                style={{
                  border: "1px solid #0000003b",
                  height: "auto",
                  padding: "0.5rem",
                  width: "100%",
                  fontWeight: 600,
                }}
              >
                Technology
              </div> */}
                <div
                  style={{
                    border: "1px solid #0000003b",
                    height: "auto",
                    padding: "0.5rem",
                    width: "100%",
                    fontWeight: 600,
                  }}
                >
                  Allocated Dates
                </div>
                <div
                  style={{
                    border: "1px solid #0000003b",
                    height: "auto",
                    padding: "0.5rem",
                    width: "100%",
                    fontWeight: 600,
                  }}
                >
                  % Allocation
                </div>
              </div>
              <div>
                {myAllocation?.map((item, index) => {
                  return (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            border: "1px solid #0000003b",
                            height: "auto",
                            padding: "0.5rem",
                            width: "10%",
                          }}
                        >
                          {index + 1}
                        </div>
                        <div
                          style={{
                            border: "1px solid #0000003b",
                            height: "auto",
                            padding: "0.5rem",
                            width: "100%",
                          }}
                        >
                          {item?.projectName}
                        </div>
                        <div
                          style={{
                            border: "1px solid #0000003b",
                            height: "auto",
                            padding: "0.5rem",
                            width: "100%",
                          }}
                        >
                          {item?.reportingManager}
                        </div>
                        <div
                          style={{
                            border: "1px solid #0000003b",
                            height: "auto",
                            padding: "0.5rem",
                            width: "100%",
                          }}
                        >
                          {item?.allocatedDates != null
                            ? item?.allocatedDates?.map((data) => {
                              return (
                                <>
                                  <div>
                                    {data && data.date == null
                                      ? ""
                                      : data.date}
                                  </div>
                                </>
                              );
                            })
                            : "-"}
                        </div>
                        <div
                          style={{
                            border: "1px solid #0000003b",
                            height: "auto",
                            padding: "0.5rem",
                            width: "100%",
                          }}
                        >
                          {item?.allocatedDates != null
                            ? item?.allocatedDates?.map((data) => {
                              return (
                                <>
                                  <div>
                                    {data && data.percentageAllocation == null
                                      ? ""
                                      : data.percentageAllocation}
                                  </div>
                                </>
                              );
                            })
                            : "-"}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        style={{ opacity: "1" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => {
          doCloseModal();
        }}
      >
        <Box in={openModal}>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
              borderRadius: "8px",
              padding: "10px 39px 36px 39px",
              width: "25%",
            }}
          >
            <Box>
              <div
                style={{
                  display: "flex",
                  margin: "1.5rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <div >
                                    <div style={{ fontSize: "20px", fontWeight: 700, marginLeft: "-1.2rem" }} id="transition-modalconst"></div>
                                </div>
                                <div onClick={() => doCloseModal()} style={{ cursor: 'pointer', marginRight: "-1.3rem" }}>
                                    <img src={close} alt="" height={20} width={20} />
                                </div> */}
              </div>
              <div style={{ display: "flex", width: "auto" }}>
                <span
                  style={{
                    alignItems: "center",
                    marginRight: "12px",
                    display: "flex",
                    width: "5rem",
                    fontSize: "16px",
                  }}
                >
                  {" "}
                  Send to:
                </span>
                <div
                  style={{
                    width: "80%",
                    // margin: "4px 30px",
                    background: "none",
                    // minWidth: "50%",
                    // maxWidth: "50%"
                  }}
                  title={tooltipUsersText}
                >
                  <MultiSelect
                    isMulti
                    autoFocus
                    menuIsOpen
                    allowCustomValue={true}
                    valueRenderer={customUserRenderer}
                    onChange={(selected) => handleRMChange(selected)}
                    options={
                      RMList &&
                      RMList.sort(function (a, b) {
                        const nameA = a?.label?.toUpperCase();
                        const nameB = b?.label?.toUpperCase();
                        if (nameA > nameB) {
                          return 1;
                        }
                        if (nameA < nameB) {
                          return -1;
                        }
                        return 0;
                      })
                    }
                    value={selectedRMObject}
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isLoading={isLoader}
                  />
                </div>
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "20px",
              }}
            >
              <Button
                onClick={() => {
                  handleSendReport(selectedFilterMonths?.value);
                  setSelectedRMObject([]);
                  doCloseModal();
                }}
                type="submit"
                variant="contained"
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  background: "#f4590d",
                }}
              >
                send mail
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default Report;
