import React, {
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import moment from "moment";
import Popover from "@material-ui/core/Popover";
import debounce from "lodash.debounce";
import { Box, MenuItem, Modal, TextField } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import Select, { components } from "react-select";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { authenticationService } from "../../services/authentication";
import { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "components/CustomButtons/Button.js";
import { close } from "helper/constant.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInputDropDown from "components/CustomInputDropDown/CustomInputDropDown";
import { publish } from "helper/events/events";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    "& label.Mui-focused": {
      color: "#f96117db", // Change label color when focused
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f96117db", // Change underline color when focused
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#dedede", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "#dedede", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#dedede", // Change border color when focused
      },
    },
  },
}));

const addProjectText =
  "Please add me to this project. This project will be used to start a timer to track the hours I work on it.";

const TimeTracker = forwardRef((props, ref) => {
  const {
    entry,
    setEntry,
    isActive,
    setIsActive,
    selectedDate,
    setSelectedDate,
    windowFocused,
    timeEntriesData,
    setTimeEntriesData,
    newProjectList,
    setNewProjectList,
    handleProjectChange,
    isEditTime,
    setIsEditTime,
    isTimeFormateChecked,
    isEditing,
    setIsEditing,
    doHandleCheckValidTime,
    dailyReportId,
    setDailyReportId,
    newLastFiveTimeEntryList,
    getNotStopEntryDetails,
    setNewLastFiveTimeEntryList,
    isUserDetails,
    newJiraTimeEntryList,
    showJiraTickets,
    doHandleClickOnFetchLatestTimeEntries,
    isFetchLatestJiraTimeEntries
  } = props;

  const [modleOpen, setModleOpen] = React.useState(false);
  const [newTime, setNewTime] = useState("00:00");
  const increment = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [projectWorkTitle, setProjectWorkTitle] = useState("");
  const [isprojectWorkTitle, setIsProjectWorkTitle] = useState(false);
  const [projectName, setProjectName] = React.useState([]);
  const [timer, setTimer] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [stop, setStop] = useState();
  const [isCounterSet, setIsCounterSet] = useState("");
  const [timerUpdatedStartTime, setTimerUpdatedStartTime] = useState("");
  const [currentTimeEntry, setcurrentTimeEntry] = useState({
    title: "",
    remark: "",
    startDate: "",
    endDate: "",
    description: "",
    version: "",
    ETA: "",
    projectId: null,
    id: "",
    type: "",
  });
  const [isTimerLoader, setIsTimerLoader] = useState(false);
  const [isStopTimerLoader, setIsStopTimerLoader] = useState(false);

  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [addProjectCode, setAddProjectCode] = useState();
  const [addProjectName, setAddProjectName] = useState();
  const [addProjectComment, setAddProjectComment] = useState(addProjectText);
  const [addProjectRequestLoader, setAddProjectRequestLoader] = useState(false);
  const [remark, setRemark] = useState("");

  const isNumber = (e) => /^[0-9. :]+$/.test(e);
  const colourStyles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "5px",
        height: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f4590d",
      },
    }),
    control: (base, state) => ({
      ...base,
      zIndex: state.isFocused ? "999" : "1",
      background: "#ffffff",
      // background: '#efefef',
      color: "#000000",
      fontWeight: "bold",
      padding: 20,
      // match with the menu
      height: 40,
      minHeight: 35,
      fontSize: 16,
      fontWeight: 400,
      cursor: "pointer",
      padding: "0px 8px",
      // border: 'none',
      // outline: 'none',
      // Overwrittes the different states of border
      borderColor: "#0000003b",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#0000003b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontWeight: "400",
        color: isDisabled
          ? "black"
          : isSelected
            ? "white"
              ? "white"
              : "black"
            : "black",
        cursor: "pointer",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#f96117db"
            : isFocused
              ? "#f961173d"
              : undefined,
        ":hover": {
          backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
        },
      };
    },
  };
  const handleOpen = () => setModleOpen(true);
  const handleCloses = () => setModleOpen(false);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();

  const doClickOnAddProject = () => setProjectModalOpen(true);

  const handleProjectModalClose = () => {
    setAddProjectCode();
    setAddProjectName();
    setAddProjectComment(addProjectText);
    setProjectModalOpen(false);
  };

  const { MenuList } = components;

  const RequestNewProject = () => (
    <div
      style={{
        // borderTop: '1px solid #ccc',
        display: "flex",
        justifyContent: "center",
        fontSize: 14,
        alignItems: "center",
        height: "200px",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      Seems like you're not part of project you are searching.
      <div
        style={{
          cursor: "pointer",
          margin: "10px 0px",
        }}
        onClick={doClickOnAddProject}
      >
        <i
          className="fa fa-plus"
          style={{ fontSize: "12px", margin: "0px 7px", fontWeight: "lighter" }}
        />
        <span
          style={{
            paddingLeft: 3,
            textDecoration: "underline",
            color: "#2f63f7",
            fontSize: "14px",
            fontWeight: "normal",
          }}
        >
          Request project allocation
        </span>
      </div>
    </div>
  );

  // const CustomMenu = (props) => {
  //     const { children, getValue } = props;

  //   console.log("children", children)

  //     const options = getValue();
  //     const lastOption = options[options.length - 1];

  //     return (
  //         <>
  //             <MenuList {...props}>{children}</MenuList>
  //             <RequestNewProject />
  //         </>
  //     );
  // };

  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  let errorToast = (errMsg) => {
    toast.error(errMsg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    if (props?.isStartTime) {
      setcurrentTimeEntry({
        ...props?.timeEntriesData,
        projectId: props?.timeEntriesData?.projectId
          ? props?.timeEntriesData?.projectId
          : props?.timeEntriesData?.project?.id,
      });
      setProjectWorkTitle(
        timeEntriesData?.workType == "meeting"
          ? "meeting"
          : props?.timeEntriesData?.title
      );
      setRemark(timeEntriesData?.remark);
      let projectSelectedArray = [];
      newProjectList &&
        newProjectList.map((item) => {
          if (item.options?.length) {
            item.options &&
              item.options.filter((optionsItem) => {
                return props?.timeEntriesData?.hasOwnProperty("projectId") &&
                  optionsItem?.id == props?.timeEntriesData?.projectId
                  ? projectSelectedArray.push(optionsItem)
                  : !props?.timeEntriesData?.hasOwnProperty("projectId") &&
                    props?.timeEntriesData?.project?.id != null &&
                    optionsItem.id == props?.timeEntriesData?.project?.id
                    ? projectSelectedArray.push(optionsItem)
                    : [];
              });
          }
        });
      setProjectName(
        props.timeEntriesData.projectId
          ? projectSelectedArray
          : props?.timeEntriesData?.project?.id != null
            ? [
              {
                id: props?.timeEntriesData?.project?.id,
                name: props?.timeEntriesData?.project?.projectCode
                  ? `${props?.timeEntriesData?.project?.projectCode} - ${props?.timeEntriesData?.project?.projectName}`
                  : props?.timeEntriesData?.project?.projectName,
                label: props?.timeEntriesData?.project?.projectCode
                  ? `${props?.timeEntriesData?.project?.projectCode} - ${props?.timeEntriesData?.project?.projectName}`
                  : props?.timeEntriesData?.project?.projectName,
                value: props?.timeEntriesData?.project?.projectCode
                  ? `${props?.timeEntriesData?.project?.projectCode} - ${props?.timeEntriesData?.project?.projectName}`
                  : props?.timeEntriesData?.project?.projectName,
              },
            ]
            : []
      );
      setTimer(parseInt(props?.isStartTime));
      if (isCounterSet == "") {
        const interval = setInterval(() => {
          setTimer((timer) => parseInt(timer) + 1);
        }, 1000);
        return () => clearInterval(interval);
      }
    } else {
      setTimer(0);
    }
    return () => {
      setIsProjectWorkTitle(false);
      clearInterval(increment.current);
      setIsCounterSet("");
    };
  }, [props?.isStartTime, isCounterSet]);

  useImperativeHandle(ref, () => ({
    doHandleStart(playEntryId) {
      doHandleStart(playEntryId);
    },
  }));

  const doHandleAddProjectMailSend = async () => {
    try {
      setAddProjectRequestLoader(true);

      let payload = {
        projectName: `${addProjectCode}-${addProjectName}`,
        comment: addProjectComment,
      };

      const Response = await authenticationService.sendAddProjectRequest(
        payload
      );

      if (Response?.status == "Success") {
        handleProjectModalClose();
        successToast("Mail send successfully");
        setAddProjectRequestLoader(false);
      }
      if (Response?.code == 404) {
        handleProjectModalClose();
        errorToast(Response?.message)
        setAddProjectRequestLoader(false);
      }
      if (Response?.code == 400) {
        handleProjectModalClose();
        errorToast(Response?.message)
        setAddProjectRequestLoader(false);
      }
    } catch (error) {
      console.log(error);
      setAddProjectRequestLoader(false);
    }
  };

  const handleTimerClick = (event) => {
    if (timer != 0) {
      const date = moment(currentTimeEntry.startDate);
      setAnchorEl(event.currentTarget);
      setNewTime(date.format("HH:mm"));
    }
    return;
  };

  const handleDeleteAlertClick = (event) => {
    handleOpen();
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onFocus = (e) => {
    if (isprojectWorkTitle) {
      debouncedChangeTitleHandler(e.target.value, currentTimeEntry);
    }
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => { };

  const formatTime = () => {
    var h = Math.floor(timer / 3600);
    var m = Math.floor((timer % 3600) / 60);
    var s = Math.floor((timer % 3600) % 60);
    var getHours = h > 0 ? (h > 9 ? h : `0${h}`) : "00";
    var getMinutes = m > 0 ? (m > 9 ? m : `0${m}`) : "00";
    var getSeconds = s > 0 ? (s > 9 ? s : `0${s}`) : "00";
    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const doHandleStart = async (playEntryId) => {
    if (
      (playEntryId?.project?.id &&
        playEntryId?.title)
      || (projectWorkTitle.length > 0 &&
        currentTimeEntry?.projectId)
    ) {
      try {
        if (isTimerLoader) {
          return;
        }
        setIsTimerLoader(true);

        let newDate = moment().format("YYYY-MM-DD");
        let newTime = moment().format("HH:mm:ss");

        const Response = await authenticationService.createTimeEntryOptions({
          title: playEntryId?.title
            ? playEntryId?.title
            : projectWorkTitle
              ? projectWorkTitle
              : "",
          remark: "",
          startDate: moment.utc(moment(`${newDate} ${newTime}`)).format(),
          endDate: null,
          description: "",
          version: "",
          ETA: "",
          projectId: playEntryId?.project?.id
            ? playEntryId?.project?.id
            : currentTimeEntry?.projectId,
          type: 0,
        });
        if (Response.error) {
          console.log(Response.error);
          setIsTimerLoader(false);
        } else {
          if (Response.data.code == 302) {
            errorToast(`${Response.data.message}`);
            setIsTimerLoader(false);
            getNotStopEntryDetails();
            setTimeout(() => {
              publish("LateArrivalEvent");
            }, 200);
          } else {
            setProjectWorkTitle(Response?.data?.title);
            setProjectName(
              Response?.data?.project
                ? [
                  {
                    id: Response?.data?.project?.id,
                    name: Response?.data?.project?.projectCode
                      ? `${Response?.data?.project?.projectCode} - ${Response?.data?.project?.projectName}`
                      : Response?.data?.project?.projectName,
                    label: Response?.data?.project?.projectCode
                      ? `${Response?.data?.project?.projectCode} - ${Response?.data?.project?.projectName}`
                      : Response?.data?.project?.projectName,
                    value: Response?.data?.project?.projectCode
                      ? `${Response?.data?.project?.projectCode} - ${Response?.data?.project?.projectName}`
                      : Response?.data?.project?.projectName,
                  },
                ]
                : []
            );
            setcurrentTimeEntry({
              ...currentTimeEntry,
              ...Response.data,
              projectId: Response?.data?.project?.id
                ? Response?.data?.project?.id
                : "",
            });
            setTimeEntriesData({
              ...Response.data,
              projectId: Response?.data?.project?.id
                ? Response?.data?.project?.id
                : "",
            });

            setIsActive(true);
            setIsPaused(true);
            setIsTimerLoader(false);
            increment.current = setInterval(() => {
              setTimer((timer) => parseInt(timer) + 1);
            }, 1000);
            setTimeout(() => {
              publish("LateArrivalEvent");
            }, 200);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsStopTimerLoader(false);
      setIsActive(false);
      setIsPaused(false);
      if (projectWorkTitle.length <= 0 && !currentTimeEntry?.projectId) {
        errorToast("Can't start, field missing: project, description");
      }
      if (projectWorkTitle.length <= 0 && currentTimeEntry?.projectId) {
        errorToast("Can't start, field missing: description");
      }
      if (projectWorkTitle.length > 0 && !currentTimeEntry?.projectId) {
        errorToast("Can't start, field missing: project");
      }
    }
  };

  const doHandleStop = async () => {
    // let newDate = moment().format("YYYY-MM-DD");

    let newDate = moment(currentTimeEntry?.startDate).format("YYYY-MM-DD");
    let newTime = moment().format("HH:mm:ss");
    if (
      projectWorkTitle.length > 0 &&
      currentTimeEntry?.projectId &&
      currentTimeEntry &&
      currentTimeEntry?.id
    ) {
      try {
        setIsStopTimerLoader(true);
        setIsCounterSet("");
        const Response = await authenticationService.createTimeEntryOptions(
          {
            id: currentTimeEntry.id,
            remark: currentTimeEntry?.remark
              ? currentTimeEntry?.remark
              : remark,
            description: currentTimeEntry.description,
            version: currentTimeEntry.version,
            ETA: currentTimeEntry.ETA,
            startDate: currentTimeEntry.startDate,
            endDate: moment.utc(moment(`${newDate} ${newTime}`)).format(),
            title: currentTimeEntry?.title
              ? currentTimeEntry?.title
              : projectWorkTitle,
            projectId: currentTimeEntry?.projectId
              ? currentTimeEntry?.projectId
              : "",
            type: 2,
          },
          currentTimeEntry.id
        );
        if (Response.error) {
          console.log(Response.error);
          setTimer(0);
          setIsStopTimerLoader(false);
        } else {
          if (Response.data.code == 302 || Response.data.code == 404) {
            errorToast(
              Response.data.code == 404
                ? "Time Entry Already Deleted"
                : `${Response.data.message}`
            );
            setEntry(
              Response.data.code == 404
                ? [...entry]
                : Response?.data?.data?.timeEntries
            );
            setIsTimerLoader(false);
            clearInterval(increment.current);
            props.setIsStartTime(0);
            setTimeEntriesData();
            setIsPaused(false);
            setStop(timer);
            setRemark("");
            setIsActive(false);
            setProjectWorkTitle("");
            setcurrentTimeEntry({
              title: "",
              remark: "",
              startDate: "",
              endDate: "",
              description: "",
              version: "",
              ETA: "",
              projectId: null,
              id: "",
            });
            setIsStopTimerLoader(false);
            setIsCounterSet("");
            setTimer(0);
            setProjectName([]);
          } else {
            setEntry(
              moment(selectedDate).format("YYYY-MM-DD") !=
                moment(currentTimeEntry?.startDate).format("YYYY-MM-DD")
                ? [...entry]
                : [...entry, Response.data]
            );
            setTimer(0);
            setProjectName([]);
            setcurrentTimeEntry({
              ...Response.data,
            });
            if (dailyReportId != "") {
              setDailyReportId(Response?.data?.DailyReport?.id);
            }
            clearInterval(increment.current);
            props.setIsStartTime(0);
            setTimeEntriesData();
            setIsPaused(false);
            setStop(timer);
            setIsActive(false);
            setProjectWorkTitle("");
            setcurrentTimeEntry({
              title: "",
              remark: "",
              startDate: "",
              endDate: "",
              description: "",
              version: "",
              ETA: "",
              projectId: null,
              id: "",
            });
            setIsStopTimerLoader(false);
            setIsCounterSet("");
            setRemark("");
            if (
              moment(Response.data.startDate).format("D-MMM-YYYY") ==
              moment(selectedDate).format("D-MMM-YYYY")
            ) {
              successToast("Time entry has been created");
            } else {
              successToast(
                `Time entry has been created ${moment(
                  currentTimeEntry?.startDate
                ).format("DD-MM-YYYY")}`
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsStopTimerLoader(false);
      setIsActive(true);
      setIsPaused(true);
      if (projectWorkTitle.length <= 0 && !currentTimeEntry?.projectId) {
        errorToast("Can't save, field missing: project, description");
      }
      if (projectWorkTitle.length <= 0 && currentTimeEntry?.projectId) {
        errorToast("Can't save, field missing: description");
      }
      if (projectWorkTitle.length > 0 && !currentTimeEntry?.projectId) {
        errorToast("Can't save, field missing: project");
      }
    }
  };

  const doHandleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const onChangesTime = async (e, date, currentTimeEntry, currentDate) => {
    try {
      const preTime =
        currentTimeEntry.startDate != ""
          ? moment(currentTimeEntry.startDate).format("HH:mm")
          : "";
      const hours = e.split(":")[0];
      const minutes = e.split(":")[1];
      let newUpdatedDate = moment(currentDate).set({
        h: hours,
        minutes: minutes,
        second: 0,
        millisecond: 0,
      });
      const isFuture = await newUpdatedDate.isAfter(moment());
      if (isFuture) {
        errorToast(`start time must be less than current time!`);
        return;
      }
      if (
        isNumber &&
        e.length <= 5 &&
        currentTimeEntry?.startDate != "" &&
        !isFuture &&
        preTime != e
      ) {
        setIsEditTime(true);
        setIsCounterSet("isTimerChange");
        var Date = "";
        if (e.includes(".")) {
          Date = e.replace(".", " ");
        } else if (e.includes(":")) {
          Date = e.replace(":", " ");
        }
        let replaceDate = Date ? Date.replace(/\s/g, "") : e.replace(/\s/g, "");
        let hours = replaceDate.substring(0, 2);
        let minutes = replaceDate.substring(2, 5);
        let newDate = moment(date);
        newDate.set({
          hour: hours,
          minute: minutes,
          second: 0,
          millisecond: 0,
        });
        const Response = await authenticationService.createTimeEntryOptions(
          {
            id: currentTimeEntry.id,
            title: currentTimeEntry.title,
            remark: currentTimeEntry.remark,
            description: currentTimeEntry.description,
            version: currentTimeEntry.version,
            ETA: currentTimeEntry.ETA,
            projectId: currentTimeEntry.projectId,
            endDate: currentTimeEntry.endDate,
            startDate: newDate.toISOString(),
            type: 1,
          },
          currentTimeEntry.id
        );
        if (Response.error) {
          console.log(Response.error);
          setIsTimerLoader(false);
          setIsEditTime(false);
        } else {
          if (Response.data.code == 302 || Response.data.code == 404) {
            errorToast(
              Response.data.code == 404
                ? "Time Entry Already Deleted"
                : `${Response.data.message}`
            );
            setEntry(
              Response.data.code == 404
                ? [...entry]
                : Response?.data?.data?.timeEntries
            );
            setIsTimerLoader(false);
            clearInterval(increment.current);
            props.setIsStartTime(0);
            setTimeEntriesData();
            setIsPaused(false);
            setStop(timer);
            setIsActive(false);
            setProjectWorkTitle("");
            setcurrentTimeEntry({
              title: "",
              remark: "",
              startDate: "",
              endDate: "",
              description: "",
              version: "",
              ETA: "",
              projectId: null,
              id: "",
            });
            setIsStopTimerLoader(false);
            setIsCounterSet("");
            setTimer(0);
            setProjectName([]);
          } else {
            successToast("Start time has been updated");
            setAnchorEl(null);
            setcurrentTimeEntry({
              ...currentTimeEntry,
              startDate: Response.data.startDate,
            });
            setTimeEntriesData({
              ...Response.data,
              startDate: Response.data.startDate,
            });
            if (isCounterSet == "isTimerChange") {
              increment.current = setInterval(() => {
                setTimer((timer) => parseInt(timer) + 1);
              }, 1000);
            }
            let object = Response?.data;
            if (object && object.endDate == null) {
              let startDate = moment.utc().format();
              let endDate = moment.utc(moment(object?.startDate)).format();
              let seconds = -Math.round(
                (moment(endDate).valueOf() - moment(startDate).valueOf()) / 1000
              );
              setTimer(parseInt(seconds));
            }
            setIsActive(true);
            setIsPaused(true);
            setIsEditTime(false);
            setTimeout(() => {
              publish("LateArrivalEvent");
            }, 200);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onchangesProjectAndTitle = async (
    title,
    projectId,
    currentTimeEntry
  ) => {
    try {
      const Response = await authenticationService.createTimeEntryOptions(
        {
          id: currentTimeEntry.id,
          remark: currentTimeEntry.remark,
          description: currentTimeEntry.description,
          version: currentTimeEntry.version,
          ETA: currentTimeEntry.ETA,
          endDate: currentTimeEntry.endDate,
          startDate: currentTimeEntry.startDate,
          id: currentTimeEntry.id,
          title: title,
          projectId: projectId,
          type: 1,
        },
        currentTimeEntry.id
      );
      if (Response.error) {
        setIsTimerLoader(false);
      } else {
        if (Response.data.code == 302 || Response.data.code == 404) {
          errorToast(
            Response.data.code == 404
              ? "Time Entry Already Deleted"
              : `${Response.data.message}`
          );
          setEntry(
            Response.data.code == 404
              ? [...entry]
              : Response?.data?.data?.timeEntries
          );
          setIsTimerLoader(false);
          clearInterval(increment.current);
          props.setIsStartTime(0);
          setTimeEntriesData();
          setIsPaused(false);
          setStop(timer);
          setIsActive(false);
          setProjectWorkTitle("");
          setcurrentTimeEntry({
            title: "",
            remark: "",
            startDate: "",
            endDate: "",
            description: "",
            version: "",
            ETA: "",
            projectId: null,
            id: "",
          });
          setIsStopTimerLoader(false);
          setIsCounterSet("");
          setTimer(0);
          setProjectName([]);
        } else {
          successToast("Title and Project has been updated");
          setAnchorEl(null);
          setProjectWorkTitle(Response?.data?.title);
          setProjectName(
            Response?.data?.project
              ? [
                {
                  id: Response?.data?.project?.id,
                  name: Response?.data?.project?.projectCode
                    ? `${Response?.data?.project?.projectCode} - ${Response?.data?.project?.projectName}`
                    : Response?.data?.project?.projectName,
                  label: Response?.data?.project?.projectCode
                    ? `${Response?.data?.project?.projectCode} - ${Response?.data?.project?.projectName}`
                    : Response?.data?.project?.projectName,
                  value: Response?.data?.project?.projectCode
                    ? `${Response?.data?.project?.projectCode} - ${Response?.data?.project?.projectName}`
                    : Response?.data?.project?.projectName,
                },
              ]
              : []
          );
          setcurrentTimeEntry({
            ...currentTimeEntry,
            title: title,
            projectId: projectId,
          });
          setTimeEntriesData({
            ...currentTimeEntry,
            title: title,
            projectId: projectId,
          });
          setIsActive(true);
          setIsPaused(true);
        }
      }
    } catch (error) { }
  };

  const onchangesRemak = async (value, currentTimeEntry) => {
    try {
      if (currentTimeEntry?.startDate != "") {
        const Response = await authenticationService.createTimeEntryOptions(
          {
            id: currentTimeEntry.id,
            remark: value,
            description: currentTimeEntry.description,
            version: currentTimeEntry.version,
            ETA: currentTimeEntry.ETA,
            endDate: currentTimeEntry.endDate,
            startDate: currentTimeEntry.startDate,
            id: currentTimeEntry.id,
            projectId: currentTimeEntry.projectId,
            title: currentTimeEntry.title,
            type: 1,
          },
          currentTimeEntry.id
        );
        if (Response.error) {
          console.log(Response.error);
          setIsTimerLoader(false);
          setIsProjectWorkTitle(false);
        } else {
          if (Response.data.code == 302 || Response.data.code == 404) {
            errorToast(
              Response.data.code == 404
                ? "Time Entry Already Deleted"
                : `${Response.data.message}`
            );
            setEntry(
              Response.data.code == 404
                ? [...entry]
                : Response?.data?.data?.timeEntries
            );
            setIsTimerLoader(false);
            clearInterval(increment.current);
            props.setIsStartTime(0);
            setTimeEntriesData();
            setIsPaused(false);
            setStop(timer);
            setIsActive(false);
            setProjectWorkTitle("");
            setcurrentTimeEntry({
              title: "",
              remark: "",
              startDate: "",
              endDate: "",
              description: "",
              version: "",
              ETA: "",
              projectId: null,
              id: "",
            });
            setIsStopTimerLoader(false);
            setIsCounterSet("");
            setTimer(0);
            setProjectName([]);
          } else {
            successToast("Remark Successfully updated");
            setAnchorEl(null);
            setcurrentTimeEntry({
              ...currentTimeEntry,
              remark: Response.data.remark,
            });
            setTimeEntriesData({
              ...currentTimeEntry,
              remark: value,
              projectId: Response?.data?.project?.id
                ? Response?.data?.project?.id
                : null,
            });
            setIsActive(true);
            setIsPaused(true);
            setIsProjectWorkTitle(false);
          }
        }
      }
    } catch (error) {
      setIsProjectWorkTitle(false);
    }
  };

  const onchangesTitle = async (value, currentTimeEntry) => {
    try {
      if (currentTimeEntry?.startDate != "") {
        const Response = await authenticationService.createTimeEntryOptions(
          {
            id: currentTimeEntry.id,
            remark: currentTimeEntry.remark,
            description: currentTimeEntry.description,
            version: currentTimeEntry.version,
            ETA: currentTimeEntry.ETA,
            endDate: currentTimeEntry.endDate,
            startDate: currentTimeEntry.startDate,
            id: currentTimeEntry.id,
            projectId: currentTimeEntry.projectId,
            title: value,
            type: 1,
          },
          currentTimeEntry.id
        );
        if (Response.error) {
          console.log(Response.error);
          setIsTimerLoader(false);
          setIsProjectWorkTitle(false);
        } else {
          if (Response.data.code == 302 || Response.data.code == 404) {
            errorToast(
              Response.data.code == 404
                ? "Time Entry Already Deleted"
                : `${Response.data.message}`
            );
            setEntry(
              Response.data.code == 404
                ? [...entry]
                : Response?.data?.data?.timeEntries
            );
            setIsTimerLoader(false);
            clearInterval(increment.current);
            props.setIsStartTime(0);
            setTimeEntriesData();
            setIsPaused(false);
            setStop(timer);
            setIsActive(false);
            setProjectWorkTitle("");
            setcurrentTimeEntry({
              title: "",
              remark: "",
              startDate: "",
              endDate: "",
              description: "",
              version: "",
              ETA: "",
              projectId: null,
              id: "",
            });
            setIsStopTimerLoader(false);
            setIsCounterSet("");
            setTimer(0);
            setProjectName([]);
          } else {
            successToast("Title Successfully updated");
            setAnchorEl(null);
            setcurrentTimeEntry({
              ...currentTimeEntry,
              title: Response.data.title,
            });
            setTimeEntriesData({
              ...currentTimeEntry,
              title: value,
              projectId: Response?.data?.project?.id
                ? Response?.data?.project?.id
                : null,
            });
            setIsActive(true);
            setIsPaused(true);
            setIsProjectWorkTitle(false);
          }
        }
      }
    } catch (error) {
      setIsProjectWorkTitle(false);
    }
  };
  const debouncedChangeTitleHandler = useCallback(
    debounce(onchangesTitle, 10),
    []
  );

  const onchangesProject = async (value, currentTimeEntry) => {
    try {
      if (
        currentTimeEntry?.startDate != "" &&
        value != currentTimeEntry?.projectId
      ) {
        const Response = await authenticationService.createTimeEntryOptions(
          {
            id: currentTimeEntry.id,
            remark: currentTimeEntry.remark,
            description: currentTimeEntry.description,
            version: currentTimeEntry.version,
            ETA: currentTimeEntry.ETA,
            endDate: currentTimeEntry.endDate,
            startDate: currentTimeEntry.startDate,
            id: currentTimeEntry.id,
            title: currentTimeEntry.title,
            projectId: value,
            type: 1,
          },
          currentTimeEntry.id
        );
        if (Response.error) {
          setIsTimerLoader(false);
        } else {
          if (Response.data.code == 302 || Response.data.code == 404) {
            errorToast(
              Response.data.code == 404
                ? "Time Entry Already Deleted"
                : `${Response.data.message}`
            );
            setEntry(
              Response.data.code == 404
                ? [...entry]
                : Response?.data?.data?.timeEntries
            );
            setIsTimerLoader(false);
            clearInterval(increment.current);
            props.setIsStartTime(0);
            setTimeEntriesData();
            setIsPaused(false);
            setStop(timer);
            setIsActive(false);
            setProjectWorkTitle("");
            setcurrentTimeEntry({
              title: "",
              remark: "",
              startDate: "",
              endDate: "",
              description: "",
              version: "",
              ETA: "",
              projectId: null,
              id: "",
            });
            setIsStopTimerLoader(false);
            setIsCounterSet("");
            setTimer(0);
            setProjectName([]);
          } else {
            successToast("Project has been updated");
            setAnchorEl(null);
            setcurrentTimeEntry({
              ...currentTimeEntry,
              projectId: value,
            });
            setTimeEntriesData({
              ...currentTimeEntry,
              projectId: value,
            });
            setIsActive(true);
            setIsPaused(true);
          }
        }
      }
    } catch (error) { }
  };
  const debouncedChangeProjectHandler = useCallback(
    debounce(onchangesProject, 10),
    []
  );

  const doDeleteTimeEntry = async () => {
    try {
      const Response = await authenticationService.deleteTimeEntry(
        currentTimeEntry?.id
      );
      if (Response.data.code == 404) {
        errorToast(`Time Entry Already Deleted`);
        setIsTimerLoader(false);
        clearInterval(increment.current);
        props.setIsStartTime(0);
        setTimeEntriesData();
        setIsPaused(false);
        setStop(timer);
        setIsActive(false);
        setProjectWorkTitle("");
        setcurrentTimeEntry({
          title: "",
          remark: "",
          startDate: "",
          endDate: "",
          description: "",
          version: "",
          ETA: "",
          projectId: null,
          id: "",
        });
        setIsStopTimerLoader(false);
        setIsCounterSet("");
        setTimer(0);
        setProjectName([]);
      } else {
        setTimeEntriesData(null);
        setProjectWorkTitle("");
        setTimer(0);
        setProjectName([]);
        setIsPaused(false);
        setStop(timer);
        setIsActive(false);
        props.setIsStartTime(0);
        setcurrentTimeEntry({
          title: "",
          remark: "",
          startDate: "",
          endDate: "",
          description: "",
          version: "",
          ETA: "",
          projectId: null,
          id: "",
        });
        doHandleReset();
        successToast("Timer cancelled");
        setTimeout(() => {
          publish("LateArrivalEvent");
        }, 200);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isTimerVisible = () => {
    let isAllowed = false;
    let isToday =
      moment(selectedDate).format("DD/MM/YYYY") ==
      moment().format("DD/MM/YYYY");
    if (isToday) {
      isAllowed = true;
    } else {
      if (timeEntriesData && !timeEntriesData.endDate && !isToday) {
        isAllowed = true;
      }

      if (!timeEntriesData && !isToday) {
        isAllowed = false;
      }
    }

    return isAllowed;
  };

  const changeStartTimeUpdate = async () => {
    let formattedTime = "";
    if (timerUpdatedStartTime != "") {
      formattedTime = await doHandleCheckValidTime(
        timerUpdatedStartTime,
        "startTime"
      );
      if (formattedTime != "") {
        onChangesTime(
          formattedTime,
          currentTimeEntry.startDate != ""
            ? moment(currentTimeEntry.startDate).format("l")
            : "00:00",
          currentTimeEntry,
          selectedDate
        );
      }
    }
  };

  const projectNameSet = (id) => {
    let projectSelect = [];
    newProjectList.map((item) => {
      if (item.options?.length) {
        item.options &&
          item.options.map((optionsItem) => {
            if (optionsItem.id == id) {
              projectSelect.push(optionsItem);
            }
          });
      }
    });
    setProjectName(projectSelect);
  };

  useEffect(() => {
    const faviconLink = document.getElementById("favicon");
    if (formatTime(timer) !== "00:00:00") {
      document.title = `${formatTime(timer)} - DWR`;
      faviconLink.href = "/reddot.gif";
    } else {
      document.title = "Daily Work Report";
      faviconLink.href = "/favicon.ico";
    }

    return () => {
      if (timer === 0) {
        document.title = `Daily Work Report`;
        faviconLink.href = "/favicon.ico";
      } else {
        document.title = `Daily Work Report`;
        faviconLink.href = "/reddot.gif";
      }
    };
  }, [timer]);

  return (
    <>
      <Modal
        open={modleOpen}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            background: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            boxShadow: "0px 2px 16px 3px rgb(84 84 84 / 75%)",
            p: 4,
            borderRadius: 5,
          }}
        >
          <div style={{ padding: "30px 25px", textAlign: "center" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#424242",
                fontSize: 30,
                padding: "0px 0px 20px 0px",
              }}
            >
              Are you sure?
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#7e7e7e",
                padding: "0px 0px 20px 0px",
              }}
            >
              Do you really want to delete{" "}
              <b style={{ color: "black" }}>current working task?</b> This
              process can not be undone.
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ marginRight: 30 }}
                onClick={() => {
                  handleCloses();
                }}
              >
                cancel
              </Button>
              <Button
                style={{ background: "#ff5454" }}
                onClick={() => {
                  doDeleteTimeEntry();
                  handleCloses();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {isTimerVisible() ? (
        <>
          <div
            style={{
              margin: "82px 0px 0px 0px",
              background: "white",
            }}
          >
            <GridContainer
              style={{
                display: "flex",
                alignItems: "center",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px",
                border: "none",
              }}
            >
              <GridItem
                xs={12}
                sm={12}
                md={7}
                lg={7}
                style={{
                  padding: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "20px", width: "100%" }}>
                  <CustomInputDropDown
                    showJiraTickets={newJiraTimeEntryList}
                    newLastFiveTimeEntryList={showJiraTickets ? newJiraTimeEntryList : newLastFiveTimeEntryList}
                    setNewLastFiveTimeEntryList={setNewLastFiveTimeEntryList}
                    newJiraTimeEntryList={newJiraTimeEntryList}
                    setIsProjectWorkTitle={setIsProjectWorkTitle}
                    setProjectWorkTitle={setProjectWorkTitle}
                    projectWorkTitle={projectWorkTitle}
                    setcurrentTimeEntry={setcurrentTimeEntry}
                    currentTimeEntry={currentTimeEntry}
                    setIsEditing={setIsEditing}
                    debouncedChangeTitleHandler={(e, currentTimeEntry) => {
                      debouncedChangeTitleHandler(e, currentTimeEntry);
                    }}
                    onchangesProjectAndTitle={(
                      title,
                      projectId,
                      currentTimeEntry
                    ) => {
                      onchangesProjectAndTitle(
                        title,
                        projectId,
                        currentTimeEntry
                      );
                    }}
                    isActive={isActive}
                    isPaused={isPaused}
                    isStartTime={props.isStartTime}
                    isprojectWorkTitle={isprojectWorkTitle}
                    doHandleStart={(playEntryId) => doHandleStart(playEntryId)}
                    AddNewTimeEntry={false}
                    timeEntriesData={timeEntriesData}
                    timer={timer}
                    setRemark={setRemark}
                    remark={remark}
                    isUserDetails={isUserDetails}
                    onchangesRemak={(value, currentTimeEntry) => {
                      onchangesRemak(value, currentTimeEntry);
                    }}
                    doHandleClickOnFetchLatestTimeEntries={doHandleClickOnFetchLatestTimeEntries}
                    isFetchLatestJiraTimeEntries={isFetchLatestJiraTimeEntries}
                  />
                </div>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={5}
                lg={5}
                style={{ padding: "0px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "600",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      width: "70%",
                      marginRight: "35px",
                      alignItems: "center",
                      zIndex: 5,
                    }}
                  >
                    <Select
                      maxMenuHeight={250}
                      styles={colourStyles}
                      placeholder="Select Project"
                      value={
                        projectName.length > 0
                          ? projectName[0]
                          : "Select Project"
                      }
                      components={{
                        IndicatorSeparator: () => null,
                        // NoOptionsMessage: () => (
                        //   <div
                        //     style={{
                        //       display: "flex",
                        //       alignItems: "center",
                        //       justifyContent: "center",
                        //       height: "200px",
                        //       fontSize: "14px",
                        //     }}
                        //   >
                        //     Request to reporting manager to add project
                        //   </div>
                        // ),

                        NoOptionsMessage: () => <RequestNewProject />,
                      }}
                      onChange={(e) => {
                        if (e.value === "Request Project Allocation") {
                          doClickOnAddProject();
                        }
                        if (e) {
                          setcurrentTimeEntry({
                            ...currentTimeEntry,
                            projectId: e.id,
                          });
                          debouncedChangeProjectHandler(e.id, currentTimeEntry);
                          projectNameSet(e.id);
                          // if (projectWorkTitle.length > 0) {
                          //   debugger;
                          //   setcurrentTimeEntry({
                          //     ...currentTimeEntry,
                          //     projectId: e.id,
                          //   });
                          //   doHandleStart(e?.id);
                          // }
                        }
                      }}
                      options={[
                        {
                          label: (
                            <span>
                              <i
                                className="fa fa-plus"
                                style={{
                                  fontSize: "12px",
                                  margin: "0px 7px",
                                  fontWeight: "lighter",
                                }}
                              />
                              <span
                                style={{
                                  paddingLeft: 3,
                                  textDecoration: "underline",
                                  color: "#2f63f7",
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                              >
                                Request project allocation
                              </span>
                            </span>
                          ),
                          value: "Request Project Allocation",
                        },
                        ...newProjectList,
                      ]}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontWeight: "600",
                      marginRight: "20px",
                    }}
                    onClick={() => {
                      setNewTime(
                        currentTimeEntry.startDate != ""
                          ? moment(currentTimeEntry.startDate).format("HH:mm")
                          : "00:00"
                      );
                    }}
                  >
                    <h4
                      style={{
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={handleTimerClick}
                    >
                      {formatTime()}
                    </h4>
                    <div>
                      <Popover
                        style={{ top: "10px" }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Typography className={classes.typography}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                paddingRight: "8",
                                fontSize: "14px",
                                fontWeight: 600,
                                marginRight: 10,
                              }}
                            >
                              START TIME
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    maxWidth:
                                      isTimeFormateChecked != "24"
                                        ? "100px"
                                        : "76px",
                                  }}
                                >
                                  <TextField
                                    className="timeNewInput"
                                    variant="outlined"
                                    size="small"
                                    // disabled={timer != 0}
                                    defaultValue={
                                      currentTimeEntry.startDate != ""
                                        ? isTimeFormateChecked == "24"
                                          ? moment(
                                            currentTimeEntry.startDate
                                          ).format("HH:mm")
                                          : moment(
                                            currentTimeEntry.startDate
                                          ).format("hh:mmA")
                                        : "00:00"
                                    }
                                    onClick={(e) => {
                                      e.target.focus();
                                      e.target.select();
                                    }}
                                    inputProps={{
                                      maxLength:
                                        isTimeFormateChecked != "24" ? 7 : 5,
                                    }}
                                    onBlur={() => {
                                      changeStartTimeUpdate();
                                    }}
                                    onChange={(e) => {
                                      setTimerUpdatedStartTime(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.keyCode == 13) {
                                        changeStartTimeUpdate();
                                        handleClose();
                                      }
                                    }}
                                    placeholder={
                                      currentTimeEntry.startDate != ""
                                        ? isTimeFormateChecked == "24"
                                          ? moment(
                                            currentTimeEntry.startDate
                                          ).format("HH:mm")
                                          : moment(
                                            currentTimeEntry.startDate
                                          ).format("hh:mmA")
                                        : "00:00"
                                    }
                                  />

                                  {/* <div style={{border:"1px solid #0000003b",padding:"4px 8px 4px 7px",borderRadius:3,userSelect:"none"}}>
                                                                        {
                                                                            currentTimeEntry.startDate !=
                                                                                ''
                                                                                ? isTimeFormateChecked ==
                                                                                    "24"
                                                                                    ? moment(
                                                                                        currentTimeEntry.startDate
                                                                                    ).format(
                                                                                        'HH:mm'
                                                                                    )
                                                                                    : moment(
                                                                                        currentTimeEntry.startDate
                                                                                    ).format(
                                                                                        'hh:mmA'
                                                                                    )
                                                                                : '00:00'
                                                                        }
                                                                    </div> */}
                                </div>
                              </div>
                            </div>
                            {currentTimeEntry?.startDate ? (
                              <div
                                style={{
                                  marginLeft: 5,
                                }}
                              >
                                {" "}
                                {moment(currentTimeEntry.startDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Typography>
                      </Popover>
                    </div>
                    <div
                      className="buttons"
                      style={{
                        margin: "10px 0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!isActive && !isPaused && !props.isStartTime ? (
                        <button
                          style={{
                            margin: "5px 0px 5px 15px",
                            height: "40px",
                            width: "40px",
                            backgroundColor: "#f4590d",

                            fontSize: "17px",
                            border: "0px solid",
                            borderRadius: "50%",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: isTimerLoader ? 0.5 : 1,
                            // cursor: isTimerLoader ? "progress" : "pointer",
                            cursor:
                              projectWorkTitle?.length > 0 &&
                                currentTimeEntry?.projectId
                                ? "pointer"
                                : "not-allowed",
                          }}
                          onClick={() => {
                            doHandleStart();
                          }}
                        // disabled={projectWorkTitle?.length > 0 &&
                        //   currentTimeEntry?.projectId}
                        >
                          {/* Start Button */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            style={{
                              transform: "scale(1.25)",
                            }}
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M9.525 18.025q-.5.325-1.012.038T8 17.175V6.825q0-.6.513-.888t1.012.038l8.15 5.175q.45.3.45.85t-.45.85l-8.15 5.175Z"
                            />
                          </svg>
                        </button>
                      ) : isStopTimerLoader ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "115px",
                            height: "60px",
                          }}
                        >
                          <CircularProgress size={30} />
                        </div>
                      ) : (
                        <>
                          <button
                            style={{
                              margin: "5px 0px 5px 15px",
                              height: "40px",
                              width: "40px",
                              backgroundColor: "#F44336",
                              fontSize: "17px",
                              border: "0px solid",
                              borderRadius: "50%",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                              cursor:
                                projectWorkTitle?.length > 0 &&
                                  currentTimeEntry?.projectId
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            onClick={() => {
                              doHandleStop();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="48"
                              style={{
                                transform: "scale(1.25)",
                              }}
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M8 6h8c1.1 0 2 .9 2 2v8c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2z"
                              />
                            </svg>
                          </button>
                          <div>
                            <img
                              src={close}
                              style={{
                                cursor: "pointer",
                                padding: "5px 0px 0px 15px",
                              }}
                              width={25}
                              height={25}
                              onClick={handleDeleteAlertClick}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>

          <Modal
            open={projectModalOpen}
            onClose={handleProjectModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                background: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 420,
                height: 410,
                bgcolor: "white",
                boxShadow: "0px 2px 16px 3px rgb(84 84 84 / 75%)",
                p: 4,
                borderRadius: 5,
              }}
            >
              <div style={{ marginTop: "15px" }}>
                <div
                  style={{
                    padding: "20px 15px",
                    // borderBottom: "2px solid #d5d5d5",
                    fontWeight: "normal",
                    fontSize: 20,
                    color: "#000000",
                    // textAlign: "center",
                  }}
                >
                  <span> Request project allocation </span>
                  <img
                    src={close}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 35,
                      right: 20,
                    }}
                    width={25}
                    height={25}
                    onClick={handleProjectModalClose}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <>
                    <TextField
                      style={{ width: "95%", margin: "10px" }}
                      label="Project Code"
                      variant="outlined"
                      onChange={(e) => {
                        setAddProjectCode(e.target?.value);
                      }}
                      className={classes.root}
                      InputProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                    />
                    <TextField
                      style={{ width: "95%", margin: "10px" }}
                      label="Project name"
                      variant="outlined"
                      onChange={(e) => {
                        setAddProjectName(e.target?.value);
                      }}
                      className={classes.root}
                      InputProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                    />
                    <TextField
                      style={{ width: "95%", margin: "10px" }}
                      label="Project Comment"
                      variant="outlined"
                      multiline
                      rows={3}
                      onChange={(e) => {
                        setAddProjectComment(e.target?.value);
                      }}
                      value={addProjectComment}
                      className={classes.root}
                      InputProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "95%",
                        margin: "10px",
                      }}
                    >
                      <Button
                        style={{
                          marginRight: 30,
                          // boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                          background: "#fff",
                          color: "#000",
                          textTransform: "capitalize",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          // handleCloses();
                          handleProjectModalClose();
                        }}
                      >
                        Cancel
                      </Button>

                      {addProjectRequestLoader ? (
                        <>
                          <div
                            style={{
                              background: "#f96117db",
                              width: "100px",
                              marginTop: "5px",
                              height: "40px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* Loading... */}
                            <CircularProgress size={20} />
                          </div>
                        </>
                      ) : (
                        <Button
                          style={{
                            background: "#f96117db",
                            textTransform: "capitalize",
                            fontSize: "14px",
                          }}
                          onClick={doHandleAddProjectMailSend}
                          disabled={
                            addProjectCode && addProjectName && addProjectComment ? false : true
                          }
                        >
                          Request
                        </Button>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
});

export default TimeTracker;
