import Lottie from "react-lottie";
import React from "react";
import linearloader from "../../assets/json/MailLaoder.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: linearloader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

// eventListeners={[
//         {
//             eventName: 'complete',
//             callback: () => window.location.reload(),
//         },
// ]}

const LinearLoader = props => {
  return ( 
      <Lottie
        options={defaultOptions}
        height={props.height ? props.height : 100}
        width={props.width ? props.width : 100}
        style={props.style ? props.style : {}}
      /> 
  );
};

export default LinearLoader;
