import { authenticationService } from "./authentication";

export function authHeader(provideContentType = true) {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUser;
   if (currentUser && currentUser.token) {
    if (provideContentType) {
      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUser.token}`
      };
    } else {
      return {
        Authorization: `Bearer ${currentUser.token}`
      };
    }
  } else {
    return { "Content-Type": "application/json" };
  }
}

export function authToken() {
  const currentUser = authenticationService.currentUser;
  if (currentUser && currentUser.token) {
    return {
      Authorization: `Bearer ${currentUser.token}`,
    }
  } else {
    return {}
  }
}

export function xAuthToken() {
  const currentUser = authenticationService.currentUser;
  if (currentUser && currentUser.token) {
    return {
      "Content-Type": "application/json",
      'x-auth-token': `${currentUser.token}`,
    }
  } else {
    return {}
  }
}
