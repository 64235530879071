import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { authenticationService } from "../../services/authentication";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import { close } from "helper/constant.jsx";
import CustomInputDropDown from "components/CustomInputDropDown/CustomInputDropDown";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const AddEntry = (props) => {
  const {
    newProjectList,
    selectedDate,
    entry,
    setEntry,
    setIsTimeData,
    setIsAddTimeDetails,
    dailyReportId,
    setDailyReportId,
    newLastFiveTimeEntryList,
    setNewLastFiveTimeEntryList,
    isTimeFormateChecked
  } = props; 
  const [workTitle, setWorkTitle] = useState("");
  const [projectId, setProjectId] = useState([]);
  const [addStartTime, setAddStartTime] = useState("00:00");
  const [addEndTime, setAddEndTime] = useState("00:00");
  const [isLoader, setIsLoader] = useState(false); 
  const [isTrue, setIsTrue] = React.useState(false);
  const classes = useStyles();
  const selectedDateFormat = moment(selectedDate).format("DD-MM-YYYY");
  const isNumber = (e) => /^[0-9. :]+$/.test(e);
  const colourStyles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "5px",
        height: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f4590d",
      },
    }),
    control: (base, state) => ({
      ...base,
      zIndex: state.isFocused ? "999" : "1",
      background: "#ffffff",
      // background: '#efefef',
      color: "#000000",
      fontWeight: "bold",
      padding: 20,
      // match with the menu
      height: 40,
      minHeight: 35,
      fontSize: 16,
      fontWeight: 400,
      cursor: "pointer",
      padding: "0px 8px",
      // border: 'none',
      // outline: 'none',
      // Overwrittes the different states of border
      borderColor: "#0000003b",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#0000003b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontWeight: "400",
        color: isDisabled
          ? "black"
          : isSelected
            ? "white"
              ? "white"
              : "black"
            : "black",
        cursor: "pointer",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#f96117db"
            : isFocused
              ? "#f961173d"
              : undefined,
        ":hover": {
          backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
        },

      };
    },
  };
  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  let errorToast = (errMsg) => {
    toast.error(errMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  useEffect(() => { 
    setAddStartTime(
      isTimeFormateChecked == "24" ? "00:00" : "00:00AM"
    );
    setAddEndTime(
      isTimeFormateChecked == "24" ? "00:00" : "00:00AM"
    );
    return () => { };
  }, [isTimeFormateChecked]);

  const doHandleCheckValidTime = (time, type) => {
    try {
      let formattedTime = "";
      if (isTimeFormateChecked == "24") {
        if (!isNumber(parseInt(time))) {
          errorToast(`${type} must be valid!`, "error");
          return;
        }
        if (!time.includes(":")) {
          if (time.length == 1) {
            let a = `${0 + time.substr(0, 1)}`;
            let b = "00";
            if (a <= 23 && b <= 59) {
              let splitData = a + ":" + b;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            formattedTime = `0${time.substr(0, 1) + ":" + "00"}`;
          }
          if (time.length == 2) {
            let a = time.substr(0, 2);
            let b = "00";
            if (a <= 23 && b <= 59) {
              let splitData = a + ":" + b;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            formattedTime = `${time.substr(0, 2) + ":" + "00"}`;
          }
          if (time.length == 3) {
            let a = `${0 + time.substr(0, 1)}`;
            let b = time.substr(1, 2);
            if (a <= 23 && b <= 59) {
              let splitData = a + ":" + b;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            formattedTime = `0${time.substr(0, 1) + ":" + time.substr(1, 2)}`;
          }
          if (time.length == 4) {
            let a = time.substr(0, 2);
            let b = time.substr(2);
            if (a <= 23 && b <= 59) {
              let splitData = a + ":" + b;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            formattedTime = `${time.substr(0, 2) + ":" + time.substr(2, 2)}`;
          }
        }
        if (time.includes(":")) {
          if (time.length == 4) {
            let a = time.slice(0, time.indexOf(":"));
            let b = ":";
            let c = time.slice(time.indexOf(":") + 1, time.length);
            let newA = a.length == 2 ? a : 0 + a;
            let newC = c;
            formattedTime = newA + b + newC;
            {
              type == "startTime"
                ? setAddStartTime(formattedTime)
                : setAddEndTime(formattedTime);
            }
          }
          if (time.length == 5) {
            formattedTime = time;
          }
        }
        let isValid = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(
          formattedTime
        );
        if (!isValid) {
          errorToast(`${type} must be valid!`, "error");
        }
        DoHandleTotalDiffTime(formattedTime, type)
        return formattedTime;
      } else {
        let manipulatedTime = "";
        if (!time.includes(":")) {
          if (time.length == 3) {
            let a = `0${time.substr(0, 1)}`;
            let b = "00";
            let c = time.substr(-2);
            if (a <= 12 && b <= 59) {
              let splitData = a + ":" + b + c;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            manipulatedTime = `0${time.substr(0, 1) +
              ":" +
              "00" +
              time.substr(-2)}`;
          }
          if (time.length == 4) {
            let a = time.substr(0, 2);
            let b = "00";
            let c = time.substr(-2);
            if (a <= 12 && b <= 59) {
              let splitData = a + ":" + b + c;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            manipulatedTime = `${time.substr(0, 2) +
              ":" +
              "00" +
              time.substr(-2)}`;
          }
          if (time.length == 6) {
            let a = `${0 + time.substr(0, 1)}`;
            let b = time.substr(1, 2);
            let c = time.substr(-2);
            if (a <= 12 && b <= 59) {
              let splitData = a + ":" + b + c;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            manipulatedTime = `0${time.substr(0, 1) +
              ":" +
              time.substr(1, 2) +
              time.substr(-2)}`;
          }
          if (time.length == 6) {
            let a = time.substr(0, 2);
            let b = time.substr(2, 2);
            let c = time.substr(-2);

            if (a <= 12 && b <= 59) {
              let splitData = a + ":" + b + c;
              {
                type == "startTime"
                  ? setAddStartTime(splitData)
                  : setAddEndTime(splitData);
              }
            }
            manipulatedTime = `${time.substr(0, 2) +
              ":" +
              time.substr(2, 2) +
              time.substr(-2)}`;
          }
        }
        if (time.includes(":")) {
          if (time.length == 6) {
            let a = time.slice(0, time.indexOf(":"));
            let b = ":";
            let c = time.slice(time.indexOf(":") + 1, time.length);
            let newA = a.length == 2 ? a : 0 + a;
            let newC = c;
            manipulatedTime = newA + b + newC;
            {
              type == "startTime"
                ? setAddStartTime(manipulatedTime)
                : setAddEndTime(manipulatedTime);
            }
          }
          if (time.length == 7) {
            manipulatedTime = time;
          }
        }
        let isValid = /^([01]?[0-9]):([0-5][0-9])([AaPp][Mm])?$/.test(
          manipulatedTime
        );
        if (!isValid) {
          errorToast(`${type} must be valid!`, "error");
        }
        if (manipulatedTime != "") {
          if (
            manipulatedTime.substr(-2) == "PM" ||
            manipulatedTime.substr(-2) == "pm" ||
            manipulatedTime.substr(-2) == "Pm" ||
            manipulatedTime.substr(-2) == "pM" ||
            manipulatedTime.substr(-1) == "P" ||
            manipulatedTime.substr(-1) == "p"
          ) {
            formattedTime =
              (parseInt(manipulatedTime.substr(0, 2)) == 12
                ? parseInt(manipulatedTime.substr(0, 2)) + 0
                : parseInt(manipulatedTime.substr(0, 2)) + 12) +
              ":" +
              manipulatedTime.substr(3, 2);
          } else {
            formattedTime =
              (parseInt(manipulatedTime.substr(0, 2)) == 12
                ? "00"
                : manipulatedTime.substr(0, 2)) +
              ":" +
              manipulatedTime.substr(3, 2);
          }
        }
        DoHandleTotalDiffTime(formattedTime, type)
        return formattedTime;
      }
    } catch (error) { }
  };


  const doHandleAddEntry = async () => {
    try {
      let formattedStartTime = "";
      let formattedEndTime = "";
      let newStartUpdatedDate = moment(selectedDate);
      let newEndUpdatedDate = moment(selectedDate);
      let timeErrorDetails = { status: false };
      if (addStartTime != "") {
        formattedStartTime = await doHandleCheckValidTime(
          addStartTime,
          "startTime"
        );
      }
      if (addEndTime != "") {
        formattedEndTime = await doHandleCheckValidTime(addEndTime, "endTime");
      }
      if (formattedStartTime != "") {
        newStartUpdatedDate.set({
          h: formattedStartTime.split(":")[0],
          minutes: formattedStartTime.split(":")[1],
          second: 0,
          millisecond: 0,
        });
      }
      if (formattedEndTime != "") {
        newEndUpdatedDate.set({
          h: formattedEndTime.split(":")[0],
          minutes: formattedEndTime.split(":")[1],
          second: 0,
          millisecond: 0,
        });
      }

      if (
        workTitle.length > 0 &&
        projectId.length != 0 &&
        timeErrorDetails.status == false
      ) {
        const isStartFuture = await newStartUpdatedDate.isAfter(moment());
        const isEndTimeFuture = await newEndUpdatedDate.isAfter(moment());
        let errorDetails = {
          status: false,
          message: "",
        };
        if (isStartFuture) {
          errorDetails.status = true;
          errorDetails.message += `Start time must be less than current time!`;
        }
        const isPast = await newStartUpdatedDate.isBefore(newEndUpdatedDate);
        if (!isPast) {
          errorDetails.status = true;
          if (errorDetails.message && errorDetails.status) {
            errorDetails.message += ",";
          }
          errorDetails.message += `End time must be greater than start time!`;
        }
        if (isEndTimeFuture) {
          errorDetails.status = true;
          if (errorDetails.message && errorDetails.status) {
            errorDetails.message += ", ";
          }
          errorDetails.message += `End time must be less than current time!`;
        }
        if (errorDetails.status) {
          errorToast(errorDetails.message);
          return;
        }
        setIsLoader(true);

        const Response = await authenticationService.addTimeEntry(
          selectedDateFormat,
          {
            title: workTitle,
            remark: "",
            startDate: newStartUpdatedDate.utc().format(),
            endDate: newEndUpdatedDate.utc().format(),
            description: "",
            projectId: projectId.id,
          }
        );
        if (Response?.data) {
          let entryArr = entry ? entry : [];
          entryArr.push({
            startDate: Response?.data.startDate,
            endDate: Response?.data.endDate,
            description: "",
            title: workTitle,
            duration: Response?.data.duration,
            remark: "",
            isNewlyAdded: true,
            projectId: projectId,
            project: Response?.data.project,
            DailyReport: { id: Response?.data?.DailyReport?.id },
            id: Response?.data?.id,
          });
          if (dailyReportId != "") {
            setDailyReportId(Response?.data?.DailyReport?.id);
          }
          setIsAddTimeDetails(false);
          setEntry([...entryArr]);
          setWorkTitle("");
          setAddStartTime("00:00");
          setAddEndTime("00:00");
          setProjectId([]);
          setIsLoader(false);
          successToast("Time entry addded");
        }
        if (Response?.error || Response?.data?.error) {
          setIsAddTimeDetails(false);
          setIsLoader(false);
        }
      } else {
        if (workTitle.length <= 0 && projectId.length == 0) {
          errorToast("Can't save, field missing: project, description");
        }
        if (workTitle.length <= 0 && projectId.length != 0) {
          errorToast("Can't save, field missing: description");
        }
        if (projectId.length == 0 && workTitle.length > 0) {
          errorToast("Can't save, field missing: project");
        }
        setIsLoader(false);
        setIsAddTimeDetails(false);
      }
    } catch (error) {
      setIsAddTimeDetails(false);
      console.log(error);
      setIsLoader(false);

    }
  };


  const DoHandleTotalDiffTime = (time, type) => {
    if (isTrue == true) { 
      if (isNumber(parseInt(addStartTime)) && isNumber(parseInt(addEndTime))) {
        const startHours = addStartTime.split(":")[0];
        const startMinutes = addStartTime.split(":")[1];
        const endHours = addEndTime.split(":")[0];
        const endMinutes = addEndTime.split(":")[1];
        let newStartUpdatedDate = moment(selectedDate).set({
          h: startHours,
          minutes: startMinutes,
          second: 0,
          millisecond: 0,
        });
        let newEndUpdatedDate = moment(selectedDate).set({
          h: endHours,
          minutes: endMinutes,
          second: 0,
          millisecond: 0,
        });
        var duration = moment.duration(
          moment(newEndUpdatedDate).diff(newStartUpdatedDate)
        );
        var seconds = duration.asSeconds();
        let d = Number(parseInt(seconds));
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);
        var hDisplay = h > 0 ? (h > 9 ? `${h}h ` : `0${h}h `) : '00h ';
        var mDisplay = m > 0 ? (m > 9 ? `${m}m ` : `0${m}m `) : '00m ';
        var sDisplay = s > 0 ? (s > 9 ? `${s}s ` : `0${s}s `) : '00s ';
        return hDisplay + mDisplay;
      } else {
        return "00h 00m";
      }
    } else {
      return "00h 00m";
    }
  };

  return (
    <>
      <GridContainer style={{ display: "flex", alignItems: "center" }}>
        <GridItem xs={12} sm={12} md={4} lg={5} style={{ padding: "0px" }}>
          <div style={{ padding: "10px 0px" }}>
            {/* <TextField
              fullWidth
              style={{
                backgroundColor: "white",
              }}
              InputLabelProps={{
                style: { color: "#0000008a" },
              }}
              size="small"
              label="What have you worked on?"
              id="fullWidth"
              value={workTitle ? workTitle : ""}
              variant="outlined"
              onChange={(e) => {
                setWorkTitle(e.target.value);
              }}
            /> */}

            <CustomInputDropDown
              newLastFiveTimeEntryList={newLastFiveTimeEntryList}
              setNewLastFiveTimeEntryList={setNewLastFiveTimeEntryList}
              AddNewTimeEntry={true}
              projectId={projectId}
              setProjectId={setProjectId}
              workTitle={workTitle}
              setWorkTitle={setWorkTitle}
              newProjectList={newProjectList}
            />
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={8} lg={7} style={{ padding: "0px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "600",
            }}
          >
            <div
              style={{
                display: "grid",
                width: "50%",
                marginRight: "35px",
                alignItems: "center",
              }}
            >
              <Select
                maxMenuHeight={250}
                styles={colourStyles}
                placeholder="Select Project"
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={projectId}
                onChange={(e) => {
                  setProjectId(e);
                }}
                options={newProjectList}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5,
                }}
              >
                <div
                  style={{
                    maxWidth: isTimeFormateChecked != "24" ? "105px" : "76px",
                  }}
                >
                  <TextField
                    className="timeNewInput"
                    variant="outlined"
                    size="small"
                    value={addStartTime}
                    style={{
                      width: isTimeFormateChecked == "12" ? "100px" : "74px",
                    }}
                    inputProps={{
                      maxLength: isTimeFormateChecked != "24" ? 7 : 5,
                    }}
                    onClick={(e) => {
                      e.target.focus();
                      e.target.select();
                    }}
                    onBlur={() => {
                      if (addStartTime != "") {
                        let startTime = doHandleCheckValidTime(
                          addStartTime,
                          "startTime"
                        );
                        setIsTrue(true);
                      }
                    }}
                    onChange={(e) => {
                      setAddStartTime(e.target.value);
                      setIsTrue(false);
                    }}
                    placeholder={
                      isTimeFormateChecked != "24" ? "00:00 AM" : "00:00"
                    }
                  />
                </div>
              </div>
              -
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <div
                  style={{
                    maxWidth: isTimeFormateChecked != "24" ? "105px" : "76px",
                  }}
                >
                  <TextField
                    className="timeNewInput"
                    style={{
                      width: isTimeFormateChecked == "12" ? "100px" : "74px",
                    }}
                    variant="outlined"
                    size="small"
                    value={addEndTime}
                    inputProps={{
                      maxLength: isTimeFormateChecked != "24" ? 7 : 5,
                    }}
                    onClick={(e) => {
                      e.target.focus();
                      e.target.select();
                    }}
                    onBlur={() => {
                      if (addEndTime != "") {
                        let endTime = doHandleCheckValidTime(
                          addEndTime,
                          "endTime"
                        );
                        setIsTrue(true);
                      }
                    }}
                    onChange={(e) => {
                      setAddEndTime(e.target.value);

                      setIsTrue(false);
                    }}
                    placeholder={
                      isTimeFormateChecked != "24" ? "00:00 AM" : "00:00"
                    }
                  />
                </div>
              </div>
              <span
                style={{
                  margin: "0px 25px",
                  borderLeft: "1px solid #d5d5d5",
                  height: 50,
                }}
              >
                {" "}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: isTimeFormateChecked != "24" ? "105px" : "76px",
                    fontSize: 18, fontWeight: 600
                  }}
                >
                  <DoHandleTotalDiffTime />
                </div>
              </div>
            </div>
            <div
              className="buttons"
              style={{
                margin: "10px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoader ? (
                <div
                  style={{
                    width: 115,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress style={{ width: 35, height: 35 }} />{" "}
                </div>
              ) : (
                <>
                  <button
                    className="addButtonFocus"
                    style={{
                      // padding: '5px 20px',
                      margin: "5px 0px 5px 15px",
                      height: "45px",
                      width: "45px",
                      backgroundColor: "#f4590d",
                      fontSize: "17px",
                      border: "0px solid",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      cursor: "pointer",
                      // opacity: isTimerLoader ? 0.5 : 1,
                      // cursor: isTimerLoader ? "progress" : "pointer",
                    }}
                    onClick={() => {
                      doHandleAddEntry();
                    }}
                  >
                    {/* Add Entry Button With Text Field */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M11 17h2v-3h3v-2h-3V9h-2v3H8v2h3v3Zm1 5q-1.875 0-3.513-.713t-2.85-1.924q-1.212-1.213-1.924-2.85T3 13q0-1.875.713-3.513t1.924-2.85q1.213-1.212 2.85-1.924T12 4q1.875 0 3.513.713t2.85 1.925q1.212 1.212 1.925 2.85T21 13q0 1.875-.713 3.513t-1.924 2.85q-1.213 1.212-2.85 1.925T12 22Zm0-9ZM5.6 2.35L7 3.75L2.75 8l-1.4-1.4L5.6 2.35Zm12.8 0l4.25 4.25l-1.4 1.4L17 3.75l1.4-1.4ZM12 20q2.925 0 4.963-2.038T19 13q0-2.925-2.038-4.963T12 6Q9.075 6 7.037 8.038T5 13q0 2.925 2.038 4.963T12 20Z"
                      />
                    </svg>
                  </button>
                  <div>
                    <img
                      src={close}
                      style={{
                        cursor: "pointer",
                        padding: "5px 0px 0px 15px",
                      }}
                      width={25}
                      height={25}
                      onClick={() => setIsAddTimeDetails(false)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default AddEntry;
