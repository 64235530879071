import styled from "styled-components";

const WorkHistoryWrapper = styled.div`
  .react-datepicker {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: 0px solid !important;
    display: flow-root !important;
    // min-width: 330px !important;
    position: relative !important;
  }
  .react-datepicker__header {
    padding-top: 15px !important;
    background: rgba(255, 255, 255) !important;
    border-bottom: none !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: rgba(249, 97, 23, 0.86);
  }

  .date-picker-block {
    .roe-card-style {
      height: 100%;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    // margin: 8px !important;
    font-size: 14px !important;
    // font-weight: bold !important;
    outline: none !important;
  }

  .react-datepicker-popper {
    border-radius: 10px !important;
    box-shadow: 0px 0px 15px 1px #0000001a !important;
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__current-month {
    display: none !important;
  }

  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    font-weight: bold;
    font-size: 0.944rem;
    margin-right: 5px;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    border: none;
  }
  .react-datepicker__month-option:hover,
  .react-datepicker__year-option:hover {
    background: rgba(249, 97, 23, 0.86);
  }

  .react-datepicker__month-container {
    width: 270px;
    margin-bottom: 10px
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view {
    display: flex;
    justify-content: center;
    top: -20px !important ;
    position: relative !important;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    position: relative;
    justify-content: center;
    display: flex;
    top: -10px;
    border: none;
    background: white;
    font-weight: bold;
    font-size: 14px;
  }

  .react-datepicker__month-dropdown-container--select {
    border: none;
  }

  .react-datepicker__month-select option:hover,
  .react-datepicker__year-select option:hover {
    background-color: rgba(249, 97, 23, 0.86) !important;
  }

  .custom-datepicker .react-datepicker__month-select option:hover,
  .custom-datepicker .react-datepicker__year-select option:hover {
    background-color: rgba(249, 97, 23, 0.86) !important;
  }

  .react-datepicker__week {
    margin-bottom: 5px;
  }
  .has-dot::before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    top: 35px; /* Adjust the distance from the date */
  }

  .dot-green.has-dot::before {
    background-color: green;
  }

  .dot-ffd54f.has-dot::before {
    background-color: #ffd54f;
  }

  .dot-0000FF.has-dot::before {
    background-color: #0000FF;
  }

  /* Tooltip Styles */
.tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 9999;
  pointer-events: none;
}

.day-wrapper {
  position: relative;
}

.custom-calendar-class {
  position: relative;
}

`;

export default WorkHistoryWrapper;
