import React, { useEffect, useState } from "react";
import moment from "moment";

const Render = (props) => {
  const {
    entry,
    isStartTime,
    selectedDate,
    timeEntriesData,
    setIsStartTime,
    setIsEditing,
    isEditing,
    timerUp,
    setTimerUp,
    totalWeekHour,
    weekTimerUp,
    setWeekTimerUp,
    isTimeDtailsDeleteLoader
  } = props;

  // const [timerUp, setTimerUp] = useState(); 

  useEffect(() => {
    let sum = 0;
    entry &&
      entry.map((item, key) => {
        let m1 = new Date(item.startDate);
        let m2 = new Date(item.endDate);
        let seconds = Math.abs((m2.getTime() - m1.getTime()) / 1000);
        sum = parseInt(sum) + parseInt(seconds);
      });

    let d = Number(
      moment(selectedDate).format("DD-MM-YYYY") ==
        moment(new Date()).format("DD-MM-YYYY")
        ? sum + parseInt(isStartTime)
        : sum
    );

    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "00h ";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "00m ";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "00s";
    setTimerUp(
      hDisplay.includes("00h") && mDisplay.includes("00m")
        ? hDisplay + mDisplay + sDisplay
        : hDisplay + mDisplay
    );

  }, [isStartTime, isTimeDtailsDeleteLoader]);


  useEffect(() => {
    if (
      timeEntriesData &&
      timeEntriesData?.startDate != "" &&
      moment(selectedDate).format("DD-MM-YYYY") ==
      moment(new Date()).format("DD-MM-YYYY") &&
      isEditing == false
    ) {
      const interval = setInterval(() => {
        let sum = 0;
        entry &&
          entry.map((item, key) => {
            let m1 = new Date(item.startDate);
            let m2 = new Date(item.endDate);
            let seconds = Math.abs((m2.getTime() - m1.getTime()) / 1000);
            sum = parseInt(sum) + parseInt(seconds);
          });
        if (
          timeEntriesData &&
          timeEntriesData?.startDate != "" &&
          moment(selectedDate).format("DD-MM-YYYY") ==
          moment(new Date()).format("DD-MM-YYYY")
        ) {
          let startDate = new Date();
          let endDate = new Date(timeEntriesData?.startDate);
          let seconds = -Math.round(
            (endDate.getTime() - startDate.getTime()) / 1000
          );

          let d = Number(
            moment(selectedDate).format("DD-MM-YYYY") ==
              moment(new Date()).format("DD-MM-YYYY")
              ? sum + parseInt(seconds)
              : sum
          );
          setIsStartTime(parseInt(seconds));
          var h = Math.floor(d / 3600);
          var m = Math.floor((d % 3600) / 60);
          var s = Math.floor((d % 3600) % 60);
          var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "00h ";
          var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "00m ";
          var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "00s";
          setTimerUp(hDisplay + mDisplay);
        }
      }, 60000);
      return () => clearInterval(interval);
    }
    else if (
      timeEntriesData &&
      timeEntriesData?.startDate != "" &&
      moment(timeEntriesData?.startDate).format("DD-MM-YYYY") ==
      moment(new Date()).format("DD-MM-YYYY") &&
      isEditing == false
    ) {
      let sum = 0;
      entry &&
        entry.map((item, key) => {
          let m1 = new Date(item.startDate);
          let m2 = new Date(item.endDate);
          let seconds = Math.abs((m2.getTime() - m1.getTime()) / 1000);
          sum = parseInt(sum) + parseInt(seconds);
        });
      if (
        timeEntriesData &&
        timeEntriesData?.startDate != "" &&
        moment(timeEntriesData?.startDate).format("DD-MM-YYYY") ==
        moment(new Date()).format("DD-MM-YYYY")
      ) {
        let startDate = new Date();
        let endDate = new Date(timeEntriesData?.startDate);
        let seconds = -Math.round(
          (endDate.getTime() - startDate.getTime()) / 1000
        );

        let d = Number(
          moment(selectedDate).format("DD-MM-YYYY") ==
            moment(new Date()).format("DD-MM-YYYY")
            ? sum + parseInt(seconds)
            : sum
        );
        setIsStartTime(parseInt(seconds));
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);
        var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "00h ";
        var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "00m ";
        var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "00s";
        setTimerUp(hDisplay + mDisplay);
      }
    } else {
      let sum = 0;
      entry &&
        entry.map((item, key) => {
          let m1 = new Date(item.startDate);
          let m2 = new Date(item.endDate);
          let seconds = Math.abs((m2.getTime() - m1.getTime()) / 1000);
          sum = parseInt(sum) + parseInt(seconds);
        });

      let d = Number(
        moment(selectedDate).format("DD-MM-YYYY") ==
          moment(new Date()).format("DD-MM-YYYY")
          ? sum + parseInt(isStartTime)
          : sum
      );

      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "00h ";
      var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "00m ";
      var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "00s";
      setTimerUp(
        hDisplay.includes("00h") && mDisplay.includes("00m")
          ? hDisplay + mDisplay + sDisplay
          : hDisplay + mDisplay
      );
    }
  });
  return <div>{timerUp}</div>;
};

export default Render;
