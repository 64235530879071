import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect,useParams } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { eyeSlash, eye } from 'helper/constant.jsx';
// service
import { authenticationService } from '../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/img/reactlogo.png';
import { useHistory } from 'react-router-dom';
import LoginWrapper from './LoginWrapper.style';

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.63)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
};

let successToast = successMsg => {
    toast.success(successMsg, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });
};
let errorToast = errMsg => {
    toast.error(errMsg, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });
};

const useStyles = makeStyles(styles);

export default function Login(props) {

    const history= useHistory()
    const [show, setShow] = useState(false);
    const [load, setLoad] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isForgotPassword, setIsForgotPassword] = React.useState(false);
    const classes = useStyles();

   

    useEffect(()=>{
        const getUserEmail = localStorage.getItem('useremail')
        setEmail(getUserEmail)
    },[])
    const handleShowChange = () => {
        setShow(!show);
    };
    const onChangeEmail = event => {
        let email = event.target.value;
        if (email == '') {
            setEmail(email);
            return;
        }
        setEmail(email);
    };
    const onChangePassword = event => {
        let password = event.target.value;
        if (password == '') {
            setPassword(password);
            return;
        }
        setPassword(password);
    };
    const onSubmit = () => {
        try {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            let trimeData = email?.trim();
            if (
                email != '' &&
                password != '' &&
                regex.test(trimeData) == true
            ) {
                setLoad(true);
                return authenticationService
                    .login(email, password)
                    .then(result => {
                        successToast('You are successfully logged in.');
                        setTimeout(() => {
                            window.location.replace('/panel/dashboard');
                            localStorage.removeItem('useremail')
                        }, 200);
                        setLoad(false);
                    })
                    .catch(error => {
                        setLoad(false);
                        if (error == 'TypeError: Failed to fetch') {
                            error = 'Internal server error';
                        }
                        errorToast(error);
                        console.log('error', error);
                    });
            }
            errorToast('Invalid email format!');
        } catch (error) {
            console.log(error);
        }
    };



    return (
        <div align="center">
            <form
                onKeyDown={e => {
                    if (email != '' && password != '')
                        if (e.keyCode === 13) {
                            onSubmit();
                        }
                }}
                tabIndex={0}
            >
                <GridContainer
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <GridItem
                        xs={11}
                        sm={7}
                        md={5}
                        lg={4}
                        style={{ marginTop: 100 }}
                    >
                        <Card>
                            <div style={{ marginTop: '20px' }}>
                                <img src={logo} />
                                <h4
                                    style={{
                                        fontWeight: 'bold',
                                        margin: -20,
                                        marginBottom: 10,
                                    }}
                                >
                                    <span style={{ color: '#f4590d' }}>
                                        <br />
                                        Daily Work Report
                                    </span>
                                </h4>
                            </div>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Email"
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            onChange={onChangeEmail}
                                            value={email}
                                            type={'text'}
                                            autocomplete="username email"
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div
                                            className="form-group"
                                            style={{
                                                marginBottom: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <LoginWrapper>
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true, 
                                                }}
                                                onChange={onChangePassword}
                                                type={
                                                    show ? 'text' : 'password'
                                                }
                                                autocomplete="new-password" 
                                            />
                                            <img
                                                src={show ? eyeSlash : eye}
                                                width={20}
                                                height={20}
                                                alt="show password"
                                                style={{
                                                    position: 'absolute',
                                                    top: 35,
                                                    right: 2,
                                                }}
                                                onClick={handleShowChange}
                                            />
                                            </LoginWrapper>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer
                                    align="left"
                                    style={{ paddingBottom: 0 }}
                                >
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{ paddingBottom: 0 }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                    color: '#f4590d',
                                                    fontWeight: 500,
                                                    cursor: 'pointer',
                                                }}
                                                className="text-center link-label-1"
                                                onClick={() => {
                                                    setIsForgotPassword(
                                                        !isForgotPassword
                                                    );
                                                }}
                                            >
                                                Forgot Password?
                                            </span>
                                            {isForgotPassword ? (
                                                <Redirect to="/forgotPassword" />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter align="center">
                                {load
                                 ? <div style={{   
                                    width: '100%',
                                    margin: '15px',
                                    marginBottom: '50px',
                                    fontWeight: '800',
                                    fontSize: '16px',
                                    background: '#f96117db',
                                    textTransform: 'capitalize',
                                    borderRadius: '50px',
                                    height:47,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center"
                                }}>
                                      <CircularProgress  size={30} style={{color: 'white' }}
                                    />
                                  </div>
                                : (
                                    <Button
                                        color="info"
                                        disabled={
                                            email != '' && password != ''
                                                ? false
                                                : true
                                        }
                                        onClick={onSubmit}
                                        style={{
                                            width: '100%',
                                            margin: '15px',
                                            marginBottom: '50px',
                                            fontWeight: '800',
                                            fontSize: '16px',
                                            background: '#f96117db',
                                            textTransform: 'capitalize',
                                            borderRadius: '50px',
                                        }}
                                    >
                                        Login
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            style={{ marginLeft: '10px' }}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M13.3 17.275q-.3-.3-.288-.725t.313-.725L16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.3-.713t.3-.712q.3-.3.713-.3t.712.3L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.6 4.6q-.275.275-.687.275t-.713-.3Z"
                                            />
                                        </svg>
                                    </Button>
                                )}
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
            <ToastContainer />
        </div>
    );
}
