
/* eslint-disable */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { authenticationService } from "services/authentication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "assets/img/reactlogo.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);

let successToast = (successMsg) => {
  toast.success(successMsg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
let errorToast = (errMsg) => {
  toast.error(errMsg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

const ForgotPassword = () => {
  const classes = useStyles();
  const [isLoader, setIsLoader] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState(false);

  const onChangeEmail = (e) => {
    let email = e.target.value;
    if (email == "") {
      setEmail(email);
      setEmailErr(true);
      return;
    }
    setEmailErr(false);
    setEmail(email);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (email != "" && regex.test(email) == true) {
        setIsLoader(true);
        const Response = await authenticationService.forgotPassword(email);
        if (Response.status == 404) {
          setIsLoader(false);
          errorToast(Response.response);
          // setEmail("")
          setIsLoader(false);
        } else {
          successToast(
            "We sent an email with instructions to reset your password!"
          );
          setEmail("");
          setIsLoader(false);
        }
      }
    } catch (e) {
      console.log(e);
      setIsLoader(false);
    }
  };
  return (
    <div align="center">
      <form
        onKeyDown={(e) => {
          if (email != "") {
            if (e.keyCode === 13) {
              handleSubmit(e);
            }
          }
        }}

        tabIndex={0}
      >
        <GridContainer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GridItem xs={11} sm={11} md={3} lg={3} style={{ marginTop: 100 }}>
            <Card>
              <div style={{ marginTop: "20px" }}>
                <img src={logo} />
                <h4 style={{ fontWeight: "bold", margin: 0 }}>
                  <span style={{ color: "#f4590d" }}>
                    <br />
                    Forgot Password?
                  </span>
                </h4>
              </div>
              <CardBody>
                <div className="">
                  <div className="form-container">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 16,
                        color: "#000000",
                        fontWeight: "600",
                        opacity: "0.6",
                        marginTop: 15,
                      }}
                    >
                      No worries, we'll send you reset instructions.
                    </div>

                    <form className="plr-60 ptb-80">
                      <div
                        className="form-group"
                        style={{
                          marginBottom: "40px",
                          margin: "0px 40px",
                        }}
                      >
                        <CustomInput
                          style={{ height: "40px" }}
                          type="email"
                          className="form-control react-form-input"
                          id="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          labelText="Email"
                          value={email ? email : ""}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(e) => onChangeEmail(e)}
                        />
                      </div>
                      {
                        isLoader
                          // true
                          ? (
                            <div
                              style={{
                                borderRadius: "50px",
                                width: "70%",
                                height: "40px",
                                fontSize: "14px",
                                color: "white",
                                fontWeight: "600",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "800",
                                fontSize: "16px",
                                background: "#f96117db",
                                textTransform: "capitalize",
                                marginTop: "20px",
                                marginBottom: 5
                              }}
                            >
                              <CircularProgress
                                style={{
                                  height: 30,
                                  width: 30,
                                  color: 'white'
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <Button
                                onClick={(e) => handleSubmit(e)}
                                disabled={email != "" ? false : true}
                                color="info"
                                className="btn form-button"
                                style={{
                                  borderRadius: "50px",
                                  width: "70%",
                                  height: "40px",
                                  fontSize: "14px",
                                  color: "white",
                                  fontWeight: "600",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "800",
                                  fontSize: "16px",
                                  background: "#f96117db",
                                  textTransform: "capitalize",
                                }}
                              >
                                Submit{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M13.3 17.275q-.3-.3-.288-.725t.313-.725L16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.3-.713t.3-.712q.3-.3.713-.3t.712.3L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.6 4.6q-.275.275-.687.275t-.713-.3Z"
                                  />
                                </svg>
                              </Button>

                              <div style={{ marginTop: 20 }}>
                                <Link to="/login">
                                  Go back to login
                                </Link>
                              </div>
                            </div>
                          )}
                    </form>
                  </div>
                </div>
              </CardBody>
              <CardFooter align="center"></CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <ToastContainer />
      </form>
    </div>
  );
};

export default ForgotPassword;
