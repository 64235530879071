import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import Button from "components/CustomButtons/Button.js";
import { notify } from "react-notify-toast";
import Select from "react-select";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { authenticationService } from "services/authentication";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Avatar, } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import UseOutsideAlerter from "components/CustomInputDropDown/OnBlurHooks.jsx";

import { close, logout, profile, setting, timer, projectRequest } from "helper/constant.jsx";
import moment from "moment";

const useStyles = makeStyles(styles);
const colourStyles = {
  control: (base, state) => ({
    ...base,
    zIndex: 9999,
    background: "#ffffff",
    color: "#000000",
    fontWeight: "bold",
    padding: 20,
    // match with the menu
    fontSize: 16,
    fontWeight: 400,
    cursor: "pointer",
    padding: "0px 8px",
    // border: 'none',
    // outline: 'none',
    // Overwrittes the different states of border
    borderColor: "#0000003b",
    // Removes weird border around container
    boxShadow: null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "#0000003b",
    },
  }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      fontWeight: "400",
      color: isDisabled
        ? "black"
        : isSelected
          ? "white"
            ? "white"
            : "black"
          : "black",
      cursor: "pointer",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#f96117db"
          : isFocused
            ? "#f961173d"
            : undefined,
      ":hover": {
        backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
      },
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
      },
    };
  },
};
let successToast = (successMsg) => {
  toast.success(successMsg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

let errorToast = (errMsg) => {
  toast.error(errMsg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export default function AdminNavbarLinks(props) {
  const { userDetails, isTimeFormat, setIsTimeFormat } = props;
  const classes = useStyles();
  const wrapperRef = useRef(null);
  // const [isTimeFormateChecked, setIsTimeFormateChecked] = React.useState("24");
  // const [timeFormat, setTimeFormat] = useState();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  UseOutsideAlerter(wrapperRef, setIsDropDownOpen, isDropDownOpen);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
      setIsDropDownOpen(true);
      // setIsTimeFormateChecked(
      //   // localStorage.getItem("isTimeFormat") == "24" ? "24" : "12"
      //   userDetails?.timeFormat
      // ); 
      setIsTimeFormat(
        localStorage.getItem("isTimeFormat") == "24" ? "24" : "12"
      );
    }
  };
  const handleCloseProfile = async () => {
    setOpenProfile(null);
    await authenticationService.logout();
    successToast("Logged out successful!");
    setTimeout(() => {
      window.location.replace("/");
    }, 500);
  };
  const handleProfileClick = async () => {
    setOpenProfile(null);
    setIsDropDownOpen(false);
  };

  const handleTimeFormateChange = async (e) => {
    try {
      if (e.value != localStorage.getItem("isTimeFormat")) {
        const Response = await authenticationService.updateTimeFormat();
        if (Response.error) {
          console.log(Response.error);
          errorToast(Response.error);
        } else {
          // setIsTimeFormateChecked(
          //   // Response && Response?.data?.timeFormat == "24" ? false : true
          //   e.value
          // );    
          setIsTimeFormat(
            e.value
          );
          localStorage.setItem(
            "isTimeFormat",
            e.value
            // Response && Response?.data?.timeFormat == "12" ? 12 : 24
          );
          successToast("Time format updated");
        }
      }
    } catch (error) {
      console.log(e);
    }
  };

  const selectTimeFormat = [
    { value: "24", label: "24 hours" },
    { value: "12", label: "12 hours" },
  ];

  // const currentDate = new Date();

  let currentDateMinutes = moment(new Date()).format('HH');
  let currentDateSeconds = moment(new Date()).format('mm');

  let currentDateMinutesFormat = moment(new Date()).format('hh');
  let currentDateSecondsFormat = moment(new Date()).format('mm A');

  return (
    <div>
      <div className={classes.manager}>
        <Button
          disableRipple
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={(e) => handleClickProfile(e)}
          className={classes.buttonLink}
          style={{
            display: window.innerWidth > 959 ? "" : "none",
            marginRight: "20px",
            borderRadius: "50%",
          }}
        >
          {userDetails?.userMeta?.location ? (
            <div>
              <img
                className={classes.icons}
                src={userDetails?.userMeta?.location}
                alt="..."
                style={{
                  width: "28px",
                  height: "28px",
                  objectFit: "cover",
                  borderRadius: "50%",
                  margin: "10px 0px 0px 0px",
                }}
              />
            </div>
          ) : (
            <>
              <div className="avatar-hover">
                <Avatar
                  style={{
                    background: "#f4590d",
                    color: "white",
                    fontWeight: "bold",
                    display: window.innerWidth > 959 ? "" : "none",
                    height: 30,
                    width: 30,
                    fontSize: 14,
                  }}
                >
                  {userDetails?.name
                    ? userDetails?.name?.includes(" ")
                      ? userDetails?.name?.split(" ")[0].charAt(0) +
                      userDetails?.name?.split(" ")[1].charAt(0)
                      : userDetails?.name?.charAt(0)
                    : null}
                </Avatar>
              </div>
            </>
          )}
          <Hidden mdUp implementation="css">
            <p
              className={classes.linkText}
              style={{ color: "#000", display: "none" }}
            >
              Profile
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={isDropDownOpen}
          anchorEl={openProfile}
          transition
          disablePortal
          style={{ width: "260px" }}
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                boxShadow: " 0px 0px 20px 0px rgba(179,179,179,1)",
                borderRadius: 8,
              }}
            >
              <Paper ref={wrapperRef}>
                <ClickAwayListener
                  onClickAway={() => {
                    if (isDropDownOpen == false) {
                      setOpenProfile(null);
                    }
                  }}
                >
                  <MenuList role="menu">
                    {/* <MenuItem
                                            style={{
                                                color: "#000",
                                                padding: "10px 16px"
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    background: "#f4590d",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    marginRight: 10,
                                                    textTransform: "uppercase",
                                                    display: window.innerWidth > 959 ? "" : "none",
                                                }}
                                            >
                                                {userDetails?.name
                                                    ? userDetails?.name?.includes(" ")
                                                        ? userDetails?.name?.split(" ")[0].charAt(0) +
                                                        userDetails?.name?.split(" ")[1].charAt(0)
                                                        : userDetails?.name?.charAt(0)
                                                    : null}
                                            </Avatar>
                                            {userDetails && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        alignItems: "start",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <h6
                                                        style={{
                                                            color: "#000",
                                                            margin: "0px",
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {userDetails?.name}
                                                    </h6>
                                                    <p
                                                        style={{
                                                            margin: "0px",
                                                            fontSize: 14,
                                                            color: "#637381",
                                                        }}
                                                    >
                                                        {userDetails?.email}
                                                    </p>
                                                </div>
                                            )}
                                        </MenuItem>
                                        <hr
                                            style={{
                                                background: "#e3e3e3",
                                                height: "2px",
                                                margin: "0px",
                                                border: "none",
                                            }}
                                        /> */}
                    <Link to="/panel/profile">
                      <MenuItem
                        disableRipple
                        onClick={handleProfileClick}
                        style={{
                          padding: "11px 16px",
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <img
                          src={profile}
                          style={{
                            fill: "red",
                            marginRight: 10,
                            marginTop: 2,
                          }}
                          width={22}
                          height={22}
                        />
                        <div>
                          <span style={{ color: "#000", fontSize: 14 }}>
                            Profile
                          </span>
                          <br></br>
                          <p
                            style={{
                              color: "#a8a8a8",
                              margin: "0px",
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            Signed in as {userDetails?.name}
                          </p>
                        </div>
                      </MenuItem>
                    </Link>
                    <hr
                      style={{
                        background: "#e3e3e3",
                        height: "1px",
                        margin: "0px 10px",
                        border: "none",
                      }}
                    />

                    <div
                      disableRipple
                      style={{
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "15px 16px",
                      }}
                    >
                      <div
                        style={{
                          color: "#000",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={timer}
                          style={{
                            fill: "red",
                            marginRight: 10,
                          }}
                          width={22}
                          height={22}
                        />
                        <span style={{ fontSize: 14 }}>Time format</span>{" "}
                      </div>

                      <span>
                        {/* {moment(currentDate).format('hh:mm')} */}
                        {/* {userDetails?.timeFormat == "24" || isTimeFormateChecked == true ? moment(currentDate).format('hh:mm') : moment(currentDate).format('LT')} */}
                        {/* {isTimeFormateChecked == 24 ? `${currentDateMinutes}  {<span> : </span>} ${currentDateSeconds}` : moment(currentDateSeconds).format('LT')} */}
                        {isTimeFormat == "24" ? (<div style={{ display: "flex", fontSize: "14px", color: "#565656" }}>
                          <div>{currentDateMinutes}</div>
                          <div className="time-format">:</div>
                          <div style={{ marginLeft: "1px" }}>{currentDateSeconds}</div>
                        </div>) : (<div style={{ display: "flex", fontSize: "14px", color: "#565656" }}>
                          <div style={{ marginRight: "0px" }}>{currentDateMinutesFormat}</div>
                          <div className="time-format">:</div>
                          <div style={{ marginLeft: "1px" }}>{currentDateSecondsFormat}</div>
                        </div>)}
                      </span>

                    </div>

                    <div
                      disableRipple
                      style={{
                        color: "#000",
                        // display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "15px 16px",
                        marginTop: "-1rem"
                      }}
                    >
                      <span>
                        {/* <select
                          name=""
                          id="mySelect"
                          value={isTimeFormateChecked == true ? 12 : 24}
                          style={{
                            padding: "8px 13px",
                            borderRadius: 5,
                            borderColor: "#d0d0d0",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onChange={(e) => {
                            handleTimeFormateChange(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          <option value="24">24 hours</option> 
                          <option value="12">12 hours</option> 
                        </select> */}
                        {/* {isTimeFormateChecked == true ?
                          <label class="switch">
                            <input type="checkbox" className="checkbox" checked={true} onClick={(e) => {
                              console.log(e.target.checked, "e");
                              handleTimeFormateChange(e.target.value);
                            }} />
                            <span class="toggle-thumb">
                              <span style={{ marginLeft: 10, color: "black" }}>12</span>
                              <span style={{ marginRight: 10, color: "black" }}>24</span>
                            </span>
                          </label> :

                          <label class="switch">
                            <input type="checkbox" className="checkbox" checked={false} onClick={(e) => {
                              console.log(e.target.checked, "e");
                              handleTimeFormateChange(e.target.value);
                            }} />
                            <span class="toggle-thumb">
                              <span style={{ marginLeft: 10, color: "black" }}>12</span>
                              <span style={{ marginRight: 10, color: "black" }}>24</span>
                            </span>
                          </label>
                        } */}

                        {/* <Select
                          // value={selectTimeFormat}
                          options={selectTimeFormat}
                        // onChange={handleChangeTableData}
                        // defaultValue={selectTimeFormat[0]}
                        /> */}

                        <Select
                          menuPosition={'fixed'}
                          menuPortalTarget={document.body}
                          styles={colourStyles}
                          options={selectTimeFormat}
                          value={isTimeFormat == "24" ? selectTimeFormat[0] : selectTimeFormat[1]}
                          onChange={(e) => handleTimeFormateChange(e)}
                        />

                      </span>

                    </div>


                    <hr
                      style={{
                        background: "#e3e3e3",
                        height: "1px",
                        margin: "0px 10px",
                        border: "none",
                      }}
                    />

                    <Link to="/panel/projectRequests">
                      <MenuItem
                        disableRipple
                        onClick={handleProfileClick}
                        style={{
                          padding: "11px 16px",
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <img
                          src={projectRequest}
                          style={{
                            fill: "red",
                            marginRight: 10,
                            marginTop: 2,
                          }}
                          width={22}
                          height={22}
                        />
                        <div>
                          <span style={{ color: "#000", fontSize: 14 }}>
                            My Project Request History
                          </span>
                        </div>
                      </MenuItem>
                    </Link>


                    <MenuItem
                      disableRipple
                      onClick={handleCloseProfile}
                      style={{ color: "#ff2e2e", padding: "13px 16px" }}
                    >
                      <img
                        src={logout}
                        style={{
                          color: "#ff2e2e",
                          margin: "0px 10px 0px 2px",
                        }}
                        width={22}
                        height={22}
                      />
                      <span style={{ color: "#ff2e2e", fontSize: 14 }}>
                        Logout
                      </span>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
