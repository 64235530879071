
/* eslint-disable */

import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory, create } from "react-router-dom";
import { createBrowserHistory } from "history";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/CustomButtons/Button.js";
import MaterialTable from "material-table";
import { authenticationService } from "../../services/authentication";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { Box, Chip, Modal, TextField, Tooltip } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import TimeTracker from "components/TimeTracker/TimeTracker";
import TableShimmer from "components/Shimmer/Shimmer";
import TotalShimmer from "components/Shimmer/TotalShimmer";
import Render from "./Render";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEntry from "components/TimeTracker/AddEntry";
import DatePickerWrapper from "../../assets/css/datepicker.style";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import TodayIcon from "@material-ui/icons/Today";
import { publish } from "helper/events/events";
import DashboardWrapper from "./DashboardWrapper.style";
import '../../assets/css/jiraWorkLogDatePicker.css'

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';


const styles = {
  typo: {
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const storageKeys = {
  lastAccessTime: "lastAccessTime",
};

export default function Dashboard(props) {
  const TimeFormat = useContext(props?.TimeFormatContext);
  const history = useHistory();

  const childRef = useRef();
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedProjectDate, setSelectedProjectDate] = React.useState(new Date());
  const [timeEntries, setTimeEntries] = React.useState([]);
  const [extraNote, setExtraNote] = React.useState("");
  const [extraNoteErr, setExtraNoteErr] = React.useState(false);
  const [entry, setEntry] = React.useState([]);
  const [timeEntriesId, setTimeEntriesId] = React.useState([]);
  const [timeEntriesData, setTimeEntriesData] = React.useState();
  const [isActive, setIsActive] = useState(false);
  const [isStartTime, setIsStartTime] = useState(0);
  const [isEditTime, setIsEditTime] = useState(false);
  const [isTimeDetailsDelete, setIsTimeDetailsDelete] = useState();
  const [isTimeDtailsDeleteLoader, setIsTimeDtailsDeleteLoader] = useState(
    false
  );
  const [isSaveAsLoader, setIsSaveAsLoader] = useState(false);
  const [isSendMailLoader, setIsSendMailLoader] = useState(false);
  const [isTimeData, setIsTimeData] = useState(false);
  const [isTotalData, setIsTotalData] = useState(false);
  const [reportDescription, setReportDescription] = useState([]);
  const [newProjectList, setNewProjectList] = useState([]);
  const [newLastFiveTimeEntryList, setNewLastFiveTimeEntryList] = useState([]);
  const [newJiraTimeEntryList, setNewJiraTimeEntryList] = useState([]);
  const [showJiraTickets, setShowJiraTickets] = useState(false);
  const [isTimeVisible, setIsTimeVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isAddTimeDetails, setIsAddTimeDetails] = React.useState(false);
  const [isTimeFormateChecked, setIsTimeFormateChecked] = React.useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isFetchLatestJiraTimeEntries, setIsFetchLatestJiraTimeEntries] = useState(false);
  const [timerUp, setTimerUp] = useState();
  const [dailyReportId, setDailyReportId] = useState();
  const [submittedReportDate, SetSubmittedReportDate] = useState([]);
  // version ===============
  const [versionOption, setVersionOption] = useState([])
  const [selectedVesrion, setSelectedVesrion] = useState()
  // const [weekTimerUp, setWeekTimerUp] = useState();
  // const [totalWeekHour, setTotalWeekHour] = useState('');

  const [holidayDetails, setHolidayDetails] = useState([]);
  const [formattedHolidayDates,setFormattedHolidayDates] = useState([]);

  const [hoveredDate, setHoveredDate] = useState(null);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRowData, setEditRowData] = useState({
    remark: ""
  });
  const [workLogTitle, setWorkLogTitle] = useState({
    title: ""
  })
  const [timeSpentDetails, setTimeSpentDetails] = useState();

  const [selectedWorkLogTime, setSelectedWorkLogTime] = useState(null);

  const [timeEntriesResult, setTimeEntriesResult] = useState(null);

  const [isJiraWorkLogSubmitted,setIsJiraWorkLogSubmitted] = useState(null);

  useEffect(() => {

    // getAllHolidayDetails();

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  

  useEffect(() => {
    const dateString = new URLSearchParams(history.location.search).get("date");
    const currentDate = new Date();

    try {
      if (dateString) {
        setSelectedDate(
          moment(dateString)
            .startOf("day")
            .toDate()
        );
        setSelectedProjectDate(
          moment(dateString)
            .startOf("day")
            .toDate()
        );
        handleDateChange(
          moment(dateString)
            .startOf("day")
            .toDate()
        );
        generateReportByDate(
          moment(dateString)
            .startOf("day")
            .toDate()
        );
        getNotStopEntryDetails();
        handleProjectChange()
        setIsTimeFormateChecked(TimeFormat?.isTimeFormat);

        if (parseInt(TimeFormat?.userDetails?.userId) === 237 || parseInt(TimeFormat?.userDetails?.userId) === 219) {
          doHandleFetchJirasLastFiveEntry()
        } else {
          doHandleFetchLastFiveEntry();
        }

      } else {
        // Set selectedDate to the current date
        generateReportByDate(currentDate);
        getNotStopEntryDetails();

        handleProjectChange()

        setIsTimeFormateChecked(TimeFormat?.isTimeFormat);

        if (parseInt(TimeFormat?.userDetails?.userId) === 237 || parseInt(TimeFormat?.userDetails?.userId) === 219) {
          doHandleFetchJirasLastFiveEntry()
        } else {
          doHandleFetchLastFiveEntry();
        }
        isTimerVisible(moment(currentDate).format("DD-MM-YYYY"));
        setSelectedDate(currentDate);
      }
    } catch (error) {
      console.error("Error in fetchData:", error);
    }

    // Remove date query parameter when page is refreshed
    const clearDateQueryParam = () => {
      const url = new URL(window.location.href);
      url.searchParams.delete("date");
      history.replace(url.pathname + url.search);
    };

    clearDateQueryParam();

    return () => {
      setIsEditing(false);
    };
  }, [history.location.search, history.replace]);

  useEffect(() => {
    if (parseInt(TimeFormat?.userDetails?.userId) == 237 || parseInt(TimeFormat?.userDetails?.userId) == 219) {
      doHandleFetchJirasLastFiveEntry();
    }
  }, [TimeFormat?.userDetails?.userId]);


  useEffect(() => {
    setIsTimeFormateChecked(TimeFormat?.isTimeFormat);
  }, [TimeFormat?.isTimeFormat]);

  // props.rowData != "" &&
  // isTimeFormateChecked == 24
  useEffect(() => {
    if (
      timeEntriesData &&
      timeEntriesData?.startDate != "" &&
      moment(selectedDate).format("DD-MM-YYYY") ==
      moment(new Date()).format("DD-MM-YYYY")
    ) {
      let startDate = new Date();
      let endDate = new Date(timeEntriesData?.startDate);
      let seconds = -Math.round(
        (endDate.getTime() - startDate.getTime()) / 1000
      );
      if (timeEntriesData && timeEntriesData?.startDate != "") {
        setIsStartTime(parseInt(seconds));
      } else {
        setIsStartTime();
      }
    }
  }, [isEditTime]);

  const handleOpen = () => setOpen(true);
  const handleCloses = () => setOpen(false);
  const isNumber = (e) => /^[0-9. :]+$/.test(e);

  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  let errorToast = (errMsg) => {
    toast.error(errMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const colourStyles = {
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "5px",
        height: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f4590d",
      },
    }),
    control: (base, state) => ({
      ...base,
      zIndex: state.isFocused ? "999" : "1",
      background: "#ffffff",
      // background: '#efefef',
      color: "#000000",
      fontWeight: "bold",
      padding: 20,
      // match with the menu
      height: 40,
      minHeight: 35,
      fontSize: 16,
      fontWeight: 400,
      cursor: "pointer",
      padding: "0px 8px",
      // width : "20rem",
      // border: 'none',
      // outline: 'none',
      // Overwrittes the different states of border
      borderColor: "#0000003b",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#0000003b",
      },
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontWeight: "400",
        color: isDisabled
          ? "black"
          : isSelected
            ? "white"
              ? "white"
              : "black"
            : "black",
        cursor: "pointer",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#f96117db"
            : isFocused
              ? "#f961173d"
              : undefined,
        ":hover": {
          backgroundColor: isSelected ? "#f96117db" : "#f961173d",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
        },
      };
    },
  };


  const onFocus = (e) => {
    let lastAccessTime = localStorage.getItem(storageKeys.lastAccessTime);
    if (lastAccessTime) {
      if (moment().diff(lastAccessTime, "hours") >= 6) {
        window.location.reload();
        localStorage.setItem(storageKeys.lastAccessTime, "");
      }
    }
  };



  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    localStorage.setItem(storageKeys.lastAccessTime, new Date());
  };

  const softDeleteTimeEntryData = async (timeId) => {
    try {
      if (timeId && timeId) {
        setIsTimeDtailsDeleteLoader(true);
        const Response = await authenticationService.deleteSoftTimeEntry(
          timeId
        );
        if (Response.error) {
          setIsTimeDtailsDeleteLoader(false);
          errorToast(`${Response.error}`);
          console.log(Response.error);
          handleCloses();
        } else {
          if (Response.data.code == 404) {
            errorToast(`Time Entry Already Deleted`);
            setIsTimeDtailsDeleteLoader(false);
            setIsTimeDetailsDelete();
            handleCloses();
            setEntry(Response?.data?.timeEntries?.timeEntries);
          } else {
            setEntry(
              entry.filter((item, key) => {
                return Response.data.id != item.id;
              })
            );
            setIsTimeDtailsDeleteLoader(false);
            setIsTimeDetailsDelete();
            successToast("Time entry deleted successfully");
            handleCloses();
            setTimeout(() => {
              publish("LateArrivalEvent");
            }, 200);
          }
        }
      }
    } catch { }
  };

  const getNotStopEntryDetails = async () => {
    try {
      const Response = await authenticationService.timeEntryNotStopTimeDetails();
      if (Response) {
        // setTotalWeekHour(Response?.weekHours)
        Response.records.map((finalVal) => {
          if (finalVal.endDate == null) {
            setTimeEntriesData(finalVal);
            let startDate = new Date();
            let endDate = new Date(finalVal.startDate);
            let seconds = -Math.round(
              (endDate.getTime() - startDate.getTime()) / 1000
            );
            setIsStartTime(seconds);
          }
        });
        SetSubmittedReportDate(Response.submittedReportDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllHolidayDetails = async () => {
    try {
      const Response = await authenticationService.getHolidayList();

      if (Response && Response?.data) {

        setHolidayDetails(Response?.data)


          const dates = Response?.data.map((data) => {
            const date = new Date(data.holidayDate);
            return moment(date).format("YYYY-MM-DD");
          });
          
          setFormattedHolidayDates(dates);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const doHandleFetchLastFiveEntry = async (e) => {
    try {
      const Response = await authenticationService.fetchLastFiveEntry();
      if (Response.error) {
        console.log(Response.error);
      } else {
        setNewLastFiveTimeEntryList(
          Response?.data
            ? Response?.data.map((item, key) => {
              return {
                id: item?.id,
                label: item?.title,
                value: item?.project?.id,
                projectCode:
                  item?.project?.projectCode != null &&
                  `${item?.project?.projectCode}  • `,
                projectName:
                  item?.project?.projectName && item?.project?.projectName,
              };
            })
            : []
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const doHandleFetchJirasLastFiveEntry = async (e) => {
    try {
      const Response = await authenticationService.fetchJirasLastFiveEntry();
      if (Response.error) {
        console.log(Response.error);
        setShowJiraTickets(false)
      } else {
        setNewJiraTimeEntryList(Response?.data && Response?.data.map((item) => {
          return (
            {
              ...item,
              label: `${item?.ticketNo} - ${item?.summary}`,
              value: 327,
              projectCode: "3006",
              projectName: "Knit"
            }
          )
        }))
        setShowJiraTickets(true)
      }
    } catch (error) {
      setShowJiraTickets(false)
      console.log(error);
    }
  };

  const doHandleClickOnFetchLatestTimeEntries = async (e) => {
    try {
      setIsFetchLatestJiraTimeEntries(true);
      const Response = await authenticationService.fetchLatestTimeEntries();
      if (Response.error) {
        console.log(Response.error);
        setIsFetchLatestJiraTimeEntries(false);
      } else {
        if (Response?.statusCode == 200) {
          doHandleFetchJirasLastFiveEntry();
          setIsFetchLatestJiraTimeEntries(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsFetchLatestJiraTimeEntries(false);
    }
  };

  const handleProjectChange = async () => {
    try {
      const Response = await authenticationService.fetchProjectList();

      if (Response && Response.data) {
        let doGetProjectData = [];

        const groups = Response?.data?.reduce((groups, game) => {
          const groupsName = game?.groups?.groupName;
          if (!groups[groupsName]) {
            groups[groupsName] = [];
          }
          groups[groupsName].push(game);
          return groups;
        }, {});
        const groupArrays = Object.entries(groups)
          .sort(function (a, b) {
            const nameA = a[0].toUpperCase();
            const nameB = b[0].toUpperCase();
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }
            return 0;
          })
          .map((groupName) => {
            let data = {
              label: groupName[0],
              options: groupName[1]
                .sort(function (a, b) {
                  const nameA = a.projectName.toUpperCase();
                  const nameB = b.projectName.toUpperCase();
                  if (nameA > nameB) {
                    return 1;
                  }
                  if (nameA < nameB) {
                    return -1;
                  }
                  return 0;
                })
                .map((item, key) => {
                  return {
                    id: item.id,
                    name: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    label: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    value: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                  };
                }),
            };
            doGetProjectData.push(data);
          });
        setNewProjectList(doGetProjectData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = async (date) => {
    setIsTimeData(true);
    setIsTotalData(true);
    setSelectedDate(date);
    setSelectedProjectDate(date);

    // Update URL without adding a new history entry
    // history.replace({
    //   pathname: history.location.pathname,
    //   search: date ? `?date=${moment(date).format("YYYY-MM-DD")}` : "",
    // });

    // let response = authenticationService.singleTimeEntryDetails(date.toLocaleDateString('en-CA'))
    let pickedDate = moment(date).format("LL");
    let today = moment(new Date()).format("LL");
    if (pickedDate == today) {
      generateDWR(date);
      setIsAddTimeDetails(false);
    } else {
      let result = await authenticationService.singleTimeEntryDetails(
        moment(date).format("DD-MM-YYYY")
      );

      setTimeEntriesResult(result);

      setDailyReportId(result?.dailyReport?.id);
      // setReportDescription(result[0]?.DailyReport?.description);
      setReportDescription(
        result?.dailyReport?.description
          ? result?.dailyReport?.description
          : setExtraNote("")
      );
      // setEntry(
      //   result.timeEntries.filter((finalVal) => {
      //     return finalVal.endDate != null;
      //   })
      // );

      setEntry(
        result?.timeEntries
          .filter((entry) => entry.endDate !== null)
          .map((entry) => {
            const hasMatchingTicket = result?.jiraTaskWorkLog.some((log) =>
              entry.title.includes(log.ticketNo)
            );
            return { ...entry, isJiraWorkReportSubmitted: hasMatchingTicket }; // Append the flag key
          })
      );

      result.timeEntries.map((finalVal) => {
        if (finalVal.endDate == null) {
          setTimeEntriesData(finalVal);
          let startDate = new Date();
          let endDate = new Date(finalVal.startDate);
          let seconds = -Math.round(
            (endDate.getTime() - startDate.getTime()) / 1000
          );
          setIsStartTime(`${seconds}`);
        }
      });
      // setEntry([...result]);
      setIsTimeData(false);
      setIsTotalData(false);
    }
    setSelectedDate(date);
    setSelectedProjectDate(date);
    isTimerVisible();
  };

  const handleOnSubmitManagement = async () => {
    let remarkData = false;
    let timeEntriesRemarkArr = [];

    for (let i = 0; i < entry.length; i++) {
      let remark_length = entry[i].remark.length;
      if (remark_length == 0) {
        timeEntriesRemarkArr.push({
          remark1: remark_length,
        });
      }
    }
    if (timeEntriesRemarkArr.length == 0) {
      remarkData = true;
    } else {
      remarkData = false;
    }
    let reportId = dailyReportId ? dailyReportId : null;

    try {
      if (remarkData == true) {
        setIsSendMailLoader(true);
        const response = await authenticationService.sendDailyReportData(
          { description: extraNote },
          reportId
        );
        if (response.emailStatus == false && response.failedStatus == true) {
          console.log(response.error);
          setIsSendMailLoader(false);
          errorToast("Something Went to Wrong!");
        } else {
          successToast("Your work report successfully sent! Thank you!");
          if (response.data) {
            setIsSendMailLoader(false);
            if (timeEntriesData != undefined || timeEntriesData != null) {
              getNotStopEntryDetails();
              generateReportByDate(new Date());
              // if (isStartTime == 0) {
              //   handleProjectChange(moment(selectedDate).format("YYYY-MM-DD"));
              // }
              // else {
              //   handleProjectChange(moment(new Date()).format("YYYY-MM-DD"));
              // }
              handleProjectChange()
              doHandleFetchLastFiveEntry();
            } else {
              history.push(`/panel/history`);
            }
          }
        }
      } else {
        errorToast("All task note need to be provided");
      }
    } catch (e) {
      console.log(e, "error");
      setIsSendMailLoader(false);
    }
  };

  const onSaveAsDraft = async (timeEntriesId, type) => {
    try {
      setIsSaveAsLoader(true);
      const response = await authenticationService.updateMultipalTimeDetails({
        dailyreport: {
          id: dailyReportId ? dailyReportId : null,
          description: extraNote,
        },
        date: moment(selectedDate).format("DD-MM-YYYY"),
      });
      if (response.error) {
        console.log(response.error);
        setIsSaveAsLoader(false);
      } else {
        setDailyReportId(response.data.id);
        successToast("Report Saved!");
        // setSelectedDate(new Date());
        setExtraNote(extraNote);
        setTimeEntries([]);
        setIsSaveAsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setIsSaveAsLoader(false);
    }
  };

  const onChangeExtraNote = (data) => {
    let extraNote = data;
    if (extraNote.length > 5000) {
      setExtraNoteErr(true);
      setExtraNote(extraNote);
      return;
    }
    setExtraNoteErr(false);
    setExtraNote(extraNote);
  };

  const prevClick = () => {
    var date = selectedDate;
    date.setDate(date.getDate() - 1);
    handleDateChange(date);
    // if (isStartTime == 0) {
    //   handleProjectChange(moment(selectedDate).format("YYYY-MM-DD"));
    // }
    // else {
    //   handleProjectChange(moment(new Date()).format("YYYY-MM-DD"));
    // }
    handleProjectChange()
  };

  const nextClick = () => {
    if (
      moment(selectedDate).format("DD-MMM-YYYY") !==
      moment(new Date()).format("DD-MMM-YYYY")
    ) {
      var date = selectedDate;
      date.setDate(date.getDate() + 1);
      handleDateChange(date);
      // if (isStartTime == 0) {
      //   handleProjectChange(moment(selectedDate).format("YYYY-MM-DD"));
      // }
      // else {
      //   handleProjectChange(moment(new Date()).format("YYYY-MM-DD"));
      // }
      handleProjectChange()
    }
  };
  const ExampleCustomInput = ({ value, onClick }) => (


    <div
      className="datepicker"
      style={{
        display: "flex",
        flexDirection: "row",
        border: "1px solid #c6d2d9",
        padding: "0px 12px",
        margin: "13px 0px 0px 0px",
        cursor: "pointer",
        height: "35px",
      }}
      onClick={onClick}
    >
      <div
        style={{
          paddingTop: 0,
          display: "flex",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <TodayIcon
          style={{
            margin: "0px 5px 0px 0px",
            height: "20px",
            color: "#57646b",
          }}
        />
        <div style={{ color: "#666" }}>
          {moment(value).format("DD-MMM-YYYY")}
        </div>
      </div>
    </div>
  );

  const generateDWR = async (date) => {
    await generateReportByDate(date);
  };

  const isDateToday = () => {
    const selectedDateFormatted = moment(new Date(selectedDate)).format(
      "dd-mm-yyyy"
    );
    const newDate = moment().format("dd-mm-yyyy");
    return newDate == selectedDateFormatted;
  };

  const isTodaysSelectedDate = () => {
    const selectedCurrentDate = moment(selectedDate).format("DD-MM-YYYY");
    const currentDate = moment(new Date()).format("DD-MM-YYYY");
    return selectedCurrentDate == currentDate;
  };

  const generateReportByDate = (date) => {
    setIsTimeData(true);
    setIsTotalData(true);
    setSelectedDate(date);
    setSelectedProjectDate(date);
    return authenticationService
      .singleTimeEntryDetails(moment(date).format("DD-MM-YYYY"))
      .then(async (result) => {
        setDailyReportId(result?.dailyReport?.id);
        setReportDescription(
          result?.dailyReport?.description
            ? result?.dailyReport?.description
            : setExtraNote("")
        );

        // setEntry(
        //   result?.timeEntries?.filter((finalVal) => {
        //     return finalVal.endDate != null;
        //   })
        // );


        setEntry(
          result?.timeEntries
            .filter((entry) => entry.endDate !== null) 
            .map((entry) => {
              const hasMatchingTicket = result?.jiraTaskWorkLog.some((log) =>
                entry.title.includes(log.ticketNo)
              );
              return { ...entry, isJiraWorkReportSubmitted: hasMatchingTicket }; // Append the flag key
            })
        );
        
        setTimeEntriesResult(result)

        let entryArr = result;
        setIsTimeData(false);
        result.timeEntries.map((finalVal) => {
          if (finalVal.endDate == null) {
            setTimeEntriesData(finalVal);
            let startDate = new Date();
            let endDate = new Date(finalVal.startDate);
            let seconds = -Math.round(
              (endDate.getTime() - startDate.getTime()) / 1000
            );
            setIsStartTime(`${seconds}`);
          }
        });
        setIsTotalData(false);
      });
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "" : "") : 0;
    var mDisplay = m > 0 ? m + (m == 1 ? "" : "") : 0;

    hDisplay = hDisplay > 9 ? hDisplay : "0" + hDisplay + ":";
    mDisplay = mDisplay > 9 ? mDisplay : "0" + mDisplay;
    return hDisplay + mDisplay;
  };

  const totalSecondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    return hDisplay + mDisplay;
  };

  const getStartValue = (data) => {
    let formatedStartTime = "";
    if (
      data.hasOwnProperty("startTime") &&
      isNumber(parseInt(data?.startTime)) &&
      data?.startTime?.length == 4 &&
      !data?.startTime?.includes(":")
    ) {
      formatedStartTime = `${data?.startTime.substr(0, 2) +
        ":" +
        data?.startTime.substr(2, 2)}`;
    }
    if (
      data.hasOwnProperty("startTime") &&
      isNumber(parseInt(data?.startTime)) &&
      data?.startTime?.length == 4 &&
      data?.startTime?.includes(":")
    ) {
      let a = data.startTime.slice(0, data.startTime.indexOf(":"));
      let b = ":";
      let c = data.startTime.slice(
        data.startTime.indexOf(":") + 1,
        data.startTime.length
      );
      let newA = a.length == 2 ? a : 0 + a;
      let newC = c;
      formatedStartTime = newA + b + newC;
    }
    if (
      /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        formatedStartTime
      )
    ) {
      return formatedStartTime;
    }
  };

  const getEndValue = (data) => {
    let formatedEndTime = "";
    if (
      data.hasOwnProperty("endTime") &&
      isNumber(parseInt(data?.endTime)) &&
      data?.endTime?.length == 4 &&
      !data?.endTime?.includes(":")
    ) {
      formatedEndTime = `${data?.endTime.substr(0, 2) +
        ":" +
        data?.endTime.substr(2, 2)}`;
    }
    if (
      data.hasOwnProperty("endTime") &&
      isNumber(parseInt(data?.endTime)) &&
      data?.endTime?.length == 4 &&
      data?.endTime?.includes(":")
    ) {
      let a = data.endTime.slice(0, data.endTime.indexOf(":"));
      let b = ":";
      let c = data.endTime.slice(
        data.endTime.indexOf(":") + 1,
        data.endTime.length
      );
      let newA = a.length == 2 ? a : 0 + a;
      let newC = c;
      formatedEndTime = newA + b + newC;
    }
    if (
      /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(formatedEndTime)
    ) {
      return formatedEndTime;
    }
  };

  const newUpdatedProjectShow = (newProjectList, projectId) => {
    let project = [];
    newProjectList.map((value, key) => {
      value.options.map((item, key) => {
        if (item.id == projectId) {
          project.push(item);
        }
      });
    });
    return project;
  };


  // change version..............................................

  const handleChangeVersion = async (id) => {
    try {
      const Response = await authenticationService.getEmployeeVersionList(id);
      if (Response) {
        let data = [];
        Response.data.projectVersion.forEach((dropdownData) => {
          if (dropdownData?.isOpen == true) {
            data.push({
              name: 'version',
              label: dropdownData?.version,
              value: dropdownData?.id,
              isCurrent: dropdownData?.isCurrent,
            });
          }
        });

        setVersionOption(
          data
        )
      }
    } catch (error) {
      console.log(error);
    }

  }

  const doHandleCheckValidTime = (time, type) => {
    try {
      let formattedTime = "";
      if (
        isTimeFormateChecked == "24" ||
        localStorage.getItem("isTimeFormat") == "24"
      ) {
        if (!isNumber(parseInt(time))) {
          errorToast(`${type} must be valid!`, "error");
          return;
        }
        if (!time.includes(":")) {
          if (time.length == 1) {
            formattedTime = `0${time.substr(0, 1) + ":" + "00"}`;
          }
          if (time.length == 2) {
            formattedTime = `${time.substr(0, 2) + ":" + "00"}`;
          }
          if (time.length == 3) {
            formattedTime = `0${time.substr(0, 1) + ":" + time.substr(1, 2)}`;
          }
          if (time.length == 4) {
            formattedTime = `${time.substr(0, 2) + ":" + time.substr(2, 2)}`;
          }
        }
        if (time.includes(":")) {
          if (time.length == 4) {
            let a = time.slice(0, time.indexOf(":"));
            let b = ":";
            let c = time.slice(time.indexOf(":") + 1, time.length);
            let newA = a.length == 2 ? a : 0 + a;
            let newC = c;
            formattedTime = newA + b + newC;
          }
          if (time.length == 5) {
            formattedTime = time;
          }
        }
        let isValid = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(
          formattedTime
        );
        if (!isValid) {
          errorToast(`${type} must be valid!`, "error");
        }
        return formattedTime;
      } else {
        let manipulatedTime = "";
        if (!time.includes(":")) {
          if (time.length == 3) {
            manipulatedTime = `0${time.substr(0, 1) +
              ":" +
              "00" +
              time.substr(-2)}`;
          }
          if (time.length == 4) {
            manipulatedTime = `${time.substr(0, 2) +
              ":" +
              "00" +
              time.substr(-2)}`;
          }
          if (time.length == 6) {
            manipulatedTime = `0${time.substr(0, 1) +
              ":" +
              time.substr(1, 2) +
              time.substr(-2)}`;
          }
          if (time.length == 6) {
            manipulatedTime = `${time.substr(0, 2) +
              ":" +
              time.substr(2, 2) +
              time.substr(-2)}`;
          }
        }
        if (time.includes(":")) {
          if (time.length == 6) {
            let a = time.slice(0, time.indexOf(":"));
            let b = ":";
            let c = time.slice(time.indexOf(":") + 1, time.length);
            let newA = a.length == 2 ? a : 0 + a;
            let newC = c;
            manipulatedTime = newA + b + newC;
          }
          if (time.length == 7) {
            manipulatedTime = time;
          }
        }
        let isValid = /^([01]?[0-9]):([0-5][0-9])([AaPp][Mm])?$/.test(
          manipulatedTime
        );
        if (!isValid) {
          errorToast(`${type} must be valid!`, "error");
        }
        if (manipulatedTime != "") {
          if (
            manipulatedTime.substr(-2) == "PM" ||
            manipulatedTime.substr(-2) == "pm" ||
            manipulatedTime.substr(-2) == "Pm" ||
            manipulatedTime.substr(-2) == "pM" ||
            manipulatedTime.substr(-1) == "P" ||
            manipulatedTime.substr(-1) == "p"
          ) {
            formattedTime =
              (parseInt(manipulatedTime.substr(0, 2)) == 12
                ? parseInt(manipulatedTime.substr(0, 2)) + 0
                : parseInt(manipulatedTime.substr(0, 2)) + 12) +
              ":" +
              manipulatedTime.substr(3, 2);
          } else if (
            manipulatedTime.substr(-2) == "AM" ||
            manipulatedTime.substr(-2) == "am" ||
            manipulatedTime.substr(-2) == "Am" ||
            manipulatedTime.substr(-2) == "aM" ||
            manipulatedTime.substr(-1) == "A" ||
            manipulatedTime.substr(-1) == "a"
          ) {
            formattedTime =
              (parseInt(manipulatedTime.substr(0, 2)) == 12
                ? "00"
                : manipulatedTime.substr(0, 2)) +
              ":" +
              manipulatedTime.substr(3, 2);
          }
        }
        return formattedTime;
      }
    } catch (error) { }
  };

  const isTimerVisible = () => {
    let isAllowed = false;
    let isToday =
      moment(selectedDate).format("DD/MM/YYYY") ==
      moment().format("DD/MM/YYYY");
    if (isToday) {
      isAllowed = true;
    } else {
      if (timeEntriesData && !timeEntriesData.endDate && !isToday) {
        isAllowed = true;
      }

      if (!timeEntriesData && !isToday) {
        isAllowed = false;
      }
    }
    setIsTimeVisible(isAllowed);

    // return isAllowed;
  };


  const doHandleWorkLogClick = (data) => {

    const jiraTaskWorkLogData = timeEntriesResult?.jiraTaskWorkLog.find(item => data.title.includes(item.ticketNo));

        const jiraTaskWorkLogDataTitle = jiraTaskWorkLogData && newJiraTimeEntryList.find(item => item.label.includes(jiraTaskWorkLogData?.ticketNo));

        if (jiraTaskWorkLogData) {

          setEditRowData({remark: jiraTaskWorkLogData?.comment});
          setTimeSpentDetails(jiraTaskWorkLogData?.timeSpent);
          setSelectedWorkLogTime(new Date(jiraTaskWorkLogData?.started));
          setWorkLogTitle({title: jiraTaskWorkLogDataTitle?.label});
          
        } else {
          setEditRowData({remark: data?.remark});
          setSelectedWorkLogTime(new Date(data?.startDate));
          setWorkLogTitle({title: data?.title});
          // setTimeSpentDetails(null);

        }



    const formattedDate = new Date(data?.startDate)

    setIsModalOpen(true);
    // setEditRowData({remark: data?.remark});
    // setWorkLogTitle({title: data?.title});
    // setSelectedWorkLogTime(formattedDate);
    setIsJiraWorkLogSubmitted(data?.isJiraWorkReportSubmitted)
  }

  const doHandleChangeWorkLogTitle = (selected) => {
    setWorkLogTitle({title:selected?.label})
  }

  const doHandleTimeSpentDetails = (e) => {
    setTimeSpentDetails(e?.target?.value)
  }

  const doHandleDateTimeDuration = (date) => {
    setSelectedWorkLogTime(date)
  }

  const doClickOnSubmitWorkLog = async() => {

    let extractedTicketNo = workLogTitle?.title.split(" - ")[0];

    let convertedTimeSpent = new Date(selectedWorkLogTime).toISOString();

    try {
      
      let jiraWorkLogPayload = {
        comment:editRowData?.remark,
        ticketNo:extractedTicketNo,
        timeSpent:timeSpentDetails,
        started:convertedTimeSpent
      }

      const Response = await authenticationService.addJiraWorkLogEntryDetails(jiraWorkLogPayload);

      if (Response && Response?.status == "SENT"){
        setIsModalOpen(false);
        successToast("Report submitted successfully");



        // setEntry((prevData) =>
        //   prevData.map((entry) =>
        //     entry?.title.includes(Response?.ticketNo)
        //       ? { ...entry, isJiraWorkReportSubmitted: true }
        //       : entry
        //   )
        // );



        setEntry((prevData) =>
          prevData.map((entry) => {

            // const condition = entry?.title.includes(Response?.ticketNo);
            const condition = workLogTitle?.title.includes(Response?.ticketNo);
            
            return condition
              ? { ...entry, isJiraWorkReportSubmitted: true }
              : entry;
          })
        );

        setTimeSpentDetails(Response?.timeSpent);

        
      }


    } catch (error) {
      console.log(error,"error")
    }

  }

  const handleJiraWorkLogEditorChange = (event, editor) => {
                  const data = editor.getData();

                  const strippedText = stripHtmlTags(data);

                  setEditRowData((prev) => ({ ...prev, remark: data }));
                }

  const stripHtmlTags = (html) => {
    // const doc = new DOMParser().parseFromString(html, "text/html");
    // return doc.body.textContent || "";


    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || ""; // Extract plain text
  };
           
  
  const getTicketNo = () => {
    let extractedTicketNo = workLogTitle?.title.split(" - ")[0];
    return extractedTicketNo
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            background: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            boxShadow: "0px 2px 16px 3px rgb(84 84 84 / 75%)",
            p: 4,
            borderRadius: 5,
          }}
        >
          <div style={{ padding: "30px 25px", textAlign: "center" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#424242",
                fontSize: 30,
                padding: "0px 0px 20px 0px",
              }}
            >
              Are you sure?
            </Typography>
            <div
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#7e7e7e",
                padding: "0px 0px 20px 0px",
              }}
            >
              Do you really want to delete these
              <span style={{ color: "#424242", fontWeight: 600 }}>
                <span
                  className="two-line-dot"
                  title={isTimeDetailsDelete?.title}
                >
                  {isTimeDetailsDelete?.title}
                </span>
                <span>
                  {" "}
                  (
                  {isTimeFormateChecked == "24"
                    ? moment(isTimeDetailsDelete?.startDate).format("HH:mm")
                    : moment(isTimeDetailsDelete?.startDate).format("hh:mm A")}
                  |
                  {isTimeFormateChecked == "24"
                    ? moment(isTimeDetailsDelete?.endDate).format("HH:mm")
                    : moment(isTimeDetailsDelete?.endDate).format("hh:mm A")}
                  )
                </span>
              </span>
              &nbsp;task? This process can not be undone.
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ marginRight: 30 }}
                onClick={() => {
                  handleCloses();
                  setIsTimeDetailsDelete();
                }}
              >
                cancel
              </Button>
              {isTimeDtailsDeleteLoader ? (
                <>
                  <div style={{ width: 100 }}>
                    <CircularProgress size={30} />
                  </div>
                </>
              ) : (
                <>
                  <Button
                    style={{ background: "#ff5454" }}
                    onClick={() => {
                      softDeleteTimeEntryData(isTimeDetailsDelete.id);
                    }}
                  >
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      <TimeTracker
        showJiraTickets={showJiraTickets}
        ref={childRef}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isStartTime={isStartTime}
        setIsStartTime={setIsStartTime}
        entry={entry}
        setEntry={setEntry}
        timeEntriesData={timeEntriesData}
        isActive={isActive}
        setIsActive={setIsActive}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        // windowFocused={windowFocused}
        setTimeEntriesData={setTimeEntriesData}
        newProjectList={newProjectList}
        setNewProjectList={setNewProjectList}
        handleProjectChange={handleProjectChange}
        isEditTime={isEditTime}
        setIsEditTime={setIsEditTime}
        isTimeFormateChecked={TimeFormat?.isTimeFormat}
        isUserDetails={TimeFormat?.userDetails}
        doHandleCheckValidTime={doHandleCheckValidTime}
        dailyReportId={dailyReportId}
        setDailyReportId={setDailyReportId}
        doHandleFetchLastFiveEntry={doHandleFetchLastFiveEntry}
        newLastFiveTimeEntryList={newLastFiveTimeEntryList}
        setNewLastFiveTimeEntryList={setNewLastFiveTimeEntryList}
        getNotStopEntryDetails={() => getNotStopEntryDetails()}
        newJiraTimeEntryList={newJiraTimeEntryList}
        doHandleClickOnFetchLatestTimeEntries={doHandleClickOnFetchLatestTimeEntries}
        isFetchLatestJiraTimeEntries={isFetchLatestJiraTimeEntries}
      />

      {/* RESULT */}
      <Card
        style={{
          marginBottom: "0px",
          // height: isTimeVisible ? '78vh' : '92vh',
          // marginTop: isTimeVisible ? '30px' : '130px',
        }}
      >
        <CardBody>
          <GridContainer
            style={{
              backgroundColor: "#FFFFFF",
              position: "relative",
              top: -7,
            }}
          >
            <GridItem xs={12} sm={4} md={4} lg={4} align="right">
              <GridContainer align="left">
                {/* <GridItem xs={12} sm={12} md={12} lg={12}> */}
                <div style={{ display: "flex" }}>
                  <div
                    className="datepicker"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #c6d2d9",
                      margin: "13px 0px",
                      borderRight: "none",
                      cursor: "pointer",
                    }}
                    onClick={prevClick}
                  >
                    <i
                      className="fa fa-angle-left "
                      style={{
                        margin: "0px 12px",
                        color: "#57646b",
                        fontSize: "20px",
                        padding: "7.5px 0px",
                      }}
                    />
                  </div>
                  <DashboardWrapper>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      className="form-control"
                      calendarClassName="custom-calender-class"
                      maxDate={new Date()}
                      customInput={<ExampleCustomInput />}
                      dayClassName={(date) => {
                        if (
                          submittedReportDate.includes(
                            moment(date).format("YYYY-MM-DD")
                          )
                        ) {
                          return "react-datepicker__dayss";
                        }
                        if (
                          formattedHolidayDates.includes(
                            moment(date).format("YYYY-MM-DD")
                          )
                        ) {
                          return "react-datepicker__holidays";
                        }

                      }}

                      renderDayContents={(day, date) => {
                        const formattedDate = moment(date).format("YYYY-MM-DD");
                        const holiday = holidayDetails.find(
                          (holiday) => moment(holiday.holidayDate).format("YYYY-MM-DD") === formattedDate
                        );
              
                        return (
                          <div
                            className="day-wrapper"
                            onMouseEnter={() => holiday && setHoveredDate(holiday.holidayName)}
                            onMouseLeave={() => setHoveredDate(null)}
                          >
                            {day}
                            {hoveredDate === holiday?.holidayName && (
                              <div className="tooltip">
                                {holiday.holidayName}
                              </div>
                            )}
                          </div>
                        );
                      }}

                    />
                  </DashboardWrapper>

                  <div
                    className="datepicker"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #c6d2d9",
                      margin: "13px 0px",
                      borderLeft: "none",
                      cursor:
                        moment(selectedDate).format("DD-MMM-YYYY") ==
                          moment(new Date()).format("DD-MMM-YYYY")
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={nextClick}
                  >
                    <i
                      className="fa fa-angle-right"
                      style={{
                        padding: "0px 12px",
                        color: "#57646b",
                        fontSize: "20px",
                        cursor:
                          moment(selectedDate).format("DD-MMM-YYYY") ==
                            moment(new Date()).format("DD-MMM-YYYY")
                            ? "not-allowed"
                            : "pointer",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      marginLeft: "20px",
                    }}
                  >
                    {isTimeData ? (
                      <></>
                    ) : (
                      <Chip
                        label={
                          entry[0]?.DailyReport?.isSubmited == true
                            ? "SENT"
                            : "PENDING"
                        }
                        className={
                          entry[0]?.DailyReport?.isSubmited == true
                            ? "report-primary"
                            : "report-secondary"
                        }
                      />
                    )}
                  </div>
                </div>
                {/* </GridItem> */}
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} lg={4} align="right"></GridItem>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              lg={4}
              align="right"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {isTotalData ? (
                <TotalShimmer />
              ) : (
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {"Total: "}
                  <span style={{ fontSize: 16, marginLeft: 3 }}>
                    {
                      <Render
                        entry={entry}
                        isStartTime={isStartTime}
                        selectedDate={selectedDate}
                        timeEntriesData={timeEntriesData}
                        setIsStartTime={setIsStartTime}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        timerUp={timerUp}
                        setTimerUp={setTimerUp}
                        isTimeDtailsDeleteLoader={isTimeDtailsDeleteLoader}
                      // totalWeekHour={totalWeekHour}
                      // weekTimerUp={weekTimerUp}
                      // setWeekTimerUp={setWeekTimerUp}
                      />
                    }
                  </span>
                </p>
              )}
              {!isAddTimeDetails &&
                !isDateToday() &&
                !isTodaysSelectedDate() ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    color: "",
                  }}
                  onClick={() => setIsAddTimeDetails(true)}
                >
                  <Tooltip title="Add Time Entry">
                    <button
                      style={{
                        margin: "5px 0px 5px 15px",
                        height: "40px",
                        width: "40px",
                        backgroundColor: "#f4590d",
                        fontSize: "17px",
                        border: "0px solid",
                        borderRadius: "50%",
                        color: "white",
                        display: "flex",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      tool
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M11 8h2v6h-2V8m4-7H9v2h6V1m-3 19c-3.87 0-7-3.13-7-7s3.13-7 7-7s7 3.13 7 7c.7 0 1.36.13 2 .35V13c0-2.12-.74-4.07-1.97-5.61l1.42-1.42c-.45-.51-.9-.97-1.41-1.41L17.62 6c-1.55-1.26-3.5-2-5.62-2a9 9 0 0 0 0 18c.59 0 1.16-.06 1.71-.17c-.31-.58-.53-1.23-.63-1.92c-.36.05-.71.09-1.08.09m8-2v-3h-2v3h-3v2h3v3h2v-3h3v-2h-3Z"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </GridItem>
          </GridContainer>

          {isAddTimeDetails ? (
            <AddEntry
              isDateToday={isDateToday}
              newProjectList={newProjectList}
              selectedDate={selectedDate}
              entry={entry}
              setEntry={setEntry}
              setIsTimeData={setIsTimeData}
              setIsAddTimeDetails={setIsAddTimeDetails}
              dailyReportId={dailyReportId}
              setDailyReportId={setDailyReportId}
              newLastFiveTimeEntryList={newLastFiveTimeEntryList}
              setNewLastFiveTimeEntryList={setNewLastFiveTimeEntryList}
              isTimeFormateChecked={TimeFormat?.isTimeFormat}
            />
          ) : (
            <></>
          )}

          <GridContainer
            align="left"
            style={{
              borderTop: "1px solid #e6e6e6",
            }}
          >
            <div
              // className={classes.padding_zero}
              className="padding_zero"
              style={{ zIndex: 0, padding: 100, width: "100%" }}
            >
              {isTimeData ? (
                <TableShimmer />
              ) : (
                <MaterialTable
                  doToggleEditingTimer={() => {
                    setIsEditing(true);
                  }}
                  doToggleCancelEditingTimer={() => {
                    setIsEditing(false);
                  }}
                  parentChildData={(row, rows) => {
                    rows.find((a) => a.id === row.parentId);
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  // title={moment(DWRData[0].date).format('D-MMM-YYYY')}
                  columns={[
                    {
                      title: "Task Details",
                      field: "taskDetails",
                      editable: "onUpdate",
                      // width: '25%',
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      editComponent: (props, rowData) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                marginRight: 25,
                                width: "70%",
                              }}
                            >
                              {props?.rowData?.workType != "meeting" ? (
                                <TextField
                                  fullWidth
                                  size="small"
                                  id="fullWidth"
                                  variant="outlined"
                                  value={
                                    props?.rowData?.taskDetails
                                      ? props?.rowData?.taskDetails?.title
                                      : props?.rowData?.title
                                  }
                                  onChange={(e) => {
                                    props.onChange({
                                      ...props.rowData.taskDetails,
                                      title: e.target.value,
                                    });

                                  }}
                                  title={
                                    props?.rowData?.taskDetails
                                      ? props?.rowData?.taskDetails?.title
                                      : props?.rowData?.title
                                  }
                                />
                              ) : (
                                <>
                                  <div
                                    style={{
                                      border: "1px solid #0000003b",
                                      padding: "9px 14px 9px 13px",
                                      borderRadius: 3,
                                      userSelect: "none",
                                    }}
                                  >
                                    {props?.rowData?.taskDetails
                                      ? props?.rowData?.taskDetails?.title
                                      : props?.rowData?.title}
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              style={{
                                width: "290px",
                              }}
                            >
                              <Select
                                maxMenuHeight={145}
                                isDisabled={true}
                                styles={colourStyles}
                                components={{
                                  // DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                value={
                                  props?.rowData?.taskDetails?.projectId
                                    ? newProjectList &&
                                    newUpdatedProjectShow(
                                      newProjectList,
                                      props?.rowData?.taskDetails?.projectId
                                    )
                                    : {
                                      id: props?.rowData?.project?.id,
                                      name: props?.rowData?.project
                                        ?.projectCode
                                        ? `${props?.rowData?.project?.projectCode} - ${props?.rowData?.project?.projectName}`
                                        : props?.rowData?.project
                                          ?.projectName,
                                      label: props?.rowData?.project
                                        ?.projectCode
                                        ? `${props?.rowData?.project?.projectCode} - ${props?.rowData?.project?.projectName}`
                                        : props?.rowData?.project
                                          ?.projectName,
                                      value: props?.rowData?.project
                                        ?.projectCode
                                        ? `${props?.rowData?.project?.projectCode} - ${props?.rowData?.project?.projectName}`
                                        : props?.rowData?.project
                                          ?.projectName,
                                    }
                                }
                                onChange={(e) => {
                                  if (e) {
                                    props.onChange({
                                      ...props.rowData.taskDetails,
                                      projectId: e.id,
                                    });
                                  }
                                }}
                                options={newProjectList}
                              />
                            </div>
                          </div>
                        </>
                      ),
                      render: (rowData) => {
                        if (rowData.projectName == "TOTAL") {
                          return (
                            <>
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {/* {rowData.projectName + `: ${rowData.timeDiff} `} */}
                              </span>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <span
                                style={{
                                  position: "relative",
                                  top: 5,
                                }}
                              >
                                {rowData?.project?.projectCode
                                  ? `${rowData?.project?.projectCode} - ${rowData?.project?.projectName}` +
                                  ` ( ${rowData.duration})`
                                  : rowData?.project?.projectName +
                                  ` ( ${rowData.duration})`}
                                <br /> {rowData.title}
                              </span>
                            </>
                          );
                        }
                      },
                    },

                    {
                      title: "Version",
                      field: "version",
                      editable: "onUpdate",
                      width: "10%",
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      editComponent: (props, rowData) => (
                        <div>                          
                          <Select
                            maxMenuHeight={145}
                            styles={colourStyles}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            value={
                              selectedVesrion ? selectedVesrion :
                                props?.rowData?.projectVersion?.find((item) => item?.label == props?.rowData?.currentVersion) || null

                            }
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            // onChange={(selectedOption) => {
                            //   setSelectedVesrion(selectedOption);
                            // }}
                            onChange={(e) => {
                              setSelectedVesrion(e);
                              if (e) {
                                props.onChange({
                                  ...props.rowData, 
                                  currentVersion: e?.label,
                                   
                                });
                              }
                            }}
                            options={props?.rowData?.projectVersion}
                          />
                        </div>
                      ),
                      render: (rowData) => {
                        return (
                          <div
                            style={{
                              position: "relative",
                              top: 13,
                            }}
                          >
                            {rowData?.currentVersion}                            
                          </div>
                        );
                      },
                    },

                    {
                      title: "StartTime",
                      field: "startTime",
                      editable: "onUpdate",
                      width: isTimeFormateChecked != "24" ? "8%" : "5%",
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      editComponent: (props, rowData) => (
                        <div>
                          <Typography className={classes.typography}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  width: "110px",
                                }}
                              >
                                <Typography className={classes.typography}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      maxWidth:
                                        isTimeFormateChecked != "24"
                                          ? "100px"
                                          : "76px",
                                    }}
                                  >
                                    <TextField
                                      className="timeNewInput"
                                      variant="outlined"
                                      size="small"
                                      value={getStartValue(props.rowData)}
                                      defaultValue={
                                        props.rowData != "" &&
                                          isTimeFormateChecked == "24"
                                          ? moment(
                                            props?.rowData?.startDate
                                          ).format("HH:mm")
                                          : moment(
                                            props?.rowData?.startDate
                                          ).format("hh:mmA")
                                      }
                                      placeholder={
                                        props.rowData != "" &&
                                          isTimeFormateChecked == "24"
                                          ? moment(
                                            props?.rowData?.startDate
                                          ).format("HH:mm")
                                          : moment(
                                            props?.rowData?.startDate
                                          ).format("hh:mm A")
                                      }
                                      inputProps={{
                                        maxLength:
                                          isTimeFormateChecked != "24" ? 7 : 5,
                                      }}
                                      onBlur={() => {
                                        if (
                                          props?.rowData?.hasOwnProperty(
                                            "startTime"
                                          )
                                        ) {
                                          const startTime = doHandleCheckValidTime(
                                            props?.rowData?.startTime,
                                            "startTime"
                                          );
                                        }
                                      }}
                                      onClick={(e) => {
                                        e.target.focus();
                                        e.target.select();
                                      }}
                                      onChange={(e) => {
                                        props.onChange(e.target.value);
                                      }}
                                    />
                                    {/* <div style={{ border: "1px solid #0000003b", padding: "7px 14px", borderRadius: 3, userSelect: "none" }}>
                                      {
                                        props.rowData != "" &&
                                          isTimeFormateChecked == "24"
                                          ? moment(
                                            props?.rowData?.startDate
                                          ).format("HH:mm")
                                          : moment(
                                            props?.rowData?.startDate
                                          ).format("hh:mmA")
                                      }
                                    </div> */}
                                  </div>
                                </Typography>
                              </div>
                            </div>
                          </Typography>
                        </div>
                      ),
                      render: (rowData) => {
                        if (rowData.projectName == "TOTAL") return;
                        return (
                          <div
                            style={{
                              position: "relative",
                              top: 13,
                            }}
                          >
                            {rowData != "" && isTimeFormateChecked == "24"
                              ? moment(rowData?.startDate).format("HH:mm")
                              : moment(rowData?.startDate).format("hh:mm A")}
                          </div>
                        );
                      },
                    },
                    {
                      title: "EndTime",
                      field: "endTime",
                      editable: "onUpdate",
                      width: isTimeFormateChecked != "24" ? "100px" : "80px",
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      editComponent: (props) => (
                        <div>
                          <Typography className={classes.typography}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  width: "110px",
                                }}
                              >
                                <Typography className={classes.typography}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      maxWidth:
                                        isTimeFormateChecked != "24"
                                          ? "100px"
                                          : "76px",
                                      transform: " translate(0%, 2%)",
                                    }}
                                  >
                                    <TextField
                                      className="timeNewInput"
                                      variant="outlined"
                                      size="small"
                                      value={getEndValue(props.rowData)}
                                      defaultValue={
                                        props.rowData != "" &&
                                          isTimeFormateChecked == "24"
                                          ? moment(
                                            props.rowData.endDate
                                          ).format("HH:mm")
                                          : moment(
                                            props.rowData.endDate
                                          ).format("hh:mmA")
                                      }
                                      inputProps={{
                                        maxLength:
                                          isTimeFormateChecked != "24" ? 7 : 5,
                                      }}
                                      onBlur={() => {
                                        if (
                                          props?.rowData?.hasOwnProperty(
                                            "endTime"
                                          )
                                        ) {
                                          const endTime = doHandleCheckValidTime(
                                            props?.rowData?.endTime,
                                            "endTime"
                                          );
                                        }
                                      }}
                                      onClick={(e) => {
                                        e.target.focus();
                                        e.target.select();
                                      }}
                                      onChange={(e) => {
                                        props.onChange(e.target.value);
                                      }}
                                      placeholder={
                                        props.rowData != "" &&
                                          isTimeFormateChecked == "24"
                                          ? moment(
                                            props.rowData.endDate
                                          ).format("HH:mm")
                                          : moment(
                                            props.rowData.endDate
                                          ).format("hh:mm A")
                                      }
                                    />
                                    {/* <div style={{ border: "1px solid #0000003b", padding: "7px 14px", borderRadius: 3, userSelect: "none" }}>
                                      {
                                        props.rowData != "" &&
                                          isTimeFormateChecked == "24"
                                          ? moment(
                                            props.rowData.endDate
                                          ).format("HH:mm")
                                          : moment(
                                            props.rowData.endDate
                                          ).format("hh:mmA")
                                      }
                                    </div> */}
                                  </div>
                                </Typography>
                              </div>
                            </div>
                          </Typography>
                        </div>
                      ),
                      render: (rowData) => {
                        if (rowData.projectName == "TOTAL") return;
                        return (
                          <div
                            style={{
                              position: "relative",
                              top: 13,
                            }}
                          >
                            {rowData != "" && isTimeFormateChecked == "24"
                              ? moment(rowData.endDate).format("HH:mm")
                              : moment(rowData.endDate).format("hh:mm A")}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Task Note*",
                      // tooltip:
                      //   "Add a note related to your task. i.e. #13 Issue resolve with minor bug fixes!",
                      field: "remark",
                      editable: "onUpdate",
                      // width: "35%",
                      sorting: false,
                      filtering: false,
                      editComponent: (props) => (
                        <CKEditor
                          editor={ClassicEditor}
                          data={props.value}
                          onInit={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            // console.log({ event, editor, data });
                            props.onChange(data);
                          }}
                          onBlur={(event, editor) => {
                            // console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            // console.log("Focus.", editor);
                          }}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                            ],
                          }}
                        />
                      ),

                      render: (rowData) => (
                        <div
                          style={{ wordBreak: "break-all" }}
                          dangerouslySetInnerHTML={{
                            __html: rowData.remark,
                          }}
                        ></div>
                      ),
                      cellStyle: {
                        verticalAlign: "text-top",
                        width: "30%",
                        maxWidth: "30px"
                      },
                    },
                  ]}
                  data={entry.sort((a, b) => {
                    return (
                      new Date(b.startDate) - new Date(a.startDate) ||
                      new Date(b.endDate) - new Date(a.endDate)
                    );
                  })}
                  editable={{
                    isEditable: (rowData) => (rowData.endDate ? true : false),
                    onRowUpdate: (newData, oldData) =>
                      new Promise(async (resolve, reject) => {
                        let formattedStartTime = "";
                        let formattedEndTime = "";
                        if (newData?.hasOwnProperty("startTime")) {
                          formattedStartTime = doHandleCheckValidTime(
                            newData?.startTime,
                            "startTime"
                          );
                        }
                        if (newData?.hasOwnProperty("endTime")) {
                          formattedEndTime = doHandleCheckValidTime(
                            newData?.endTime,
                            "endTime"
                          );
                        }
                        let newStartUpdatedDate = moment(newData.startDate);
                        if (newData?.startTime) {
                          newStartUpdatedDate.set({
                            h: formattedStartTime?.split(":")[0],
                            minutes: formattedStartTime?.split(":")[1],
                            second: 0,
                            millisecond: 0,
                          });
                        }
                        let newEndUpdatedDate = moment(newData.endDate);
                        if (newData?.endTime) {
                          newEndUpdatedDate.set({
                            h: formattedEndTime?.split(":")[0],
                            minutes: formattedEndTime?.split(":")[1],
                            second: 0,
                            millisecond: 0,
                          });
                        }

                        const isStartFuture = await newStartUpdatedDate.isAfter(
                          moment()
                        );
                        const isEndTimeFuture = await newEndUpdatedDate.isAfter(
                          moment()
                        );
                        let errorDetails = {
                          status: false,
                          message: "",
                        };
                        if (isStartFuture) {
                          errorDetails.status = true;
                          errorDetails.message += `Start time must be less than current time!`;
                        }
                        const isPast = await newStartUpdatedDate.isBefore(
                          newEndUpdatedDate
                        );
                        if (!isPast) {
                          errorDetails.status = true;
                          if (errorDetails.message && errorDetails.status) {
                            errorDetails.message +=
                              ",                                ";
                          }
                          errorDetails.message += `End time must be greater than start time!`;
                        }
                        if (isEndTimeFuture) {
                          errorDetails.status = true;
                          if (errorDetails.message && errorDetails.status) {
                            errorDetails.message += ", ";
                          }
                          errorDetails.message += `End time must be less than current time!`;
                        }
                        if (errorDetails.status) {
                          errorToast(errorDetails.message);
                          reject();
                          return;
                        }
                        if (
                          newStartUpdatedDate.isAfter(moment()) <=
                          newStartUpdatedDate.isBefore(newEndUpdatedDate) &&
                          newData?.taskDetails?.title != ""
                        ) {
                          {
                            let updatedStartDate = {
                              status: false,
                              date: "",
                            };
                            let updatedEndDate = {
                              status: false,
                              date: "",
                            };
                            if (newData.startTime) {
                              updatedStartDate.date = newStartUpdatedDate
                                .utc()
                                .format();
                              updatedStartDate.status = true;
                            }
                            if (newData.endTime) {
                              updatedEndDate.date = newEndUpdatedDate
                                .utc()
                                .format();
                              updatedEndDate.status = true;
                            }
                            // update
                            let obj = {
                              ...newData,
                              title: newData?.taskDetails?.hasOwnProperty(
                                "title"
                              )
                                ? newData?.taskDetails?.title
                                : newData?.title,
                              projectId: newData?.taskDetails?.hasOwnProperty(
                                "projectId"
                              )
                                ? newData?.taskDetails?.projectId
                                : newData?.project?.id,
                            };
                        console.log(newData,"newData..")

                            if (updatedStartDate.status) {
                              obj.startDate = updatedStartDate.date;
                              obj.projectId = newData?.taskDetails?.hasOwnProperty(
                                "projectId"
                              )
                                ? newData?.taskDetails?.projectId
                                : newData?.project?.id;
                            }
                            // send project version Id
                            obj.currentVersion = selectedVesrion ? selectedVesrion?.label : newData?.currentVersion;
                            obj.projectVersionId = selectedVesrion ? selectedVesrion?.id : newData?.projectVersion?.find((item) => item?.isCurrent == true)?.id;
                            if (updatedEndDate.status) {
                              obj.endDate = updatedEndDate.date;
                              obj.projectId = newData?.taskDetails?.hasOwnProperty(
                                "projectId"
                              )
                                ? newData?.taskDetails?.projectId
                                : newData?.project?.id;
                            }
                            setIsEditing(true);
                            // add new entry
                            const Response = await authenticationService.updateTimeDetails(
                              obj,
                              newData.id
                            );
                            if (Response.data) {
                              let sum = 0;
                              entry.map((item, index) => {
                                let m1 = new Date(item.startDate);
                                let m2 = new Date(item.endDate);
                                let seconds = Math.abs(
                                  (m2.getTime() - m1.getTime()) / 1000
                                );
                                sum = parseInt(sum) + parseInt(seconds);
                                if (item.id == Response.data.id) {
                                  let arr = [...entry];
                                  arr[index].startDate =
                                    Response.data.startDate;
                                  arr[index].endDate = Response.data.endDate;
                                  arr[index].duration = Response.data.duration;
                                  arr[index].title = Response.data.title;
                                  arr[index].project = Response.data.project;
                                  arr[index].currentVersion = Response.data?.currentVersion;
                                  setSelectedVesrion()
                                  setEntry(arr);
                                }
                              });
                              if (
                                timeEntriesData &&
                                timeEntriesData?.startDate != "" &&
                                moment(selectedDate).format("DD-MM-YYYY") ==
                                moment(new Date()).format("DD-MM-YYYY")
                              ) {
                                let startDate = new Date();
                                let endDate = new Date(
                                  timeEntriesData?.startDate
                                );
                                let seconds = -Math.round(
                                  (endDate.getTime() - startDate.getTime()) /
                                  1000
                                );
                                let d = parseInt(
                                  moment(selectedDate).format("DD-MM-YYYY") ==
                                    moment(new Date()).format("DD-MM-YYYY")
                                    ? sum + parseInt(seconds)
                                    : sum
                                );
                                var h = Math.floor(d / 3600);
                                var m = Math.floor((d % 3600) / 60);
                                var s = Math.floor((d % 3600) % 60);
                                var hDisplay =
                                  h > 0 ? h + (h == 1 ? "h " : "h ") : "00h ";
                                var mDisplay =
                                  m > 0 ? m + (m == 1 ? "m " : "m ") : "00m ";
                                var sDisplay =
                                  s > 0 ? s + (s == 1 ? "s " : "s ") : "00s";
                                setTimerUp(
                                  hDisplay.includes("00h") &&
                                    mDisplay.includes("00m")
                                    ? hDisplay + mDisplay + sDisplay
                                    : hDisplay + mDisplay
                                );
                              }
                              setTimeout(() => {
                                publish("LateArrivalEvent");
                              }, 200);
                            }
                            let data = entry;
                            data.map((item) => {
                              if (item.id == newData.id) {
                                setTimeEntriesId([...timeEntriesId, item]);
                                item.remark = newData.remark;
                                item.version = newData.version;
                                item.ETA = newData.ETA;
                              }
                            });
                            // setTimeEntries(data)
                            setEntry(data);
                            resolve();
                          }
                          setIsEditing(false);
                        } else {
                          errorToast(`Title can not be empty!`);
                          reject();
                        }
                      }),
                    onRowUpdateCancelled: (rowData) => {
                      setIsEditing(false);
                    },
                  }}
                  actions={[

                    (rowData) => (
                      parseInt(TimeFormat?.userDetails?.userId) === 237 ?
                          {
                              icon: ()=><MoreTimeIcon style={{ color: rowData?.isJiraWorkReportSubmitted ? 'green' : 'black' }}/>,
                              tooltip: "Worklog",
                              onClick: (event, rowData) => {
                                    doHandleWorkLogClick(rowData)
                                  },
                          } : null

                  ),


                    {
                      icon: PlayArrowIcon,
                      tooltip: "Play",
                      disabled: isActive || isStartTime != 0 ? true : false,
                      onClick: (event, rowData) => {
                        // doHandleTimePlay(rowData);
                        childRef.current.doHandleStart(rowData);
                      },
                    },
                    {
                      icon: "delete",
                      tooltip: "Delete",
                      onClick: (event, rowData) => {
                        setIsTimeDetailsDelete(rowData);
                        handleOpen();
                      },
                    },
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: "#f2f6f8",
                      color: "#000",
                      // paddingLeft: '8px',
                    },
                    showEmptyDataSourceMessage: true,
                    draggable: false,
                    toolbar: false,
                    actionsColumnIndex: 6,
                    paging: false,
                    // cellStyle: { minHeight: '100px', maxHeight: '100px' },
                    // minBodyHeight: 400,
                    // minBodyHeight: "35vh",
                    // maxBodyHeight: "100vh",
                    rowStyle: (rowData) => {
                      if (rowData.projectName === "TOTAL") {
                        return {
                          backgroundColor: "#E4EAEE",
                        };
                        // return { backgroundColor: '#ffcdd2' };
                      }
                      return {};
                    },
                    search: false,
                  }}
                />
              )}
            </div>
            {/* <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{
                                // backgroundColor: isTotalData ? "#FFF" : "#e4eaee",
                                margin: '0px 15px',
                                zIndex: 0,
                            }}
                        >
                            {isTotalData ? (
                                <TotalShimmer />
                            ) : (
                                <p
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 700,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {'Total: '}
                                    <span
                                        style={{ fontSize: 16, marginLeft: 3 }}
                                    >
                                        {
                                            <Render
                                                entry={entry}
                                                isStartTime={isStartTime}
                                                selectedDate={selectedDate}
                                                timeEntriesData={
                                                    timeEntriesData
                                                }
                                                setIsStartTime={setIsStartTime}
                                                setIsEditing={setIsEditing}
                                                isEditing={isEditing}
                                                timerUp={timerUp}
                                                setTimerUp={setTimerUp}
                                                isTimeDtailsDeleteLoader={
                                                    isTimeDtailsDeleteLoader
                                                }
                                                // totalWeekHour={totalWeekHour}
                                                // weekTimerUp={weekTimerUp}
                                                // setWeekTimerUp={setWeekTimerUp}
                                            />
                                        }
                                    </span>
                                </p>
                            )}
                        </GridItem> */}
            {/* total week comment */}
            {/* <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ 
                margin: "0px 15px",
                zIndex: 0,
              }}
            > 
              {isTotalData ? (
                <TotalShimmer />
              ) : (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {"Week Total: "}
                  <span style={{ fontSize: 16, marginLeft: 3 }}>
                    {weekTimerUp}
                  </span>
                </p>
              )} 
            </GridItem> */}
            <GridItem xs={12} sm={12} md={6} lg={6} style={{ marginTop: 30 }}>
              <b>Notes (Optional)</b>
              <CKEditor
                editor={ClassicEditor}
                data={reportDescription ? reportDescription : extraNote}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log({ event, editor, data });
                  onChangeExtraNote(data);
                }}
                onBlur={(event, editor) => {
                  // console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  // console.log("Focus.", editor);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: 30 }}>
              <div
                style={{
                  display: "flex",
                }}
              >
                {!isSaveAsLoader ? (
                  <Button
                    startIcon={<SaveIcon />}
                    color={"info"}
                    disabled={isSendMailLoader}
                    style={{
                      background: "#f96117db",
                      fontWeight: "bold",
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                    onClick={(e) => {
                      onSaveAsDraft(timeEntriesId, "Save Draft");
                    }}
                  >
                    {"Save as draft"}
                  </Button>
                ) : (
                  <div
                    style={{
                      background: "#f96117db",
                      fontWeight: "bold",
                      borderRadius: "50px",
                      margin: "5px 20px 5px 1px",
                      width: 175,
                      height: 42,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      style={{ height: 25, width: 25, color: "white" }}
                    />
                  </div>
                )}
                <br />
                {!isSendMailLoader ? (
                  <Button
                    startIcon={<SendIcon />}
                    style={{
                      background: "#f96117db",
                      fontWeight: "bold",
                      borderRadius: "50px",
                    }}
                    disabled={
                      isStartTime != 0 &&
                        moment(selectedDate).format("DD-MM-YYYY") ==
                        moment(new Date()).format("DD-MM-YYYY")
                        ? true
                        : false ||
                          (isActive == true &&
                            moment(selectedDate).format("DD-MM-YYYY") ==
                            moment(new Date()).format("DD-MM-YYYY"))
                          ? true
                          : false || (entry && entry.length <= 0)
                            ? true
                            : isSaveAsLoader
                              ? true
                              : false
                    }
                    color={"info"}
                    onClick={() => {
                      handleOnSubmitManagement();
                    }}
                  >
                    Send To Management
                  </Button>
                ) : (
                  <div
                    style={{
                      background: "#f96117db",
                      fontWeight: "bold",
                      borderRadius: "50px",
                      margin: "5px 20px 5px 1px",
                      width: 221,
                      height: 42,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      style={{ height: 25, width: 25, color: "white" }}
                    />
                  </div>
                )}
              </div>
            </GridItem>
          </GridContainer>



          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div style={{display: "flex"}}>
            <DialogTitle>
              Worklog Entry
            </DialogTitle>
            <div style={{display: "flex", alignItems: "center"}}>
            <Chip
                label={
                  isJiraWorkLogSubmitted
                    ? "SUBMITTED"
                    : "PENDING"
                }
                className={
                  isJiraWorkLogSubmitted
                    ? "report-primary"
                    : "report-secondary"
                }
            />
            </div>
            </div>
            {/* <DialogContent>
              <CKEditor
                editor={ClassicEditor}
                data={editRowData?.remark || ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditRowData((prev) => ({ ...prev, remark: data }));
                }}
              />
            </DialogContent> */}


            <DialogContent>
              <Grid container spacing={2}>
                
                <Grid item xs={6}>
                <span>Time spent</span>
                <TextField
                  style={{height:"50px"}}
                  InputProps={{ style: { height: '100%' } }}
                  id="outlined-basic" 
                  label="" 
                  variant="outlined"
                  value={timeSpentDetails}
                  onChange={doHandleTimeSpentDetails}
                  disabled={isJiraWorkLogSubmitted}
                 />
                </Grid>



                <Grid item xs={6}>
                    <span>Ticket Dropdown</span>
                    <Select
                    
                    styles={{
                      control: (provided) => ({
                          ...provided,
                          height: '50px',
                      }),
                    }}

                      // defaultValue={workLogTitle?.title}
                      // onChange={setSelectedOption}

                      onChange={doHandleChangeWorkLogTitle}
                      options={newJiraTimeEntryList}
                      value={workLogTitle}
                      getOptionLabel={(e) => e.label || e.title}
                      isDisabled={isJiraWorkLogSubmitted}
                    />

                </Grid>

                {/* <Grid item xs={12} style={{display: 'flex'}}>

                    

                <div className="custom-datepicker-container">
                  <DatePicker
                    className="custom-datepicker"
                    selected={selectedWorkLogTime}
                    onChange={doHandleDateTimeDuration}
                    showTimeSelect
                    dateFormat="MM/d/yyyy, h:mm aa"
                    disabled={isJiraWorkLogSubmitted}
                  />
                </div>
                </Grid> */}




                <Grid item xs={6}>
                <span>Started</span>
                <div className="custom-datepicker-container">
                  <DatePicker
                    className="custom-datepicker"
                    selected={selectedWorkLogTime}
                    onChange={doHandleDateTimeDuration}
                    showTimeSelect
                    dateFormat="MM/d/yyyy, h:mm aa"
                    disabled={isJiraWorkLogSubmitted}
                  />
                </div>
                </Grid>

                <Grid item xs={6}>
                <span>Ticket No.</span>
                <TextField
                  style={{height:"50px"}}
                  InputProps={{ style: { height: '100%' } }}
                  id="outlined-basic" 
                  label="" 
                  variant="outlined"
                  value={getTicketNo()}
                  // onChange={doHandleTimeSpentDetails}
                  disabled={isJiraWorkLogSubmitted}
                 />
                </Grid>


                {/* <Grid item xs={12}>
                <CKEditor
                editor={ClassicEditor}
                data={editRowData?.remark || ""}
                // onChange={(event, editor) => {


                //   const data = editor.getData();
                //   console.log("dataCKmodal",data)
                //   setEditRowData((prev) => ({ ...prev, remark: data }));
                // }}
                onChange={handleJiraWorkLogEditorChange}

              />
                </Grid> */}


              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                  value={editRowData?.remark || ""}
                  onChange={(e) => {
                    const data = e.target.value;
                    setEditRowData((prev) => ({ ...prev, remark: data }));
                  }}
                  inputProps={{
                    style: { height: '150px', overflow: 'auto' },
                  }}
                  disabled={isJiraWorkLogSubmitted}
                />
              </Grid>



              </Grid>
            </DialogContent>

            <DialogActions style={{margin: "0px 20px"}}>
              <Button
                style={{width:"70px", height:"35px", borderRadius: "5px", textTransform: 'capitalize', fontWeight: 800, fontSize: "15px", backgroundColor: "#1242c1"}}
                onClick={() => {
                  doClickOnSubmitWorkLog()
                  // setIsModalOpen(false);
                }}
                disabled={isJiraWorkLogSubmitted || (!editRowData?.remark || !workLogTitle?.title || !selectedWorkLogTime || !timeSpentDetails)}
              >
                Save
              </Button>
              <Button
                style={{width:"70px", height:"35px", borderRadius: "5px", textTransform: 'capitalize', fontWeight: 800, fontSize: "15px"}}
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>

            </DialogActions>
          </Dialog>




          <ToastContainer />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
