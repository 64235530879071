import { currentUserSubject, api_endpoint } from "../consonants";
import { authHeader, xAuthToken } from "./authHeader";
import { handleResponse } from "./handleResponse";
import axios from "axios";

export const authenticationService = {
  login,
  fetchUserLateArrivalHistory,
  updateTimeFormat,
  timeEntryNotStopTimeDetails,
  deleteSoftTimeEntry,
  deleteTimeEntry,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
  fetchDWRreport,
  sendReport,
  fetchHistory,
  checkReportAlreadyExist,
  me,
  changeStatus,
  updateMultipalTimeDetails,
  fetchProjectListResourceAllocation,
  viewReport,
  clocifyProfile,
  sendTimeDetails,
  applyLeave,
  applyCompOff,
  updateLeave,
  updateCompOff,
  deleteLeave,
  deleteCompOff,
  updateTimeDetails,
  fetchResourceAllocationReport,
  fetchMyLeave,
  fetchProjectVersion,
  fetchReport,
  timeEntryDetails,
  singleTimeEntryDetails,
  fetchProjectList,
  fetchProjectListDatewise,
  fetchFilterProjectList,
  sendDailyReportData,
  fetchNewHistory,
  uploadProfilePicture,
  deleteProfilePicture,
  addTimeEntry,
  doSendReport,
  fetchLastFiveEntry,
  fetchJirasLastFiveEntry,
  addPassword,
  verifyAddPasswordToken,
  fetchMyTeamsUserList,
  fetchMyTeamsProjectList,
  fetchDepartmentList,
  fetchReportingManagerList,
  fetchAllReportingManagerList,
  fetchMyTeamsTechList,
  fetchMyTeamsClientList,
  filterMyTeamReport,
  fetchMySchedulerTeamsList,
  fetchFilterSchedulerTeamsList,
  fetchResourceAllocationClientCount,
  fetchNoAllocationList,
  fetchMyAllocationList,
  fetchResourceAllocationSetting,
  addResourceAllocationSetting,
  downloadCSVNoAllocation,
  deleteAssignProject,
  addProjectEmployeeSchedul,
  updateEmployeeSchedulerProject,
  sendAddProjectRequest,
  fetchRequestedProjectDetails,
  fetchHolidays,
  fetchLatestTimeEntries,
  getEmployeeVersionList,
  getHolidayList,
  addJiraWorkLogEntryDetails,
  currentUser: currentUserSubject.asObservable(),
  createTimeEntryOptions,
  get currentUser() {
    return currentUserSubject.value;
  },
};

function storeUser(user) {
  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return (
    fetch(`${process.env.REACT_APP_API_ENDPOINT}user/login`, requestOptions)
      // return fetch(`  ${process.env.REACT_APP_API_ENDPOINT} user/login`, requestOptions)
      // return fetch(`https://global.api.clockify.me/auth/token`, requestOptions)
      // return fetch(`https://api.clockify.me/api/auth/token`, requestOptions)
      .then(handleResponse)
      .then((user) => {
        storeUser(user);
        return user;
      })
  );
}

async function forgotPassword(email) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}user/forgotpassword`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
}

async function resetPassword(value, id) {
  try {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}user/resetPassword/${id}`,
      value,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error);
    return error
  }
}

async function addPassword(value, id) {
  try {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}user/generatePassword/${id}`,
      value,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return error
  }
}

function verifyAddPasswordToken(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}user/token/${token}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function changePassword(passwordData) {
  try {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    let { data } = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}user/edit/password`,
      passwordData,
      {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function fetchDWRreport(date) {
  const requestOptions = {
    method: "POST",
    // headers: authHeader(),
    headers: {
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI2MWQyZDg3NGI1NzZkZjc2NjczNWI1ZTIiLCJtdWx0aUZhY3RvciI6ZmFsc2UsImlzcyI6ImNsb2NraWZ5IiwibmFtZSI6IkFuamFsaSBQYXRlbCIsImV4cCI6MTY3MTA3MDYwNywidHlwZSI6ImFjY2VzcyIsImlhdCI6MTY3MTAyNzQwNywianRpIjoiT1RFMFl6QmtOR0l0TkROaVlpMDBaVFV3TFdJMk1tVXROakU1TjJaaFl6UmhOVFkyIiwiZW1haWwiOiJhbmphbGkucEB1cHNxdWFyZS5pbiJ9.IFaJvPJvdv3VHzBuAg_ekpzGH8FNByB0k9Q9Po - yYkG7h86c5EtSuFIPkpPi2PypxeJ1iMrkZ1f4DCnMM251CYeeEztt7Hzb_wtpFCS50L_t - z_3yaGUzSyZY4U0lkN3_yyhdIbf5RFtAuoJiZbSpF6osBWoFZYdd5Ey6xNC0fMZu4EOIxX0FDl6rjtw3YiA_luZmJhdcYf7G9sYL - DX7NNlVoFw2_Sr55asmYM03xXpBG3tFtcWQM1IfJfO07f44GUgekGxo15IWn1bs0LpvfnpyfumwFNWbYD73KnpkEUWbmTNvFKKMwbl_MEeiINi0z1cbWYEWQbjMhVximdiS",
    },

    body: JSON.stringify({ date }),
  };

  return fetch(api_endpoint + `/daily-reports`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function checkReportAlreadyExist(date) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ date }),
  };

  return fetch(api_endpoint + `/daily-reports/find`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function sendReport(description, timeEntries, dailyReportId, isDraft) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ description, timeEntries, isDraft }),
  };

  return fetch(
    api_endpoint + `/daily-reports/send/${dailyReportId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchHistory() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(api_endpoint + `/daily-reports`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function me() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    // headers: authHeader(),
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return (
    fetch(`${process.env.REACT_APP_API_ENDPOINT}profile`, requestOptions)
      // return fetch(api_endpoint + `/users/me`, requestOptions)
      .then(handleResponse)
      .then((res) => {
        return res;
      })
  );
}

function changeStatus(role) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ role }),
  };

  return fetch(api_endpoint + `/users/change-status`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function viewReport(dailyReportId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    api_endpoint + `/daily-reports/view/${dailyReportId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function clocifyProfile() {
  const requestOptions = {
    method: "GET",
    headers: xAuthToken(),
  };

  return fetch(`https://api.clockify.me/api/v1/user/`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function createTimeEntryOptions(data) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/entryOps`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function sendTimeDetails(data) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/createTimeEntry`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function sendDailyReportData(description, reportId) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(description),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}daily_report/createDailyReport/${reportId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function updateTimeDetails(data, startTimeId) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/updateEntry/${startTimeId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function updateMultipalTimeDetails(data) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/updateMultipleEntry/${data.date}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      // console.log("response UpdateStop--", res);
      return res;
    });
}

function timeEntryNotStopTimeDetails() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/entry/emptyEntries`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response.data;
    });
}

function timeEntryDetails() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response.data;
    });
}

function singleTimeEntryDetails(time) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/${time}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response.data;
    });
}
function fetchNewHistory(limit, page) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}daily_report?limit=${limit}&page=${page}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchProjectListResourceAllocation() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}project/all/list`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchProjectList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}project/list`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchDepartmentList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}department/all`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchProjectListDatewise(date) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}project/lists/${date}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchFilterProjectList(id) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}project/list/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchLastFiveEntry() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/latest/fetchRecords`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchJirasLastFiveEntry() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}tasks?page=1&limit=40`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchUserLateArrivalHistory() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}lateUser`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}


function uploadProfilePicture(data) {
  const form = new FormData();
  form.append("image", data.image.file);

  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      // "Content-Type": "multipart/form-data",
    },
    body: form,
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}uploadImage`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function deleteProfilePicture() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      // "Content-Type": "multipart/form-data",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}removeImage`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteTimeEntry(timeEntryId) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      // "Content-Type": "multipart/form-data",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/deleteEntry/${timeEntryId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteSoftTimeEntry(timeEntryId) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      // "Content-Type": "multipart/form-data",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/delete/${timeEntryId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function addTimeEntry(date, data) {
  let token = JSON.parse(localStorage.getItem("currentUser"));

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}time_entry/add/timeEntry/${date}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateTimeFormat() {
  let token = JSON.parse(localStorage.getItem("currentUser"));

  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}user/changeTimeFormat`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchMyTeamsUserList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}teamUserList`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchHolidays(startDate, endDate) {
  // holidays/filter/all?startDate=2024-01-01&endDate=2024-01-31
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}holidays/filter/all?startDate=${startDate}&endDate=${endDate}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}


function fetchReportingManagerList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    // `${process.env.REACT_APP_API_ENDPOINT}reportingManagerList`,
    `${process.env.REACT_APP_API_ENDPOINT}all`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// reportingManagerList

function fetchAllReportingManagerList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    // `${process.env.REACT_APP_API_ENDPOINT}reportingManagerList`,
    `${process.env.REACT_APP_API_ENDPOINT}reportingManagerListByLogin`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchMyTeamsProjectList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}projectList`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchMyTeamsTechList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}technology/all`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchMyTeamsClientList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}client/all`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function sendAddProjectRequest(details) {
  let token = JSON.parse(localStorage.getItem("currentUser"));

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(details),
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}action/project`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchRequestedProjectDetails() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}action/project`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function filterMyTeamReport(data) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}data/my-team`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function fetchMySchedulerTeamsList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_SCHEDULER_API_ENDPOINT}get_userdata`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function fetchFilterSchedulerTeamsList(object) {
  if (object?.type) {
    // let token = JSON.parse(localStorage.getItem("currentUser"));
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${token.data.access_token}`,
    //     "Content-Type": "application/json",
    //   },
    //   responseType: "arraybuffer",
    //   body: JSON.stringify(object)
    // };
    // return fetch(
    //   // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    //   `${process.env.REACT_APP_API_ENDPOINT}filterResource`,
    //   requestOptions
    // )
    //   // .then(handleResponse)
    //   .then((res) => {
    //     return res;
    //   });

    try {
      var givenToken = null;
      let token = JSON.parse(localStorage.getItem("currentUser"));
      // if (token) {
      //   givenToken = {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`
      //   };
      // } else {
      //   givenToken = getHeader();
      // }
      const requestOptions = {
        method: "POST",
        // headers: givenToken
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
          "Content-Type": "application/json"
        },
        responseType: "arraybuffer"
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}panel/user-scheduler/filterResource`,
        object,
        requestOptions,
      );

      const { data, error } = response;
      return { status: true, data: data };

    } catch (error) {
      if (error?.response?.data) {
        // handleResponseAxios(error.response);
        return error.response.data;
      }
      return error;
    }
  }

  else {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.data.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(object),
    };
    return fetch(
      // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
      `${process.env.REACT_APP_API_ENDPOINT}panel/user-scheduler/filterResource`,
      requestOptions
    )
      .then(handleResponse)
      .then((res) => {
        return res;
      });
  }
}

async function fetchNoAllocationList(month) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}availableList/${(month)}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function fetchMyLeave(selectedYear) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}leave?startMonth=${selectedYear}-01&endMonth=${selectedYear}-12`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function fetchProjectVersion(id) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function applyLeave(object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}leave`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function applyCompOff(object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}leave/compOff`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function updateLeave(id, object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}leave/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function updateCompOff(id, object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}leave/compOff/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteLeave(id, object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}leave/delete/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteCompOff(id, object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}leave/compOff/delete/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function fetchMyAllocationList(month) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}allocation/${(month)}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function fetchResourceAllocationReport(object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
    `${process.env.REACT_APP_API_ENDPOINT}allocation/report`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

// async function fetchReport(object) {
//   let token = JSON.parse(localStorage.getItem("currentUser"));
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token.data.access_token}`,
//       "Content-Type": "application/json",
//     },
//     // body: JSON.stringify(object),
//   };
//   return fetch(
//     // `${process.env.REACT_APP_SCHEDULER_API_ENDPOINTS}get_project_data_by_filter/?user_id=${[employeeId]}&project_id=${[projectId]}&startdate=${startDate}&due_date=${endDate}`,
//     `${process.env.REACT_APP_API_ENDPOINT}allocation/report/all?startDate=${object?.startDate}&endDate=${object?.endDate}&client=${object?.client}&type=${object?.type}`,
//     requestOptions
//   )
//     .then(handleResponse)
//     .then((res) => {
//       return res;
//     });

// }


async function fetchReport(object) {
  if (object?.type == 3) {

    try {
      var givenToken = null;
      let token = JSON.parse(localStorage.getItem("currentUser"));
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
          "Content-Type": "application/json"
        },
        responseType: "arraybuffer"
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}allocation/report/all?startDate=${object?.startDate}&endDate=${object?.endDate}&client=${object?.client}&type=${object?.type}`,
        requestOptions,
      );

      const { data, error } = response;
      return { status: true, data: data };

    } catch (error) {
      if (error?.response?.data) {
        // handleResponseAxios(error.response);
        return error.response.data;
      }
      return error;
    }
  }

  else {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.data.access_token}`,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(object),
    };
    return fetch(
      // `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINTS }get_project_data_by_filter /? user_id = ${ [employeeId]} & project_id=${ [projectId]} & startdate=${ startDate } & due_date=${ endDate }`,
      `${process.env.REACT_APP_API_ENDPOINT}allocation/report/all?startDate=${object?.startDate}&endDate=${object?.endDate}&client=${object?.client}&type=${object?.type}`,
      requestOptions
    )
      .then(handleResponse)
      .then((res) => {
        return res;
      });
  }
}

async function fetchResourceAllocationClientCount(object) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  };
  return fetch(
    // `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINTS }get_project_data_by_filter /? user_id = ${ [employeeId]} & project_id=${ [projectId]} & startdate=${ startDate } & due_date=${ endDate }`,
    `${process.env.REACT_APP_API_ENDPOINT}resourceAllocation/client/count`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function downloadCSVNoAllocation(month) {
  try {
    var givenToken = null;
    let token = JSON.parse(localStorage.getItem("currentUser"));

    const requestOptions = {
      method: "GET",
      // headers: givenToken
      headers: {
        Authorization: `Bearer ${token.data.access_token}`,
        "Content-Type": "application/json"
      },
      responseType: "arraybuffer"
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}availableList/${(month)}?type=download`,
      requestOptions,
    );

    const { data, error } = response;
    return { status: true, data: data };

  } catch (error) {
    if (error?.response?.data) {
      // handleResponseAxios(error.response);
      return error.response.data;
    }
    return error;
  }
}

function doSendReport(payload) {
  try {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.data.access_token} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    return fetch(
      // `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINTS } assign_project / `,
      `${process.env.REACT_APP_API_ENDPOINT}sendReort`,
      requestOptions
    )
      .then(handleResponse)
      .then((res) => {
        return res;
      });
  }
  catch (err) {
    console.log(err);
    return err;
  }
}

// function addProjectEmployeeSchedul( payload ) {
//   try {
//     let token = JSON.parse(localStorage.getItem("currentUser"));
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${ token.data.access_token } `,
//         "Content-Type": "application/json",
//       },
//       c
//     };
//     return fetch(
//       // `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINTS } assign_project / `,
//        `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINT } resourceAllocation`,
//       requestOptions
//     )
//       .then(handleResponse)
//       .then((res) => {
//         return res;
//       });
//   }
//   catch (err) {
//     console.log(err);
//     return err;
//   }
// }

async function fetchResourceAllocationSetting(type) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token} `,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}allocation/setting/get/${type}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function addResourceAllocationSetting(payload) {
  try {

    let token = JSON.parse(localStorage.getItem("currentUser"));
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.data.access_token} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    return fetch(
      // `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINTS } assign_project / `,
      `${process.env.REACT_APP_API_ENDPOINT}allocation/setting/add`,
      requestOptions
    )
      .then(handleResponse)
      .then((res) => {
        return res;
      });
  }
  catch (err) {
    console.log(err);
    return err;
  }
}


function deleteAssignProject(id) {
  // const formData = new FormData();
  // formData.append("assignment_id", id)
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token.data.access_token} `,
      // "Content-Type": "application/json",
    },
    // body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}panel/user-scheduler/resourceAllocation/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function addProjectEmployeeSchedul(payload) {
  try {

    let token = JSON.parse(localStorage.getItem("currentUser"));
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.data.access_token} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    return fetch(
      // `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINTS } assign_project / `,
      `${process.env.REACT_APP_API_ENDPOINT}panel/user-scheduler/resourceAllocation`,
      requestOptions
    )
      .then(handleResponse)
      .then((res) => {
        return res;
      });
  }
  catch (err) {
    console.log(err);
    return err;
  }
}

// function downloadResourceAllocationSheet() {
//   try {

//     let token = JSON.parse(localStorage.getItem("currentUser"));
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${ token.data.access_token } `,
//         "Content-Type": "application/json",
//       },
//       // body: JSON.stringify(payload),
//     };
//     return fetch(
//       // `${ process.env.REACT_APP_SCHEDULER_API_ENDPOINTS } assign_project / `,
//       `${ process.env.REACT_APP_API_ENDPOINT } filterResource`,
//       requestOptions
//     )
//       .then(handleResponse)
//       .then((res) => {
//         return res;
//       });
//   }
//   catch (err) {
//     console.log(err);
//     return err;
//   }
// }

async function updateEmployeeSchedulerProject({ UpdateProjectData }) {
  try {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token.data.access_token} `,
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(UpdateProjectData),
    };
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}panel/user-scheduler/resourceAllocation/${UpdateProjectData.id} `,
      requestOptions
    )
      .then(handleResponse)
      .then((res) => {

        return res;
      });
  }
  catch (err) {
    console.log(err);
    return err;
  }
}

async function fetchLatestTimeEntries() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}tasks/sync-recent`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}



function logout() {
  // remove user from local storage to log user out
  window.localStorage.removeItem("currentUser");
  window.localStorage.removeItem("isTimeFormat");
  currentUserSubject.next(null);
}




// project version list ==============

// export const getEmployeeVersionList = async (id) => {
//   try {
//       const requestOptions = {
//           method: "GET",
//           headers: getHeader()
//       };

//       const { data } = await axios.get(
//           `${BaseURL}project/version/${id}`,
//           requestOptions
//       );
//       return data;
//   } catch (err) {
//       if (err?.response?.data) {
//           handleResponseAxios(err.response);
//           return err.response.data;
//       }
//       console.log(err);
//   }
// };
async function getEmployeeVersionList(id) {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}project/version/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

async function getHolidayList() {
  let token = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(object),
  };
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}holidays/me`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });

}

function addJiraWorkLogEntryDetails(payload) {

  try {

    let token = JSON.parse(localStorage.getItem("currentUser"));
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.data.access_token} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}tasks/add-worklog`,
      requestOptions
    )
      .then(handleResponse)
      .then((res) => {
        return res;
      });
  }
  catch (err) {
    console.log(err);
    return err;
  }
}