import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)",
    // padding: "10px",
    margin: "-15px 0px 0px 0px",
    borderRadius: "4px",
    position: "relative",
  },

  line0: {
    width: "400px",
    height: "440px",
    background: "#e9e9e9",
    position: "relative",
   
  },
  line1: {
    width: "360px",
    height: "55px",
    alignSelf: "center",
    position: "absolute",
    top: "200px",
    left:20
  },
  line2: {
    width: "360px",
    height: "55px",
    alignSelf: "center",
    position: "absolute",
    top: "270px",
    left:20
  },
  line3: {
    width: "360px",
    height: "55px",
    alignSelf: "center",
    position: "absolute",
    top: "340px",
    left:20
  },
  cercle: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    alignSelf: "center",
    position: "absolute",
    left: "150px",
    top:" 50px"
  },
};
class ProfileShimmer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.container}>
          <Shimmer>
            <div className={classes.line0} /> 
            <div className={classes.cercle} />
            <br />
            <div className={classes.line1} /> 
            <br />
            <div className={classes.line2} /> 
            <br />
            <div className={classes.line3} /> 
          </Shimmer>
        </div>
      </>
    );
  }
}

export default injectSheet(StyleSheet)(ProfileShimmer);
