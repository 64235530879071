import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';
import RTLNavbarLinks from './RTLNavbarLinks.js';
import logo from 'assets/img/reactlogo.png';
import ramIcon from 'assets/img/ram.png';
import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js';
import { authenticationService } from '../../services/authentication';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { Box, Modal } from '@material-ui/core';
import { close } from 'helper/constant.jsx';
/* Drawer */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { subscribe } from 'helper/events/events.js';
import { unsubscribe } from 'helper/events/events.js';
import { useLocation } from 'react-router-dom';



const useStyles = makeStyles(styles);
export default function Header(props) {
    const { userDetails } = props;
    const TimeFormat = useContext(props.TimeFormatContext);
    const theme = useTheme();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [lateArrivalArr, setLateArrivalArr] = React.useState([]);
    const [isApiCalling, SetIsApiCalling] = React.useState(false);
    const location = useLocation()

    const handleDrawerOpen = () => {
        setOpen(open ? false : true);
        if (open) {
            props.closeDrawer();
        } else {
            props.openDrawer();
        }
    };
    React.useEffect(() => {

        fetchUserLateArrival();
        subscribe('LateArrivalEvent', () => {
            if (!isApiCalling) {
                fetchUserLateArrival();
            }
        });
        subscribe('profileUpdate', data => {
            props.setUserDetails(data.detail);
        });
        return () => {
            unsubscribe('LateArrivalEvent');
            unsubscribe('profileUpdate');
        };
    }, []);

    function makeBrand() {
        var name;
        props.routes.map(prop => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                name = props.rtlActive ? prop.rtlName : prop.name;
            }
            return null;
        });
        return name;
    }

    var { color } = props;
    color = 'warning';
    const appBarClasses = classNames({
        [' ' + classes[color]]: color,
    });

    const handleOpen = () => setModalOpen(true);
    const handleCloses = () => setModalOpen(false);

    const DoHandleLateArrivalTime = ({ startTime }) => {
        let startMoment = moment.utc(startTime).set({
            h: 10,
            m: 0,
            s: 0,
        });
        let endMoment = moment.utc(startTime).format();
        var duration = moment.duration(moment(endMoment).diff(startMoment));
        var seconds = duration.asSeconds();
        let d = Number(parseInt(seconds));
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);
        var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '00h ';
        var mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '00m ';
        var sDisplay = s > 0 ? s + (s == 1 ? 's ' : 's ') : '00s';
        return hDisplay + mDisplay + " late";
    };

    const fetchUserLateArrival = async () => {
        try {
            SetIsApiCalling(true);
            const response = await authenticationService.fetchUserLateArrivalHistory();
            if (response.error) {
                console.log(response.error);
            } else {
                setLateArrivalArr(response?.data);
            }
            SetIsApiCalling(false);
        } catch (error) {
            SetIsApiCalling(false);
            console.log(error);
        }
    };

    var brand = (
        <div
            className={classes.logo}
            style={{
                // marginLeft: open ? '230px' : '75px',
                transition: 'all 0.3s ease',
                marginLeft: 10,
                display: 'flex',
            }}
        >
            <a
                href="/"
                className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive,
                })}
                style={{
                    color: '#000',
                    fontSize: 17,
                    paddingBottom: 0,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <div className={classes.logoImage}>
                    <img
                        src={logo}
                        alt="logo"
                        style={{ height: 40, width: 40 }}
                    />
                </div>
            </a>
            <div
                style={{
                    fontSize: 17,
                    paddingBottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <a
                    style={{
                        color: '#000',
                    }}
                    href="/"
                    className={classNames(classes.logoLink, {
                        [classes.logoLinkRTL]: props.rtlActive,
                    })}
                >
                    {/* {location?.pathname == "/panel/user-scheduler" ? "Resource Allocation" : 'Daily Work Report'} */}
                    {location?.pathname == "/panel/report" ? userDetails?.isReportingManager == true ? "Allocation - Me & My Team" : "My Allocation" : "Daily Work Report" && location?.pathname == "/panel/user-scheduler" ? "Resource Allocation" : 'Daily Work Report' && location?.pathname == "/panel/allocation-report" ? "Clientwise - Resource Deployment" : 'Daily Work Report'}
                    <sup style={{ color: "gray", fontSize: "12px" }}>{` ${process.env.REACT_APP_PRODUCTION_MODE}`}</sup>
                </a>
                <div align="left" style={{ marginTop: -5, padding: 0 }}>
                    <span
                        title="Release Note"
                        className="overHeere"
                        style={{
                            fontSize: 12,
                            color: '#757575',
                            fontWeight: 'bold',
                            textTransform: 'lowercase',
                            marginLeft: 5,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >{`${process.env.REACT_APP_VERSON}`}</span>
                </div>
            </div>
        </div>
    );
    return (
        <AppBar
            className={classes.appBar}
            style={{
                backgroundColor: '#fff',
                boxShadow: '0 5px 10px rgba(0,0,0,.1)',
                position: 'fixed',
            }}
        >
            <Modal
                open={modalOpen}
                onClose={handleCloses}
                toggle={handleCloses}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    style={{
                        background: 'white',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 700,
                        height: 500,
                        bgcolor: 'white',
                        boxShadow: '0px 2px 16px 3px rgb(84 84 84 / 75%)',
                        p: 4,
                        borderRadius: 5,
                    }}
                >
                    <div
                        style={{
                            padding: '20px 25px',
                            // borderBottom: "2px solid #d5d5d5",
                            fontWeight: 600,
                            fontSize: 18,
                            color: '#000000',
                            // textAlign: "center",
                        }}
                    >
                        <span> Late Arrival</span>
                        <img
                            src={close}
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: 19,
                                right: 20,
                            }}
                            width={25}
                            height={25}
                            onClick={handleCloses}
                        />
                    </div>
                    <div style={{ height: 430, overflow: 'auto' }}>
                        <table style={{ width: '100%' }}>
                            <tr
                                style={{
                                    height: 40,
                                    userSelect: 'none',
                                    textAlign: 'left',
                                }}
                            >
                                <th style={{ padding: '0px 0px 0px 25px' }}>
                                    Date
                                </th>
                                <th style={{ padding: '0px 0px 0px 25px' }}>
                                    Start Time
                                </th>
                                <th style={{ padding: '0px 0px 0px 25px' }}>
                                    Arrival
                                </th>
                            </tr>
                            {lateArrivalArr.data &&
                                lateArrivalArr.data.map((item, key) => {
                                    return (
                                        <tr
                                            className="table-row"
                                            style={{
                                                textAlign: 'start',
                                                margin: '10px 0px',
                                                // cursor: "pointer",
                                                color: 'gray',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    padding:
                                                        '12px 0px 12px 25px',
                                                }}
                                            >
                                                {moment
                                                    .utc(item.startDate)
                                                    .format(
                                                        'DD-MM-YYYY, hh:mm'
                                                    )}
                                                {key >= 3 ? (
                                                    <sup>
                                                        <span
                                                            style={{
                                                                background:
                                                                    'red',
                                                                fontSize: 10,
                                                                color: 'white',
                                                                padding:
                                                                    '2px 5px',
                                                                fontWeight: 600,
                                                                borderRadius: 3,
                                                                marginLeft: 5,
                                                            }}
                                                        >
                                                            PENALTY
                                                        </span>
                                                    </sup>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td
                                                style={{
                                                    padding:
                                                        '12px 0px 12px 25px',
                                                }}
                                            >
                                                {moment
                                                    .utc(item.startDate)
                                                    .format('hh:mm:ss A')}
                                            </td>
                                            <td
                                                style={{
                                                    padding:
                                                        '12px 0px 12px 25px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        fontSize: 14,
                                                        height: 15,
                                                        width: 15,
                                                        marginRight: '10px',
                                                        background:
                                                            key == 0
                                                                ? '#ffca2acf'
                                                                : key == 1
                                                                    ? '#ff8400c7'
                                                                    : key == 2
                                                                        ? '#ff0000bd'
                                                                        : key >= 3
                                                                            ? 'red'
                                                                            : '',
                                                    }}
                                                >
                                                    <span></span>
                                                </Avatar>
                                                <DoHandleLateArrivalTime
                                                    startTime={item?.startDate}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                        </table>
                    </div>
                </Box>
            </Modal>

            <Toolbar className={classes.container}>
                {!props.isDrawerOpen && (
                    <Hidden smDown implementation="css">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.handleDrawer}
                        >
                            <Menu />
                        </IconButton>
                    </Hidden>
                )}
                <IconButton onClick={handleDrawerOpen}>
                    {open ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>

                {brand}

                <div className={classes.flex} style={{ paddingLeft: 10 }}>
                    {/*  <Hidden smDown implementation="css">
                        <Button
                            color={'transparent'}
                            href="/panel/dashboard"
                            className={`${classes.title} customFont`}
                            style={{
                                fontSize: 25,
                                border:
                                    makeBrand() == 'Dashboard'
                                        ? '1px solid grey'
                                        : 'none',
                            }}
                        >
                            <span style={{ fontSize: 20 }}>{'Dashboard'}</span>
                        </Button>
                        <Button
                            color={'transparent'}
                            href="/panel/history"
                            className={`${classes.title} customFont`}
                            style={{
                                fontSize: 25,
                                border:
                                    makeBrand() == 'History'
                                        ? '1px solid grey'
                                        : 'none',
                            }}
                        >
                            <span style={{ fontSize: 20 }}>{'History'}</span>
                        </Button>
                    </Hidden>*/}
                </div>
                {props.memoryUsage && props.memoryUsage.freeMem && (
                    <div className="tooltip">
                        <Avatar
                            className="bg-transparent hover-late "
                            style={{
                                fontSize: 14,
                                height: 36,
                                width: 36,
                                marginRight: '6px',
                                background: '#ffca2acf',
                            }}
                        >
                            <span className="first-date">
                                <span>

                                    <img src={ramIcon}
                                        alt="logo"
                                        style={{ height: 40, width: 40 }}
                                    />
                                </span>
                            </span>
                            <span className="second-date">
                                <img src={ramIcon}
                                    alt="logo"
                                    style={{ height: 35, width: 35 }}
                                /> <span style={{ color: "black" }}>memory</span>
                            </span>


                        </Avatar>

                        <span className="tooltiptextOne">
                            <div onClick={() => {
                                if (!props.isFetchingLoading) {

                                    props.doGetMemoryStatus()
                                }
                            }} style={{ position: "absolute", right: 0, cursor: "pointer" }}>{props.isFetchingLoading ? 'fetching' : 'refresh'}</div>
                            <span style={{ fontSize: 12 }}>
                                Memory usage:
                            </span>
                            <br />
                            Used Memory:-&nbsp;

                            {props.memoryUsage.usedMem}
                            <br />
                            Free Memory:-&nbsp;

                            {props.memoryUsage.freeMem}
                            <br />
                            Total Memory:-&nbsp;
                            {props.memoryUsage.totalMem}
                        </span>
                    </div>
                )}
                {lateArrivalArr?.data && lateArrivalArr?.data.length > 0 ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '6px 120px 6px 0px',
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >

                                {lateArrivalArr?.data?.length > 0 ? (
                                    <div className="tooltip">
                                        <Avatar
                                            className="hover-late"
                                            style={{
                                                fontSize: 14,
                                                height: 36,
                                                width: 36,
                                                marginRight: '6px',
                                                background: '#ffca2acf',
                                            }}
                                            onClick={() => {
                                                handleOpen();
                                            }}
                                        >
                                            <span className="first-date">
                                                <span>
                                                    <b>1</b>
                                                    <sup
                                                        style={{
                                                            fontSize: 8,
                                                            fontWeight: 900,
                                                        }}
                                                    >
                                                        st
                                                    </sup>
                                                </span>
                                            </span>
                                            <span className="second-date">
                                                <b>
                                                    {moment
                                                        .utc(
                                                            lateArrivalArr?.data &&
                                                            lateArrivalArr
                                                                ?.data[0]
                                                                .startDate
                                                        )
                                                        .format('DD MMM YYYY')}
                                                </b>
                                            </span>
                                        </Avatar>

                                        <span className="tooltiptext">
                                            <span style={{ fontSize: 12 }}>
                                                Late Arrival:
                                            </span>
                                            <br />
                                            {moment
                                                .utc(
                                                    lateArrivalArr?.data &&
                                                    lateArrivalArr?.data[0]
                                                        .startDate
                                                )
                                                .format('DD MMM, YYYY')}
                                            <br />
                                            Start Time:-&nbsp;
                                            {moment
                                                .utc(
                                                    lateArrivalArr?.data &&
                                                    lateArrivalArr?.data[0]
                                                        .startDate
                                                )
                                                .format('hh:mm:ss A')}
                                        </span>
                                    </div>
                                ) : (
                                    <Avatar
                                        style={{
                                            fontSize: 14,
                                            height: 36,
                                            width: 36,
                                            background: '#c5c5c5',
                                            marginRight: '6px',
                                        }}
                                    >
                                        <span>
                                            <b>01</b>
                                            <sup
                                                style={{
                                                    fontSize: 8,
                                                    fontWeight: 900,
                                                }}
                                            >
                                                st
                                            </sup>
                                        </span>
                                    </Avatar>
                                )}
                                {lateArrivalArr?.data?.length > 1 ? (
                                    <div className="tooltip">
                                        <Avatar
                                            className="hover-late"
                                            style={{
                                                fontSize: 14,
                                                height: 36,
                                                width: 36,
                                                marginRight: '6px',
                                                background: '#ff8400c7',
                                            }}
                                            onClick={() => {
                                                handleOpen();
                                            }}
                                        >
                                            <span className="first-date">
                                                <span>
                                                    <b>2</b>
                                                    <sup
                                                        style={{
                                                            fontSize: 8,
                                                            fontWeight: 900,
                                                        }}
                                                    >
                                                        nd
                                                    </sup>
                                                </span>
                                            </span>
                                            <span className="second-date">
                                                <b>
                                                    {moment
                                                        .utc(
                                                            lateArrivalArr?.data &&
                                                            lateArrivalArr
                                                                ?.data[1]
                                                                .startDate
                                                        )
                                                        .format('DD MMM YYYY')}
                                                </b>
                                            </span>
                                        </Avatar>
                                        <span className="tooltiptext">
                                            <span style={{ fontSize: 12 }}>
                                                Late Arrival:
                                            </span>
                                            <br />
                                            {moment
                                                .utc(
                                                    lateArrivalArr?.data &&
                                                    lateArrivalArr?.data[1]
                                                        .startDate
                                                )
                                                .format('DD MMM, YYYY')}
                                            <br />
                                            Start Time:-&nbsp;
                                            {moment
                                                .utc(
                                                    lateArrivalArr?.data &&
                                                    lateArrivalArr?.data[1]
                                                        .startDate
                                                )
                                                .format('hh:mm:ss A')}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="tooltip">
                                        <Avatar
                                            style={{
                                                fontSize: 14,
                                                height: 36,
                                                width: 36,
                                                marginRight: '6px',
                                                background: '#c5c5c5',
                                            }}
                                        >
                                            <span>
                                                <b>2</b>
                                                <sup
                                                    style={{
                                                        fontSize: 8,
                                                        fontWeight: 900,
                                                    }}
                                                >
                                                    nd
                                                </sup>
                                            </span>
                                        </Avatar>
                                        <span className="tooltiptext">
                                            {lateArrivalArr?.data?.length == 1
                                                ? `2 instances `
                                                : `1  instance `}{' '}
                                            remaning
                                        </span>
                                    </div>
                                )}
                                {lateArrivalArr?.data?.length > 2 ? (
                                    <div className="tooltip">
                                        <Avatar
                                            className="hover-late"
                                            style={{
                                                fontSize: 14,
                                                height: 36,
                                                width: 36,
                                                marginRight: '6px',
                                                background: '#ff0000bd',
                                            }}
                                            onClick={() => {
                                                handleOpen();
                                            }}
                                        >
                                            <span className="first-date">
                                                <b>3</b>
                                                <sup
                                                    style={{
                                                        fontSize: 8,
                                                        fontWeight: 900,
                                                    }}
                                                >
                                                    rd
                                                </sup>
                                            </span>
                                            <span className="second-date">
                                                <b>
                                                    {moment
                                                        .utc(
                                                            lateArrivalArr?.data &&
                                                            lateArrivalArr
                                                                ?.data[2]
                                                                .startDate
                                                        )
                                                        .format('DD MMM YYYY')}
                                                </b>
                                            </span>
                                        </Avatar>
                                        <span className="tooltiptext">
                                            <span style={{ fontSize: 12 }}>
                                                Late Arrival:
                                            </span>
                                            <br />
                                            {moment
                                                .utc(
                                                    lateArrivalArr?.data &&
                                                    lateArrivalArr?.data[2]
                                                        .startDate
                                                )
                                                .format('DD MMM, YYYY')}
                                            <br />
                                            Start Time:-&nbsp;
                                            {moment
                                                .utc(
                                                    lateArrivalArr?.data &&
                                                    lateArrivalArr?.data[2]
                                                        .startDate
                                                )
                                                .format('hh:mm:ss A')}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="tooltip">
                                        <Avatar
                                            style={{
                                                fontSize: 14,
                                                height: 36,
                                                width: 36,
                                                marginRight: '6px',
                                                background: '#c5c5c5',
                                            }}
                                        >
                                            <span>
                                                {' '}
                                                <b>3</b>
                                                <sup
                                                    style={{
                                                        fontSize: 8,
                                                        fontWeight: 900,
                                                    }}
                                                >
                                                    rd
                                                </sup>
                                            </span>
                                        </Avatar>
                                        <span className="tooltiptext">
                                            {lateArrivalArr?.data?.length == 1
                                                ? `2 instances `
                                                : `1  instance `}{' '}
                                            remaning
                                        </span>
                                    </div>
                                )}

                                {lateArrivalArr?.data.length > 3 ? (
                                    <Avatar
                                        style={{
                                            color: 'white',
                                            marginRight: 6,
                                            cursor: 'pointer',
                                            height: 36,
                                            width: 36,
                                            fontSize: 14,
                                            background: 'red',
                                        }}
                                        onClick={() => {
                                            handleOpen();
                                        }}
                                    >
                                        +{lateArrivalArr.count - 3}
                                    </Avatar>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}


                <Hidden smDown implementation="css">
                    {props.rtlActive ? (
                        <RTLNavbarLinks />
                    ) : (
                        <AdminNavbarLinks userDetails={props.userDetails} isTimeFormat={TimeFormat?.isTimeFormat} setIsTimeFormat={TimeFormat?.setIsTimeFormat} />
                    )}
                </Hidden>
                <Hidden mdUp implementation="css">
                    {/* <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton> */}

                    <AdminNavbarLinks userDetails={props.userDetails} isTimeFormat={TimeFormat?.isTimeFormat} setIsTimeFormat={TimeFormat?.setIsTimeFormat} />
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    closeDrawer: PropTypes.func,
    openDrawer: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object),
};
