import React, { useEffect, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import { icon } from 'helper/constant.jsx';
import { authenticationService } from 'services/authentication';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';

const AlreadyLoggedIn = ({ props }) => {
    const [userDetails, setUserDetails] = useState();
    const [isProfileLoader, setIsProfileLoader] = useState(true);

    useEffect(() => {
        userProfile();
    }, []);

    let generatePasswordToken = props.location.pathname.slice(19);

    let successToast = successMsg => {
        toast.success(successMsg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };

    const Container = {
        margin: '53px auto',
        maxWidth: 800,
        textAlign: 'left',
        padding: 20,
        background: '#fff',
    };

    const userProfile = async () => {
        try {
            setIsProfileLoader(true);
            const response = await authenticationService.me();
            setIsProfileLoader(false);
            setUserDetails(response?.data);
        } catch (error) {
            setIsProfileLoader(false);
            console.log(error);
        }
    };

    const handleCloseProfile = async () => {
        await authenticationService.logout();
        successToast('Logged out successful!');

        setTimeout(() => {
            window.location.replace(`/register-user/${generatePasswordToken}`);
        }, 500);
    };

    return (
        <>
            {!isProfileLoader ? (
                <div className="container-fluid" style={Container}>
                    <p>
                        <div style={{ display: 'flex' }}>
                            <img
                                src={icon}
                                alt="icon"
                                height="50px"
                                width="50px"
                            />
                            <div
                                style={{
                                    margin: '12px 0px 0px 5px',
                                    fontSize: '25px',
                                    fontWeight: 600,
                                }}
                            >
                                Daily Work Report
                            </div>
                        </div>
                    </p>
                    <h4 style={{ color: '#212b35', fontSize: '24px' }}>
                        You are currently logged in as{' '}
                        <b>{userDetails ? userDetails.email : 'Someone'} </b>
                        and it seems that you are the one who issued the
                        invitation recently. You cannot accept your own
                        invitation. Please re-login with another account to fix
                        the issue.
                    </h4>
                    <div
                        style={{
                            marginTop: '1rem',
                            fontSize: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {/* <a className="loginURL"
                  style={{ cursor: "pointer" }} onClick={handleCloseProfile}>Logout</a> */}
                        <button
                            style={{
                                padding: '10px 20px',
                                border: 'none',
                                fontSize: '18px',
                                backgroundColor: '#ADD8E6',
                                borderRadius: '50px',
                                background: '#f96117db',
                                color: 'white',
                                cursor: 'pointer',
                                //   boxShadow:
                                //       '0px 8px 15px rgba(173, 216, 230, 0.5)',
                            }}
                            onClick={handleCloseProfile}
                        >
                            Logout
                        </button>
                    </div>
                </div>
            ) : (
                <GridContainer align="center">
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <CircularProgress style={{ marginTop: '3rem' }} />
                    </GridItem>
                </GridContainer>
            )}
        </>
    );
};

export default AlreadyLoggedIn;
