import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { authenticationService } from "../../services/authentication";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "components/CustomButtons/Button.js";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import "react-datepicker/dist/react-datepicker.css";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { publish } from "helper/events/events";
import TotalShimmer from "components/Shimmer/TotalShimmer";
import DatePicker from "react-datepicker";
import TodayIcon from "@material-ui/icons/Today";
import WorkHistoryWrapper from "./WorkHistoryWrapper.style";
import { useHistory } from "react-router-dom";
import { ReactComponent as WorkHistoryTimelineSvg } from "../../../src/assets/img/WorkHistoryTimeline.svg";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  padding_none: {
    padding: 0,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 270,
  },
}));
export default function WorkHistory(props) {
  const history = useHistory();
  const TimeFormat = useContext(props?.TimeFormatContext);
  const messagesEndRef = useRef(null);
  const classes = useStyles();
  const [isLoad, setIsLoad] = React.useState(false);
  const [dailyReport, setDailyReport] = React.useState([]);
  const [timeEntries, setTimeEntries] = React.useState([]);
  const [viewReportDate, setViewReportDate] = React.useState(null);
  const [viewReportSubmited, setViewReportSubmited] = React.useState();
  const [viewReportDesc, setViewReportDesc] = React.useState(null);
  const [isLoadViewTable, setIsLoadViewTable] = React.useState(false);
  const [showReportTable, setShowReportTable] = React.useState(false);
  const [reportingManager, setReportingManager] = React.useState([]);
  const [extraNoteErr, setExtraNoteErr] = React.useState(false);
  const [extraNote, setExtraNote] = React.useState("");
  const [dailyReportId, setDailyReportId] = React.useState(null);
  const [totalWorkingHours, setTotalWorkingHours] = React.useState(null);
  const [buttonLable, setButtonLable] = React.useState("Resend Email");
  const [timeEntriesId, setTimeEntriesId] = React.useState([]);
  const [isSaveAsLoader, setIsSaveAsLoader] = useState(false);
  const [isSendMailLoader, setIsSendMailLoader] = useState(false);
  const [newProjectList, setNewProjectList] = React.useState([]);
  const [nullTimeEntries, setNullTimeEntries] = React.useState([]);
  const [isNextPageLoader, setIsNextPageLoader] = useState(false);
  const [isTimeFormateChecked, setIsTimeFormateChecked] = React.useState("");
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPages: 0,
    isLastPage: false,
  });
  const [isLoader, setIsLoader] = useState(false);
  const [startDate, setStartDate] = useState(
    moment()
      .startOf("isoWeek")
      .toDate()
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [hoveredDayIndex, setHoveredDayIndex] = useState(null);

  const [holidayDetails, setHolidayDetails] = useState([]);
  const [formattedHolidayDates,setFormattedHolidayDates] = useState([]);
  const [hoveredDate, setHoveredDate] = useState(null);


  const onChangeExtraNote = (data) => {
    let extraNote = data;
    if (extraNote.length > 5000) {
      setExtraNoteErr(true);
      setExtraNote(extraNote);
      return;
    }
    setExtraNoteErr(false);
    setExtraNote(extraNote);
  };

  React.useEffect(() => {
    fetchDetailsHistory();
    // getAllHolidayDetails();
  }, []);
  React.useEffect(() => {
    setIsTimeFormateChecked(TimeFormat?.isTimeFormat);
  }, [TimeFormat?.isTimeFormat]);

  useEffect(() => {
    if (isNextPageLoader == true) {
      scrollToBottom();
    }
    if (timeEntries) {
      TotalWorkingHours();
    }
  }, [isNextPageLoader, timeEntries]);

  let successToast = (successMsg) => {
    toast.success(successMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  let errorToast = (errMsg) => {
    toast.error(errMsg, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const colourStyles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "5px",
        height: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f4590d",
      },
    }),
    control: (base, state) => ({
      ...base,
      zIndex: state.isFocused ? "999" : "1",
      background: "#ffffff",
      // background: '#efefef',
      color: "#000000",
      fontWeight: "bold",
      padding: 20,
      // match with the menu
      height: 40,
      minHeight: 35,
      fontSize: 16,
      fontWeight: 400,
      cursor: "pointer",
      padding: "0px 8px",
      // border: 'none',
      // outline: 'none',
      // Overwrittes the different states of border
      borderColor: "#0000003b",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#0000003b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontWeight: "400",
        color: isDisabled
          ? "black"
          : isSelected
            ? "white"
              ? "white"
              : "black"
            : "black",
        cursor: "pointer",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#f96117db"
            : isFocused
              ? "#f961173d"
              : undefined,
        ":hover": {
          backgroundColor: isFocused && isSelected ? "#f96117db" : "#f961173d",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6"),
        },
      };
    },
  };
  const scrollToBottom = () => {
    const scroll =
      messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;
    messagesEndRef.current.scrollTo(0, scroll);
  };

  const isNumber = (e) => /^[0-9. :]+$/.test(e);

  const getStartValue = (data) => {
    let formatedStartTime = "";
    if (
      data.hasOwnProperty("startTime") &&
      isNumber(parseInt(data?.startTime)) &&
      data?.startTime?.length == 4 &&
      !data?.startTime?.includes(":")
    ) {
      formatedStartTime = `${data?.startTime.substr(0, 2) +
        ":" +
        data?.startTime.substr(2, 2)}`;
    }
    if (
      data.hasOwnProperty("startTime") &&
      isNumber(parseInt(data?.startTime)) &&
      data?.startTime?.length == 4 &&
      data?.startTime?.includes(":")
    ) {
      let a = data.startTime.slice(0, data.startTime.indexOf(":"));
      let b = ":";
      let c = data.startTime.slice(
        data.startTime.indexOf(":") + 1,
        data.startTime.length
      );
      let newA = a.length == 2 ? a : 0 + a;
      let newC = c;
      formatedStartTime = newA + b + newC;
    }
    if (
      /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        formatedStartTime
      )
    ) {
      return formatedStartTime;
    }
  };

  const getEndValue = (data) => {
    let formatedEndTime = "";
    if (
      data.hasOwnProperty("endTime") &&
      isNumber(parseInt(data?.endTime)) &&
      data?.endTime?.length == 4 &&
      !data?.endTime?.includes(":")
    ) {
      formatedEndTime = `${data?.endTime.substr(0, 2) +
        ":" +
        data?.endTime.substr(2, 2)}`;
    }
    if (
      data.hasOwnProperty("endTime") &&
      isNumber(parseInt(data?.endTime)) &&
      data?.endTime?.length == 4 &&
      data?.endTime?.includes(":")
    ) {
      let a = data.endTime.slice(0, data.endTime.indexOf(":"));
      let b = ":";
      let c = data.endTime.slice(
        data.endTime.indexOf(":") + 1,
        data.endTime.length
      );
      let newA = a.length == 2 ? a : 0 + a;
      let newC = c;
      formatedEndTime = newA + b + newC;
    }
    if (
      /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(formatedEndTime)
    ) {
      return formatedEndTime;
    }
  };

  const fetchDetailsHistory = async (isNextPage = false) => {
    try {
      setIsLoad(isNextPage != true ? true : false);
      !isNextPage && setIsLoader(true);
      let nextPage = parseInt(pageInfo.currentPage) + 1;
      isNextPage && setIsNextPageLoader(true);

      const response = await authenticationService.fetchNewHistory(
        125,
        nextPage
      );
      if (response) {
        if (response.error) {
          console.log(response.error);
        }
        if (response.data) {
          setDailyReport(
            isNextPage
              ? [...dailyReport, ...response?.data]
              : [...response?.data]
          );
          setPageInfo({
            currentPage: parseInt(response.currentPage),
            totalPages: response.totalPages,
            isLastPage: response.isLastPage,
          });
          isNextPage && setIsNextPageLoader(false);
          successToast("Worked report history fetched");
          setReportingManager(reportingManager);
          setIsLoad(false);
          handleProjectChange();
        }
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      isNextPage && setIsNextPageLoader(false);
      setIsLoad(false);
      if (error == "TypeError: Failed to fetch") {
        error = "Internal server error";
      }
      errorToast(error);
    }
  };


  const getAllHolidayDetails = async () => {
    try {
      const Response = await authenticationService.getHolidayList();

      if (Response && Response?.data) {

        setHolidayDetails(Response?.data)


          const dates = Response?.data.map((data) => {
            const date = new Date(data.holidayDate);
            return moment(date).format("YYYY-MM-DD");
          });

          
          setFormattedHolidayDates(dates);
      
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleProjectChange = async () => {
    try {
      const Response = await authenticationService.fetchProjectList();
      if (Response && Response.data) {
        let doGetProjectData = [];
        const groups = Response.data.reduce((groups, game) => {
          const groupsName = game.groups.groupName;
          if (!groups[groupsName]) {
            groups[groupsName] = [];
          }
          groups[groupsName].push(game);
          return groups;
        }, {});
        const groupArrays = Object.entries(groups)
          .sort(function (a, b) {
            const nameA = a[0].toUpperCase();
            const nameB = b[0].toUpperCase();
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }
            return 0;
          })
          .map((groupName) => {
            let data = {
              label: groupName[0],
              options: groupName[1]
                .sort(function (a, b) {
                  const nameA = a.projectName.toUpperCase();
                  const nameB = b.projectName.toUpperCase();
                  if (nameA > nameB) {
                    return 1;
                  }
                  if (nameA < nameB) {
                    return -1;
                  }
                  return 0;
                })
                .map((item, key) => {
                  return {
                    id: item.id,
                    name: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    label: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                    value: item?.projectCode
                      ? `${item?.projectCode} - ${item?.projectName}`
                      : item?.projectName,
                  };
                }),
            };
            doGetProjectData.push(data);
          });
        setNewProjectList(doGetProjectData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "" : "") : 0;
    var mDisplay = m > 0 ? m + (m == 1 ? "" : "") : 0;

    hDisplay = hDisplay > 9 ? hDisplay : "0" + hDisplay + ":";
    mDisplay = mDisplay > 9 ? mDisplay : "0" + mDisplay;
    return hDisplay + mDisplay;
  };

  const totalSecondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";
    return hDisplay + mDisplay;
  };

  const onSaveAsDraft = async (timeEntriesId) => {
    try {
      setIsSaveAsLoader(true);
      const response = await authenticationService.updateMultipalTimeDetails({
        dailyreport: {
          id: dailyReportId ? dailyReportId : null,
          description: extraNote,
        },
        date: moment(viewReportDate).format("DD-MM-YYYY"),
      });
      if (response.error) {
        console.log(response.error);
        setIsSaveAsLoader(false);
      } else {
        // notify.show("Report Saved!", "success");
        successToast("Report Saved!");
        setIsSaveAsLoader(false);
        if (dailyReport && dailyReport?.length) {
          let dailyReportRecord = [...dailyReport];
          await Promise.all(
            dailyReportRecord &&
            dailyReportRecord.map((item, index) => {
              if (item.id == dailyReportId) {
                dailyReportRecord[index].description =
                  response.data.description;
              }
            })
          );
          setDailyReport(dailyReportRecord);
        }
      }
    } catch (error) {
      console.log(error);
      setIsSaveAsLoader(false);
    }
  };

  const handleOnSubmitManagement = async () => {
    let remarkData = false;
    let timeEntriesRemarkArr = [];

    for (let i = 0; i < timeEntries.length; i++) {
      let remark_length = timeEntries[i].remark.length;
      if (remark_length == 0) {
        timeEntriesRemarkArr.push({
          remark1: remark_length,
        });
      }
    }

    if (timeEntriesRemarkArr.length == 0) {
      remarkData = true;
    } else {
      remarkData = false;
    }

    let reportId = dailyReportId;
    try {
      setIsSendMailLoader(true);
      if (remarkData == true) {
        const response = await authenticationService.sendDailyReportData(
          { description: extraNote },
          reportId
        );
        if (response.emailStatus == false && response.failedStatus == true) {
          console.log(response.error);
          setIsSendMailLoader(false);
        } else {
          successToast("Your work report successfully sent! Thank you!");
          setTimeout(() => {
            window.location.replace("/panel/history");
          }, 1000);
        }
      } else {
        errorToast("All task note need to be provided");
        setIsSendMailLoader(false);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  const onViewReport = async (row) => {
    let emailSent = row.emailLog.filter((item) => item.emailStatus != "DRAFT");
    setButtonLable(
      emailSent.length > 0 ? "Resend Email" : "Send To Management"
    );
    setIsLoadViewTable(true);
    let date = row.date;
    let desc = row.description;
    let timeEntries = row.timeEntries;
    let sum = 0;
    timeEntries = timeEntries.sort(
      (a, b) => new Date(a.startDate) - new Date(b.startDate)
    );
    await Promise.all(
      timeEntries.map(async (item, index) => {
        item.projectName = item.project ? item.project.projectName : "";
        item.reportingManager = item.project
          ? item.project.reportingManager
            ? item.project.reportingManager.email
            : 0
          : 0;
        item.defaultRM = item.project
          ? item.project.reportingManager
            ? item.project.reportingManager.email
            : 0
          : 0;
        item.start = moment(item.startDate).format("hh:mm A");
        if (item?.duration) {
          let duration = item.duration;
          item.end = moment(item.endDate).format("hh:mm A");
          item.timeDiff = duration;
        } else {
          item.timeDiff = "NA";
          item.end = "NA";
        }
      })
    );
    let totalWorkingHour = null;
    let checkTotalForEmpty = timeEntries.filter(
      (item) => item.timeDiff == "NA"
    );
    if (checkTotalForEmpty.length > 0) {
      totalWorkingHour = "NA";
    } else {
      timeEntries &&
        timeEntries.map((item, key) => {
          let m1 = new Date(item.startDate);
          let m2 = new Date(item.endDate);
          let seconds = Math.abs((m2.getTime() - m1.getTime()) / 1000);
          sum = parseInt(sum) + parseInt(seconds);
        });
      let finalDuration = await totalSecondsToHms(sum);
      totalWorkingHour = finalDuration;
      setTotalWorkingHours(totalWorkingHour);
    }
    setDailyReportId(row.id);
    desc ? setExtraNote(desc) : setExtraNote("");
    setShowReportTable(true);
    setViewReportDate(date);
    setViewReportDesc(desc);
    setViewReportSubmited(row.isSubmited);
    setNullTimeEntries(row.timeEntries);
    setTimeEntries(
      row.timeEntries.filter((finalVal) => {
        return finalVal.endDate != null;
      })
    );
    setIsLoadViewTable(false);
  };

  const newUpdatedProjectShow = (newProjectList, projectId) => {
    let project = [];
    newProjectList.map((value, key) => {
      value.options.map((item, key) => {
        if (item.id == projectId) {
          project.push(item);
        }
      });
    });
    return project;
  };

  const doHandleCheckValidTime = (time, type) => {
    try {
      let formattedTime = "";
      if (isTimeFormateChecked == 24) {
        if (!isNumber(parseInt(time))) {
          errorToast(`${type} must be valid!`, "error");
          return;
        }
        if (!time.includes(":")) {
          if (time.length == 1) {
            formattedTime = `0${time.substr(0, 1) + ":" + "00"}`;
          }
          if (time.length == 2) {
            formattedTime = `${time.substr(0, 2) + ":" + "00"}`;
          }
          if (time.length == 3) {
            formattedTime = `0${time.substr(0, 1) + ":" + time.substr(1, 2)}`;
          }
          if (time.length == 4) {
            formattedTime = `${time.substr(0, 2) + ":" + time.substr(2, 2)}`;
          }
        }
        if (time.includes(":")) {
          if (time.length == 4) {
            let a = time.slice(0, time.indexOf(":"));
            let b = ":";
            let c = time.slice(time.indexOf(":") + 1, time.length);
            let newA = a.length == 2 ? a : 0 + a;
            let newC = c;
            formattedTime = newA + b + newC;
          }
          if (time.length == 5) {
            formattedTime = time;
          }
        }
        let isValid = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(
          formattedTime
        );
        if (!isValid) {
          errorToast(`${type} must be valid!`, "error");
        }
        return formattedTime;
      } else {
        let manipulatedTime = "";
        if (!time.includes(":")) {
          if (time.length == 3) {
            manipulatedTime = `0${time.substr(0, 1) +
              ":" +
              "00" +
              time.substr(-2)}`;
          }
          if (time.length == 4) {
            manipulatedTime = `${time.substr(0, 2) +
              ":" +
              "00" +
              time.substr(-2)}`;
          }
          if (time.length == 6) {
            manipulatedTime = `0${time.substr(0, 1) +
              ":" +
              time.substr(1, 2) +
              time.substr(-2)}`;
          }
          if (time.length == 6) {
            manipulatedTime = `${time.substr(0, 2) +
              ":" +
              time.substr(2, 2) +
              time.substr(-2)}`;
          }
        }
        if (time.includes(":")) {
          if (time.length == 6) {
            let a = time.slice(0, time.indexOf(":"));
            let b = ":";
            let c = time.slice(time.indexOf(":") + 1, time.length);
            let newA = a.length == 2 ? a : 0 + a;
            let newC = c;
            manipulatedTime = newA + b + newC;
          }
          if (time.length == 7) {
            manipulatedTime = time;
          }
        }
        let isValid = /^([01]?[0-9]):([0-5][0-9])([AaPp][Mm])?$/.test(
          manipulatedTime
        );
        if (!isValid) {
          errorToast(`${type} must be valid!`, "error");
        }
        if (manipulatedTime != "") {
          if (
            manipulatedTime.substr(-2) == "PM" ||
            manipulatedTime.substr(-2) == "pm" ||
            manipulatedTime.substr(-2) == "Pm" ||
            manipulatedTime.substr(-2) == "pM" ||
            manipulatedTime.substr(-1) == "P" ||
            manipulatedTime.substr(-1) == "p"
          ) {
            formattedTime =
              (parseInt(manipulatedTime.substr(0, 2)) == 12
                ? parseInt(manipulatedTime.substr(0, 2)) + 0
                : parseInt(manipulatedTime.substr(0, 2)) + 12) +
              ":" +
              manipulatedTime.substr(3, 2);
          } else if (
            manipulatedTime.substr(-2) == "AM" ||
            manipulatedTime.substr(-2) == "am" ||
            manipulatedTime.substr(-2) == "Am" ||
            manipulatedTime.substr(-2) == "aM" ||
            manipulatedTime.substr(-1) == "A" ||
            manipulatedTime.substr(-1) == "a"
          ) {
            formattedTime =
              (parseInt(manipulatedTime.substr(0, 2)) == 12
                ? "00"
                : manipulatedTime.substr(0, 2)) +
              ":" +
              manipulatedTime.substr(3, 2);
          }
        }
        return formattedTime;
      }
    } catch (error) { }
  };

  const TotalWorkingHours = () => {
    let sum = 0;
    let UpdatedTime;
    timeEntries &&
      timeEntries.map((item, key) => {
        let m1 = new Date(item.startDate);
        let m2 = new Date(item.endDate);
        let seconds = Math.abs((m2.getTime() - m1.getTime()) / 1000);
        sum = parseInt(sum) + parseInt(seconds);
      });
    let d = sum;
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "00h ";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "00m ";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "00s";
    UpdatedTime =
      hDisplay.includes("00h") && mDisplay.includes("00m")
        ? hDisplay + mDisplay + sDisplay
        : hDisplay + mDisplay;
    return UpdatedTime;
  };

  const ExampleCustomInput = ({ value, onClick }) => (
    <div
      className="datepicker"
      style={{
        borderRadius: "5px",
        fontWeight: "bold",
        padding: "8px 12px",
        cursor: "pointer",
        border: "1px solid rgb(198, 210, 217)",
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = "#EEEEEE";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = "#ffffff";
      }}
      onClick={onClick}
    >
      <div
        style={{
          paddingTop: 0,
          display: "flex",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <div style={{ color: "#666" }}>
          {moment(value).format("DD-MMM-YYYY")}
        </div>
      </div>
    </div>
  );

  const dayClassName = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const reportForDay = dailyReport.find((report) =>
      moment(report.date).isSame(formattedDate, "day")
    );

    let dotColor = "transparent";

    if (reportForDay) {
      if (reportForDay.isSubmited === true) {
        dotColor = "green";
      } else if (reportForDay.isSubmited === false) {
        dotColor = "#ffd54f";
      }
    }

    else if (
      formattedHolidayDates.includes(
        moment(date).format("YYYY-MM-DD")
      )
    ) {
      dotColor = "#0000FF";
    }

    return dotColor ? `has-dot dot-${dotColor.replace("#", "")}` : "";
  };

  useEffect(() => {
    setSelectedDate(new Date());
    const todayDayReport = dailyReport.find((report) => {
      return moment(report.date).isSame(new Date(), "day");
    });

    if (todayDayReport) {
      onViewReport(todayDayReport);
    } else {
      setViewReportDate(new Date());
    }
  }, [dailyReport]);

  const prevWeek = () => {
    setStartDate(
      moment(startDate)
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .toDate()
    );
  };

  const nextWeek = () => {
    setStartDate(
      moment(startDate)
        .add(1, "weeks")
        .startOf("isoWeek")
        .toDate()
    );
  };

  const handleDayClick = (day) => {
    setSelectedDate(selectedDate === day ? null : day);

    // Find the report for the clicked day
    const clickedDayReport = dailyReport.find((report) =>
      moment(report.date).isSame(day, "day")
    );

    if (!clickedDayReport) {
      setShowReportTable(false);
      setViewReportDate(day);
    } else {
      onViewReport(clickedDayReport);
    }
  };

  const renderDay = (dayOffset) => {
    const day = moment(startDate)
      .add(dayOffset, "days")
      .toDate();
    const isSelected = moment(day).isSame(selectedDate, "day");
    const isFutureDate = moment(day).isAfter(moment(), "day");

    const dotId = `dot_${dayOffset}`;
    const dotElement = document.getElementById(dotId);

    if (dotElement) {
      let backgroundColor = "transparent";

      const reportForDay = dailyReport.find((report) => {
        return moment(report.date).isSame(day, "day");
      });

      if (reportForDay) {
        if (reportForDay.isSubmited === true) {
          backgroundColor = "green";
        } else if (reportForDay.isSubmited === false) {
          backgroundColor = "#ffd54f";
        }
      }

      else if (
        formattedHolidayDates.includes(
          moment(day).format("YYYY-MM-DD")
        )
      ) {
        backgroundColor = "#0000FF";
      }

      

      dotElement.style.backgroundColor = backgroundColor;
    }

    const isWeekend =
      moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7;

    const reportForDay = dailyReport.find((report) =>
      moment(report.date).isSame(day, "day")
    );

    // Define a style object for the common styles
    const commonStyle = {
      textAlign: "center",
      padding: "16px 16px 8px 16px",
      width: "10%",
      backgroundColor: "white",
      cursor:
        (isWeekend && !reportForDay) || isFutureDate
          ? "not-allowed"
          : "pointer",
      borderBottom: `2px solid ${hoveredDayIndex === dayOffset || isSelected
        ? "rgb(244, 89, 13)"
        : "transparent"
        }`,

      transition: "border-color 0.3s ease",
      color:
        isFutureDate || (isWeekend && !reportForDay)
          ? "#a0a0a0"
          : isSelected
            ? "rgb(244, 89, 13)"
            : "black",
    };

    return (
      <div
        key={dayOffset}
        style={commonStyle}
        onClick={() =>
          isFutureDate || (isWeekend && !reportForDay)
            ? null
            : handleDayClick(day)
        }
        onMouseOver={() => {
          !isSelected && setHoveredDayIndex(dayOffset);
        }}
        onMouseOut={() => {
          setHoveredDayIndex(null);
        }}
        title={
          isWeekend && !reportForDay
            ? "Full day Weekly-off"
            : `${moment(day).format("DD-MMM-YYYY")}`
        }
      >
        <div
          style={{
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          {moment(day).format("ddd")}
        </div>

        <div
          style={{
            fontSize: "18px",
          }}
        >
          {moment(day).format("DD-MMM")}
        </div>

        <div>
          <span
            id={dotId}
            style={{
              marginTop: "8px",
              height: "8px",
              width: "8px",
              backgroundColor: "",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
        </div>
      </div>
    );
  };

  const filteredDailyReport = useMemo(() => {
    return dailyReport.reduce((acc, report) => {
      const reportDateFormatted = moment(report.date).format("YYYY-MM-DD");
      acc[reportDateFormatted] = report;
      return acc;
    }, {});
  }, [dailyReport]);



  return (
    <div className="history_spacing">
      {isLoad ? (
        <GridContainer align="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {/* <CircularProgress /> */}
            <div style={{ marginTop: 40 }}>
              <TotalShimmer />
              <br></br>
              <TotalShimmer />
              <br></br>
              <TotalShimmer />
              <br></br>
              <TotalShimmer />
              <br></br>
              <TotalShimmer />
              <br></br>
              <TotalShimmer />
              <br></br>
            </div>
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <WorkHistoryWrapper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setStartDate(
                      moment(date)
                        .startOf("isoWeek")
                        .toDate()
                    );
                    handleDayClick(date);
                  }}
                  // showMonthYearDropdown
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<ExampleCustomInput />}
                  dateFormat="dd MMMM yyyy"
                  maxDate={new Date()}
                  className="form-control custom-datepicker"
                  calendarClassName="custom-calender-class"
                  dayClassName={dayClassName}
                  filterDate={(date) => {
                    // Get the day of the week
                    const dayOfWeek = date.getDay();

                    // Check if it's Saturday or Sunday
                    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

                    // Check if there's a report for the day
                    const reportForDay =
                      filteredDailyReport[moment(date).format("YYYY-MM-DD")];

                    // Disable weekends (Saturday and Sunday) unless there's a report
                    return !isWeekend || (isWeekend && reportForDay);
                  }}

                  renderDayContents={(day, date) => {
                    const formattedDate = moment(date).format("YYYY-MM-DD");
                    const holiday = holidayDetails.find(
                      (holiday) => moment(holiday.holidayDate).format("YYYY-MM-DD") === formattedDate
                    );
          
                    return (
                      <div
                        className="day-wrapper"
                        onMouseEnter={() => holiday && setHoveredDate(holiday.holidayName)}
                        onMouseLeave={() => setHoveredDate(null)}
                      >
                        {day}
                        {hoveredDate === holiday?.holidayName && (
                          <div className="tooltip">
                            {holiday.holidayName}
                          </div>
                        )}
                      </div>
                    );
                  }}

                />
              </div>
              <div>
                <button
                  className="datepicker"
                  style={{
                    borderRadius: "5px",
                    fontWeight: "bold",
                    padding: "12px 18px",
                    cursor: "pointer",
                    border: "1px solid rgb(198, 210, 217)",
                  }}
                  onClick={() => {
                    handleDayClick(new Date());
                    setSelectedDate(new Date());
                    setStartDate(
                      moment(new Date())
                        .startOf("isoWeek")
                        .toDate()
                    );
                  }}
                >
                  Today
                </button>
              </div>
            </div>
            <div
              style={{
                paddingBottom: "10px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <div
                className="datepicker"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  border: "1px solid #c6d2d9",
                  borderRadius: "8px",
                  padding: "5px",
                  marginRight: "22px",
                }}
                onClick={prevWeek}
              >
                <i
                  className="fa fa-angle-left"
                  style={{
                    margin: "0px 5px",
                    color: "#57646b",
                    fontSize: "16px",
                  }}
                />
              </div>
              <GridContainer
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                  width: "100%",
                  backgroundColor: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {[0, 1, 2, 3, 4, 5, 6].map((dayOffset) => renderDay(dayOffset))}
              </GridContainer>
              <div
                className="datepicker"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  border: "1px solid #c6d2d9",
                  borderRadius: "8px",
                  padding: "5px",
                  marginLeft: "22px",
                }}
                onClick={nextWeek}
              >
                <i
                  className="fa fa-angle-right"
                  style={{
                    margin: "0px 5px",
                    color: "#57646b",
                    fontSize: "16px",
                  }}
                />
              </div>
            </div>
          </WorkHistoryWrapper>
        </>

        // <GridContainer>
        //   {/* <GridItem   xs={12} sm={12} md={12} > */}
        //   <Card style={{ marginTop: 0 }}>
        //     <CardBody>
        //       <Paper className={classes.root} elevation={0}>
        //         <TableContainer
        //           ref={messagesEndRef}
        //           className={classes.container}
        //           style={{
        //             minHeight: showReportTable ? 270 : 400,
        //             // marginTop: '00px',
        //           }}
        //         >
        //           <Table className={classes.table} aria-label="simple table">
        //             <TableHead>
        //               <TableRow
        //                 style={{
        //                   paddingTop: 7,
        //                   paddingBottom: 7,
        //                 }}
        //               >
        //                 <TableCell
        //                   style={{
        //                     paddingTop: 7,
        //                     paddingBottom: 7,
        //                   }}
        //                 >
        //                   Report Date
        //                 </TableCell>
        //                 <TableCell
        //                   style={{
        //                     paddingTop: 7,
        //                     paddingBottom: 7,
        //                   }}
        //                   align="left"
        //                 >
        //                   Email Status
        //                 </TableCell>
        //                 <TableCell
        //                   style={{
        //                     paddingTop: 7,
        //                     paddingBottom: 7,
        //                   }}
        //                   align="left"
        //                 >
        //                   Total Email Sent{" "}
        //                 </TableCell>
        //                 <TableCell
        //                   style={{
        //                     paddingTop: 7,
        //                     paddingBottom: 7,
        //                   }}
        //                   align="left"
        //                 >
        //                   Email Sent At{" "}
        //                 </TableCell>
        //               </TableRow>
        //             </TableHead>
        //             <TableBody>
        //               {dailyReport
        //                 .sort(function(a, b) {
        //                   return new Date(b.date) - new Date(a.date);
        //                 })
        //                 .map((row) => {
        //                   return row.emailLog.length > 0 ? (
        //                     <TableRow
        //                       onClick={() => onViewReport(row)}
        //                       style={{
        //                         paddingTop: 7,
        //                         paddingBottom: 7,
        //                         cursor: "pointer",
        //                       }}
        //                     >
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         component="th"
        //                         scope="row"
        //                       >
        //                         {moment(row.date).format("D-MMM-YYYY")}
        //                       </TableCell>
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         align="left"
        //                       >
        //                         <Chip
        //                           label={
        //                             row.emailLog &&
        //                             row.emailLog[0].emailStatus === true &&
        //                             row.emailLog[0].failedStatus === false
        //                               ? "SENT"
        //                               : row.emailLog &&
        //                                 row.emailLog[0].emailStatus ===
        //                                   false &&
        //                                 row.emailLog[0].failedStatus === true
        //                               ? "FAILED"
        //                               : "PENDING"
        //                           }
        //                           style={{
        //                             backgroundColor:
        //                               row.emailLog &&
        //                               row.emailLog[0].emailStatus === true &&
        //                               row.emailLog[0].failedStatus === false
        //                                 ? "#4caf50"
        //                                 : row.emailLog &&
        //                                   row.emailLog[0].emailStatus ===
        //                                     false &&
        //                                   row.emailLog[0].failedStatus ===
        //                                     true
        //                                 ? "#FF5723"
        //                                 : "#FFD54F",
        //                             color:
        //                               row.emailLog &&
        //                               row.emailLog[0].emailStatus === true &&
        //                               row.emailLog[0].failedStatus === false
        //                                 ? "#fff"
        //                                 : row.emailLog &&
        //                                   row.emailLog[0].emailStatus ===
        //                                     false &&
        //                                   row.emailLog[0].failedStatus ===
        //                                     true
        //                                 ? "#fff"
        //                                 : "#000",
        //                             width: 100,
        //                           }}
        //                         />
        //                       </TableCell>
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         align="left"
        //                       >
        //                         {row.totalEmailCount}
        //                       </TableCell>
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         align="left"
        //                       >
        //                         {row.emailLog.map((value, key) => {
        //                           return value.createdAt
        //                             ? moment(value.createdAt).format(
        //                                 "D-MMM-YYYY hh:mm A"
        //                               )
        //                             : "-";
        //                         })}
        //                       </TableCell>
        //                     </TableRow>
        //                   ) : (
        //                     <TableRow
        //                       onClick={() => onViewReport(row)}
        //                       style={{
        //                         paddingTop: 7,
        //                         paddingBottom: 7,
        //                         cursor: "pointer",
        //                       }}
        //                     >
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         component="th"
        //                         scope="row"
        //                       >
        //                         {moment(row.date).format("D-MMM-YYYY")}
        //                       </TableCell>
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         align="left"
        //                       >
        //                         <Chip
        //                           label={"PENDING"}
        //                           style={{
        //                             backgroundColor: "#FFD54F",
        //                             color: "#000",
        //                             width: 100,
        //                           }}
        //                         />
        //                       </TableCell>
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         align="left"
        //                       >
        //                         {0}
        //                       </TableCell>
        //                       <TableCell
        //                         style={{
        //                           paddingTop: 7,
        //                           paddingBottom: 7,
        //                         }}
        //                         align="left"
        //                       ></TableCell>
        //                     </TableRow>
        //                   );
        //                 })}
        //             </TableBody>
        //           </Table>
        //           {!pageInfo.isLastPage && pageInfo.currentPage != "0" ? (
        //             <div
        //               style={{
        //                 fontSize: 14,
        //                 textAlign: "center",

        //                 paddingBottom: 10,
        //                 display: "flex",
        //                 justifyContent: "center",
        //                 alignItems: "center",
        //               }}
        //             >
        //               {isNextPageLoader ? (
        //                 <div
        //                   className=""
        //                   style={{
        //                     color: "#709dcd",
        //                     marginTop: 10,
        //                     width: "fit-content",
        //                   }}
        //                 >
        //                   {/* <LinearLoader height="35px" width="130px" /> */}
        //                   fetching......
        //                 </div>
        //               ) : (
        //                 <div
        //                   style={{
        //                     display: "flex",
        //                     justifyContent: "center",
        //                     marginTop: 10,
        //                     cursor: "pointer",
        //                   }}
        //                   onClick={() =>
        //                     !pageInfo.isLastPage
        //                       ? fetchDetailsHistory(true)
        //                       : {}
        //                   }
        //                 >
        //                   <div
        //                     className=""
        //                     style={{
        //                       margin: "0px",
        //                       borderRadius: "7px",
        //                       color: "#709dcd",
        //                     }}
        //                   >
        //                     View more
        //                   </div>
        //                 </div>
        //               )}
        //             </div>
        //           ) : (
        //             <></>
        //           )}
        //         </TableContainer>
        //       </Paper>
        //       {dailyReport.length == 0 && (
        //         <GridContainer align="center">
        //           <GridItem xs={12} sm={12} md={12} lg={12}>
        //             <div
        //               style={{
        //                 marginTop: "5%",
        //                 marginBottom: "5%",
        //               }}
        //             >
        //               No record to display
        //             </div>
        //           </GridItem>
        //         </GridContainer>
        //       )}
        //     </CardBody>
        //   </Card>
        //   {/* </GridItem> */}
        // </GridContainer>
      )}

      {showReportTable && (
        <Card style={{ marginTop: 0 }}>
          <CardBody style={{ padding: "0px 15px 15px 15px" }}>
            <GridContainer
              style={{
                // backgroundColor: '#E4EAEE',
                // border: '1px solid #C6D2D9',
                height: 60,
              }}
            >
              <GridItem xs={12} sm={4} md={4} lg={4} align="right">
                <GridContainer align="left">
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <p style={{ fontSize: 18 }}>
                        {moment(viewReportDate).format("DD-MMM-YYYY")}
                      </p>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          marginLeft: 15,
                        }}
                      >
                        <Chip
                          label={
                            viewReportSubmited == true ? "SENT" : "PENDING"
                          }
                          className={
                            viewReportSubmited == true
                              ? "report-primary"
                              : "report-secondary"
                          }
                        />
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4} align="right"></GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4} align="right">
                {totalWorkingHours && (
                  <p style={{ fontSize: 14 }}>
                    {"Total: "}
                    <span style={{ fontSize: 16 }}>
                      {nullTimeEntries.length == timeEntries.length ? (
                        <span>
                          <span
                            style={{
                              marginRight: 10,
                              fontWeight: 700,
                            }}
                          >
                            <TotalWorkingHours />
                          </span>{" "}
                          {/* <span
                            onClick={(e) => setShowReportTable(false)}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            &#10006;
                          </span> */}
                        </span>
                      ) : (
                        <>
                          <b>NA</b>
                          <Tooltip title="Please stop the timer and reload the page to view total working hours">
                            <IconButton aria-label="delete">
                              <ContactSupportIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {totalWorkingHours == "NA" && (
                        <Tooltip title="Please stop the timer from clockify and reload the page to view total working hours">
                          <IconButton aria-label="delete">
                            <ContactSupportIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </span>
                  </p>
                )}
              </GridItem>
            </GridContainer>
            <GridContainer align="left">
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title={moment(viewReportDate).format("D-MMM-YYYY")}
                  columns={[
                    {
                      title: "Task Details",
                      field: "taskDetails",
                      editable: "onUpdate",
                      // width: '25%',
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      editComponent: (props, rowData) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              marginRight: 25,
                              width: "60%",
                            }}
                          >
                            <TextField
                              fullWidth
                              size="small"
                              id="fullWidth"
                              variant="outlined"
                              value={
                                props?.rowData?.taskDetails
                                  ? props?.rowData?.taskDetails?.title
                                  : props?.rowData?.title
                              }
                              onChange={(e) => {
                                props.onChange({
                                  ...props.rowData.taskDetails,
                                  title: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              width: "290px",
                            }}
                          >
                            <Select
                              maxMenuHeight={145}
                              styles={colourStyles}
                              components={{
                                // DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              value={
                                props?.rowData?.taskDetails?.projectId
                                  ? newProjectList &&
                                  newUpdatedProjectShow(
                                    newProjectList,
                                    props?.rowData?.taskDetails?.projectId
                                  )
                                  : {
                                    id: props?.rowData?.project?.id,
                                    name: props?.rowData?.project?.projectCode
                                      ? `${props?.rowData?.project?.projectCode} - ${props?.rowData?.project?.projectName}`
                                      : props?.rowData?.project?.projectName,
                                    label: props?.rowData?.project
                                      ?.projectCode
                                      ? `${props?.rowData?.project?.projectCode} - ${props?.rowData?.project?.projectName}`
                                      : props?.rowData?.project?.projectName,
                                    value: props?.rowData?.project
                                      ?.projectCode
                                      ? `${props?.rowData?.project?.projectCode} - ${props?.rowData?.project?.projectName}`
                                      : props?.rowData?.project?.projectName,
                                  }
                              }
                              onChange={(e) => {
                                if (e) {
                                  props.onChange({
                                    ...props.rowData.taskDetails,
                                    projectId: e.id,
                                  });
                                }
                              }}
                              options={newProjectList}
                            />
                          </div>
                        </div>
                      ),
                      render: (rowData) => {
                        if (rowData.projectName == "TOTAL") {
                          return (
                            <>
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {/* {rowData.projectName + `: ${rowData.timeDiff} `} */}
                              </span>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <span
                                style={{
                                  position: "relative",
                                  top: 5,
                                }}
                              >
                                {rowData?.project?.projectCode
                                  ? `${rowData?.project?.projectCode} - ${rowData?.project?.projectName}` +
                                  ` ( ${rowData.duration})`
                                  : rowData?.project?.projectName +
                                  ` ( ${rowData.duration})`}{" "}
                                <br /> {rowData.title}
                              </span>
                            </>
                          );
                        }
                      },
                    },
                    {
                      title: "version",
                      field: "version",
                      width: "8%",
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      render: (rowData) =>
                      (
                        <div
                          style={{
                            position: "relative",
                            top: 13,
                          }}
                        >
                          {rowData.version ? rowData.version : '-'}
                        </div>
                      ),
                    },

                    {
                      title: "StartTime",
                      field: "startTime",
                      editable: "onUpdate",
                      width: "8%",
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      editComponent: (props, rowData) => (
                        <div>
                          <Typography className={classes.typography}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: isTimeFormateChecked != 24 ? 100 : 76,
                              }}
                            >
                              <TextField
                                className="timeNewInput"
                                variant="outlined"
                                size="small"
                                value={getStartValue(props.rowData)}
                                defaultValue={
                                  props.rowData.rowData != ""
                                    ? isTimeFormateChecked == 24
                                      ? moment(
                                        props?.rowData?.startDate
                                      ).format("HH:mm")
                                      : moment(
                                        props?.rowData?.startDate
                                      ).format("hh:mmA")
                                    : "00:00"
                                }
                                inputProps={{
                                  maxLength: isTimeFormateChecked != 24 ? 7 : 5,
                                }}
                                onClick={(e) => {
                                  e.target.focus();
                                  e.target.select();
                                }}
                                onBlur={() => {
                                  if (
                                    props?.rowData?.hasOwnProperty("startTime")
                                  ) {
                                    doHandleCheckValidTime(
                                      props?.rowData?.startTime,
                                      "startTime"
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                }}
                                placeholder={
                                  props.rowData.rowData != ""
                                    ? isTimeFormateChecked == 24
                                      ? moment(
                                        props?.rowData?.startDate
                                      ).format("HH:mm")
                                      : moment(
                                        props?.rowData?.startDate
                                      ).format("hh:mm A")
                                    : "00:00"
                                }
                              />
                            </div>
                          </Typography>
                        </div>
                      ),
                      render: (rowData) => {
                        if (rowData.projectName == "TOTAL") return;
                        return (
                          <div
                            style={{
                              position: "relative",
                              top: 13,
                            }}
                          >
                            {rowData != ""
                              ? isTimeFormateChecked == 24
                                ? moment(rowData?.startDate).format("HH:mm")
                                : moment(rowData?.startDate).format("hh:mm A")
                              : "00:00"}
                          </div>
                        );
                      },
                    },
                    {
                      title: "EndTime",
                      field: "endTime",
                      editable: "onUpdate",
                      width: isTimeFormateChecked != 24 ? "100px" : "80px",
                      cellStyle: {
                        verticalAlign: "top",
                      },
                      sorting: false,
                      filtering: false,
                      editComponent: (props) => (
                        <div>
                          <Typography className={classes.typography}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: isTimeFormateChecked != 24 ? 100 : 76,
                              }}
                            >
                              <TextField
                                className="timeNewInput"
                                variant="outlined"
                                size="small"
                                value={getEndValue(props.rowData)}
                                defaultValue={
                                  props.rowData.rowData != ""
                                    ? isTimeFormateChecked == 24
                                      ? moment(props.rowData.endDate).format(
                                        "HH:mm"
                                      )
                                      : moment(props.rowData.endDate).format(
                                        "hh:mmA"
                                      )
                                    : "00:00"
                                }
                                inputProps={{
                                  maxLength: isTimeFormateChecked != 24 ? 7 : 5,
                                }}
                                onClick={(e) => {
                                  e.target.focus();
                                  e.target.select();
                                }}
                                onBlur={() => {
                                  if (
                                    props?.rowData?.hasOwnProperty("endTime")
                                  ) {
                                    doHandleCheckValidTime(
                                      props?.rowData?.endTime,
                                      "endTime"
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                }}
                                placeholder={
                                  props.rowData.rowData != ""
                                    ? isTimeFormateChecked == 24
                                      ? moment(props.rowData.endDate).format(
                                        "HH:mm"
                                      )
                                      : moment(props.rowData.endDate).format(
                                        "hh:mm A"
                                      )
                                    : "00:00"
                                }
                              />
                            </div>
                          </Typography>
                        </div>
                      ),
                      render: (rowData) => {
                        if (rowData.projectName == "TOTAL") return;
                        return (
                          <div
                            style={{
                              position: "relative",
                              top: 13,
                            }}
                          >
                            {rowData != ""
                              ? isTimeFormateChecked == 24
                                ? moment(rowData.endDate).format("HH:mm")
                                : moment(rowData.endDate).format("hh:mm A")
                              : "00:00"}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Task Note*",
                      field: "remark",
                      editable: "onUpdate",
                      width: "35%",
                      sorting: false,
                      filtering: false,
                      editComponent: (props) => (
                        <CKEditor
                          editor={ClassicEditor}
                          data={props.value}
                          onInit={(editor) => { }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            props.onChange(data);
                          }}
                          onBlur={(event, editor) => { }}
                          onFocus={(event, editor) => { }}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                            ],
                          }}
                        />
                      ),
                      render: (rowData) => (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: rowData.remark,
                          }}
                        ></div>
                      ),
                      cellStyle: {
                        verticalAlign: "text-top",
                      },
                    },
                  ]}
                  data={timeEntries.sort((a, b) => {
                    return (
                      new Date(b.startDate) - new Date(a.startDate) ||
                      new Date(b.endDate) - new Date(a.endDate)
                    );
                  })}
                  // editable={{
                  //   isEditable: (rowData) => (rowData.endDate ? true : false),
                  //   onRowUpdate: (newData, oldData) =>
                  //     new Promise(async (resolve, reject) => {
                  //       {
                  //         let updatedStartDate = {
                  //           status: false,
                  //           date: "",
                  //         };
                  //         let updatedEndDate = {
                  //           status: false,
                  //           date: "",
                  //         };
                  //         let formattedStartTime = "";
                  //         let formattedEndTime = "";
                  //         if (newData?.hasOwnProperty("startTime")) {
                  //           formattedStartTime = doHandleCheckValidTime(
                  //             newData?.startTime,
                  //             "startTime"
                  //           );
                  //         }
                  //         if (newData?.hasOwnProperty("endTime")) {
                  //           formattedEndTime = doHandleCheckValidTime(
                  //             newData?.endTime,
                  //             "endTime"
                  //           );
                  //         }
                  //         let newStartUpdatedDate = moment(newData.startDate);
                  //         if (newData?.startTime) {
                  //           newStartUpdatedDate.set({
                  //             h: formattedStartTime?.split(":")[0],
                  //             minutes: formattedStartTime?.split(":")[1],
                  //             second: 0,
                  //             millisecond: 0,
                  //           });
                  //         }
                  //         let newEndUpdatedDate = moment(newData.endDate);
                  //         if (newData?.endTime) {
                  //           newEndUpdatedDate.set({
                  //             h: formattedEndTime?.split(":")[0],
                  //             minutes: formattedEndTime?.split(":")[1],
                  //             second: 0,
                  //             millisecond: 0,
                  //           });
                  //         }

                  //         const isStartFuture = await newStartUpdatedDate.isAfter(
                  //           moment()
                  //         );
                  //         const isEndTimeFuture = await newEndUpdatedDate.isAfter(
                  //           moment()
                  //         );
                  //         let errorDetails = {
                  //           status: false,
                  //           message: "",
                  //         };
                  //         if (isStartFuture) {
                  //           errorDetails.status = true;
                  //           errorDetails.message += `Start time must be less than current time!`;
                  //         }
                  //         const isPast = await newStartUpdatedDate.isBefore(
                  //           newEndUpdatedDate
                  //         );
                  //         if (!isPast) {
                  //           errorDetails.status = true;
                  //           if (errorDetails.message && errorDetails.status) {
                  //             errorDetails.message +=
                  //               ",                                ";
                  //           }
                  //           errorDetails.message += `End time must be greater than start time!`;
                  //         }
                  //         if (isEndTimeFuture) {
                  //           errorDetails.status = true;
                  //           if (errorDetails.message && errorDetails.status) {
                  //             errorDetails.message += ", ";
                  //           }
                  //           errorDetails.message += `End time must be less than current time!`;
                  //         }
                  //         if (errorDetails.status) {
                  //           errorToast(errorDetails.message);
                  //           reject();
                  //           return;
                  //         }

                  //         if (
                  //           newStartUpdatedDate.isAfter(moment()) <=
                  //           newStartUpdatedDate.isBefore(newEndUpdatedDate) &&
                  //           newData?.taskDetails?.title != ""
                  //         ) {
                  //           if (newData.startTime) {
                  //             updatedStartDate.date = newStartUpdatedDate
                  //               .utc()
                  //               .format();
                  //             updatedStartDate.status = true;
                  //           }
                  //           if (newData.endTime) {
                  //             updatedEndDate.date = newEndUpdatedDate
                  //               .utc()
                  //               .format();
                  //             updatedEndDate.status = true;
                  //           }
                  //           let obj = {
                  //             ...newData,
                  //             title: newData?.taskDetails?.hasOwnProperty(
                  //               "title"
                  //             )
                  //               ? newData?.taskDetails?.title
                  //               : newData?.title,
                  //             projectId: newData?.taskDetails?.hasOwnProperty(
                  //               "projectId"
                  //             )
                  //               ? newData?.taskDetails?.projectId
                  //               : newData?.project?.id,
                  //           };
                  //           if (updatedStartDate.status) {
                  //             obj.startDate = updatedStartDate.date;
                  //             obj.projectId = newData?.taskDetails?.hasOwnProperty(
                  //               "projectId"
                  //             )
                  //               ? newData?.taskDetails?.projectId
                  //               : newData?.project?.id;
                  //           }
                  //           if (updatedEndDate.status) {
                  //             obj.endDate = updatedEndDate.date;
                  //             obj.projectId = newData?.taskDetails?.hasOwnProperty(
                  //               "projectId"
                  //             )
                  //               ? newData?.taskDetails?.projectId
                  //               : newData?.project?.id;
                  //           }
                  //           const Response = await authenticationService.updateTimeDetails(
                  //             obj,
                  //             newData.id
                  //           );
                  //           if (Response.data) {
                  //             timeEntries.map((item, index) => {
                  //               if (item.id == Response.data.id) {
                  //                 let arr = [...timeEntries];
                  //                 arr[index].startDate =
                  //                   Response.data.startDate;
                  //                 arr[index].endDate = Response.data.endDate;
                  //                 arr[index].duration = Response.data.duration;
                  //                 arr[index].title = Response.data.title;
                  //                 arr[index].project = Response.data.project;
                  //                 // setEntry(arr);
                  //                 setTimeEntries(arr);
                  //               }
                  //             });
                  //             setTimeout(() => {
                  //               publish("LateArrivalEvent");
                  //             }, 200);
                  //           }
                  //           let data = timeEntries;
                  //           data.map((item) => {
                  //             if (item.id == newData.id) {
                  //               setTimeEntriesId([...timeEntriesId, item]);
                  //               item.remark = newData.remark;
                  //               item.version = newData.version;
                  //               item.ETA = newData.ETA;
                  //             }
                  //             if (item.projectName == newData.projectName) {
                  //               item.reportingManager =
                  //                 newData.reportingManager;
                  //             }
                  //           });
                  //           setTimeEntries(data);
                  //           resolve();
                  //         } else {
                  //           errorToast(`Title can not be empty!`);
                  //           reject();
                  //         }
                  //       }
                  //     }),
                  // }}
                  options={{
                    headerStyle: {
                      backgroundColor: "#f2f6f8",
                      color: "#000",
                    },
                    draggable: false,
                    toolbar: false,
                    actionsColumnIndex: 6,
                    paging: false,
                    rowStyle: (rowData) => {
                      if (rowData.projectName === "TOTAL") {
                        return {
                          backgroundColor: "#E4EAEE",
                        };
                      }
                      return {};
                    },
                    search: false,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} style={{ marginTop: 30 }}>
                <b>Notes (Optional)</b>
                <CKEditor
                  disabled
                  editor={ClassicEditor}
                  data={extraNote}
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    onChangeExtraNote(data);
                  }}
                  onBlur={(event, editor) => { }}
                  onFocus={(event, editor) => { }}
                  config={{
                    fontSize: {
                      options: [9, 11, 13, "default", 17, 19, 21],
                    },
                    toolbar: [
                      "fontSize",
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                  }}
                />
              </GridItem>
            </GridContainer>
            <br></br>
            {/* <GridContainer align="left">
              {timeEntries.length > 0 && (
                <GridItem
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  style={{ marginTop: 30 }}
                >
                  <div style={{ display: "flex" }}>
                    <GridContainer align="left">
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {!isSaveAsLoader ? (
                            <Button
                              disabled={isSendMailLoader}
                              startIcon={<SaveIcon />}
                              style={{
                                background: "#f96117db",
                                fontWeight: "bold",
                                borderRadius: "50px",
                                marginRight: "20px",
                              }}
                              color={"info"}
                              onClick={() => {
                                onSaveAsDraft(timeEntriesId);
                              }}
                            >
                              {"Save as draft"}
                            </Button>
                          ) : (
                            <div
                              style={{
                                background: "#f96117db",
                                fontWeight: "bold",
                                borderRadius: "50px",
                                margin: "5px 20px 5px 1px",
                                width: 175,
                                height: 42,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress
                                style={{
                                  height: 25,
                                  width: 25,
                                  color: "white",
                                }}
                              />
                            </div>
                          )}

                          {!isSendMailLoader ? (
                            <Button
                              disabled={isSaveAsLoader}
                              startIcon={<SendIcon />}
                              style={{
                                background: "#f96117db",
                                fontWeight: "bold",
                                borderRadius: "50px",
                              }}
                              color={"info"}
                              onClick={() => {
                                handleOnSubmitManagement();
                              }}
                            >
                              {buttonLable}
                            </Button>
                          ) : (
                            <div
                              style={{
                                background: "#f96117db",
                                fontWeight: "bold",
                                borderRadius: "50px",
                                margin: "5px 20px 5px 1px",
                                width: 221,
                                height: 42,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress
                                style={{
                                  height: 25,
                                  width: 25,
                                  color: "white",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              )}
            </GridContainer> */}
          </CardBody>
        </Card>
      )}

      {!isLoad && !showReportTable && (
        <Card style={{ marginTop: 0, }}>
          <CardBody
            style={{
              padding: "15px 15px 15px 15px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: '63vh'
            }}
          >
            <div style={{ width: "300px", height: "350px" }}>
              <WorkHistoryTimelineSvg
                alt="WorkHistoryTimeline"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <p style={{ fontSize: 14 }}>
              <span>
                There are no time entries logged yet. Once you start working,
                you will see them listed here.
              </span>
            </p>
            <a
              style={{
                padding: "8px 10px",
                fontSize: "18px",
                color: "rgb(244, 89, 13)",
                cursor: "pointer"
              }}
              onClick={() => {
                let pickedDate = moment(viewReportDate).format("LL");
                let today = moment(new Date()).format("LL");
                pickedDate != today
                  ? history.push(
                    `/panel/dashboard?date=${moment(viewReportDate).format(
                      "DD-MMM-YYYY"
                    )}`
                  )
                  : history.push(`/panel/dashboard`);
              }}
            >
              Go to Dashboard
            </a>
          </CardBody>
        </Card>
      )}
      <ToastContainer />
    </div>
  );
}
