import React, { useEffect } from 'react'

const OnBlurHooks = (ref,setIsDropDownOpen,isDropDownOpen) => { 
  return ( 
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) { 
            if (ref.current && !ref.current.contains(event.target)) { 
              setIsDropDownOpen(false)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]) 
  )
}

export default OnBlurHooks